import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import IndexVisiteur from "../visiteurs/IndexVisiteur";
import Login from "../visiteurs/Login";
import Verify from "../visiteurs/Verify";
import Authentification from "../visiteurs/Authentification";
import Signup from "../visiteurs/Signup";
import MobileBottomBar from "../agents/MobileBottomBar";
import DesktopSidebar from "../agents/DesktopSidebar";
import IndexClient from "../clients/IndexClient";
import Accueil from "../clients/Accueil";
import IndexTransaction from "../agents/Transactions/IndexTransaction";
import AgentTransactions from "../agents/Transactions/AgentTransactions";
import Accepter from "../agents/Transactions/Accepter";
import AccepterRetrait from "../agents/Transactions/AccepterRetrait";
import DepotManuel from "../clients/depots/DepotManuel";
import DepotManuelSucces from "../clients/depots/DepotManuelSucces";
import RetraitManuel from "../clients/retraits/RetraitsManuel";
import AideIndex from "../clients/aides/AideIndex";
import Tarifs from "../clients/aides/Tarifs";
import IndexParametre from "../clients/parametre/IndexParametre";
import { InfosAppProvider } from "../contexts/InfosAppProvider";

const AgentsRoutes = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const location = useLocation();
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  
  return (
    <InfosAppProvider>
      <div className="flex h-screen bg-black m-0 p-0 overflow-hidden">
        {/* Desktop Sidebar - visible only on desktop */}
        {!isMobile && <DesktopSidebar />}
        
        {/* Main Content */}
        <div className={`flex-1 ${!isMobile ? "pl-64" : ""} overflow-auto m-0 p-0`}>
          <Routes>
            <Route path="/" element={<IndexTransaction />} />
            <Route path="/transactions" element={<AgentTransactions />} />
            <Route path="/accepter/:id/:idTran" element={<Accepter />} />
            <Route path="/Accepter-retrait/:id/:idTran" element={<AccepterRetrait />} />
            <Route path="/depot-manuel" element={<DepotManuel />} />
            <Route path="/depot-manuel-succes" element={<DepotManuelSucces />} />
            <Route path="/retrait-manuel" element={<RetraitManuel />} />
            <Route path="/tarifs" element={<Tarifs />} />
            <Route path="/setting" element={<IndexParametre />} />
            <Route path="/aide" element={<AideIndex />} />
          </Routes>
        </div>
        
        {/* Mobile Bottom Bar - visible only on mobile */}
        {isMobile && <MobileBottomBar />}
      </div>
    </InfosAppProvider>
  );
};

export default AgentsRoutes;
