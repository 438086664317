import {
  faCheck,
  faSyncAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import Lottie from "lottie-react";
import bravo from "../../animation/succes.json";
import { useParams, useNavigate } from "react-router-dom";

export default function RetraitManuelSucces() {
  const navigate = useNavigate();
  const { id } = useParams();

  const depotXbet = localStorage.getItem("depotxbet");
  const depotMontant = localStorage.getItem("depotmontant");
  const storedNumero = localStorage.getItem("depotnumero") || "";
  const storedNom = localStorage.getItem("depotnom") || "";

  const transactionDetails = [
    { label: "ID Payforbet", value: id },
    { label: "Type de Transaction", value: "Retrait" },
    { label: "Compte 1xbet", value: depotXbet },
    { label: "Numéro MoMo", value: storedNumero },
    { label: "Nom Numéro", value: storedNom },
  ];

  return (
    <div className="bg-black min-h-screen flex flex-col items-center justify-start md:justify-start px-4 py-8 md:pt-12">
      {/* Content Container - ensures everything stays centered */}
      <div className="w-full max-w-md md:max-w-lg flex flex-col items-center">
        {/* Success Animation */}
        <div className="flex justify-center items-center mb-6 md:mb-5">
          <Lottie className="w-36 md:w-32" animationData={bravo} loop={true} />
        </div>

        {/* Success Message */}
        <div className="text-center mb-8 md:mb-6 w-full">
          <h1 className="text-bleu font-albert text-2xl md:text-3xl font-bold mb-3 md:mb-2">
            Demande envoyée !
          </h1>
          <p className="text-white font-albert text-sm md:text-base mx-auto">
            Vous recevrez une notification une fois traitée. Merci ! ☺
          </p>
        </div>

        {/* Transaction Details Card */}
        <div className="bg-[#272728] rounded-xl border border-[#333333] w-full shadow-lg overflow-hidden mb-6 md:mb-6">
          {/* Card Content */}
          <div className="p-5 md:p-4">
            {transactionDetails.map((item, index) => (
              <div 
                key={index} 
                className="flex justify-between items-center py-3 md:py-2 border-b border-gray-700 last:border-b-0"
              >
                <div className="text-white font-bold text-sm md:text-base">{item.label}</div>
                <div className="text-white font-bold text-sm md:text-base">{item.value}</div>
              </div>
            ))}
            
            {/* Divider */}
            <div className="h-px bg-gray-600 my-3 md:my-2"></div>
            
            {/* Amount Row with Special Styling */}
            <div className="flex justify-between items-center py-3 md:py-2">
              <div className="text-white font-bold text-sm md:text-base">Montant</div>
              <div className="flex items-center">
                <span className="text-[#EFB034] border border-[#EFB034] rounded-lg px-3 py-1 text-xs mr-3 font-medium">
                  En cours
                </span>
                <span className="text-white font-bold text-sm md:text-base">{depotMontant} F CFA</span>
              </div>
            </div>
          </div>
        </div>

        {/* Action Button */}
        <button
          onClick={() => navigate("/clients/menu-transaction")}
          className="bg-bleu hover:bg-blue-700 text-white font-albert font-bold py-3 px-6 rounded-lg w-full transition-colors duration-300 flex items-center justify-center"
        >
          <FontAwesomeIcon icon={faSyncAlt} className="mr-2" />
          <span>Mes transactions</span>
        </button>
      </div>
    </div>
  );
}