import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faWallet,
  faMoneyBill,
  faMapMarkerAlt,
  faCity,
  faLock,
  faCheck,
  faArrowDown,
  faInfoCircle,
  faCircleCheck,
  faExclamationTriangle,
  faPhone
} from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";

export default function InfosRetrait({ onClose }) {
  const [activeStep, setActiveStep] = useState(null);

  const toggleStep = (step) => {
    if (activeStep === step) {
      setActiveStep(null);
    } else {
      setActiveStep(step);
    }
  };

  const steps = [
    {
      icon: faWallet,
      title: "Appuyez sur Retirer",
      details: "Connectez-vous à votre compte 1xBet et localisez le bouton 'Retirer' dans la section de votre solde.",
      color: "from-blue-600 to-blue-800"
    },
    {
      icon: faMoneyBill,
      title: "Choisissez le moyen de paiement",
      details: "Sélectionnez '1XBET (Liquide/Espèces)' dans la liste des méthodes de retrait disponibles.",
      color: "from-indigo-600 to-indigo-800"
    },
    {
      icon: faArrowDown,
      title: "Saisissez le montant à retirer",
      details: "Entrez le montant que vous souhaitez retirer. Le montant minimum est de 900 FCFA.",
      color: "from-purple-600 to-purple-800"
    },
    {
      icon: faCity,
      title: "Choisissez la ville",
      details: "Sélectionnez 'Cotonou' dans la liste des villes disponibles.",
      color: "from-violet-600 to-violet-800"
    },
    {
      icon: faMapMarkerAlt,
      title: "Choisissez la rue",
      details: "Sélectionnez 'PayforB Auto Gaw' comme point de retrait.",
      color: "from-fuchsia-600 to-fuchsia-800"
    },
    {
      icon: faPhone,
      title: "Confirmez avec le code",
      details: "Vous recevrez un code de confirmation par SMS. Entrez ce code et validez votre demande de retrait.",
      color: "from-pink-600 to-pink-800"
    },
    {
      icon: faLock,
      title: "Obtenez le code de retrait",
      details: "Patientez que la transaction soit approuvée, puis appuyez sur 'Obtenir le Code'. Copiez le code généré pour l'utiliser dans ce formulaire.",
      color: "from-rose-600 to-rose-800"
    }
  ];

  return (
    <div className="bg-gradient-to-b from-[#0F172A] to-[#1E293B] min-h-[60vh] rounded-t-xl overflow-hidden">
      {/* Header */}
      <div className="relative">
        <div className="p-4 flex justify-between items-center">
          <h2 className="text-white text-xl font-semibold">Procédure de retrait</h2>
          <button 
            onClick={onClose}
            className="w-8 h-8 flex items-center justify-center rounded-full bg-gray-800 hover:bg-gray-700 transition-colors"
          >
            <FontAwesomeIcon icon={faTimes} className="text-white" />
          </button>
        </div>

        <div className="absolute -top-10 right-0 w-40 h-40 bg-indigo-600 rounded-full opacity-10 blur-2xl"></div>
        <div className="absolute -top-5 -left-10 w-40 h-40 bg-purple-600 rounded-full opacity-10 blur-2xl"></div>
      </div>

      {/* Introduction */}
      <div className="px-5 py-2 mb-4">
        <div className="bg-indigo-900/20 backdrop-blur-sm rounded-xl p-3 border border-indigo-500/20 flex items-start">
          <FontAwesomeIcon icon={faInfoCircle} className="text-indigo-400 mt-0.5 mr-3 flex-shrink-0" />
          <p className="text-indigo-100 text-sm">
            Suivez ces étapes dans votre compte 1xBet pour générer un code de retrait que vous utiliserez ensuite dans ce formulaire.
          </p>
        </div>
      </div>

      {/* Steps */}
      <div className="px-4 pb-6">
        <div className="flex flex-col space-y-3">
          {steps.map((step, index) => (
            <div 
              key={index}
              className="bg-white/5 backdrop-blur-sm rounded-xl border border-white/10 overflow-hidden transition-all duration-200"
            >
              <div 
                className="flex items-center p-3 cursor-pointer"
                onClick={() => toggleStep(index)}
              >
                <div className={`w-8 h-8 rounded-full bg-gradient-to-br ${step.color} flex items-center justify-center mr-3 shadow-lg shadow-indigo-900/20`}>
                  <FontAwesomeIcon icon={step.icon} className="text-white text-sm" />
                </div>
                <div className="flex-1">
                  <p className="text-white text-sm font-medium">
                    {index + 1}. {step.title}
                  </p>
                </div>
                <FontAwesomeIcon 
                  icon={activeStep === index ? faCircleCheck : faArrowDown} 
                  className={`${activeStep === index ? 'text-green-400' : 'text-gray-400'} text-sm transition-transform duration-200 ${activeStep === index ? 'rotate-0' : '-rotate-90'}`} 
                />
              </div>
              
              {activeStep === index && (
                <div className="bg-indigo-900/20 border-t border-indigo-500/20 px-4 py-3">
                  <p className="text-gray-300 text-sm">
                    {step.details}
                  </p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Bottom note */}
      <div className="px-4 pb-8">
        <div className="mt-4 bg-amber-900/20 rounded-xl p-3 border border-amber-500/20">
          <div className="flex items-start">
            <FontAwesomeIcon icon={faExclamationTriangle} className="text-amber-400 mt-0.5 mr-3 flex-shrink-0" />
            <p className="text-amber-200 text-sm">
              Assurez-vous de copier correctement le code de retrait à 4 caractères. Ce code est nécessaire pour compléter votre demande de retrait sur cette application.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}