import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faEnvelope,
  faPhone,
  faFutbol,
  faCoins,
  faArrowRight,
  faArrowLeft,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import logo from "../ressources/images/logo.png";

export default function Signup() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState(null);
  const formRef = useRef(null);

  // Multi-step form
  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = 3;

  // Theme colors
  const theme = {
    noir: "#1C1C1CFF",
    bleu: "#0087BFFF",
    vert: "#28C281FF",
    softRed: "#ff6f61",
    softYellow: "#f9d57d",
    softPurple: "#b19cd9",
    black: "#1C1C1CFF",
    gris: "#272728FF",
  };

  // Form data
  const [formData, setFormData] = useState({
    fullName: "",
    country: "",
    phone: "",
    email: "",
    xbetId: "",
    momoNumber: "",
    momoName: "",
    refCode: ""
  });
  
  // Récupérer l'email et le code de parrainage du localStorage au chargement du composant
  useEffect(() => {
    const savedEmail = localStorage.getItem("userEmail");
    const savedRef = localStorage.getItem("ref");
    
    const updates = {};
    if (savedEmail) updates.email = savedEmail;
    if (savedRef) updates.refCode = savedRef;
    
    if (Object.keys(updates).length > 0) {
      setFormData(prev => ({
        ...prev,
        ...updates
      }));
    }
  }, []);

  // African countries list
  const africanCountries = [
    { value: "BJ", label: "Bénin", flag: "https://flagcdn.com/w20/bj.png" },
    { value: "CI", label: "Côte d'Ivoire", flag: "https://flagcdn.com/w20/ci.png" },
    { value: "SN", label: "Sénégal", flag: "https://flagcdn.com/w20/sn.png" },
    { value: "TG", label: "Togo", flag: "https://flagcdn.com/w20/tg.png" },
    { value: "NG", label: "Nigeria", flag: "https://flagcdn.com/w20/ng.png" },
    { value: "CM", label: "Cameroun", flag: "https://flagcdn.com/w20/cm.png" },
    { value: "GH", label: "Ghana", flag: "https://flagcdn.com/w20/gh.png" },
    { value: "ML", label: "Mali", flag: "https://flagcdn.com/w20/ml.png" },
    { value: "NE", label: "Niger", flag: "https://flagcdn.com/w20/ne.png" },
    { value: "BF", label: "Burkina Faso", flag: "https://flagcdn.com/w20/bf.png" }
  ];

  const [selectedCountry, setSelectedCountry] = useState(null);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Validation for form steps
  const validateStep = (step) => {
    setFormError(null);
    
    if (step === 1) {
      if (!formData.fullName || !formData.email) {
        setFormError("Veuillez remplir tous les champs requis.");
        return false;
      }
      if (!formData.country) {
        setFormError("Veuillez sélectionner votre pays.");
        return false;
      }
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
        setFormError("Veuillez entrer une adresse email valide.");
        return false;
      }
    } else if (step === 2) {
      if (!formData.phone || !formData.xbetId) {
        setFormError("Veuillez remplir tous les champs requis.");
        return false;
      }
    } else if (step === 3) {
      if (!formData.momoNumber || !formData.momoName) {
        setFormError("Veuillez remplir tous les champs requis.");
        return false;
      }
    }
    
    return true;
  };

  // Navigation functions
  const nextStep = () => {
    if (currentStep < totalSteps && validateStep(currentStep)) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
      setFormError(null);
    }
  };

  // Form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateStep(3)) return;
    
    setLoading(true);
    
    // Simulate API call
    setTimeout(() => {
      setLoading(false);
      // Show success alert and redirect to login
      alert("Inscription réussie! Vous pouvez maintenant vous connecter.");
      navigate("/login");
    }, 1500);
  };

  return (
    <div style={{ backgroundColor: theme.noir, minHeight: "100vh", padding: "20px" }}>
      {/* Logo Header */}
      <div className="text-center mb-8">
        <div style={{ 
          width: "120px", 
          height: "120px", 
          margin: "0 auto",
          backgroundColor: theme.bleu,
          borderRadius: "16px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: "0 4px 20px rgba(0, 135, 191, 0.3)"
        }}>
          <img src={logo} alt="Logo Payforbet" style={{ width: "70%", height: "auto" }} />
        </div>
        
        <h1 style={{ 
          color: "white", 
          marginTop: "20px",
          fontWeight: "bold",
          fontSize: "28px"
        }}>
          Bienvenue sur Payforbet
        </h1>
        
        <p style={{ color: "rgba(255, 255, 255, 0.7)", fontSize: "16px", marginTop: "8px" }}>
          {currentStep === 1 && "Commençons par quelques informations de base"}
          {currentStep === 2 && "Maintenant, vos informations de compte"}
          {currentStep === 3 && "Dernière étape pour finaliser votre inscription"}
        </p>
      </div>
      
      {/* Error message */}
      {formError && (
        <div style={{
          backgroundColor: "rgba(255, 111, 97, 0.1)",
          border: "1px solid rgba(255, 111, 97, 0.3)",
          color: theme.softRed,
          padding: "10px 15px",
          borderRadius: "8px",
          fontSize: "14px",
          marginBottom: "20px",
          textAlign: "center"
        }}>
          {formError}
        </div>
      )}
      
      {/* Progress bar */}
      <div style={{
        width: "80%",
        height: "8px",
        backgroundColor: theme.gris,
        borderRadius: "4px",
        margin: "0 auto 30px auto",
        overflow: "hidden"
      }}>
        <div style={{
          width: `${(currentStep / totalSteps) * 100}%`,
          height: "100%",
          background: `linear-gradient(to right, ${theme.bleu}, ${theme.vert})`,
          borderRadius: "4px",
          transition: "width 0.3s ease"
        }}></div>
      </div>
      
      {/* Step indicators */}
      <div style={{
        display: "flex",
        justifyContent: "center",
        gap: "15px",
        marginBottom: "30px"
      }}>
        {Array.from({ length: totalSteps }).map((_, index) => (
          <div key={index} style={{
            width: "35px",
            height: "35px",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "14px",
            fontWeight: "600",
            color: "white",
            backgroundColor: index + 1 === currentStep ? theme.bleu : 
                           index + 1 < currentStep ? theme.vert : theme.gris,
            transition: "all 0.3s ease",
            transform: index + 1 === currentStep ? "scale(1.1)" : "scale(1)"
          }}>
            {index + 1 < currentStep ? <FontAwesomeIcon icon={faCheck} /> : index + 1}
          </div>
        ))}
      </div>
      
      <form ref={formRef} onSubmit={handleSubmit} style={{ maxWidth: "500px", margin: "0 auto" }}>
        {/* Step 1: Personal Information */}
        {currentStep === 1 && (
          <div>
            {/* Full Name */}
            <div style={{ marginBottom: "20px" }}>
              <label style={{ 
                display: "block", 
                color: "white", 
                marginBottom: "8px",
                fontSize: "15px"
              }}>
                <FontAwesomeIcon icon={faUser} style={{ color: theme.vert, marginRight: "8px" }} />
                Votre nom complet
              </label>
              <input
                type="text"
                name="fullName"
                value={formData.fullName}
                onChange={handleInputChange}
                placeholder="Nom et prénom"
                style={{
                  width: "100%",
                  padding: "15px",
                  backgroundColor: theme.gris,
                  color: "white",
                  border: "1px solid rgba(0, 135, 191, 0.3)",
                  borderRadius: "10px",
                  fontSize: "16px",
                  outline: "none"
                }}
                required
              />
            </div>
            
            {/* Country selection */}
            <div style={{ marginBottom: "20px" }}>
              <label style={{ 
                display: "block", 
                color: "white", 
                marginBottom: "8px",
                fontSize: "15px"
              }}>
                <FontAwesomeIcon icon={faFutbol} style={{ color: theme.vert, marginRight: "8px" }} />
                Votre pays
              </label>
              
              <div style={{ 
                display: "grid", 
                gridTemplateColumns: "1fr 1fr", 
                gap: "10px", 
                marginTop: "10px" 
              }}>
                {africanCountries.map(country => (
                  <div
                    key={country.value}
                    onClick={() => {
                      setSelectedCountry(country);
                      setFormData(prev => ({ ...prev, country: country.value }));
                    }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "10px",
                      backgroundColor: selectedCountry?.value === country.value ? 
                        `linear-gradient(to right, ${theme.bleu}, ${theme.vert})` : theme.gris,
                      border: selectedCountry?.value === country.value ? 
                        `2px solid white` : `1px solid rgba(0, 135, 191, 0.3)`,
                      borderRadius: "10px",
                      cursor: "pointer"
                    }}
                  >
                    <img
                      src={country.flag}
                      alt={`drapeau ${country.label}`}
                      style={{ height: "20px", marginRight: "10px" }}
                    />
                    <span style={{ color: "white", fontSize: "14px" }}>{country.label}</span>
                    {selectedCountry?.value === country.value && (
                      <FontAwesomeIcon icon={faCheck} style={{ color: "white", marginLeft: "auto" }} />
                    )}
                  </div>
                ))}
              </div>
            </div>
            
            {/* Email */}
            <div style={{ marginBottom: "20px" }}>
              <label style={{ 
                display: "block", 
                color: "white", 
                marginBottom: "8px",
                fontSize: "15px"
              }}>
                <FontAwesomeIcon icon={faEnvelope} style={{ color: theme.vert, marginRight: "8px" }} />
                Votre Email
              </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="votre@email.com"
                style={{
                  width: "100%",
                  padding: "15px",
                  backgroundColor: theme.gris,
                  color: "white",
                  border: "1px solid rgba(0, 135, 191, 0.3)",
                  borderRadius: "10px",
                  fontSize: "16px",
                  outline: "none"
                }}
                required
              />
            </div>
          </div>
        )}
        
        {/* Step 2: Account Information */}
        {currentStep === 2 && (
          <div>
            {/* Phone Number */}
            <div style={{ marginBottom: "20px" }}>
              <label style={{ 
                display: "block", 
                color: "white", 
                marginBottom: "8px",
                fontSize: "15px"
              }}>
                <FontAwesomeIcon icon={faPhone} style={{ color: theme.vert, marginRight: "8px" }} />
                Numéro WhatsApp
              </label>
              <input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                placeholder="Votre numéro WhatsApp"
                style={{
                  width: "100%",
                  padding: "15px",
                  backgroundColor: theme.gris,
                  color: "white",
                  border: "1px solid rgba(0, 135, 191, 0.3)",
                  borderRadius: "10px",
                  fontSize: "16px",
                  outline: "none"
                }}
                required
              />
              <div style={{ fontSize: "12px", color: "rgba(255, 255, 255, 0.5)", marginTop: "5px" }}>
                Nous utiliserons ce numéro pour communiquer avec vous via WhatsApp
              </div>
            </div>
            
            {/* 1xbet ID */}
            <div style={{ marginBottom: "20px" }}>
              <label style={{ 
                display: "block", 
                color: "white", 
                marginBottom: "8px",
                fontSize: "15px"
              }}>
                <FontAwesomeIcon icon={faFutbol} style={{ color: theme.vert, marginRight: "8px" }} />
                ID compte 1xbet
              </label>
              <input
                type="number"
                name="xbetId"
                value={formData.xbetId}
                onChange={handleInputChange}
                placeholder="ID 1xbet"
                style={{
                  width: "100%",
                  padding: "15px",
                  backgroundColor: theme.gris,
                  color: "white",
                  border: "1px solid rgba(0, 135, 191, 0.3)",
                  borderRadius: "10px",
                  fontSize: "16px",
                  outline: "none"
                }}
                required
              />
              <div style={{ fontSize: "12px", color: "rgba(255, 255, 255, 0.5)", marginTop: "5px" }}>
                Votre identifiant sur la plateforme 1xbet (par exemple: 87654321)
              </div>
            </div>
          </div>
        )}
        
        {/* Step 3: Payment Information */}
        {currentStep === 3 && (
          <div>
            {/* MoMo Number */}
            <div style={{ marginBottom: "20px" }}>
              <label style={{ 
                display: "block", 
                color: "white", 
                marginBottom: "8px",
                fontSize: "15px"
              }}>
                <FontAwesomeIcon icon={faCoins} style={{ color: theme.vert, marginRight: "8px" }} />
                Numéro MoMo paiement
              </label>
              <input
                type="number"
                name="momoNumber"
                value={formData.momoNumber}
                onChange={handleInputChange}
                placeholder="229...."
                style={{
                  width: "100%",
                  padding: "15px",
                  backgroundColor: theme.gris,
                  color: "white",
                  border: "1px solid rgba(0, 135, 191, 0.3)",
                  borderRadius: "10px",
                  fontSize: "16px",
                  outline: "none"
                }}
                required
              />
              <div style={{ fontSize: "12px", color: "rgba(255, 255, 255, 0.5)", marginTop: "5px" }}>
                Numéro MoMo qui sera utilisé pour vos paiements
              </div>
            </div>
            
            {/* MoMo Owner Name */}
            <div style={{ marginBottom: "20px" }}>
              <label style={{ 
                display: "block", 
                color: "white", 
                marginBottom: "8px",
                fontSize: "15px"
              }}>
                <FontAwesomeIcon icon={faUser} style={{ color: theme.vert, marginRight: "8px" }} />
                Nom du propriétaire MoMo
              </label>
              <input
                type="text"
                name="momoName"
                value={formData.momoName}
                onChange={handleInputChange}
                placeholder="Nom complet du propriétaire"
                style={{
                  width: "100%",
                  padding: "15px",
                  backgroundColor: theme.gris,
                  color: "white",
                  border: "1px solid rgba(0, 135, 191, 0.3)",
                  borderRadius: "10px",
                  fontSize: "16px",
                  outline: "none"
                }}
                required
              />
              <div style={{ fontSize: "12px", color: "rgba(255, 255, 255, 0.5)", marginTop: "5px" }}>
                Le nom du propriétaire enregistré sur le compte Mobile Money
              </div>
            </div>
            
            {/* Referral Code */}
            <div style={{ marginBottom: "20px" }}>
              <label style={{ 
                display: "block", 
                color: "white", 
                marginBottom: "8px",
                fontSize: "15px",
                opacity: "0.8"
              }}>
                <FontAwesomeIcon icon={faUser} style={{ color: theme.vert, marginRight: "8px", opacity: "0.8" }} />
                Code de parrainage (optionnel)
              </label>
              <input
                type="text"
                name="refCode"
                value={formData.refCode}
                onChange={handleInputChange}
                placeholder="Code de parrainage (si vous en avez un)"
                style={{
                  width: "100%",
                  padding: "15px",
                  backgroundColor: theme.gris,
                  color: "white",
                  border: "1px solid rgba(0, 135, 191, 0.3)",
                  borderRadius: "10px",
                  fontSize: "16px",
                  outline: "none",
                  opacity: "0.8"
                }}
              />
              <div style={{ fontSize: "12px", color: "rgba(255, 255, 255, 0.5)", marginTop: "5px" }}>
                Si quelqu'un vous a invité à rejoindre Payforbet, entrez son code ici
              </div>
            </div>
          </div>
        )}
        
        {/* Navigation Buttons */}
        <div style={{ 
          display: "flex", 
          justifyContent: "space-between", 
          marginTop: "30px",
          marginBottom: "30px"
        }}>
          {currentStep > 1 ? (
            <button
              type="button"
              onClick={prevStep}
              style={{
                backgroundColor: theme.gris,
                color: "white",
                border: "none",
                padding: "12px 20px",
                borderRadius: "10px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                gap: "8px"
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
              Précédent
            </button>
          ) : (
            <div></div>
          )}
          
          {currentStep < totalSteps ? (
            <button
              type="button"
              onClick={nextStep}
              style={{
                background: `linear-gradient(to right, ${theme.bleu}, ${theme.vert})`,
                color: "white",
                border: "none",
                padding: "12px 25px",
                borderRadius: "10px",
                cursor: "pointer",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                gap: "8px"
              }}
            >
              Suivant
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
          ) : (
            <button
              type="submit"
              style={{
                background: `linear-gradient(to right, ${theme.bleu}, ${theme.vert})`,
                color: "white",
                border: "none",
                padding: "12px 25px",
                borderRadius: "10px",
                cursor: "pointer",
                fontWeight: "bold",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px"
              }}
            >
              {loading ? "Inscription en cours..." : "Terminer l'inscription"}
            </button>
          )}
        </div>
        
        {/* Terms & Conditions */}
        <div style={{ 
          textAlign: "center", 
          color: "rgba(255, 255, 255, 0.6)", 
          fontSize: "13px",
          marginTop: "20px"
        }}>
          En vous inscrivant, vous acceptez nos <span style={{ color: theme.vert, cursor: "pointer" }}>conditions d'utilisation</span> et notre <span style={{ color: theme.vert, cursor: "pointer" }}>politique de confidentialité</span>
        </div>

        {/* Login Link */}
        <div style={{ 
          textAlign: "center", 
          marginTop: "30px",
          color: "white",
          fontSize: "14px"
        }}>
          Vous avez déjà un compte? <Link to="/login" style={{ color: theme.bleu, textDecoration: "none" }}>Se connecter</Link>
        </div>
      </form>
    </div>
  );
}