import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { FiEye, FiSearch, FiRefreshCw, FiFilter, FiDownload, FiX } from "react-icons/fi";
import { FaChevronLeft, FaChevronRight, FaMoneyBillWave, FaCalendarAlt, FaUsers, FaListAlt, FaCheckCircle, FaExclamationTriangle, FaCheckDouble } from "react-icons/fa";
import { updateRetraitAffiliation } from "./updateRetraitAffiliation";
import InfosContext from "../../contexts/InfosAppProvider";

const RetraitListe = () => {
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [retraitsPerPage, setRetraitsPerPage] = useState(10);
  const [filter, setFilter] = useState('tous'); // 'tous', 'terminé', 'en cours'
  const [retraits, setRetraits] = useState([]);
  const [notification, setNotification] = useState(null);

  const { allRetraitAffiliations, token } = useContext(InfosContext);
  
  // Fonction pour afficher une notification
  const showNotification = (type, message) => {
    setNotification({ type, message });
    // Fermer automatiquement après 5 secondes
    setTimeout(() => setNotification(null), 5000);
  };

  // État pour les statistiques
  const [stats, setStats] = useState({
    totalRetraits: 0,
    totalMontant: 0,
    retraitsTermines: 0,
    retraitsEnCours: 0
  });

  // Fonction pour gérer la recherche
  const inputHandler = (event) => setSearchInput(event.target.value);

  // Fonction pour formater les montants
  const formatMontant = (montant) => {
    return new Intl.NumberFormat('fr-FR').format(montant);
  };

  // Convertir l'objet allRetraitAffiliations en tableau
  const retraitsArray = allRetraitAffiliations ? Object.values(allRetraitAffiliations) : [];
  
  // Mettre à jour l'état local des retraits
  useEffect(() => {
    if (allRetraitAffiliations) {
      setRetraits(Object.values(allRetraitAffiliations));
    }
  }, [allRetraitAffiliations]);

  // Calcul des statistiques
  useEffect(() => {
    const termines = retraitsArray.filter(retrait => retrait.statut === "terminé");
    const enCours = retraitsArray.filter(retrait => retrait.statut !== "terminé");
    const totalMontant = retraitsArray.reduce((sum, retrait) => sum + (parseFloat(retrait.montant) || 0), 0);
    
    setStats({
      totalRetraits: retraitsArray.length,
      totalMontant: totalMontant,
      retraitsTermines: termines.length,
      retraitsEnCours: enCours.length
    });
  }, [allRetraitAffiliations]);

  // Filtrer les retraits selon la recherche et le filtre
  const filteredRetraits = retraits.filter(retrait => {
    // Filtre par statut
    if (filter === 'terminé' && retrait.statut !== "terminé") return false;
    if (filter === 'en cours' && retrait.statut !== "terminé" && retrait.statut !== "rejeté") return false;
    if (filter === 'rejeté' && retrait.statut !== "rejeté") return false;
    
    // Filtre par recherche
    return (
      retrait.mode?.toLowerCase().includes(searchInput.toLowerCase()) ||
      retrait.statut?.toLowerCase().includes(searchInput.toLowerCase()) ||
      retrait.numeroRetrait?.toString().includes(searchInput) ||
      retrait.idUtilisateur?.toString().includes(searchInput)
    );
  });

  // Pagination
  const indexOfLastRetrait = currentPage * retraitsPerPage;
  const indexOfFirstRetrait = indexOfLastRetrait - retraitsPerPage;
  const currentRetraits = filteredRetraits.slice(indexOfFirstRetrait, indexOfLastRetrait);
  const totalRetraits = filteredRetraits.length;

  // Navigation de pagination
  const paginateNext = () => setCurrentPage((prev) => prev + 1);
  const paginatePrevious = () => setCurrentPage((prev) => Math.max(prev - 1, 1));

  // Réinitialiser les filtres
  const resetFilters = () => {
    setFilter('tous');
    setSearchInput('');
    setCurrentPage(1);
  };

  // Générer un rapport Excel (simulé)
  const handleExportExcel = () => {
    alert('Fonctionnalité d\'export Excel à implémenter');
  };

  return (
    <div className="w-full relative">
      {/* Notification */}
      {notification && (
        <div className={`fixed top-4 right-4 z-50 p-4 rounded-lg shadow-lg flex items-center justify-between ${
          notification.type === 'success' ? 'bg-green-500' : 
          notification.type === 'error' ? 'bg-red-500' : 
          notification.type === 'warning' ? 'bg-yellow-500' : 'bg-blue-500'
        } text-white max-w-md animate-fade-in-down`}>
          <div className="flex items-center">
            {notification.type === 'success' && <FaCheckDouble className="mr-3 text-xl" />}
            {notification.type === 'error' && <FaExclamationTriangle className="mr-3 text-xl" />}
            {notification.type === 'warning' && <FaExclamationTriangle className="mr-3 text-xl" />}
            <p>{notification.message}</p>
          </div>
          <button 
            onClick={() => setNotification(null)}
            className="ml-4 p-1 rounded-full hover:bg-white hover:bg-opacity-20 transition-colors"
          >
            <FiX className="text-white" />
          </button>
        </div>
      )}
      
      {/* En-tête de la page */}
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-white mb-2">Retraits par affiliation</h1>
        <p className="text-gray-400">Gérez et consultez les retraits d'affiliation effectués sur la plateforme</p>
      </div>

      {/* Cartes de statistiques */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-8">
        <div className="bg-gris rounded-xl p-5 shadow-lg flex items-center justify-between">
          <div>
            <p className="text-gray-400 text-sm">Total retraits</p>
            <h3 className="text-white text-2xl font-bold mt-1">{stats.totalRetraits}</h3>
          </div>
          <div className="bg-bleu bg-opacity-20 p-3 rounded-full">
            <FaListAlt className="text-bleu text-xl" />
          </div>
        </div>

        <div className="bg-gris rounded-xl p-5 shadow-lg flex items-center justify-between">
          <div>
            <p className="text-gray-400 text-sm">Montant total</p>
            <h3 className="text-white text-2xl font-bold mt-1">
              {formatMontant(stats.totalMontant)} F
            </h3>
          </div>
          <div className="bg-vert bg-opacity-20 p-3 rounded-full">
            <FaMoneyBillWave className="text-vert text-xl" />
          </div>
        </div>

        <div className="bg-gris rounded-xl p-5 shadow-lg flex items-center justify-between">
          <div>
            <p className="text-gray-400 text-sm">Terminés</p>
            <h3 className="text-white text-2xl font-bold mt-1">
              {stats.retraitsTermines}
            </h3>
          </div>
          <div className="bg-softPurple bg-opacity-20 p-3 rounded-full">
            <FaCheckCircle className="text-softPurple text-xl" />
          </div>
        </div>

        <div className="bg-gris rounded-xl p-5 shadow-lg flex items-center justify-between">
          <div>
            <p className="text-gray-400 text-sm">En cours</p>
            <h3 className="text-white text-2xl font-bold mt-1">
              {stats.retraitsEnCours}
            </h3>
          </div>
          <div className="bg-softYellow bg-opacity-20 p-3 rounded-full">
            <FaCalendarAlt className="text-softYellow text-xl" />
          </div>
        </div>
      </div>

      {/* Barre de recherche et filtres */}
      <div className="flex flex-col md:flex-row gap-4 justify-between mb-6">
        <div className="flex-1">
          <div className="relative">
            <input
              type="text"
              placeholder="Rechercher un retrait..."
              value={searchInput}
              onChange={inputHandler}
              className="w-full py-2 pl-10 pr-4 bg-gris border border-gray-700 rounded-lg text-white text-sm focus:outline-none focus:border-bleu"
            />
            <div className="absolute left-3 top-2.5 text-gray-400">
              <FiSearch />
            </div>
          </div>
        </div>
        
        <div className="flex gap-2">
          <button 
            className={`px-4 py-2 rounded-lg text-sm ${filter === 'tous' ? 'bg-bleu text-white' : 'bg-gris text-gray-300 hover:bg-noir'}`}
            onClick={() => { setFilter('tous'); setCurrentPage(1); }}
          >
            Tous
          </button>
          <button 
            className={`px-4 py-2 rounded-lg text-sm ${filter === 'terminé' ? 'bg-green-500 text-white' : 'bg-gris text-gray-300 hover:bg-noir'}`}
            onClick={() => { setFilter('terminé'); setCurrentPage(1); }}
          >
            Terminés
          </button>
          <button 
            className={`px-4 py-2 rounded-lg text-sm ${filter === 'en cours' ? 'bg-yellow-500 text-white' : 'bg-gris text-gray-300 hover:bg-noir'}`}
            onClick={() => { setFilter('en cours'); setCurrentPage(1); }}
          >
            En attente
          </button>
          <button 
            className={`px-4 py-2 rounded-lg text-sm ${filter === 'rejeté' ? 'bg-red-500 text-white' : 'bg-gris text-gray-300 hover:bg-noir'}`}
            onClick={() => { setFilter('rejeté'); setCurrentPage(1); }}
          >
            Rejetés
          </button>
          
          <button
            onClick={resetFilters}
            className="p-2 bg-gris rounded-lg text-gray-400 hover:text-bleu transition-colors"
            title="Réinitialiser les filtres"
          >
            <FiRefreshCw />
          </button>
          
          <button
            onClick={handleExportExcel}
            className="flex items-center gap-2 bg-softPurple hover:bg-purple-600 text-white py-2 px-4 rounded-lg transition-colors"
          >
            <FiDownload /> Exporter
          </button>
        </div>
      </div>

      {/* Pagination et sélection du nombre par page */}
      <div className="flex items-center justify-between mb-4 bg-gris p-3 rounded-lg">
        <div className="flex items-center gap-2">
          <span className="text-gray-400 text-sm">Afficher</span>
          <select
            value={retraitsPerPage}
            onChange={(e) => {
              setRetraitsPerPage(Number(e.target.value));
              setCurrentPage(1);
            }}
            className="bg-noir border border-gray-700 rounded text-white text-sm py-1 px-2 focus:outline-none focus:border-bleu"
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
          </select>
          <span className="text-gray-400 text-sm">par page</span>
        </div>
        
        <div className="flex items-center">
          <span className="text-gray-400 text-sm mr-4">
            {totalRetraits === 0 ? '0' : indexOfFirstRetrait + 1} - {Math.min(indexOfLastRetrait, totalRetraits)} sur {totalRetraits}
          </span>
          <div className="flex gap-2">
            <button
              onClick={paginatePrevious}
              disabled={currentPage === 1}
              className={`p-2 rounded-lg ${currentPage === 1 ? 'text-gray-600 cursor-not-allowed' : 'text-gray-400 hover:bg-noir hover:text-white'}`}
            >
              <FaChevronLeft size={14} />
            </button>
            <button
              onClick={paginateNext}
              disabled={indexOfLastRetrait >= totalRetraits}
              className={`p-2 rounded-lg ${indexOfLastRetrait >= totalRetraits ? 'text-gray-600 cursor-not-allowed' : 'text-gray-400 hover:bg-noir hover:text-white'}`}
            >
              <FaChevronRight size={14} />
            </button>
          </div>
        </div>
      </div>
      
      {/* Tableau des retraits */}
      <div className="bg-gris rounded-xl shadow-lg overflow-hidden">
        {totalRetraits === 0 ? (
          <div className="p-8 text-center">
            <FiFilter className="text-gray-500 text-4xl mx-auto mb-4" />
            <p className="text-gray-400 text-lg">Aucun retrait trouvé avec les critères de recherche actuels.</p>
            <button 
              onClick={resetFilters}
              className="mt-4 px-4 py-2 bg-bleu rounded-lg text-white text-sm hover:bg-blue-600 transition-colors inline-flex items-center gap-2"
            >
              <FiRefreshCw size={14} /> Réinitialiser les filtres
            </button>
          </div>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-700">
              <thead className="bg-noir">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">#</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Date</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Montant</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">N° Retrait</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">ID Utilisateur</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Mode</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Statut</th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-700">
                {currentRetraits.map((retrait, index) => (
                  <tr key={retrait.id || index} className="hover:bg-noir transition-colors">
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                      {indexOfFirstRetrait + index + 1}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                      {new Date(retrait.date).toLocaleDateString()}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-vert">
                      {formatMontant(retrait.montant)} F
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                      {retrait.numeroRetrait || '-'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                      {retrait.idUtilisateur || '-'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                      {retrait.mode || '-'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      <span className={`px-2 py-1 rounded-full text-xs font-bold ${
                        retrait.statut === "terminé"
                          ? 'bg-green-100 text-green-600'
                          : retrait.statut === "rejeté"
                          ? 'bg-red-100 text-red-600'
                          : 'bg-yellow-100 text-yellow-600'
                      }`}>
                        {retrait.statut === "terminé" ? 'Terminé' : 
                         retrait.statut === "rejeté" ? 'Rejeté' : 
                         retrait.statut || 'En attente'}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      <div className="flex items-center justify-center">
                        <div className="flex space-x-2">
                          <Link
                            to={`/admin/retrait-detail/${retrait.idUtilisateur}`}
                            className="p-2 bg-bleu bg-opacity-10 hover:bg-opacity-20 text-bleu rounded-lg transition-colors"
                            title="Voir les détails"
                          >
                            <FiEye size={18} />
                          </Link>
                          {retrait.statut !== "terminé" && retrait.statut !== "rejeté" && (
                            <span 
                              className="p-2 bg-green-500 hover:bg-green-600 text-white rounded-lg cursor-pointer transition-colors" 
                              title="Approuver"
                              onClick={() => {
                                // Afficher un état de chargement
                                showNotification('warning', "Approbation en cours...");
                                
                                console.log("Début de l'approbation, Détails:", {
                                  id: retrait.id, 
                                  numeroRetrait: retrait.numeroRetrait,
                                  idUtilisateur: retrait.idUtilisateur,
                                  statut: retrait.statut
                                });
                                
                                // Pour retrouver le retrait, il faut utiliser l'ID du retrait lui-même
                                // Le code debugge et affiche tous les champs pour voir ce qui est disponible
                                console.log("Tous les champs du retrait:", retrait);
                                
                                // Utiliser l'ID du retrait (pas l'idUtilisateur, ni le numeroRetrait)
                                const idAUtiliser = retrait.id;
                                console.log("ID du retrait envoyé à l'API:", idAUtiliser);
                                
                                updateRetraitAffiliation(idAUtiliser, "terminé", token)
                                  .then((response) => {
                                    console.log("Réponse reçue:", response);
                                    
                                    if (response && response.error) {
                                      // Si l'API renvoie une erreur
                                      showNotification('error', response.message || "Erreur lors de l'approbation du retrait.");
                                    } else {
                                      // Mettre à jour l'état local
                                      const updatedRetraits = retraits.map(r => 
                                        r.id === retrait.id ? {...r, statut: "terminé"} : r
                                      );
                                      setRetraits(updatedRetraits);
                                      showNotification('success', "Retrait approuvé avec succès!");
                                    }
                                  })
                                  .catch(err => {
                                    console.error("Erreur catch lors de l'approbation:", err);
                                    showNotification('error', "Erreur lors de la communication avec le serveur. Veuillez réessayer.");
                                  });
                              }}
                            >
                              <FaCheckCircle size={18} />
                            </span>
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      
      {/* Pagination mobile (seulement visible sur mobile) */}
      <div className="md:hidden flex justify-center mt-4">
        <div className="flex items-center gap-2 bg-gris p-2 rounded-lg">
          <button
            onClick={paginatePrevious}
            disabled={currentPage === 1}
            className={`p-2 rounded-lg ${currentPage === 1 ? 'text-gray-600 cursor-not-allowed' : 'text-gray-400 hover:bg-noir hover:text-white'}`}
          >
            <FaChevronLeft size={14} />
          </button>
          <span className="text-white">
            Page {currentPage}
          </span>
          <button
            onClick={paginateNext}
            disabled={indexOfLastRetrait >= totalRetraits}
            className={`p-2 rounded-lg ${indexOfLastRetrait >= totalRetraits ? 'text-gray-600 cursor-not-allowed' : 'text-gray-400 hover:bg-noir hover:text-white'}`}
          >
            <FaChevronRight size={14} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default RetraitListe;
