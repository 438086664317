import React, { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import InfosContext from "../contexts/InfosAppProvider";

const TransactionCard = ({
  icon,
  title,
  date,
  amount,
  id,
  bgColor,
  idTran,
  status = "completed" // Statut par défaut
}) => {
  const navigate = useNavigate();
  const { userInfos } = useContext(InfosContext);
  const [isNavigating, setIsNavigating] = useState(false);
  
  const handleTransactionClick = () => {
    // Éviter les clics multiples
    if (isNavigating) return;
    setIsNavigating(true);
    
    // Log toutes les données disponibles pour le débogage
    console.log("Données complètes de la transaction:", { 
      id, idTran, title, amount, status, userInfos 
    });
    
    // Vérifier si nous avons un ID de transaction valide
    if (!idTran) {
      console.error("ID de transaction manquant");
      setIsNavigating(false);
      return;
    }
    
    // Pour l'ID utilisateur, nous utilisons en priorité celui de l'utilisateur connecté
    let userId = "59"; // ID par défaut en cas de problème
    
    if (userInfos && userInfos.idUser) {
      // Utilisation de l'ID de l'utilisateur actuel
      userId = userInfos.idUser;
    }
    
    // Encoder les paramètres pour éviter tout problème d'URL
    const encodedUserId = encodeURIComponent(userId);
    const encodedTransactionId = encodeURIComponent(idTran);
    
    // Construire le chemin avec les paramètres correctement encodés
    // Ajout du préfixe "/clients" pour respecter la structure des routes imbriquées
    const path = `/clients/infos-transaction/${encodedUserId}/${encodedTransactionId}`;
    console.log(`Navigation vers: ${path}`);
    
    // Ajouter un légère temporisation pour éviter les problèmes de navigation
    setTimeout(() => {
      try {
        navigate(path);
      } catch (error) {
        console.error("Erreur de navigation:", error);
      } finally {
        // Rétablir l'état après un délai même en cas d'erreur
        setTimeout(() => setIsNavigating(false), 300);
      }
    }, 50);
  };

  // Détermine la couleur de fond en fonction du statut et du type
  const getStatusColor = () => {
    if (status === "en cours") return "bg-[#f9d57d]";
    if (title.includes("Dépôt")) return "bg-[#28C281FF]";
    if (title.includes("Retrait")) return "bg-[#ff6f61]";
    return bgColor || "bg-[#0087BFFF]"; // Couleur par défaut si bgColor n'est pas défini
  };
  
  // Formate la date pour un affichage plus convivial
  const formatDate = (dateString) => {
    if (!dateString) return "";
    if (typeof dateString === "string" && dateString.includes("/")) return dateString;
    
    try {
      const parsedDate = new Date(dateString);
      if (isNaN(parsedDate.getTime())) return dateString; // Date invalide
      
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);
      
      if (parsedDate.toDateString() === today.toDateString()) {
        return `Aujourd'hui à ${parsedDate.getHours().toString().padStart(2, '0')}:${parsedDate.getMinutes().toString().padStart(2, '0')}`;
      } else if (parsedDate.toDateString() === yesterday.toDateString()) {
        return `Hier à ${parsedDate.getHours().toString().padStart(2, '0')}:${parsedDate.getMinutes().toString().padStart(2, '0')}`;
      } else {
        // Format complet pour les autres dates
        return parsedDate.toLocaleString('fr-FR', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        });
      }
    } catch (e) {
      console.error("Erreur de formatage de date:", e);
      return dateString;
    }
  };
  
  // Fonction pour obtenir la classe de statut appropriée
  const getStatusClass = () => {
    if (status === "en cours") 
      return "bg-[#f9d57d]/20 text-[#f9d57d]";
    if (status === "annulé" || status === "échoué" || status === "echoué") 
      return "bg-[#ff6f61]/20 text-[#ff6f61]";
    return "bg-[#28C281FF]/20 text-[#28C281FF]";
  };
  
  // Fonction pour afficher le libellé de statut approprié
  const getStatusLabel = () => {
    if (status === "en cours") return "En attente";
    if (status === "annulé" || status === "échoué" || status === "echoué") return "Annulé";
    if (status === "terminé" || status === "réussi" || status === "valide") return "Complété";
    return status.charAt(0).toUpperCase() + status.slice(1); // Première lettre en majuscule
  };
  
  // Obtenir la couleur du montant selon le type de transaction
  const getAmountColor = () => {
    return title.includes("Dépôt") ? "text-[#28C281FF]" : "text-[#ff6f61]";
  };

  return (
    <div
      onClick={handleTransactionClick}
      className={`mx-6 md:mx-auto md:max-w-xl bg-[#1C1C1CFF] rounded-xl shadow-lg overflow-hidden mt-4 transform transition-all duration-300 hover:scale-[1.02] hover:shadow-xl cursor-pointer border-l-4 border-[#0087BFFF] ${isNavigating ? 'pointer-events-none opacity-75' : ''}`}
    >
      <div className="p-4 flex items-center">
        {/* Icône avec fond */}
        <div
          className={`w-14 h-14 ${getStatusColor()} rounded-xl text-white flex justify-center items-center shadow-md mr-3 flex-shrink-0`}
        >
          <FontAwesomeIcon icon={icon} size="lg" />
        </div>
        
        {/* Informations */}
        <div className="flex-grow">
          <div className="flex justify-between items-start">
            <div>
              <h3 className="text-white font-bold text-lg">{title}</h3>
              <p className="text-gray-400 text-xs">{formatDate(date)}</p>
            </div>
            
            <div className="text-right">
              <p className={`font-bold text-lg ${getAmountColor()}`}>
                {amount}
              </p>
              <p className="text-gray-400 text-xs" title={idTran}>{id}</p>
            </div>
          </div>
          
          {/* Indicateur de statut */}
          <div className="mt-2 flex justify-between items-center">
            <div className={`text-xs px-2 py-1 rounded-full ${getStatusClass()}`}>
              {getStatusLabel()}
            </div>
            
            <div className="text-[#0087BFFF] text-xs font-medium flex items-center gap-1 group">
              <span>Voir détails</span>
              <span className="group-hover:translate-x-1 transition-transform duration-300">→</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionCard;
