import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faFilter, faTrash, faCheck } from "@fortawesome/free-solid-svg-icons";
import "./style.css";
import React, { useState, useContext, useEffect } from "react";
import InfosContext from "../../contexts/InfosAppProvider";

export default function Filtres({ onClose }) {
  const { selectedStatuses, removeStatus, addStatus, setOpen } = useContext(InfosContext);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  // État pour les cases à cocher
  const [checkedStatuses, setCheckedStatuses] = useState({
    réussi: selectedStatuses.includes("réussi"),
    "en cours": selectedStatuses.includes("en cours"),
    échoué: selectedStatuses.includes("échoué"),
  });
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Fonction pour gérer les changements d'état des cases à cocher
  const handleCheckboxChange = (status) => {
    setCheckedStatuses((prev) => {
      const newCheckedStatus = !prev[status]; // Inverser la valeur actuelle
      return { ...prev, [status]: newCheckedStatus }; // Mettre à jour l'état
    });
  };
  
  // Fonction pour effacer tous les filtres
  const handleClearAll = () => {
    setCheckedStatuses({
      réussi: false,
      "en cours": false,
      échoué: false,
    });
  };
  
  // Fonction pour appliquer les filtres
  const handleApplyFilters = () => {
    // Met à jour les statuts en fonction de leur état
    Object.keys(checkedStatuses).forEach((status) => {
      if (checkedStatuses[status]) {
        addStatus(status); // Ajoute le statut si coché
      } else {
        removeStatus(status); // Retire le statut si décoché
      }
    });
    setOpen(false);
    if (onClose) onClose();
  };

  return (
    <div className={`${isMobile ? "bg-[#414151FF]" : "bg-noir"} rounded-lg ${isMobile ? "pb-8" : ""}`}>
      {/* Header */}
      <div className="flex flex-row p-5 items-center border-b border-gris">
        <div className="flex items-center text-white font-bold">
          <FontAwesomeIcon icon={faFilter} className="mr-2 text-bleu" />
          <span>Filtrer les transactions</span>
        </div>

        <div className="flex flex-grow justify-end text-white">
          {isMobile && (
            <div
              onClick={onClose}
              className="flex items-center justify-center h-7 w-7 rounded-full bg-gris hover:bg-red-600 transition-colors cursor-pointer"
            >
              <FontAwesomeIcon icon={faTimes} className="text-white" />
            </div>
          )}
        </div>
      </div>

      {/* Content */}
      <div className="p-5">
        <div className="flex flex-col text-white">
          <div className="mb-4 font-medium text-gray-300">Statut de transaction</div>

          <div className="space-y-4">
            {["réussi", "en cours", "échoué"].map((status) => (
              <div 
                key={status} 
                className={`flex items-center justify-between p-3 rounded-lg transition-all duration-300 ${
                  checkedStatuses[status] 
                    ? `${
                      status === "réussi" 
                        ? "bg-vert/10 border border-vert" 
                        : status === "en cours" 
                          ? "bg-bleu/10 border border-bleu" 
                          : "bg-red-600/10 border border-red-600"
                      }`
                    : "bg-gris border border-transparent"
                }`}
              >
                <div className="flex items-center">
                  <div className={`w-3 h-3 rounded-full mr-3 ${
                    status === "réussi" ? "bg-vert" : 
                    status === "en cours" ? "bg-bleu" : "bg-red-600"
                  }`}></div>
                  <div className={`font-medium ${
                    checkedStatuses[status] 
                      ? status === "réussi" 
                        ? "text-vert" 
                        : status === "en cours" 
                          ? "text-bleu" 
                          : "text-red-600" 
                      : "text-white"
                  }`}>
                    {status.charAt(0).toUpperCase() + status.slice(1)}
                  </div>
                </div>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={checkedStatuses[status]}
                    onChange={() => handleCheckboxChange(status)}
                    className="sr-only peer"
                  />
                  <div className={`w-11 h-6 peer-focus:outline-none rounded-full 
                  ${
                    checkedStatuses[status] 
                      ? status === "réussi" 
                        ? "bg-vert" 
                        : status === "en cours" 
                          ? "bg-bleu" 
                          : "bg-red-600"
                      : "bg-gray-700"
                  } 
                  after:content-[''] after:absolute after:top-[2px] after:left-[2px] 
                  after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 
                  after:transition-all ${checkedStatuses[status] ? "after:translate-x-5" : ""}`}></div>
                </label>
              </div>
            ))}
          </div>
        </div>

        {/* Buttons */}
        <div className="flex mt-8 justify-center items-center space-x-4">
          <button 
            onClick={handleClearAll}
            className="flex items-center justify-center text-white border border-gris rounded-lg px-4 py-3 w-1/2 hover:bg-gris transition-all duration-300 hover:shadow-md"
          >
            <FontAwesomeIcon icon={faTrash} className="mr-2 text-gray-400" />
            <span className="font-medium">Tout effacer</span>
          </button>

          <button
            onClick={handleApplyFilters}
            className="flex items-center justify-center text-white border border-bleu rounded-lg px-4 py-3 w-1/2 bg-bleu hover:bg-bleu/90 transition-all duration-300 shadow-sm hover:shadow-md shadow-bleu/20"
          >
            <FontAwesomeIcon icon={faCheck} className="mr-2" />
            <span className="font-medium">Appliquer</span>
          </button>
        </div>
      </div>
    </div>
  );
}
