import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const TransactionCardH = ({
  icon,
  title,
  date,
  amount,
  id,
  bgColor,
  type,
  idTran,
  status = "réussi" // Supposé être "réussi" car c'est un historique
}) => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  
  // Déterminer l'icône et la couleur de statut
  let statusIcon = faClock;
  let statusColor = "text-yellow-500";
  let bgStatusColor = "bg-yellow-500";
  
  if (status === "accepté" || status === "accepter" || status === "réussi") {
    statusIcon = faCheckCircle;
    statusColor = "text-vert";
    bgStatusColor = "bg-vert";
  } else if (status === "refusé" || status === "refuser" || status === "échoué") {
    statusIcon = faTimesCircle;
    statusColor = "text-red-500";
    bgStatusColor = "bg-red-500";
  }
  
  // Tronquer l'ID pour mobile
  const displayId = isMobile && id && id.length > 8 ? id.substring(0, 8) + "..." : id;
  
  // Formater le montant pour s'assurer qu'il tient sur mobile
  const displayAmount = isMobile ? amount.replace('F CFA', '') : amount;
  
  // Couleur de bordure basée sur le type (dépôt ou retrait)
  const borderColor = type === "depot" ? "border-vert" : "border-[#AA1D22FF]";
  
  // Classe pour l'icône (couleur du texte)
  const iconColor = type === "depot" ? "text-vert" : "text-[#AA1D22FF]";
  
  return (
    <div className="bg-gris hover:bg-[#2a2a2b] transition-all duration-300 flex flex-row rounded-xl border border-[#333333] opacity-100 mb-3 shadow-sm hover:shadow-md overflow-hidden mx-1">
      {/* Indicateur coloré à gauche basé sur le type */}
      <div className={`w-1 ${type === "depot" ? "bg-vert" : "bg-[#AA1D22FF]"}`}></div>
      
      <div className="flex flex-1 items-center p-3 md:p-4">
        {/* Icône de gauche avec arrière-plan indiquant l'état */}
        <div
          className={`w-10 h-10 md:w-12 md:h-12 ${bgStatusColor} bg-opacity-20 rounded-full flex justify-center items-center mr-2 md:mr-4 flex-shrink-0 border-2 ${borderColor}`}
        >
          <FontAwesomeIcon icon={icon} className={`text-base md:text-lg ${iconColor}`} />
        </div>

        {/* Infos principales */}
        <div className="flex-1 min-w-0">
          <div className="flex items-center flex-wrap">
            <h3 className="text-white font-medium text-sm md:text-base mr-2 truncate">{title}</h3>
            <span className={`${statusColor} text-xs flex items-center whitespace-nowrap`}>
              <FontAwesomeIcon icon={statusIcon} className="mr-1" />
              {isMobile ? "" : (status || "Réussi")}
            </span>
          </div>
          <div className="text-gray-400 text-xs mt-1 truncate">{id}</div>
          <div className="text-gray-400 text-xs truncate">{isMobile ? date.split(' à')[0] : date}</div>
        </div>

        {/* Montant à droite */}
        <div className="text-right flex-shrink-0 ml-2">
          <div className={`text-base md:text-lg font-bold ${type === "depot" ? "text-vert" : "text-[#AA1D22FF]"} whitespace-nowrap`}>
            {displayAmount}
          </div>
          <div className="flex items-center justify-end mt-1 text-white text-xs md:text-sm">
            <span className="mr-1 bg-gris/70 px-2 py-0.5 rounded-full text-xs">Historique</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionCardH;
