import React, { useState, useEffect, useContext } from 'react';
import { FiSave, FiPlus, FiTrash2, FiEdit3, FiSend, FiSearch } from 'react-icons/fi';
import InfosContext from "../../../contexts/InfosAppProvider";
import { database, ref, set, push, update, onValue, get } from "../../../contexts/FirebaseConfig";

const NotificationTemplates = () => {
  // Context
  const { userInfos, token } = useContext(InfosContext);
  
  // Templates state
  const [templates, setTemplates] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  
  // New/edit template state
  const [currentTemplate, setCurrentTemplate] = useState({
    id: null,
    name: '',
    title: '',
    body: '',
    type: 'info',
    target: 'clients'
  });
  
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({ type: '', text: '' });
  
  // Load templates
  useEffect(() => {
    loadTemplates();
  }, []);
  
  const loadTemplates = async () => {
    setIsLoading(true);
    
    try {
      // In a real implementation, fetch from database
      // Here using mock data for demonstration
      const mockTemplates = [
        {
          id: 'template1',
          name: 'Bienvenue',
          title: 'Bienvenue sur PayForBet',
          body: 'Merci de vous être inscrit! Découvrez notre application et commencez à effectuer vos premières transactions.',
          type: 'info',
          target: 'clients',
          createdBy: 'Admin',
          createdAt: '2023-05-15'
        },
        {
          id: 'template2',
          name: 'Promotion Weekend',
          title: 'Promotion spéciale weekend',
          body: 'Profitez de frais réduits ce weekend sur toutes vos transactions! Offre valable du vendredi au dimanche.',
          type: 'promo',
          target: 'clients',
          createdBy: 'Admin',
          createdAt: '2023-05-20'
        },
        {
          id: 'template3',
          name: 'Maintenance Système',
          title: 'Maintenance planifiée',
          body: 'Notre système sera en maintenance le {DATE} de {HEURE_DEBUT} à {HEURE_FIN}. Certaines fonctionnalités pourraient être temporairement indisponibles.',
          type: 'alerte',
          target: 'tous',
          createdBy: 'Admin',
          createdAt: '2023-05-22'
        },
        {
          id: 'template4',
          name: 'Rappel Formation',
          title: 'Rappel: Formation mensuelle',
          body: 'N\'oubliez pas la formation mensuelle des agents ce jeudi à 14h sur la plateforme habituelle.',
          type: 'rappel',
          target: 'agents',
          createdBy: 'Admin',
          createdAt: '2023-05-25'
        },
        {
          id: 'template5',
          name: 'Mise à jour importante',
          title: 'Mise à jour importante',
          body: 'Une mise à jour importante a été effectuée sur notre système. Veuillez consulter les nouvelles fonctionnalités.',
          type: 'important',
          target: 'tous',
          createdBy: 'Admin',
          createdAt: '2023-06-01'
        }
      ];
      
      setTemplates(mockTemplates);
    } catch (error) {
      console.error('Erreur lors du chargement des modèles:', error);
      setMessage({ type: 'error', text: 'Erreur lors du chargement des modèles.' });
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentTemplate({
      ...currentTemplate,
      [name]: value
    });
  };
  
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  
  const handleSaveTemplate = async () => {
    if (!currentTemplate.name || !currentTemplate.title || !currentTemplate.body) {
      setMessage({ type: 'error', text: 'Veuillez remplir tous les champs obligatoires.' });
      return;
    }
    
    setIsLoading(true);
    
    try {
      const newTemplate = {
        ...currentTemplate,
        createdBy: userInfos?.nom || 'Admin',
        createdAt: new Date().toISOString().split('T')[0]
      };
      
      // In a real implementation, save to database
      // Here just adding to local state
      if (isEditing && currentTemplate.id) {
        // Update existing template
        const updatedTemplates = templates.map(template => 
          template.id === currentTemplate.id ? newTemplate : template
        );
        setTemplates(updatedTemplates);
        
        setMessage({ type: 'success', text: 'Modèle mis à jour avec succès!' });
      } else {
        // Create new template
        const newId = `template${Date.now()}`;
        newTemplate.id = newId;
        
        setTemplates([...templates, newTemplate]);
        setMessage({ type: 'success', text: 'Nouveau modèle créé avec succès!' });
      }
      
      // Reset form
      setCurrentTemplate({
        id: null,
        name: '',
        title: '',
        body: '',
        type: 'info',
        target: 'clients'
      });
      
      setIsEditing(false);
    } catch (error) {
      console.error('Erreur lors de la sauvegarde du modèle:', error);
      setMessage({ type: 'error', text: 'Erreur lors de la sauvegarde du modèle.' });
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleEditTemplate = (template) => {
    setCurrentTemplate(template);
    setIsEditing(true);
  };
  
  const handleDeleteTemplate = async (id) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer ce modèle?')) {
      setIsLoading(true);
      
      try {
        // In a real implementation, delete from database
        // Here just removing from local state
        const updatedTemplates = templates.filter(template => template.id !== id);
        setTemplates(updatedTemplates);
        
        setMessage({ type: 'success', text: 'Modèle supprimé avec succès!' });
      } catch (error) {
        console.error('Erreur lors de la suppression du modèle:', error);
        setMessage({ type: 'error', text: 'Erreur lors de la suppression du modèle.' });
      } finally {
        setIsLoading(false);
      }
    }
  };
  
  const handleUseTemplate = (template) => {
    // In a real implementation, this would pre-fill the notification form
    // For this example, we'll just show a message
    setMessage({ type: 'info', text: `Modèle "${template.name}" sélectionné. Redirection vers le formulaire de création...` });
    
    // Redirect would happen here in real implementation
    // For demo, we'll clear the message after a few seconds
    setTimeout(() => {
      setMessage({ type: '', text: '' });
    }, 3000);
  };
  
  const handleCancel = () => {
    setCurrentTemplate({
      id: null,
      name: '',
      title: '',
      body: '',
      type: 'info',
      target: 'clients'
    });
    setIsEditing(false);
  };
  
  // Filter templates based on search term
  const filteredTemplates = templates.filter(template => 
    template.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    template.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    template.body.toLowerCase().includes(searchTerm.toLowerCase())
  );
  
  return (
    <div className="w-full">
      {/* En-tête */}
      <div className="mb-6">
        <div className="flex items-center gap-2">
          <h2 className="text-xl font-bold text-white">Modèles de notification</h2>
        </div>
        <p className="text-gray-400 mt-1">Créez et gérez des modèles pour vos notifications fréquentes</p>
      </div>
      
      {/* Message de succès/erreur */}
      {message.text && (
        <div className={`mb-4 p-3 rounded-lg ${
          message.type === 'success' ? 'bg-vert bg-opacity-10 text-vert' :
          message.type === 'error' ? 'bg-softRed bg-opacity-10 text-softRed' :
          'bg-bleu bg-opacity-10 text-bleu'
        }`}>
          {message.text}
        </div>
      )}
      
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Formulaire de création/édition */}
        <div className="lg:col-span-1">
          <div className="bg-gris rounded-xl p-5 shadow-lg">
            <h3 className="text-lg font-semibold text-white mb-4">
              {isEditing ? 'Modifier le modèle' : 'Nouveau modèle'}
            </h3>
            
            <div className="space-y-4">
              <div>
                <label htmlFor="name" className="block text-gray-300 mb-1.5 text-sm">
                  Nom du modèle*
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={currentTemplate.name}
                  onChange={handleChange}
                  className="w-full py-2 px-4 bg-noir border border-gray-700 rounded-lg text-white text-sm focus:outline-none focus:border-bleu"
                  placeholder="Ex: Bienvenue, Promotion, etc."
                  required
                />
              </div>
              
              <div>
                <label htmlFor="title" className="block text-gray-300 mb-1.5 text-sm">
                  Titre de la notification*
                </label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  value={currentTemplate.title}
                  onChange={handleChange}
                  className="w-full py-2 px-4 bg-noir border border-gray-700 rounded-lg text-white text-sm focus:outline-none focus:border-bleu"
                  placeholder="Ex: Bienvenue sur PayForBet"
                  required
                />
              </div>
              
              <div>
                <label htmlFor="body" className="block text-gray-300 mb-1.5 text-sm">
                  Contenu de la notification*
                </label>
                <textarea
                  id="body"
                  name="body"
                  value={currentTemplate.body}
                  onChange={handleChange}
                  className="w-full py-2 px-4 bg-noir border border-gray-700 rounded-lg text-white text-sm focus:outline-none focus:border-bleu"
                  placeholder="Vous pouvez utiliser {VARIABLE} pour les données dynamiques"
                  rows="4"
                  required
                />
                <div className="text-gray-400 text-xs mt-1">
                  Pour les variables: utilisez {'{VARIABLE}'} pour les valeurs à remplacer
                </div>
              </div>
              
              <div>
                <label htmlFor="type" className="block text-gray-300 mb-1.5 text-sm">
                  Type de notification
                </label>
                <select
                  id="type"
                  name="type"
                  value={currentTemplate.type}
                  onChange={handleChange}
                  className="w-full py-2 px-4 bg-noir border border-gray-700 rounded-lg text-white text-sm focus:outline-none focus:border-bleu"
                >
                  <option value="info">Information</option>
                  <option value="alerte">Alerte</option>
                  <option value="important">Important</option>
                  <option value="promo">Promotion</option>
                  <option value="rappel">Rappel</option>
                </select>
              </div>
              
              <div>
                <label htmlFor="target" className="block text-gray-300 mb-1.5 text-sm">
                  Cible par défaut
                </label>
                <select
                  id="target"
                  name="target"
                  value={currentTemplate.target}
                  onChange={handleChange}
                  className="w-full py-2 px-4 bg-noir border border-gray-700 rounded-lg text-white text-sm focus:outline-none focus:border-bleu"
                >
                  <option value="clients">Clients</option>
                  <option value="agents">Agents</option>
                  <option value="admins">Administrateurs</option>
                  <option value="tous">Tous les utilisateurs</option>
                </select>
              </div>
              
              <div className="flex gap-3 pt-2">
                {isEditing && (
                  <button
                    type="button"
                    onClick={handleCancel}
                    className="px-4 py-2 bg-noir hover:bg-gray-800 text-white rounded-lg text-sm transition-colors"
                  >
                    Annuler
                  </button>
                )}
                
                <button
                  type="button"
                  onClick={handleSaveTemplate}
                  className="flex-1 px-4 py-2 bg-bleu hover:bg-blue-600 text-white rounded-lg text-sm transition-colors flex items-center justify-center gap-2"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <span className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-white"></span>
                  ) : (
                    <FiSave size={16} />
                  )}
                  {isEditing ? 'Mettre à jour' : 'Enregistrer'}
                </button>
              </div>
            </div>
          </div>
        </div>
        
        {/* Liste des modèles */}
        <div className="lg:col-span-2">
          <div className="bg-gris rounded-xl shadow-lg overflow-hidden">
            <div className="p-4 border-b border-gray-700 flex items-center">
              <div className="relative flex-1">
                <input
                  type="text"
                  placeholder="Rechercher un modèle..."
                  className="w-full py-2 pl-10 pr-4 bg-noir border border-gray-700 rounded-lg text-white text-sm focus:outline-none focus:border-bleu"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <div className="absolute left-3 top-2.5 text-gray-400">
                  <FiSearch size={16} />
                </div>
              </div>
            </div>
            
            <div className="overflow-x-auto">
              {filteredTemplates.length > 0 ? (
                <table className="min-w-full divide-y divide-gray-700">
                  <thead className="bg-noir">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                        Nom
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                        Titre
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                        Type
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                        Créé le
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-700">
                    {filteredTemplates.map((template) => (
                      <tr key={template.id} className="hover:bg-noir transition-colors">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                          {template.name}
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-300">
                          <div className="truncate max-w-[150px]">{template.title}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          <div className={`px-2 py-1 rounded-full text-xs inline-block ${
                            template.type === 'info' ? 'bg-bleu bg-opacity-10 text-bleu' :
                            template.type === 'alerte' ? 'bg-softRed bg-opacity-10 text-softRed' :
                            template.type === 'important' ? 'bg-softYellow bg-opacity-10 text-softYellow' :
                            template.type === 'promo' ? 'bg-softPurple bg-opacity-10 text-softPurple' :
                            'bg-vert bg-opacity-10 text-vert'
                          }`}>
                            {template.type.charAt(0).toUpperCase() + template.type.slice(1)}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                          {template.createdAt}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          <div className="flex items-center gap-2">
                            <button
                              onClick={() => handleUseTemplate(template)}
                              className="p-1.5 bg-bleu bg-opacity-10 hover:bg-opacity-20 text-bleu rounded-lg transition-colors"
                              title="Utiliser ce modèle"
                            >
                              <FiSend size={16} />
                            </button>
                            <button
                              onClick={() => handleEditTemplate(template)}
                              className="p-1.5 bg-softYellow bg-opacity-10 hover:bg-opacity-20 text-softYellow rounded-lg transition-colors"
                              title="Modifier ce modèle"
                            >
                              <FiEdit3 size={16} />
                            </button>
                            <button
                              onClick={() => handleDeleteTemplate(template.id)}
                              className="p-1.5 bg-softRed bg-opacity-10 hover:bg-opacity-20 text-softRed rounded-lg transition-colors"
                              title="Supprimer ce modèle"
                            >
                              <FiTrash2 size={16} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="py-8 text-center text-gray-400">
                  {searchTerm ? 'Aucun modèle ne correspond à votre recherche.' : 'Aucun modèle disponible. Créez-en un!'}
                </div>
              )}
            </div>
          </div>
          
          {/* Variables disponibles */}
          <div className="bg-gris rounded-xl p-5 shadow-lg mt-6">
            <h3 className="text-lg font-semibold text-white mb-3">Variables disponibles</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
              <div className="bg-noir p-3 rounded-lg">
                <code className="text-softYellow text-sm">{'{NOM_CLIENT}'}</code>
                <div className="text-gray-300 text-xs mt-1">Nom de l'utilisateur</div>
              </div>
              <div className="bg-noir p-3 rounded-lg">
                <code className="text-softYellow text-sm">{'{DATE}'}</code>
                <div className="text-gray-300 text-xs mt-1">Date actuelle (format JJ/MM/AAAA)</div>
              </div>
              <div className="bg-noir p-3 rounded-lg">
                <code className="text-softYellow text-sm">{'{HEURE}'}</code>
                <div className="text-gray-300 text-xs mt-1">Heure actuelle (format HH:MM)</div>
              </div>
              <div className="bg-noir p-3 rounded-lg">
                <code className="text-softYellow text-sm">{'{MONTANT}'}</code>
                <div className="text-gray-300 text-xs mt-1">Montant d'une transaction</div>
              </div>
              <div className="bg-noir p-3 rounded-lg">
                <code className="text-softYellow text-sm">{'{ID_TRANSACTION}'}</code>
                <div className="text-gray-300 text-xs mt-1">Identifiant de transaction</div>
              </div>
              <div className="bg-noir p-3 rounded-lg">
                <code className="text-softYellow text-sm">{'{STATUT}'}</code>
                <div className="text-gray-300 text-xs mt-1">Statut d'une transaction</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationTemplates;