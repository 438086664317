import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Button from './Button';
import { 
  FiHome, 
  FiDollarSign, 
  FiUsers, 
  FiUserCheck, 
  FiTrendingDown,
  FiSettings, 
  FiGift,
  FiBell,
  FiHeadphones
} from 'react-icons/fi';
import { 
  FaSignOutAlt, 
  FaCashRegister, 
  FaUserTie,
  FaMoneyBillWave,
  FaPercentage,
  FaHeadset
} from 'react-icons/fa';

const SideBar = ({ isSidebarOpen }) => {
    const navigate = useNavigate();
    
  return (
    <aside className={`h-screen z-40 w-72 p-3 border-r border-gray-700 bg-gris text-white transition-transform lg:translate-x-0 ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"}`}>
      <div className='flex gap-2 bg-noir w-full px-4 py-3 mb-5 rounded-xl border-l-4 border-bleu'>
        <div className='flex flex-col'>
          <h1 className='text-sm font-bold text-white'>Espace Administrateur</h1>
          <h2 className='text-xs text-gray-300'>Tableau de contrôle</h2>
        </div>
      </div>
      
      <div>
        <h2 className='text-gray-400 mb-4 font-medium text-xs uppercase tracking-wider pl-2'>
          Menu Principal
        </h2>
        
        <div className='space-y-1'>
          <Link to={'/admin'}>
            <Button title={"Tableau de bord"} icon={<FiHome />} />
          </Link>
          <Link to={'/admin/transactions'}>
            <Button title={"Transactions"} icon={<FiDollarSign />} />
          </Link>
          
          <h2 className='text-gray-400 mt-6 mb-2 font-medium text-xs uppercase tracking-wider pl-2'>
            Gestion des Utilisateurs
          </h2>
          
          <Link to={'/admin/listAgents'}>
            <Button title={"Agents"} icon={<FiUsers />} />
          </Link>
          <Link to={'/admin/listAssistants'}>
            <Button title={"Assistants"} icon={<FaHeadset />} />
          </Link>
          <Link to={'/admin/listClients'}>
            <Button title={"Clients"} icon={<FiUserCheck />} />
          </Link>
          
          <h2 className='text-gray-400 mt-6 mb-2 font-medium text-xs uppercase tracking-wider pl-2'>
            Finance et Paiements
          </h2>
          
          <Link to={'/admin/liste-retrait'}>
            <Button title={"Retraits par affiliation"} icon={<FiTrendingDown />} />
          </Link>
          <Link to={'/admin/caisses'}>
            <Button title={"Gestion des caisses"} icon={<FaCashRegister />} />
          </Link>
          <Link to={'/admin/caissiers'}>
            <Button title={"Gestion des caissiers"} icon={<FaUserTie />} />
          </Link>
          <Link to={'/admin/attribution-caisses'}>
            <Button title={"Attribution de caisses"} icon={<FaMoneyBillWave />} />
          </Link>
          <Link to={'/admin/bonus-mensuel'}>
            <Button title={"Bonus mensuels"} icon={<FaPercentage />} />
          </Link>
          <Link to={'/admin/bonus-retraits'}>
            <Button title={"Retraits de bonus"} icon={<FiGift />} />
          </Link>
          <Link to={'/admin/bonus-payes'}>
            <Button title={"Historique des bonus payés"} icon={<FaMoneyBillWave />} badge="new" />
          </Link>
          
          <h2 className='text-gray-400 mt-6 mb-2 font-medium text-xs uppercase tracking-wider pl-2'>
            Système
          </h2>
          
          <Link to={'/admin/configuration'}>
            <Button title={"Configuration"} icon={<FiSettings />} />
          </Link>
          <Link to={'/admin/notifications'}>
            <Button title={"Notifications Push"} icon={<FiBell />} />
          </Link>

          <div className="mt-8 pt-6 border-t border-gray-700">
            <div onClick={() => {
              localStorage.setItem("token", "");
              localStorage.setItem("isAuthenticated", 0);
              navigate("/login");
            }}>
              <Button title={"Déconnexion"} icon={<FaSignOutAlt />} />
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
}

export default SideBar;
