import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = localStorage.getItem("isAuthenticated");
  const location = useLocation();
  const currentPath = location.pathname;

  // Ajouter un effet pour vérifier la cohérence entre le rôle et le chemin
  useEffect(() => {
    // Vérifier si l'utilisateur est authentifié
    if (isAuthenticated === "1") {
      const role = localStorage.getItem("userRole") || "";
      
      // Vérification pour le rôle assistant
      if (role === "assistant" && !currentPath.includes("/assistant/")) {
        console.log("ProtectedRoute: Assistant détecté sur un mauvais chemin, redirection vers /assistant/");
        window.location.href = "/assistant/";
      }
    }
  }, [currentPath]);

  // Si l'utilisateur n'est pas authentifié (isAuthenticated != 1), rediriger
  if (isAuthenticated != "1") {
    return <Navigate to="/login" replace />;
  }

  // Si authentifié, afficher les enfants (les routes protégées)
  return children;
};

export default ProtectedRoute;
