import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faWallet,
  faMoneyBill,
  faMapMarkerAlt,
  faCity,
  faLock,
  faCheck,
  faCircleCheck,
  faArrowRight,
  faInfoCircle,
  faExclamationTriangle,
  faPhone,
  faQuestionCircle,
  faHandPointRight
} from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";

export default function InfosRetraitpc({ onClose }) {
  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    {
      icon: faWallet,
      title: "Appuyez sur Retirer",
      details: "Connectez-vous à votre compte 1xBet et localisez le bouton 'Retirer' dans la section de votre solde.",
      color: "from-blue-600 to-blue-800"
    },
    {
      icon: faMoneyBill,
      title: "Choisissez le moyen de paiement",
      details: "Sélectionnez '1XBET (Liquide/Espèces)' dans la liste des méthodes de retrait disponibles.",
      color: "from-indigo-600 to-indigo-800"
    },
    {
      icon: faArrowRight,
      title: "Saisissez le montant à retirer",
      details: "Entrez le montant que vous souhaitez retirer. Le montant minimum est de 900 FCFA.",
      color: "from-purple-600 to-purple-800"
    },
    {
      icon: faCity,
      title: "Choisissez la ville",
      details: "Sélectionnez 'Cotonou' dans la liste des villes disponibles.",
      color: "from-violet-600 to-violet-800"
    },
    {
      icon: faMapMarkerAlt,
      title: "Choisissez la rue",
      details: "Sélectionnez 'PayforB Auto Gaw' comme point de retrait.",
      color: "from-fuchsia-600 to-fuchsia-800"
    },
    {
      icon: faPhone,
      title: "Confirmez avec le code",
      details: "Vous recevrez un code de confirmation par SMS. Entrez ce code et validez votre demande de retrait.",
      color: "from-pink-600 to-pink-800"
    },
    {
      icon: faLock,
      title: "Obtenez le code de retrait",
      details: "Patientez que la transaction soit approuvée, puis appuyez sur 'Obtenir le Code'. Copiez le code généré pour l'utiliser dans ce formulaire.",
      color: "from-rose-600 to-rose-800"
    }
  ];

  return (
    <div className="bg-gradient-to-b from-[#0F172A] to-[#1E293B] rounded-2xl overflow-hidden w-[800px] max-w-[90vw] shadow-2xl shadow-black/30 border border-indigo-500/20">
      {/* Header */}
      <div className="relative">
        <div className="px-6 py-5 flex justify-between items-center border-b border-indigo-500/20">
          <h2 className="text-white text-2xl font-semibold">Procédure de retrait 1xBet</h2>
          <button 
            onClick={onClose}
            className="w-8 h-8 flex items-center justify-center rounded-full bg-gray-800 hover:bg-gray-700 transition-colors"
          >
            <FontAwesomeIcon icon={faTimes} className="text-white" />
          </button>
        </div>

        <div className="absolute -top-20 right-20 w-40 h-40 bg-indigo-600 rounded-full opacity-10 blur-2xl"></div>
        <div className="absolute -top-10 -left-10 w-60 h-60 bg-purple-600 rounded-full opacity-10 blur-3xl"></div>
      </div>

      {/* Introduction */}
      <div className="px-6 py-4">
        <div className="bg-indigo-900/20 backdrop-blur-sm rounded-xl p-4 border border-indigo-500/20 flex items-start">
          <FontAwesomeIcon icon={faInfoCircle} className="text-indigo-400 mt-0.5 mr-4 text-xl flex-shrink-0" />
          <div>
            <h3 className="text-white font-medium mb-1">Instructions pour le retrait</h3>
            <p className="text-indigo-100 text-sm">
              Suivez ces étapes dans votre compte 1xBet pour générer un code de retrait que vous utiliserez ensuite dans ce formulaire. Cliquez sur chaque étape pour obtenir plus de détails.
            </p>
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="px-6 pb-6 flex">
        {/* Steps */}
        <div className="w-1/3 pr-4 border-r border-indigo-500/20">
          <h3 className="text-white font-medium mb-3">Étapes à suivre</h3>
          <div className="space-y-1">
            {steps.map((step, index) => (
              <div
                key={index}
                onClick={() => setActiveStep(index)}
                className={`flex items-center p-3 rounded-lg transition-colors cursor-pointer ${
                  activeStep === index 
                    ? 'bg-indigo-600/20 border border-indigo-500/30'
                    : 'hover:bg-white/5'
                }`}
              >
                <div className={`w-8 h-8 rounded-full bg-gradient-to-br ${step.color} flex items-center justify-center mr-3 shadow-lg`}>
                  <FontAwesomeIcon icon={step.icon} className="text-white text-sm" />
                </div>
                <p className={`text-sm ${activeStep === index ? 'text-white font-medium' : 'text-gray-300'}`}>
                  {index + 1}. {step.title}
                </p>
                {activeStep === index && (
                  <FontAwesomeIcon icon={faHandPointRight} className="ml-auto text-indigo-400 animate-pulse" />
                )}
              </div>
            ))}
          </div>
        </div>

        {/* Details */}
        <div className="w-2/3 pl-6">
          <div className="bg-white/5 backdrop-blur-sm rounded-xl border border-white/10 p-5 h-full relative overflow-hidden">
            <div className="absolute -right-16 -top-16 w-32 h-32 rounded-full bg-gradient-to-br from-indigo-600/10 to-purple-600/10 blur-xl"></div>
            
            <div className="flex items-center mb-4">
              <div className={`w-12 h-12 rounded-xl bg-gradient-to-br ${steps[activeStep].color} flex items-center justify-center mr-4 shadow-lg`}>
                <FontAwesomeIcon icon={steps[activeStep].icon} className="text-white text-lg" />
              </div>
              <h3 className="text-white text-xl font-semibold">
                Étape {activeStep + 1}: {steps[activeStep].title}
              </h3>
            </div>
            
            <div className="bg-indigo-900/20 backdrop-blur-sm rounded-xl p-4 mb-4 border border-indigo-500/20">
              <p className="text-gray-200 leading-relaxed">
                {steps[activeStep].details}
              </p>
            </div>

            {activeStep === 0 && (
              <div className="bg-blue-900/20 backdrop-blur-sm rounded-xl p-4 border border-blue-500/20">
                <div className="flex items-start">
                  <FontAwesomeIcon icon={faQuestionCircle} className="text-blue-400 mt-0.5 mr-3 flex-shrink-0" />
                  <p className="text-blue-200 text-sm">
                    Le bouton de retrait est généralement situé dans la section "Mon compte" ou "Portefeuille" de votre interface 1xBet. Il peut apparaître comme "Retirer" ou "Retrait".
                  </p>
                </div>
              </div>
            )}

            {activeStep === 6 && (
              <div className="bg-amber-900/20 backdrop-blur-sm rounded-xl p-4 border border-amber-500/20">
                <div className="flex items-start">
                  <FontAwesomeIcon icon={faExclamationTriangle} className="text-amber-400 mt-0.5 mr-3 flex-shrink-0" />
                  <div>
                    <p className="text-amber-200 text-sm font-medium mb-1">Important:</p>
                    <p className="text-amber-200 text-sm">
                      Le code de retrait est composé de 4 caractères. Assurez-vous de le copier correctement car il est nécessaire pour compléter votre demande de retrait sur cette application.
                    </p>
                  </div>
                </div>
              </div>
            )}

            <div className="mt-6 flex justify-between">
              <button
                onClick={() => setActiveStep(Math.max(0, activeStep - 1))}
                disabled={activeStep === 0}
                className={`px-4 py-2 rounded-lg flex items-center text-sm ${
                  activeStep === 0
                    ? 'bg-gray-800/50 text-gray-500 cursor-not-allowed'
                    : 'bg-gray-800 text-white hover:bg-gray-700'
                }`}
              >
                <FontAwesomeIcon icon={faArrowRight} className="mr-2 rotate-180" />
                Précédent
              </button>
              
              <button
                onClick={() => setActiveStep(Math.min(steps.length - 1, activeStep + 1))}
                disabled={activeStep === steps.length - 1}
                className={`px-4 py-2 rounded-lg flex items-center text-sm ${
                  activeStep === steps.length - 1
                    ? 'bg-gray-800/50 text-gray-500 cursor-not-allowed'
                    : 'bg-indigo-600 text-white hover:bg-indigo-700'
                }`}
              >
                Suivant
                <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}