import React, { useContext, useState, useEffect } from 'react';
import StatisticsChart from '../components/StatisticsChart';
import { TbMoneybag, TbUsers, TbUserCheck } from "react-icons/tb";
import { PiMoney, PiChartLineUp } from "react-icons/pi";
import { GiReceiveMoney } from "react-icons/gi";
import { FaCashRegister, FaUserTie, FaRegBell } from "react-icons/fa";
import { FiTrendingUp, FiTrendingDown, FiAlertCircle } from "react-icons/fi";
import InfosContext from "../../contexts/InfosAppProvider";

const Dashbord = () => {
  const {
    infosAdmin,
    setInfosAdmin,
    userInfos,
    setUserInfos,
    transactions,
    setTransactions,
    listeUser,
    listeCaisses,
  } = useContext(InfosContext);

  const [totalDepots, setTotalDepots] = useState(0);
  const [totalRetraits, setTotalRetraits] = useState(0);
  const [totalEncours, setTotalEncours] = useState(0);
  const [totalAgents, setTotalAgents] = useState(0);
  const [totalClients, setTotalClients] = useState(0);
  const [alertesRecentes, setAlertesRecentes] = useState(0); 
  const [notifications, setNotifications] = useState([]);
  const [notificationsStats, setNotificationsStats] = useState(null);
  
  // Récupérer le nombre exact de caisses
  const nombreCaisses = listeCaisses ? Object.keys(listeCaisses).length : 0;

  // Fonction pour formater la date (retourne un jour au format 'yyyy-mm-dd')
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toISOString().split('T')[0]; // Retourne la date au format 'yyyy-mm-dd'
  };

  // Fonction pour obtenir les 7 derniers jours
  const getLast7Days = () => {
    const dates = [];
    for (let i = 6; i >= 0; i--) {
      const date = new Date();
      date.setDate(date.getDate() - i);
      dates.push(formatDate(date.getTime()));
    }
    return dates;
  };

  // Fonction pour formater les montants
  const formatMontant = (montant) => {
    return new Intl.NumberFormat('fr-FR').format(montant);
  };

  useEffect(() => {
    if (!transactions) return;
    
    const dates = getLast7Days();
    let depots = 0;
    let retraits = 0;
    let enCours = 0;

    // Parcourir toutes les transactions
    Object.values(transactions).forEach((transaction) => {
      const dateTransaction = formatDate(transaction.dateHeure);

      // Vérifier si la transaction est dans les 7 derniers jours
      if (dates.includes(dateTransaction)) {
        const montant = parseFloat(transaction.montant);

        // Vérifier si c'est un dépôt
        if (transaction.type === 'depot') {
          if(transaction.statut === 'terminé'){
            depots += montant;
          }
        }

        // Vérifier si c'est un retrait
        if (transaction.type === 'retrait') {
          if(transaction.statut === 'terminé'){
            retraits += montant;
          }
        }

        if(transaction.statut === 'en cours'){
          enCours += montant;
        }
      }
    });

    // Mettre à jour les totaux dans le state
    setTotalDepots(depots);
    setTotalRetraits(retraits);
    setTotalEncours(enCours);
  }, [transactions]); // Recalcule lorsque `transactions` change
  
  // Effet pour calculer le nombre d'agents et clients
  useEffect(() => {
    if (!listeUser) return;
    
    // Compter les agents et clients
    let agents = 0;
    let clients = 0;
    
    // Filtrer les entrées qui sont effectivement des objets utilisateur
    Object.entries(listeUser).forEach(([key, user]) => {
      // Vérifier si c'est un utilisateur ou un autre type de données
      if (user && typeof user === 'object' && user.role) {
        if (user.role === 'agent') {
          agents++;
        } else if (user.role === 'client') {
          clients++;
        }
      }
    });
    
    setTotalAgents(agents);
    setTotalClients(clients);
  }, [listeUser]);
  
  // Effet séparé pour traiter les notifications
  useEffect(() => {
    if (!listeUser) return;
    
    // Extraire les notifications si elles existent dans listeUser
    const notifs = [];
    Object.entries(listeUser).forEach(([key, item]) => {
      // Vérifier si l'entrée a les propriétés d'une notification
      if (item && typeof item === 'object' && item.title && item.body && item.date) {
        notifs.push(item);
      }
    });
    
    if (notifs.length > 0) {
      // Prendre les 3 notifications les plus récentes
      const recentNotifs = [...notifs]
        .sort((a, b) => new Date(b.date) - new Date(a.date))
        .slice(0, 3);
      
      setNotifications(recentNotifs);
      setAlertesRecentes(recentNotifs.length);
    }
    
    // Stocker les statistiques des notifications si disponibles
    if (listeUser.notificationsStats) {
      setNotificationsStats(listeUser.notificationsStats);
    }
  }, [listeUser]);

  // Fonction pour formater la date pour l'affichage
  const formatDisplayDate = (dateString) => {
    try {
      if (!dateString) return 'Date inconnue';
      
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        return dateString; // Retourne la chaîne originale si la conversion échoue
      }
      
      return date.toLocaleDateString('fr-FR', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    } catch (error) {
      console.error("Erreur lors du formatage de la date:", error);
      return dateString;
    }
  };

  // Extraire les 4 transactions les plus récentes
  const getRecentTransactions = () => {
    if (!transactions) return [];
    
    return Object.values(transactions)
      .sort((a, b) => b.dateHeure - a.dateHeure)
      .slice(0, 4)
      .map(tx => ({
        id: tx.idTransaction ? tx.idTransaction.substring(0, 8) : 'TX-???',
        client: tx.idCompte1xbet || 'Inconnu',
        montant: parseFloat(tx.montant || 0),
        type: tx.type || 'inconnu',
        statut: tx.statut || 'inconnu',
        date: formatDisplayDate(tx.dateHeure)
      }));
  };
  
  const recentTransactions = getRecentTransactions();

  return (
    <div className='w-full'>
      {/* En-tête du dashboard */}
      <div className='mb-8'>
        <h1 className='text-2xl font-bold text-white mb-2'>Tableau de bord</h1>
        <p className='text-gray-400'>Vue d'ensemble des activités et performances</p>
      </div>
      
      {/* Cartes de statistiques principales */}
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-10'>
        <div className='bg-gris rounded-xl p-6 border-l-4 border-bleu shadow-lg'>
          <div className='flex justify-between items-start mb-4'>
            <div>
              <p className='text-gray-400 text-sm uppercase tracking-wider mb-1'>Total Dépôts</p>
              <h2 className='text-2xl font-bold text-white'>{formatMontant(totalDepots)} F CFA</h2>
            </div>
            <div className='bg-black bg-opacity-30 p-3 rounded-lg'>
              <TbMoneybag className='text-bleu text-xl' />
            </div>
          </div>
          <div className='flex items-center text-vert text-xs'>
            <FiTrendingUp className='mr-1' />
            <span>+12.5% depuis la semaine dernière</span>
          </div>
        </div>
        
        <div className='bg-gris rounded-xl p-6 border-l-4 border-softRed shadow-lg'>
          <div className='flex justify-between items-start mb-4'>
            <div>
              <p className='text-gray-400 text-sm uppercase tracking-wider mb-1'>Total Retraits</p>
              <h2 className='text-2xl font-bold text-white'>{formatMontant(totalRetraits)} F CFA</h2>
            </div>
            <div className='bg-black bg-opacity-30 p-3 rounded-lg'>
              <PiMoney className='text-softRed text-xl' />
            </div>
          </div>
          <div className='flex items-center text-softRed text-xs'>
            <FiTrendingDown className='mr-1' />
            <span>-3.2% depuis la semaine dernière</span>
          </div>
        </div>
        
        <div className='bg-gris rounded-xl p-6 border-l-4 border-vert shadow-lg'>
          <div className='flex justify-between items-start mb-4'>
            <div>
              <p className='text-gray-400 text-sm uppercase tracking-wider mb-1'>En Cours</p>
              <h2 className='text-2xl font-bold text-white'>{formatMontant(totalEncours)} F CFA</h2>
            </div>
            <div className='bg-black bg-opacity-30 p-3 rounded-lg'>
              <GiReceiveMoney className='text-vert text-xl' />
            </div>
          </div>
          <div className='flex items-center text-gray-400 text-xs'>
            <span>{transactions ? Object.values(transactions).filter(t => t.statut === 'en cours').length : 0} transactions en attente</span>
          </div>
        </div>
      </div>
      
      {/* Statistiques secondaires */}
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-10'>
        <div className='bg-gris rounded-lg p-4 flex items-center gap-4'>
          <div className='bg-bleu bg-opacity-10 p-3 rounded-full'>
            <TbUsers className='text-bleu text-xl' />
          </div>
          <div>
            <p className='text-gray-400 text-xs'>Total Agents</p>
            <h3 className='text-xl font-semibold text-white'>{totalAgents}</h3>
          </div>
        </div>
        
        <div className='bg-gris rounded-lg p-4 flex items-center gap-4'>
          <div className='bg-softPurple bg-opacity-10 p-3 rounded-full'>
            <TbUserCheck className='text-softPurple text-xl' />
          </div>
          <div>
            <p className='text-gray-400 text-xs'>Total Clients</p>
            <h3 className='text-xl font-semibold text-white'>{totalClients}</h3>
          </div>
        </div>
        
        <div className='bg-gris rounded-lg p-4 flex items-center gap-4'>
          <div className='bg-vert bg-opacity-10 p-3 rounded-full'>
            <FaCashRegister className='text-vert text-xl' />
          </div>
          <div>
            <p className='text-gray-400 text-xs'>Caisses Actives</p>
            <h3 className='text-xl font-semibold text-white'>{nombreCaisses}</h3>
          </div>
        </div>
        
        <div className='bg-gris rounded-lg p-4 flex items-center gap-4'>
          <div className='bg-softRed bg-opacity-10 p-3 rounded-full'>
            <FaRegBell className='text-softRed text-xl' />
          </div>
          <div>
            <p className='text-gray-400 text-xs'>Alertes</p>
            <h3 className='text-xl font-semibold text-white'>{alertesRecentes}</h3>
          </div>
        </div>
      </div>
      
      {/* Sections principales du dashboard */}
      <div className='grid grid-cols-1 lg:grid-cols-3 gap-6 mb-8'>
        {/* Graphique principal */}
        <div className='lg:col-span-2 bg-gris rounded-xl p-6 shadow-lg'>
          <h2 className='text-lg font-semibold text-white mb-6'>Activité Financière</h2>
          <StatisticsChart />
        </div>
        
        {/* Dernières alertes */}
        <div className='bg-gris rounded-xl p-6 shadow-lg'>
          <div className='flex justify-between items-center mb-6'>
            <h2 className='text-lg font-semibold text-white'>Alertes Récentes</h2>
            <button className='text-bleu text-sm hover:underline'>Voir tout</button>
          </div>
          
          <div className='space-y-4'>
            {notifications && notifications.length > 0 ? (
              notifications.map((notif, index) => (
                <div key={index} className='border-l-4 border-softRed bg-noir p-4 rounded-r-lg'>
                  <div className='flex items-start gap-3'>
                    <FiAlertCircle className='text-softRed mt-1' />
                    <div>
                      <h4 className='text-white text-sm font-medium'>{notif.title || 'Notification'}</h4>
                      <p className='text-gray-400 text-xs mt-1'>{notif.body && notif.body.length > 50 
                        ? `${notif.body.substring(0, 50)}...` 
                        : notif.body || 'Aucun détail'}</p>
                      <p className='text-gray-400 text-xs mt-1'>{formatDisplayDate(notif.date)}</p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className='border-l-4 border-gray-600 bg-noir p-4 rounded-r-lg'>
                <div className='flex items-start gap-3'>
                  <FiAlertCircle className='text-gray-400 mt-1' />
                  <div>
                    <h4 className='text-white text-sm font-medium'>Aucune notification récente</h4>
                    <p className='text-gray-400 text-xs mt-1'>Le système est à jour</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      
      {/* Transactions récentes */}
      <div className='bg-gris rounded-xl p-6 shadow-lg'>
        <div className='flex justify-between items-center mb-6'>
          <h2 className='text-lg font-semibold text-white'>Transactions Récentes</h2>
          <button className='text-bleu text-sm hover:underline'>Voir toutes les transactions</button>
        </div>
        
        <div className='overflow-x-auto'>
          <table className='w-full text-sm text-left'>
            <thead className='text-xs text-gray-400 uppercase border-b border-gray-700'>
              <tr>
                <th className='px-4 py-3'>ID</th>
                <th className='px-4 py-3'>Client</th>
                <th className='px-4 py-3'>Montant</th>
                <th className='px-4 py-3'>Type</th>
                <th className='px-4 py-3'>Statut</th>
                <th className='px-4 py-3'>Date</th>
                <th className='px-4 py-3'>Action</th>
              </tr>
            </thead>
            <tbody>
              {recentTransactions.length > 0 ? (
                recentTransactions.map((tx, index) => (
                  <tr key={index} className='border-b border-gray-800 hover:bg-noir'>
                    <td className='px-4 py-3 text-white'>{tx.id}</td>
                    <td className='px-4 py-3 text-white'>{tx.client}</td>
                    <td className='px-4 py-3 text-white'>{formatMontant(tx.montant)} F</td>
                    <td className='px-4 py-3'>
                      <span className={`px-2 py-1 rounded-full text-xs ${tx.type === 'depot' ? 'bg-bleu bg-opacity-10 text-bleu' : 'bg-softRed bg-opacity-10 text-softRed'}`}>
                        {tx.type === 'depot' ? 'Dépôt' : 'Retrait'}
                      </span>
                    </td>
                    <td className='px-4 py-3'>
                      <span className={`px-2 py-1 rounded-full text-xs ${tx.statut === 'terminé' ? 'bg-vert bg-opacity-10 text-vert' : 'bg-softYellow bg-opacity-10 text-softYellow'}`}>
                        {tx.statut === 'terminé' ? 'Terminé' : 'En cours'}
                      </span>
                    </td>
                    <td className='px-4 py-3 text-gray-400'>{tx.date}</td>
                    <td className='px-4 py-3'>
                      <button className='px-3 py-1 bg-bleu bg-opacity-10 hover:bg-opacity-20 text-bleu rounded-lg text-xs transition-colors'>
                        Détails
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="text-center py-4 text-gray-400">
                    Aucune transaction récente disponible
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      
      {/* Nouvelles fonctionnalités (en aperçu) */}
      <div className='grid grid-cols-1 md:grid-cols-3 gap-6 mt-10'>
        <div className='bg-gris rounded-xl p-6 border-t-4 border-bleu shadow-lg'>
          <div className='flex justify-between items-center mb-4'>
            <h2 className='text-lg font-semibold text-white'>Gestion des caisses</h2>
            <FaCashRegister className='text-bleu text-xl' />
          </div>
          <p className='text-gray-400 text-sm mb-4'>Gérez vos caisses et suivez leur statut en temps réel.</p>
          <button className='bg-bleu hover:bg-blue-600 text-white px-4 py-2 rounded-lg text-sm w-full transition-colors'>
            Accéder
          </button>
        </div>
        
        <div className='bg-gris rounded-xl p-6 border-t-4 border-vert shadow-lg'>
          <div className='flex justify-between items-center mb-4'>
            <h2 className='text-lg font-semibold text-white'>Bonus mensuels</h2>
            <PiChartLineUp className='text-vert text-xl' />
          </div>
          <p className='text-gray-400 text-sm mb-4'>Configurez et suivez les bonus mensuels des agents et affiliés.</p>
          <button className='bg-vert hover:bg-green-600 text-white px-4 py-2 rounded-lg text-sm w-full transition-colors'>
            Configurer
          </button>
        </div>
        
        <div className='bg-gris rounded-xl p-6 border-t-4 border-softPurple shadow-lg'>
          <div className='flex justify-between items-center mb-4'>
            <h2 className='text-lg font-semibold text-white'>Notifications Push</h2>
            <FaRegBell className='text-softPurple text-xl' />
          </div>
          <p className='text-gray-400 text-sm mb-4'>Envoyez des notifications ciblées à vos utilisateurs.</p>
          <button className='bg-softPurple hover:bg-purple-600 text-white px-4 py-2 rounded-lg text-sm w-full transition-colors'>
            Envoyer
          </button>
        </div>
      </div>
    </div>
  );
};

export default Dashbord;