import React, { useState, useEffect } from 'react';
import { FiEdit, FiPlusCircle, FiChevronDown, FiChevronUp, FiSearch, FiRefreshCw, FiArrowUp, FiArrowDown, FiCheckCircle, FiXCircle, FiAlertCircle, FiEye } from 'react-icons/fi';
import { FaPercentage, FaCoins, FaCalendarAlt, FaHistory, FaUsersCog, FaSpinner, FaMoneyBillWave } from 'react-icons/fa';
import bonusService from '../../services/bonusService';
import BonusPreview from './BonusPreview';
import axios from 'axios';
import { toast } from 'react-toastify';

// Configuration de l'API
const API_URL = process.env.REACT_APP_BASE_URL || 'https://b2a2-197-234-221-167.ngrok-free.app/api';

const BonusList = () => {
  // Programmes de bonus (données prédéfinies)
  const [programmes, setProgrammes] = useState([
    {
      id: 1,
      nom: "Programme standard",
      description: "Bonus mensuel de 1% pour tous les clients sur la base du volume de transactions",
      typeUtilisateur: "clients",
      dateCreation: "2023-01-15",
      actif: true,
      regles: [
        { seuil: 0, pourcentage: 1.0 }
      ]
    }
  ]);

  // État pour les données provenant de l'API
  const [historique, setHistorique] = useState([]);
  const [bonusPayes, setBonusPayes] = useState([]);
  const [statistiques, setStatistiques] = useState({
    totalBonusCalcules: 0,
    totalBonusDemandes: 0,
    totalBonusPayes: 0,
    montantTotalCalcule: 0,
    montantTotalDemande: 0,
    montantTotalPaye: 0,
    nombreUtilisateursAvecBonus: 0,
    dernieresDemandes: []
  });
  const [loading, setLoading] = useState(true);
  const [calculLoading, setCalculLoading] = useState(false);
  const [approbationLoading, setApprobationLoading] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [demandesRetrait, setDemandesRetrait] = useState([]);
  const [bonusPreviewData, setBonusPreviewData] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  
  // Statistiques des bonus payés
  const [statsBonusPaids, setStatsBonusPaids] = useState({
    total: 0,
    montantTotal: 0,
    demandeFaitParClient: 0,
    montantDemandeClient: 0
  });

  // États pour les accordéons ouverts
  const [programmeOuvert, setProgrammeOuvert] = useState(null);
  
  // États pour la recherche
  const [rechercheHistorique, setRechercheHistorique] = useState('');
  
  // États pour le tri
  const [triHistorique, setTriHistorique] = useState({
    key: 'id',
    direction: 'ascending'
  });
  
  // États pour les filtres
  const [filtreHistorique, setFiltreHistorique] = useState({
    mois: 'tous',
    statut: 'tous',
    type: 'tous'
  });

  // États pour le calcul de bonus
  const [parametresCalcul, setParametresCalcul] = useState({
    mois: new Date().getMonth(),
    annee: new Date().getFullYear(),
    tauxBonus: 1,
    forceCalculation: false
  });

  // Fonction pour formater les montants
  const formatMontant = (montant) => {
    if (!montant && montant !== 0) return '0';
    return new Intl.NumberFormat('fr-FR').format(montant);
  };

  // Chargement initial des données
  useEffect(() => {
    fetchData();
  }, []);

  // Fonction pour récupérer les données depuis l'API
  const fetchData = async () => {
    setLoading(true);
    try {
      console.log("URL de l'API utilisée:", API_URL);
      
      // Récupérer les statistiques des bonus
      const statsData = await bonusService.getStatistiques();
      
      // Récupérer les bonus payés depuis l'API
      let bonusPayesData = [];
      try {
        // Essayer d'abord avec l'API admin/retraits
        const bonusResponse = await axios.get(`${API_URL}/v1/admin/retraits/historique`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        
        console.log("Réponse historique:", bonusResponse.data);
        
        if (bonusResponse.data && bonusResponse.data.historique) {
          bonusPayesData = bonusResponse.data.historique.filter(bonus => 
            bonus.statut === 'payé' || bonus.statut === 'à payer' || bonus.statut === 'approuvé'
          );
          console.log("Bonus payés récupérés (alternatif):", bonusPayesData);
        }
      } catch (err) {
        console.error("Erreur lors de la récupération directe des bonus payés:", err);
        // Essayer avec le service standard
        try {
          bonusPayesData = await bonusService.getBonusPayes();
          console.log("Bonus payés récupérés (service):", bonusPayesData);
        } catch (serviceErr) {
          console.error("Erreur service getBonusPayes:", serviceErr);
        }
      }
      
      // Mettre à jour l'état des bonus payés
      setBonusPayes(bonusPayesData);
      
      // Calculer les statistiques des bonus payés
      const stats = {
        total: bonusPayesData.length,
        montantTotal: bonusPayesData.reduce((sum, bonus) => sum + (parseFloat(bonus.montant) || 0), 0),
        demandeFaitParClient: bonusPayesData.filter(bonus => bonus.demandeParClient === true).length,
        montantDemandeClient: bonusPayesData.filter(bonus => bonus.demandeParClient === true)
          .reduce((sum, bonus) => sum + (parseFloat(bonus.montant) || 0), 0)
      };
      setStatsBonusPaids(stats);
      
      if (statsData && statsData.success && statsData.statistiques) {
        setStatistiques(statsData.statistiques);
        
        // Convertir les données de l'API en format utilisable pour l'historique
        const historiqueData = [];
        
        // Ajouter les bonus payés
        if (statsData.statistiques.periodes) {
          statsData.statistiques.periodes.forEach(periode => {
            if (periode.nombrePayes > 0) {
              historiqueData.push({
                id: `periode-${periode.mois}-${periode.annee}-paye`,
                utilisateur: { id: 'global', nom: 'Tous les utilisateurs', type: 'global' },
                programme: 'Programme standard',
                montant: periode.montantPaye,
                pourcentage: 1.0,
                volumeReference: periode.montantPaye * 100,
                mois: `${getNomMois(periode.mois)} ${periode.annee}`,
                datePaiement: new Date(Date.now()).toISOString().split('T')[0],
                statut: 'payé',
                nombreUtilisateurs: periode.nombrePayes
              });
            }
            
            if (periode.nombreDemandes > periode.nombrePayes) {
              historiqueData.push({
                id: `periode-${periode.mois}-${periode.annee}-demande`,
                utilisateur: { id: 'global', nom: 'Tous les utilisateurs', type: 'global' },
                programme: 'Programme standard',
                montant: periode.montantDemande - periode.montantPaye,
                pourcentage: 1.0,
                volumeReference: (periode.montantDemande - periode.montantPaye) * 100,
                mois: `${getNomMois(periode.mois)} ${periode.annee}`,
                datePaiement: null,
                statut: 'en attente',
                nombreUtilisateurs: periode.nombreDemandes - periode.nombrePayes
              });
            }
          });
        }
        
        // Ajouter les demandes de retrait en attente
        if (statsData.statistiques.dernieresDemandes) {
          setDemandesRetrait(statsData.statistiques.dernieresDemandes);
          
          statsData.statistiques.dernieresDemandes.forEach(demande => {
            if (demande.statut === 'en attente') {
              historiqueData.push({
                id: demande.id,
                utilisateur: { id: demande.idUser, nom: demande.nomUser, type: 'client' },
                programme: 'Programme standard',
                montant: demande.montant,
                pourcentage: 1.0,
                volumeReference: demande.montant * 100,
                mois: `${getNomMois(demande.mois)} ${demande.annee}`,
                datePaiement: null,
                statut: 'en attente',
                numeroReception: demande.numeroReception,
                demandeParClient: true
              });
            }
          });
        }
        
        // Ajouter les bonus individuels payés
        if (bonusPayesData && bonusPayesData.length > 0) {
          bonusPayesData.forEach(bonus => {
            // Vérifier que bonus est un objet valide
            if (bonus && typeof bonus === 'object') {
              historiqueData.push({
                id: bonus.id || `bonus-${Math.random().toString(36).substring(7)}`,
                utilisateur: { 
                  id: bonus.idUser || 'unknown', 
                  nom: bonus.nomUser || 'Client inconnu', 
                  type: 'client' 
                },
                programme: 'Programme standard',
                montant: bonus.montant || 0,
                pourcentage: 1.0,
                volumeReference: (bonus.montant || 0) * 100,
                mois: bonus.mois && bonus.annee 
                  ? `${getNomMois(parseInt(bonus.mois))} ${bonus.annee}` 
                  : 'Date inconnue',
                datePaiement: bonus.dateApprobation || bonus.datePaiement 
                  ? new Date(bonus.dateApprobation || bonus.datePaiement).toISOString().split('T')[0]
                  : new Date().toISOString().split('T')[0],
                statut: 'payé',
                numeroReception: bonus.numeroReception || 'Non spécifié',
                demandeParClient: bonus.demandeFaitParClient === true
              });
            } else {
              console.warn("Bonus invalide ignoré:", bonus);
            }
          });
        }
        
        setHistorique(historiqueData);
      } else {
        console.error('Format de réponse statistiques inattendu:', statsData);
        toast.error('Erreur lors de la récupération des statistiques');
      }
    } catch (error) {
      console.error('Erreur lors du chargement des données:', error);
      toast.error('Erreur lors du chargement des données');
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour obtenir le nom du mois
  const getNomMois = (numeroMois) => {
    const mois = [
      'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
      'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
    ];
    return mois[numeroMois - 1] || 'Inconnu';
  };

  // Fonction pour toggler l'ouverture d'un programme
  const toggleProgramme = (id) => {
    if (programmeOuvert === id) {
      setProgrammeOuvert(null);
    } else {
      setProgrammeOuvert(id);
    }
  };

  // Fonction pour gérer la recherche dans l'historique
  const handleRechercheHistorique = (e) => {
    setRechercheHistorique(e.target.value);
  };

  // Fonction pour trier l'historique
  const handleTriHistorique = (key) => {
    let direction = 'ascending';
    if (triHistorique.key === key && triHistorique.direction === 'ascending') {
      direction = 'descending';
    }
    setTriHistorique({ key, direction });
  };

  // Fonction pour filtrer l'historique
  const handleFiltreHistorique = (e) => {
    const { name, value } = e.target;
    setFiltreHistorique({
      ...filtreHistorique,
      [name]: value
    });
  };

  // Fonction pour mettre à jour les paramètres de calcul
  const handleParametresCalculChange = (e) => {
    const { name, value, type, checked } = e.target;
    setParametresCalcul({
      ...parametresCalcul,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  // Fonction pour calculer l'aperçu des bonus mensuels
  const handleCalculerBonusPreview = async () => {
    setPreviewLoading(true);
    try {
      const response = await bonusService.calculerBonusPreview(
        Number(parametresCalcul.mois),
        Number(parametresCalcul.annee),
        Number(parametresCalcul.tauxBonus)
      );
      
      if (response.success && response.preview) {
        setBonusPreviewData(response.preview);
        setShowPreview(true);
      } else {
        toast.error(response.message || 'Erreur lors du calcul de l\'aperçu des bonus');
      }
    } catch (error) {
      console.error('Erreur lors du calcul de l\'aperçu des bonus:', error);
      toast.error('Erreur lors du calcul de l\'aperçu des bonus');
    } finally {
      setPreviewLoading(false);
    }
  };

  // Fonction pour approuver les bonus mensuels
  const handleApprouverBonusMensuel = async () => {
    setApprobationLoading(true);
    try {
      // Récupérer l'ID de l'administrateur depuis le localStorage
      const adminInfo = JSON.parse(localStorage.getItem('userInfos') || '{}');
      const idAdmin = adminInfo.id || 'admin-default';
      
      const response = await bonusService.approuverBonusMensuel(
        Number(parametresCalcul.mois),
        Number(parametresCalcul.annee),
        Number(parametresCalcul.tauxBonus),
        idAdmin
      );
      
      if (response.success) {
        toast.success(`Bonus mensuels approuvés avec succès pour ${getNomMois(parametresCalcul.mois)} ${parametresCalcul.annee}`);
        setShowPreview(false);
        setBonusPreviewData(null);
        fetchData(); // Recharger les données
      } else {
        toast.error(response.message || 'Erreur lors de l\'approbation des bonus');
      }
    } catch (error) {
      console.error('Erreur lors de l\'approbation des bonus mensuels:', error);
      toast.error('Erreur lors de l\'approbation des bonus');
    } finally {
      setApprobationLoading(false);
    }
  };

  // Fonction pour fermer l'aperçu
  const handleFermerApercu = () => {
    setShowPreview(false);
    setBonusPreviewData(null);
  };

  // Fonction pour calculer les bonus mensuels
  const handleCalculerBonusMensuel = async () => {
    setCalculLoading(true);
    try {
      const response = await bonusService.calculerBonusMensuel(
        Number(parametresCalcul.mois),
        Number(parametresCalcul.annee),
        Number(parametresCalcul.tauxBonus),
        parametresCalcul.forceCalculation
      );
      
      if (response.success) {
        toast.success(`Bonus mensuels calculés avec succès pour ${getNomMois(parametresCalcul.mois)} ${parametresCalcul.annee}`);
        fetchData(); // Recharger les données
      } else {
        toast.error(response.message || 'Erreur lors du calcul des bonus');
      }
    } catch (error) {
      console.error('Erreur lors du calcul des bonus mensuels:', error);
      toast.error('Erreur lors du calcul des bonus');
    } finally {
      setCalculLoading(false);
    }
  };

  // Fonction pour calculer les bonus du mois actuel
  const handleCalculerBonusMoisActuel = async () => {
    setCalculLoading(true);
    try {
      const response = await bonusService.calculerBonusMoisActuel(
        Number(parametresCalcul.tauxBonus),
        parametresCalcul.forceCalculation
      );
      
      if (response.success) {
        toast.success(response.message || 'Bonus calculés pour le mois actuel');
        fetchData(); // Recharger les données
      } else {
        toast.error(response.message || 'Erreur lors du calcul des bonus');
      }
    } catch (error) {
      console.error('Erreur lors du calcul des bonus pour le mois actuel:', error);
      toast.error('Erreur lors du calcul des bonus');
    } finally {
      setCalculLoading(false);
    }
  };

  // Fonction pour approuver une demande de retrait
  const handleApprouverRetrait = async (idDemande) => {
    setApprobationLoading(true);
    try {
      // Récupérer l'ID de l'admin depuis le localStorage (ajustez selon votre logique d'authentification)
      const adminInfo = JSON.parse(localStorage.getItem('userInfos') || '{}');
      const idAdmin = adminInfo.id || 'admin-default';
      
      const response = await bonusService.approuverRetraitBonus(idDemande, idAdmin);
      
      if (response.success) {
        toast.success('Retrait de bonus approuvé avec succès');
        fetchData(); // Recharger les données
      } else {
        toast.error(response.message || 'Erreur lors de l\'approbation du retrait');
      }
    } catch (error) {
      console.error('Erreur lors de l\'approbation du retrait:', error);
      toast.error('Erreur lors de l\'approbation du retrait');
    } finally {
      setApprobationLoading(false);
    }
  };

  // Fonction pour filtrer l'historique des bonus
  const getHistoriqueFiltree = () => {
    return historique
      .filter(bonus => {
        // Filtre de recherche
        if (rechercheHistorique && 
            !bonus.utilisateur.nom.toLowerCase().includes(rechercheHistorique.toLowerCase()) &&
            !bonus.programme.toLowerCase().includes(rechercheHistorique.toLowerCase())) {
          return false;
        }
        
        // Filtre par mois
        if (filtreHistorique.mois !== 'tous' && !bonus.mois.includes(filtreHistorique.mois)) {
          return false;
        }
        
        // Filtre par statut
        if (filtreHistorique.statut !== 'tous' && bonus.statut !== filtreHistorique.statut) {
          return false;
        }
        
        // Filtre par type d'utilisateur
        if (filtreHistorique.type !== 'tous' && bonus.utilisateur.type !== filtreHistorique.type) {
          return false;
        }
        
        return true;
      })
      .sort((a, b) => {
        let aValue, bValue;
        
        if (triHistorique.key === 'utilisateur') {
          aValue = a.utilisateur.nom;
          bValue = b.utilisateur.nom;
        } else if (triHistorique.key === 'mois') {
          // On trie par date (plus récent d'abord par défaut)
          const [aMois, aAnnee] = a.mois.split(' ');
          const [bMois, bAnnee] = b.mois.split(' ');
          const moisOrdre = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
          
          if (aAnnee !== bAnnee) {
            aValue = parseInt(aAnnee);
            bValue = parseInt(bAnnee);
          } else {
            aValue = moisOrdre.indexOf(aMois);
            bValue = moisOrdre.indexOf(bMois);
          }
        } else if (triHistorique.key === 'datePaiement') {
          // Si on trie par date de paiement
          aValue = a.datePaiement || '0';
          bValue = b.datePaiement || '0';
        } else {
          aValue = a[triHistorique.key];
          bValue = b[triHistorique.key];
        }
        
        if (aValue < bValue) {
          return triHistorique.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return triHistorique.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
  };
  
  // Fonction pour obtenir les bonus payés filtrés
  const getBonusPayesFiltre = () => {
    return bonusPayes
      .filter(bonus => {
        // Filtre de recherche
        if (rechercheHistorique && 
            !(bonus.nomUser || '').toLowerCase().includes(rechercheHistorique.toLowerCase())) {
          return false;
        }
        
        // Filtre par mois et année
        if (filtreHistorique.mois !== 'tous') {
          const moisAnnee = filtreHistorique.mois.split(' ');
          if (moisAnnee.length === 2) {
            const moisIndex = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'].indexOf(moisAnnee[0]) + 1;
            if (parseInt(bonus.mois) !== moisIndex || parseInt(bonus.annee) !== parseInt(moisAnnee[1])) {
              return false;
            }
          }
        }
        
        return true;
      })
      .sort((a, b) => {
        let aValue, bValue;
        
        if (triHistorique.key === 'utilisateur') {
          aValue = a.nomUser || '';
          bValue = b.nomUser || '';
        } else if (triHistorique.key === 'mois') {
          // On trie par date (plus récent d'abord par défaut)
          if (a.annee !== b.annee) {
            aValue = parseInt(a.annee);
            bValue = parseInt(b.annee);
          } else {
            aValue = parseInt(a.mois);
            bValue = parseInt(b.mois);
          }
        } else if (triHistorique.key === 'datePaiement') {
          // Si on trie par date de paiement
          aValue = a.dateApprobation || a.datePaiement || '0';
          bValue = b.dateApprobation || b.datePaiement || '0';
        } else if (triHistorique.key === 'montant') {
          aValue = parseFloat(a.montant) || 0;
          bValue = parseFloat(b.montant) || 0;
        } else {
          aValue = a[triHistorique.key] || '';
          bValue = b[triHistorique.key] || '';
        }
        
        if (aValue < bValue) {
          return triHistorique.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return triHistorique.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
  };

  // Obtenir l'historique filtré
  const historiqueFiltree = getHistoriqueFiltree();

  // Mois uniques pour le filtre
  const moisUniques = [...new Set(historique.map(bonus => bonus.mois))];

  return (
    <div className="w-full">
      {/* En-tête de la page */}
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-white mb-2">Bonus mensuels</h1>
        <p className="text-gray-400">Gérez les programmes de bonus et les attributions</p>
      </div>

      {/* Cartes de statistiques */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-8">
        <div className="bg-gris rounded-xl p-5 shadow-lg flex items-center justify-between">
          <div>
            <p className="text-gray-400 text-sm">Programmes actifs</p>
            <h3 className="text-white text-2xl font-bold mt-1">
              {programmes.filter(p => p.actif).length}
            </h3>
          </div>
          <div className="bg-bleu bg-opacity-20 p-3 rounded-full">
            <FaPercentage className="text-bleu text-xl" />
          </div>
        </div>

        <div className="bg-gris rounded-xl p-5 shadow-lg flex items-center justify-between">
          <div>
            <p className="text-gray-400 text-sm">Bonus payés</p>
            <h3 className="text-white text-2xl font-bold mt-1">
              {loading ? (
                <FaSpinner className="animate-spin text-white" />
              ) : (
                `${formatMontant(statsBonusPaids.montantTotal || statistiques.montantTotalPaye)} F`
              )}
            </h3>
          </div>
          <div className="bg-vert bg-opacity-20 p-3 rounded-full">
            <FaCoins className="text-vert text-xl" />
          </div>
        </div>

        <div className="bg-gris rounded-xl p-5 shadow-lg flex items-center justify-between">
          <div>
            <p className="text-gray-400 text-sm">Bonus en attente</p>
            <h3 className="text-white text-2xl font-bold mt-1">
              {loading ? (
                <FaSpinner className="animate-spin text-white" />
              ) : (
                `${formatMontant(statistiques.montantTotalDemande - statistiques.montantTotalPaye)} F`
              )}
            </h3>
          </div>
          <div className="bg-softYellow bg-opacity-20 p-3 rounded-full">
            <FaCalendarAlt className="text-softYellow text-xl" />
          </div>
        </div>

        <div className="bg-gris rounded-xl p-5 shadow-lg flex items-center justify-between">
          <div>
            <p className="text-gray-400 text-sm">Bénéficiaires</p>
            <h3 className="text-white text-2xl font-bold mt-1">
              {loading ? (
                <FaSpinner className="animate-spin text-white" />
              ) : (
                bonusPayes.length || statistiques.nombreUtilisateursAvecBonus
              )}
            </h3>
          </div>
          <div className="bg-softPurple bg-opacity-20 p-3 rounded-full">
            <FaUsersCog className="text-softPurple text-xl" />
          </div>
        </div>
      </div>

      {/* Section de calcul de bonus */}
      <div className="mb-10 bg-gris rounded-xl p-6 shadow-lg">
        <h2 className="text-xl font-bold text-white mb-4">Calculer les bonus</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <h3 className="text-lg font-semibold text-white mb-3">Pour un mois spécifique</h3>
            <div className="space-y-4">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-gray-400 text-sm mb-1">Mois</label>
                  <select 
                    name="mois"
                    value={parametresCalcul.mois}
                    onChange={handleParametresCalculChange}
                    className="w-full py-2 px-3 bg-noir border border-gray-700 rounded-lg text-white text-sm focus:outline-none focus:border-bleu"
                  >
                    {Array.from({ length: 12 }, (_, i) => (
                      <option key={i} value={i + 1}>{getNomMois(i + 1)}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="block text-gray-400 text-sm mb-1">Année</label>
                  <select
                    name="annee"
                    value={parametresCalcul.annee}
                    onChange={handleParametresCalculChange}
                    className="w-full py-2 px-3 bg-noir border border-gray-700 rounded-lg text-white text-sm focus:outline-none focus:border-bleu"
                  >
                    {Array.from({ length: 5 }, (_, i) => {
                      const year = new Date().getFullYear() - 2 + i;
                      return <option key={year} value={year}>{year}</option>;
                    })}
                  </select>
                </div>
              </div>
              <div>
                <label className="block text-gray-400 text-sm mb-1">Taux de bonus (%)</label>
                <input 
                  type="number"
                  name="tauxBonus"
                  value={parametresCalcul.tauxBonus}
                  onChange={handleParametresCalculChange}
                  min="0.1"
                  max="10"
                  step="0.1"
                  className="w-full py-2 px-3 bg-noir border border-gray-700 rounded-lg text-white text-sm focus:outline-none focus:border-bleu"
                />
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  name="forceCalculation"
                  checked={parametresCalcul.forceCalculation}
                  onChange={handleParametresCalculChange}
                  className="mr-2"
                />
                <label className="text-gray-300 text-sm">Forcer le recalcul (y compris les bonus déjà payés)</label>
              </div>
              <div className="flex gap-2">
                <button
                  onClick={handleCalculerBonusPreview}
                  disabled={calculLoading || previewLoading}
                  className="bg-softPurple hover:bg-purple-600 text-white py-2 px-4 rounded-lg transition-colors flex items-center justify-center"
                >
                  {previewLoading ? (
                    <>
                      <FaSpinner className="animate-spin mr-2" />
                      Calcul de l'aperçu...
                    </>
                  ) : (
                    <>
                      <FiEye className="mr-2" />
                      Aperçu et approbation
                    </>
                  )}
                </button>
                <button
                  onClick={handleCalculerBonusMensuel}
                  disabled={calculLoading || previewLoading}
                  className="bg-bleu hover:bg-blue-600 text-white py-2 px-4 rounded-lg transition-colors flex items-center justify-center"
                >
                  {calculLoading ? (
                    <>
                      <FaSpinner className="animate-spin mr-2" />
                      Calcul en cours...
                    </>
                  ) : (
                    'Calculer sans approbation'
                  )}
                </button>
              </div>
            </div>
          </div>
          <div>
            <h3 className="text-lg font-semibold text-white mb-3">Pour le mois actuel</h3>
            <p className="text-gray-300 mb-4">
              Calcule automatiquement les bonus pour tous les clients pour le mois actuel ({getNomMois(new Date().getMonth() + 1)} {new Date().getFullYear()}).
            </p>
            <button
              onClick={handleCalculerBonusMoisActuel}
              disabled={calculLoading}
              className="bg-bleu hover:bg-blue-600 text-white py-2 px-4 rounded-lg transition-colors flex items-center justify-center w-full"
            >
              {calculLoading ? (
                <>
                  <FaSpinner className="animate-spin mr-2" />
                  Calcul en cours...
                </>
              ) : (
                'Calculer les bonus du mois actuel'
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Demandes de retrait en attente */}
      {demandesRetrait.length > 0 && demandesRetrait.some(demande => demande.statut === 'en attente') && (
        <div className="mb-10 bg-gris rounded-xl shadow-lg overflow-hidden">
          <div className="bg-softYellow bg-opacity-10 border-l-4 border-softYellow px-6 py-4">
            <h2 className="text-xl font-bold text-white mb-2">Demandes de retrait en attente</h2>
            <p className="text-gray-300 mb-4">Les demandes suivantes nécessitent votre approbation</p>
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-700">
              <thead className="bg-noir">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Client
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Montant
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Période
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Numéro de réception
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Date de demande
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-700">
                {demandesRetrait
                  .filter(demande => demande.statut === 'en attente')
                  .map(demande => (
                    <tr key={demande.id} className="hover:bg-noir transition-colors">
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                        {demande.nomUser}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-white font-medium">
                        {formatMontant(demande.montant)} F
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                        {getNomMois(demande.mois)} {demande.annee}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                        {demande.numeroReception}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                        {new Date(demande.dateDemande).toLocaleDateString('fr-FR')}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        <button
                          onClick={() => handleApprouverRetrait(demande.id)}
                          disabled={approbationLoading}
                          className="bg-vert text-white py-1 px-3 rounded flex items-center hover:bg-opacity-80 transition-colors"
                        >
                          {approbationLoading ? (
                            <FaSpinner className="animate-spin" />
                          ) : (
                            <>
                              <FiCheckCircle className="mr-1" /> Approuver
                            </>
                          )}
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* Section des programmes de bonus */}
      <div className="mb-10">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-bold text-white">Programme de bonus</h2>
        </div>

        <div className="space-y-4">
          {programmes.map(programme => (
            <div 
              key={programme.id} 
              className={`bg-gris rounded-xl shadow-lg overflow-hidden border-l-4 ${programme.actif ? 'border-vert' : 'border-gray-500'}`}
            >
              <div 
                className="flex justify-between items-center p-5 cursor-pointer"
                onClick={() => toggleProgramme(programme.id)}
              >
                <div className="flex-1">
                  <div className="flex items-center gap-2">
                    <h3 className="text-white font-semibold">{programme.nom}</h3>
                    <span className={`px-2 py-0.5 rounded-full text-xs ${
                      programme.actif 
                        ? 'bg-vert bg-opacity-10 text-vert' 
                        : 'bg-gray-500 bg-opacity-10 text-gray-400'
                    }`}>
                      {programme.actif ? 'Actif' : 'Inactif'}
                    </span>
                  </div>
                  <p className="text-gray-400 text-sm mt-1">{programme.description}</p>
                </div>
                <div className="flex items-center gap-4">
                  <span className="text-gray-400 text-sm">
                    {programme.typeUtilisateur === 'agents' ? 'Agents' : 'Clients'}
                  </span>
                  {programmeOuvert === programme.id ? <FiChevronUp /> : <FiChevronDown />}
                </div>
              </div>
              
              {programmeOuvert === programme.id && (
                <div className="p-5 border-t border-gray-700">
                  <h4 className="text-white font-medium mb-3">Règles de calcul</h4>
                  <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-700">
                      <thead className="bg-noir">
                        <tr>
                          <th className="px-4 py-2 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                            {programme.typeUtilisateur === 'agents' ? 'Seuil de volume (F)' : 'Seuil de dépôt (F)'}
                          </th>
                          <th className="px-4 py-2 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                            Pourcentage de bonus
                          </th>
                          <th className="px-4 py-2 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                            Exemple de montant
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-700">
                        {programme.regles.map((regle, index) => (
                          <tr key={index} className="hover:bg-noir transition-colors">
                            <td className="px-4 py-2 whitespace-nowrap text-sm text-white">
                              {`${formatMontant(regle.seuil)} F et plus`}
                            </td>
                            <td className="px-4 py-2 whitespace-nowrap text-sm text-white">
                              {regle.pourcentage}%
                            </td>
                            <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-300">
                              {formatMontant(100000 * regle.pourcentage / 100)} F pour 100 000 F de dépôts
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <p className="text-gray-400 text-xs mt-3">
                    Programme créé le {programme.dateCreation}
                  </p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Modal d'aperçu des bonus */}
      {showPreview && (
        <div className="fixed inset-0 bg-black bg-opacity-80 z-50 flex items-center justify-center p-4" style={{position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, overflow: 'hidden', zIndex: 9999}}>
          <div className="w-full max-w-6xl max-h-[90vh] overflow-y-auto" style={{border: '1px solid #333', boxShadow: '0 20px 25px -5px rgba(0, 0, 0, 0.8)'}}>
            <BonusPreview 
              previewData={bonusPreviewData}
              onApprove={handleApprouverBonusMensuel}
              onCancel={handleFermerApercu}
              loading={previewLoading}
              approvalLoading={approbationLoading}
            />
          </div>
        </div>
      )}

      {/* Section des bonus payés */}
      <div className="mb-12">
        <div className="flex items-center gap-2 mb-6">
          <FaCoins className="text-vert" />
          <h2 className="text-xl font-bold text-white">Bonus payés</h2>
        </div>
        
        {/* Tableau des bonus payés */}
        <div className="bg-gris rounded-xl shadow-lg overflow-hidden">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-700">
              <thead className="bg-noir">
                <tr>
                  <th 
                    className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleTriHistorique('id')}
                  >
                    <div className="flex items-center">
                      ID
                      {triHistorique.key === 'id' && (
                        triHistorique.direction === 'ascending' ? <FiArrowUp className="ml-1" /> : <FiArrowDown className="ml-1" />
                      )}
                    </div>
                  </th>
                  <th 
                    className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleTriHistorique('utilisateur')}
                  >
                    <div className="flex items-center">
                      Bénéficiaire
                      {triHistorique.key === 'utilisateur' && (
                        triHistorique.direction === 'ascending' ? <FiArrowUp className="ml-1" /> : <FiArrowDown className="ml-1" />
                      )}
                    </div>
                  </th>
                  <th 
                    className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleTriHistorique('mois')}
                  >
                    <div className="flex items-center">
                      Période
                      {triHistorique.key === 'mois' && (
                        triHistorique.direction === 'ascending' ? <FiArrowUp className="ml-1" /> : <FiArrowDown className="ml-1" />
                      )}
                    </div>
                  </th>
                  <th 
                    className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleTriHistorique('montant')}
                  >
                    <div className="flex items-center">
                      Montant
                      {triHistorique.key === 'montant' && (
                        triHistorique.direction === 'ascending' ? <FiArrowUp className="ml-1" /> : <FiArrowDown className="ml-1" />
                      )}
                    </div>
                  </th>
                  <th 
                    className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleTriHistorique('datePaiement')}
                  >
                    <div className="flex items-center">
                      Date de paiement
                      {triHistorique.key === 'datePaiement' && (
                        triHistorique.direction === 'ascending' ? <FiArrowUp className="ml-1" /> : <FiArrowDown className="ml-1" />
                      )}
                    </div>
                  </th>
                  <th 
                    className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                  >
                    Demande client
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-700">
                {bonusPayes.length > 0 ? (
                  getBonusPayesFiltre().map((bonus) => (
                    <tr key={bonus.id || `paid-${Math.random()}`} className="hover:bg-noir transition-colors">
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                        {bonus.id}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        <div className="text-white">{bonus.nomUser || 'Client inconnu'}</div>
                        <div className="text-gray-400 text-xs capitalize">
                          client
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                        {bonus.mois && bonus.annee ? `${getNomMois(parseInt(bonus.mois))} ${bonus.annee}` : 'Date inconnue'}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        <div className="text-white font-medium">{formatMontant(bonus.montant)} F</div>
                        {bonus.numeroReception && (
                          <div className="text-gray-400 text-xs">
                            MoMo: {bonus.numeroReception}
                          </div>
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                        {bonus.dateApprobation ? new Date(bonus.dateApprobation).toISOString().split('T')[0] : 
                         bonus.datePaiement ? new Date(bonus.datePaiement).toISOString().split('T')[0] : '-'}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        {bonus.demandeFaitParClient === true ? (
                          <span className="px-2 py-1 rounded-full text-xs bg-vert bg-opacity-10 text-vert">
                            Demande client
                          </span>
                        ) : (
                          <span className="px-2 py-1 rounded-full text-xs bg-gray-500 bg-opacity-10 text-gray-400">
                            Calcul auto
                          </span>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="px-6 py-8 text-center text-gray-400">
                      {loading ? 'Chargement des données...' : 'Aucun bonus payé trouvé.'}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      
      {/* Historique des attributions de bonus */}
      <div>
        <div className="flex items-center gap-2 mb-6">
          <FaHistory className="text-bleu" />
          <h2 className="text-xl font-bold text-white">Historique des bonus</h2>
        </div>

        {/* Filtres et recherche pour l'historique */}
        <div className="flex flex-col md:flex-row gap-4 justify-between mb-6">
          <div className="flex-1">
            <div className="relative">
              <input
                type="text"
                placeholder="Rechercher un bénéficiaire ou un programme..."
                className="w-full py-2 pl-10 pr-4 bg-gris border border-gray-700 rounded-lg text-white text-sm focus:outline-none focus:border-bleu"
                value={rechercheHistorique}
                onChange={handleRechercheHistorique}
              />
              <div className="absolute left-3 top-2.5 text-gray-400">
                <FiSearch />
              </div>
            </div>
          </div>
          
          <div className="flex flex-wrap gap-3">
            <select
              name="mois"
              value={filtreHistorique.mois}
              onChange={handleFiltreHistorique}
              className="bg-gris border border-gray-700 rounded-lg text-white text-sm py-2 px-3 focus:outline-none focus:border-bleu"
            >
              <option value="tous">Tous les mois</option>
              {moisUniques.map((mois, index) => (
                <option key={index} value={mois}>{mois}</option>
              ))}
            </select>
            
            <select
              name="statut"
              value={filtreHistorique.statut}
              onChange={handleFiltreHistorique}
              className="bg-gris border border-gray-700 rounded-lg text-white text-sm py-2 px-3 focus:outline-none focus:border-bleu"
            >
              <option value="tous">Tous les statuts</option>
              <option value="payé">Payé</option>
              <option value="en attente">En attente</option>
            </select>
            
            <select
              name="type"
              value={filtreHistorique.type}
              onChange={handleFiltreHistorique}
              className="bg-gris border border-gray-700 rounded-lg text-white text-sm py-2 px-3 focus:outline-none focus:border-bleu"
            >
              <option value="tous">Tous les types</option>
              <option value="client">Clients</option>
              <option value="global">Global</option>
            </select>
            
            <button
              onClick={() => {
                setFiltreHistorique({ mois: 'tous', statut: 'tous', type: 'tous' });
                setRechercheHistorique('');
              }}
              className="flex items-center gap-1 text-bleu hover:text-blue-400 transition-colors"
            >
              <FiRefreshCw size={16} /> Réinitialiser
            </button>
            
            <button
              onClick={fetchData}
              className="flex items-center gap-1 bg-gris border border-gray-700 rounded-lg text-white text-sm py-2 px-3 hover:bg-gray-800 transition-colors"
            >
              <FiRefreshCw size={16} /> Actualiser
            </button>
          </div>
        </div>

        {/* Tableau de l'historique */}
        {loading ? (
          <div className="flex justify-center items-center py-10">
            <FaSpinner className="animate-spin text-bleu text-3xl" />
          </div>
        ) : (
          <div className="bg-gris rounded-xl shadow-lg overflow-hidden">
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-700">
                <thead className="bg-noir">
                  <tr>
                    <th 
                      className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer"
                      onClick={() => handleTriHistorique('id')}
                    >
                      <div className="flex items-center">
                        ID
                        {triHistorique.key === 'id' && (
                          triHistorique.direction === 'ascending' ? <FiArrowUp className="ml-1" /> : <FiArrowDown className="ml-1" />
                        )}
                      </div>
                    </th>
                    <th 
                      className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer"
                      onClick={() => handleTriHistorique('utilisateur')}
                    >
                      <div className="flex items-center">
                        Bénéficiaire
                        {triHistorique.key === 'utilisateur' && (
                          triHistorique.direction === 'ascending' ? <FiArrowUp className="ml-1" /> : <FiArrowDown className="ml-1" />
                        )}
                      </div>
                    </th>
                    <th 
                      className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer"
                      onClick={() => handleTriHistorique('programme')}
                    >
                      <div className="flex items-center">
                        Programme
                        {triHistorique.key === 'programme' && (
                          triHistorique.direction === 'ascending' ? <FiArrowUp className="ml-1" /> : <FiArrowDown className="ml-1" />
                        )}
                      </div>
                    </th>
                    <th 
                      className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer"
                      onClick={() => handleTriHistorique('mois')}
                    >
                      <div className="flex items-center">
                        Période
                        {triHistorique.key === 'mois' && (
                          triHistorique.direction === 'ascending' ? <FiArrowUp className="ml-1" /> : <FiArrowDown className="ml-1" />
                        )}
                      </div>
                    </th>
                    <th 
                      className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer"
                      onClick={() => handleTriHistorique('montant')}
                    >
                      <div className="flex items-center">
                        Montant
                        {triHistorique.key === 'montant' && (
                          triHistorique.direction === 'ascending' ? <FiArrowUp className="ml-1" /> : <FiArrowDown className="ml-1" />
                        )}
                      </div>
                    </th>
                    <th 
                      className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer"
                      onClick={() => handleTriHistorique('statut')}
                    >
                      <div className="flex items-center">
                        Statut
                        {triHistorique.key === 'statut' && (
                          triHistorique.direction === 'ascending' ? <FiArrowUp className="ml-1" /> : <FiArrowDown className="ml-1" />
                        )}
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-700">
                  {historiqueFiltree.length > 0 ? (
                    historiqueFiltree.map((bonus) => (
                      <tr key={bonus.id} className="hover:bg-noir transition-colors">
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                          {bonus.id}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          <div className="text-white">{bonus.utilisateur.nom}</div>
                          <div className="text-gray-400 text-xs capitalize">
                            {bonus.utilisateur.type === 'global' 
                              ? (bonus.nombreUtilisateurs > 0 ? `${bonus.nombreUtilisateurs} utilisateurs` : 'Tous')
                              : bonus.utilisateur.type
                            }
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                          {bonus.programme}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                          {bonus.mois}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          <div className="text-white font-medium">{formatMontant(bonus.montant)} F</div>
                          <div className="text-gray-400 text-xs">
                            {bonus.pourcentage}% sur {formatMontant(bonus.volumeReference)} F
                          </div>
                          {bonus.numeroReception && (
                            <div className="text-gray-400 text-xs">
                              MoMo: {bonus.numeroReception}
                            </div>
                          )}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          <div>
                            <span className={`px-2 py-1 rounded-full text-xs ${
                              bonus.statut === 'payé' 
                                ? 'bg-vert bg-opacity-10 text-vert' 
                                : 'bg-softYellow bg-opacity-10 text-softYellow'
                            }`}>
                              {bonus.statut === 'payé' ? (
                                bonus.datePaiement ? `Payé le ${bonus.datePaiement}` : 'Payé'
                              ) : 'En attente'}
                            </span>
                          </div>
                          {bonus.demandeParClient && (
                            <div className="mt-1">
                              <span className="px-2 py-1 rounded-full text-xs bg-bleu bg-opacity-10 text-bleu">
                                Demande client
                              </span>
                            </div>
                          )}
                          {!bonus.demandeParClient && bonus.statut === 'payé' && (
                            <div className="mt-1">
                              <span className="px-2 py-1 rounded-full text-xs bg-gray-500 bg-opacity-10 text-gray-400">
                                Calcul auto
                              </span>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" className="px-6 py-8 text-center text-gray-400">
                        {loading ? 'Chargement des données...' : 'Aucun bonus trouvé avec les critères de recherche actuels.'}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BonusList;