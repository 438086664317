import React, { useState, useContext } from 'react';
import InfosContext from '../../contexts/InfosAppProvider';
import DashboardHeader from '../components/DashboardHeader';
import BottomNavBar from '../components/BottomNavBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faArrowDown, 
  faArrowUp, 
  faCheck, 
  faTimes, 
  faClock, 
  faFilter,
  faSpinner,
  faChevronLeft,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';

export default function TransactionsList() {
  const { userInfos, momoTransactions } = useContext(InfosContext);
  const [activeTab, setActiveTab] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const transactionsPerPage = 8;

  if (!userInfos || !momoTransactions) {
    return (
      <div className="min-h-screen bg-noir flex items-center justify-center">
        <div className="w-16 h-16 border-4 border-bleu border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  // Filtrer les transactions selon l'onglet actif
  const filteredTransactions = momoTransactions.filter(transaction => {
    if (activeTab === 'all') return true;
    if (activeTab === 'depot') return transaction.type === 'depot';
    if (activeTab === 'retrait') return transaction.type === 'retrait';
    if (activeTab === 'successful') return transaction.status === 'SUCCESSFUL';
    if (activeTab === 'failed') return transaction.status === 'FAILED';
    if (activeTab === 'pending') return transaction.status === 'PENDING';
    return true;
  });

  // Calculer les transactions à afficher pour la page courante
  const indexOfLastTransaction = currentPage * transactionsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
  const currentTransactions = filteredTransactions.slice(indexOfFirstTransaction, indexOfLastTransaction);

  // Changer de page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const totalPages = Math.ceil(filteredTransactions.length / transactionsPerPage);

  const getStatusIcon = (status) => {
    switch (status) {
      case 'SUCCESSFUL': return faCheck;
      case 'FAILED': return faTimes;
      case 'PENDING': return faClock;
      default: return faSpinner;
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'SUCCESSFUL': return 'text-vert';
      case 'FAILED': return 'text-softRed';
      case 'PENDING': return 'text-softYellow';
      default: return 'text-gray-400';
    }
  };

  const getStatusBgColor = (status) => {
    switch (status) {
      case 'SUCCESSFUL': return 'bg-vert/10';
      case 'FAILED': return 'bg-softRed/10';
      case 'PENDING': return 'bg-softYellow/10';
      default: return 'bg-gray-700';
    }
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const tabs = [
    { id: 'all', label: 'Toutes' },
    { id: 'depot', label: 'Dépôts', icon: faArrowDown, color: 'text-bleu' },
    { id: 'retrait', label: 'Retraits', icon: faArrowUp, color: 'text-vert' },
    { id: 'successful', label: 'Réussies', icon: faCheck, color: 'text-vert' },
    { id: 'failed', label: 'Échouées', icon: faTimes, color: 'text-softRed' },
    { id: 'pending', label: 'En attente', icon: faClock, color: 'text-softYellow' }
  ];

  return (
    <div className="min-h-screen bg-noir text-white">
      <DashboardHeader user={userInfos} />
      
      <div className="container mx-auto px-4 py-6 md:pl-72 pb-24 md:pb-6">
        <div className="flex items-center justify-between mb-6">
          <h1 className="text-2xl font-bold">Transactions MTN</h1>
          
          <div className="flex items-center space-x-2">
            <div className="bg-gris px-4 py-2 rounded-lg text-gray-400 text-sm">
              <strong className="text-bleu">{filteredTransactions.length}</strong> transactions
            </div>
            
            <div className="relative">
              <div className="p-2 bg-gris rounded-lg text-gray-300">
                <FontAwesomeIcon icon={faFilter} />
              </div>
            </div>
          </div>
        </div>
        
        {/* Tabs */}
        <div className="mb-8 overflow-x-auto">
          <div className="flex space-x-2 min-w-max">
            {tabs.map(tab => (
              <button 
                key={tab.id}
                onClick={() => {
                  setActiveTab(tab.id);
                  setCurrentPage(1);
                }}
                className={`flex items-center space-x-2 px-4 py-2 rounded-lg transition ${
                  activeTab === tab.id 
                    ? 'bg-bleu text-white' 
                    : 'bg-gris text-gray-400 hover:bg-gris/70'
                }`}
              >
                {tab.icon && (
                  <FontAwesomeIcon 
                    icon={tab.icon} 
                    className={activeTab === tab.id ? 'text-white' : tab.color} 
                  />
                )}
                <span>{tab.label}</span>
              </button>
            ))}
          </div>
        </div>
        
        {/* Transactions List */}
        <div className="bg-gris rounded-xl overflow-hidden shadow-lg mb-6 border border-bleu/10">
          {currentTransactions.length > 0 ? (
            <>
              <div className="overflow-x-auto">
                <table className="w-full min-w-full divide-y divide-bleu/10">
                  <thead className="bg-noir/30">
                    <tr>
                      <th className="px-6 py-4 text-left text-xs font-semibold text-gray-400 uppercase tracking-wider">
                        Type
                      </th>
                      <th className="px-6 py-4 text-left text-xs font-semibold text-gray-400 uppercase tracking-wider">
                        Numéro
                      </th>
                      <th className="px-6 py-4 text-left text-xs font-semibold text-gray-400 uppercase tracking-wider">
                        ID Transaction
                      </th>
                      <th className="px-6 py-4 text-left text-xs font-semibold text-gray-400 uppercase tracking-wider">
                        Date
                      </th>
                      <th className="px-6 py-4 text-left text-xs font-semibold text-gray-400 uppercase tracking-wider">
                        Montant
                      </th>
                      <th className="px-6 py-4 text-left text-xs font-semibold text-gray-400 uppercase tracking-wider">
                        Statut
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-bleu/10">
                    {currentTransactions.map(transaction => (
                      <tr key={transaction.id} className="hover:bg-noir/30 transition">
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className={`w-8 h-8 rounded-full flex items-center justify-center mr-3 
                              ${transaction.type === 'depot' ? 'bg-bleu/10' : 'bg-vert/10'}`}
                            >
                              <FontAwesomeIcon 
                                icon={transaction.type === 'depot' ? faArrowDown : faArrowUp} 
                                className={transaction.type === 'depot' ? 'text-bleu' : 'text-vert'} 
                              />
                            </div>
                            <span className="font-medium">
                              {transaction.type === 'depot' ? 'Dépôt' : 'Retrait'}
                            </span>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                          {transaction.partyId}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                          {transaction.externalId.substring(0, 8)}...
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                          {formatDate(transaction.updatedAt || transaction.createdAt)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="font-bold">
                            {transaction.amount} {transaction.webhookData?.currency || 'XOF'}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className={`inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium
                            ${getStatusBgColor(transaction.status)} ${getStatusColor(transaction.status)}`}
                          >
                            <FontAwesomeIcon icon={getStatusIcon(transaction.status)} className="mr-1" />
                            {transaction.status}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              
              {/* Pagination */}
              {totalPages > 1 && (
                <div className="bg-noir/20 px-6 py-3 flex items-center justify-between border-t border-bleu/10">
                  <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                    <div>
                      <p className="text-sm text-gray-400">
                        Affichage de <span className="font-medium text-white">{indexOfFirstTransaction + 1}</span> à <span className="font-medium text-white">
                          {Math.min(indexOfLastTransaction, filteredTransactions.length)}
                        </span> sur <span className="font-medium text-white">{filteredTransactions.length}</span> résultats
                      </p>
                    </div>
                    <div>
                      <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                        <button
                          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                          disabled={currentPage === 1}
                          className={`relative inline-flex items-center rounded-l-md px-2 py-2 ${
                            currentPage === 1 
                              ? 'text-gray-600 cursor-not-allowed' 
                              : 'text-gray-400 hover:bg-gris'
                          }`}
                        >
                          <FontAwesomeIcon icon={faChevronLeft} className="h-3 w-3" />
                        </button>
                        
                        {[...Array(totalPages)].map((_, index) => {
                          const pageNum = index + 1;
                          const isVisible = 
                            pageNum === 1 || 
                            pageNum === totalPages || 
                            (pageNum >= currentPage - 1 && pageNum <= currentPage + 1);
                          
                          if (!isVisible && pageNum === 2) {
                            return (
                              <span key="ellipsis-start" className="relative inline-flex items-center px-4 py-2 text-sm text-gray-500">
                                ...
                              </span>
                            );
                          }
                          
                          if (!isVisible && pageNum === totalPages - 1) {
                            return (
                              <span key="ellipsis-end" className="relative inline-flex items-center px-4 py-2 text-sm text-gray-500">
                                ...
                              </span>
                            );
                          }
                          
                          if (!isVisible) {
                            return null;
                          }
                          
                          return (
                            <button
                              key={pageNum}
                              onClick={() => paginate(pageNum)}
                              className={`relative inline-flex items-center px-4 py-2 text-sm ${
                                currentPage === pageNum
                                  ? 'bg-bleu text-white font-medium'
                                  : 'text-gray-400 hover:bg-gris'
                              }`}
                            >
                              {pageNum}
                            </button>
                          );
                        })}
                        
                        <button
                          onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                          disabled={currentPage === totalPages}
                          className={`relative inline-flex items-center rounded-r-md px-2 py-2 ${
                            currentPage === totalPages 
                              ? 'text-gray-600 cursor-not-allowed' 
                              : 'text-gray-400 hover:bg-gris'
                          }`}
                        >
                          <FontAwesomeIcon icon={faChevronRight} className="h-3 w-3" />
                        </button>
                      </nav>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="py-20 text-center">
              <div className="w-16 h-16 mx-auto bg-noir/20 rounded-full flex items-center justify-center mb-4">
                <FontAwesomeIcon icon={faSpinner} className="text-gray-500 text-xl" />
              </div>
              <h3 className="text-lg font-medium text-gray-300 mb-1">Aucune transaction trouvée</h3>
              <p className="text-gray-500">
                Changez les filtres ou réessayez plus tard
              </p>
            </div>
          )}
        </div>
      </div>
      
      <BottomNavBar />
    </div>
  );
}