import React, { useState, useContext } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";

import InfosContextAdmin from "../../contexts/admin/AdminInfosAppProvider";
import InfosContext from "../../contexts/InfosAppProvider";

import Swal from "sweetalert2";

import { CopyToClipboard } from "react-copy-to-clipboard";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css"; // Importation des styles
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import du CSS

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowDown,
  faArrowUp,
  faCheck,
  faTimes,
  faCopy
} from "@fortawesome/free-solid-svg-icons";

import updateTransaction from "./updateTransaction";
import { messagea } from "./alert/message";

const AccepterRetrait = () => {
  //const { id } = useParams();
  const navigate = useNavigate(); // Utilisation de useNavigate

  const { id, idTran } = useParams();

  //const [textToCopy, setTextToCopy] = useState("Voici le texte à copier !");

  const notifySuccess = () => {
    toast.success("code copié", {
      //   position: toast.POSITION.TOP_RIGHT,
      //   autoClose: 3000, // 3 secondes avant de disparaître
    });
  };

  const [loading, setLoading] = useState(false); // Etat pour indiquer si le chargement est en cours
  const [progress, setProgress] = useState(66); // Progression fixe pour l'exemple
  const [loadingd, setLoadingd] = useState(false); // Etat pour indiquer si le chargement est en cours

  const transaction = {
    idCompte1xbet: "111222",
    idTransaction: "-O96NnLdcXJb1vV9v_On",
    idTransactionMobile: "5555888889",
    idUser: "5",
    montant: "4000",
    moyenPaiement: "MTN",
    statut: "en cours",
    type: "depot",
  };

  const {
    infosAdmin,
    setInfosAdmin,
    userInfos,
    setUserInfos,
    transactions,
    setTransactions,
    listeUser,
    listeCaisses,
    token,
  } = useContext(InfosContext);

  const infos = Object.values(transactions).find(
    (transaction) => transaction.idTransaction == idTran
  );

  const [textToCopy, setTextToCopy] = useState(`${infos.idCompte1xbet}`);
  // alert(infos.idUser);

  const user = Object.values(listeUser).find(
    (transaction) => transaction.id == infos.idUser
  );

  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(transaction.idCompte1xbet);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Réinitialisation après 2 secondes
  };

  const handleValidate = async () => {
    setLoading(true);

    const re = await updateTransaction(token, infos.idUser, idTran, "terminé");
    console.log("Transaction validée");

    const result = await messagea("retrait validé");
    setLoading(false);
    // Act based on the user's response
    if (result.isConfirmed) {
      //alert("hi");

      navigate("/agents/");
    }
  };

  const handleReject = async () => {
    setLoadingd(true);

    const re = await updateTransaction(token, infos.idUser, idTran, "echoué");
    console.log("Transaction validée");

    const result = await messagea("retrait annulé");
    setLoadingd(false);
    // Act based on the user's response
    if (result.isConfirmed) {
      //alert("hi");
      navigate("/agents/");
    }
  };

  const handleBack = () => {
    window.history.back(); // Retour à la page précédente
  };

  return (
    <>
      <ToastContainer />
      <div className="min-h-screen flex justify-center items-start pt-4 md:pt-12 bg-gradient-to-br from-[#0F172A] to-black">
        <div className="max-w-lg w-full mx-4 bg-[#1C1C1CFF] text-white px-4 sm:px-8 py-5 sm:py-6 rounded-xl shadow-2xl border border-gray-700/50 backdrop-blur-sm relative overflow-hidden">
          {/* Éléments décoratifs en arrière-plan */}
          <div className="absolute -top-24 -right-24 w-48 h-48 bg-bleu/5 rounded-full blur-3xl"></div>
          <div className="absolute -bottom-24 -left-24 w-48 h-48 bg-bleu/5 rounded-full blur-3xl"></div>
          
          {/* En-tête avec bouton de retour */}
          <div className="flex items-center justify-between mb-4 sm:mb-6">
            <button
              onClick={handleBack}
              className="flex items-center py-1.5 sm:py-2 px-3 sm:px-4 bg-noir hover:bg-gray-800 text-white font-medium text-sm sm:text-base rounded-lg shadow-md transition-all duration-300 border border-gray-700/40"
            >
              <FontAwesomeIcon icon={faArrowLeft} className="mr-1.5 sm:mr-2" />
              Retour
            </button>
            
            <div className={`px-2 sm:px-3 py-1 rounded-full text-xs font-medium ${
              infos.statut === "en cours" 
                ? "bg-bleu/20 text-bleu border border-bleu/30" 
                : infos.statut === "terminé" 
                  ? "bg-green-500/20 text-green-500 border border-green-500/30"
                  : "bg-red-500/20 text-red-500 border border-red-500/30"
            }`}>
              {infos.statut.charAt(0).toUpperCase() + infos.statut.slice(1)}
            </div>
          </div>

          {/* Titre et informations sur le type de transaction */}
          <div className="text-center mb-3 sm:mb-6">
            <h2 className="text-xl sm:text-2xl font-bold text-white mb-1">
              Détails du Retrait
            </h2>
            <div className="flex items-center justify-center text-gray-400 text-sm">
              <FontAwesomeIcon icon={faArrowUp} className="text-red-400 mr-2" />
              Transaction de type retrait
            </div>
          </div>

          {/* Montant mis en valeur */}
          <div className="bg-gradient-to-br from-red-500/10 to-gray-800/20 rounded-xl border border-red-500/20 p-3 sm:p-4 mb-4 sm:mb-6 text-center">
            <div className="text-gray-400 text-sm mb-1">Montant</div>
            <div className="text-3xl font-bold text-white">{infos.montant} <span className="text-lg">FCFA</span></div>
          </div>

          {/* Informations de la transaction */}
          <div className="space-y-3 sm:space-y-4 text-gray-300 mb-4 sm:mb-6">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div className="bg-noir rounded-lg p-3 border border-gray-800/60">
                <div className="text-xs text-gray-500 mb-1">ID Compte 1xbet</div>
                <div className="font-medium text-white">{infos.idCompte1xbet}</div>
              </div>
              
              <div className="bg-noir rounded-lg p-3 border border-gray-800/60">
                <div className="text-xs text-gray-500 mb-1">ID Transaction</div>
                <div className="font-medium text-white truncate">{idTran}</div>
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div className="bg-noir rounded-lg p-3 border border-gray-800/60">
                <div className="text-xs text-gray-500 mb-1">Numéro WhatsApp</div>
                <div className="font-medium text-white">{user.whatsapp}</div>
              </div>
              
              <div className="bg-noir rounded-lg p-3 border border-gray-800/60">
                <div className="text-xs text-gray-500 mb-1">Numéro MoMo</div>
                <div className="font-medium text-white">{infos.numeroRetraitMomo}</div>
              </div>
            </div>

            <div className="bg-noir rounded-lg p-3 border border-gray-800/60">
              <div className="text-xs text-gray-500 mb-1">Nom du bénéficiaire MoMo</div>
              <div className="font-medium text-white">{infos.nomMtnMomo}</div>
            </div>
          </div>

          {/* Bouton de copie avec effets visuels améliorés */}
          <CopyToClipboard text={textToCopy} onCopy={notifySuccess}>
            <button
              className={`w-full py-3 mt-2 rounded-xl text-white font-medium shadow-lg transition-all duration-300 border flex items-center justify-center ${
                copied
                  ? "bg-vert border-vert/30 hover:bg-vert/90"
                  : "bg-bleu border-bleu/30 hover:bg-bleu/90"
              }`}
            >
              <FontAwesomeIcon icon={copied ? faCheck : faCopy} className="mr-2" />
              {copied ? "ID Copié avec succès !" : "Copier l'ID Compte 1xbet"}
            </button>
          </CopyToClipboard>

          {/* Actions avec boutons améliorés */}
          <div className="flex flex-col sm:flex-row justify-between sm:space-x-4 space-y-3 sm:space-y-0 mt-6">
            <button
              onClick={handleValidate}
              className="w-full sm:w-1/2 h-12 flex justify-center items-center bg-gradient-to-r from-vert to-emerald-500 hover:from-vert/90 hover:to-emerald-500/90 rounded-xl text-white font-bold shadow-lg hover:shadow-vert/20 transition-all duration-300 border border-vert/30"
            >
              {loading ? (
                <div className="w-5 h-5 mr-2 animate-spin">
                  <CircularProgressbar
                    value={progress}
                    styles={buildStyles({
                      pathColor: "white",
                      trailColor: "rgba(255, 255, 255, 0.2)",
                      strokeLinecap: "round",
                    })}
                  />
                </div>
              ) : (
                <FontAwesomeIcon icon={faCheck} className="mr-2" />
              )}

              <span>{loading ? "Validation..." : "Valider"}</span>
            </button>
            
            <button
              onClick={handleReject}
              className="w-full sm:w-1/2 h-12 flex justify-center items-center bg-gradient-to-r from-red-600 to-red-500 hover:from-red-600/90 hover:to-red-500/90 rounded-xl text-white font-bold shadow-lg hover:shadow-red-500/20 transition-all duration-300 border border-red-500/30"
            >
              {loadingd ? (
                <div className="w-5 h-5 mr-2 animate-spin">
                  <CircularProgressbar
                    value={progress}
                    styles={buildStyles({
                      pathColor: "white",
                      trailColor: "rgba(255, 255, 255, 0.2)",
                      strokeLinecap: "round",
                    })}
                  />
                </div>
              ) : (
                <FontAwesomeIcon icon={faTimes} className="mr-2" />
              )}

              <span>{loadingd ? "Rejet..." : "Rejeter"}</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccepterRetrait;
