import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faClock, faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const TransactionCard = ({
  icon,
  title,
  date,
  amount,
  id,
  bgColor, // Sera utilisé comme couleur de bordure
  type,
  idTran,
  status = "en cours" // Par défaut, on suppose que c'est en cours
}) => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  
  // Déterminer l'icône en fonction du statut
  let statusIcon = faClock;
  
  if (status === "accepté" || status === "accepter" || status === "réussi") {
    statusIcon = faCheckCircle;
  } else if (status === "refusé" || status === "refuser" || status === "échoué") {
    statusIcon = faTimesCircle;
  }
  
  // Tronquer l'ID pour mobile
  const displayId = isMobile && id && id.length > 8 ? `${id.substring(0, 8)}...` : id;
  
  // Formater le montant pour s'assurer qu'il tient sur mobile
  const displayAmount = isMobile ? amount.replace('F CFA', '') : amount;
  
  return (
    <div
      onClick={() => {
        if (type === "depot") navigate(`/agents/accepter/${id}/${idTran}`);
        if (type === "retrait") navigate(`/agents/accepter-retrait/${id}/${idTran}`);
      }}
      className={`bg-gris hover:bg-[#2a2a2b] transition-all duration-300 flex flex-row rounded-xl border ${
        status === "accepté" || status === "accepter" || status === "réussi" 
          ? "border-vert" 
          : status === "refusé" || status === "refuser" || status === "échoué" 
            ? "border-red-500" 
            : "border-yellow-500"
      } opacity-100 mb-3 shadow-sm hover:shadow-md cursor-pointer overflow-hidden mx-1 ${isMobile ? "scale-[0.95]" : ""}`}
    >
      {/* Indicateur coloré à gauche basé sur le statut */}
      <div className={`w-1 ${
        status === "accepté" || status === "accepter" || status === "réussi" 
          ? "bg-vert" 
          : status === "refusé" || status === "refuser" || status === "échoué" 
            ? "bg-red-500" 
            : "bg-yellow-500"
      }`}></div>
      
      <div className="flex flex-1 items-center p-3 md:p-4">
        {/* Icône de gauche avec arrière-plan indiquant l'état */}
        <div
          className={`w-9 h-9 md:w-12 md:h-12 rounded-full flex justify-center items-center mr-2 md:mr-4 flex-shrink-0 border-2 ${
            status === "accepté" || status === "accepter" || status === "réussi" 
              ? "border-vert bg-vert bg-opacity-20" 
              : status === "refusé" || status === "refuser" || status === "échoué" 
                ? "border-red-500 bg-red-500 bg-opacity-20" 
                : "border-yellow-500 bg-yellow-500 bg-opacity-20"
          }`}
        >
          <FontAwesomeIcon icon={icon} className={`text-sm md:text-lg ${
            status === "accepté" || status === "accepter" || status === "réussi" 
              ? "text-vert" 
              : status === "refusé" || status === "refuser" || status === "échoué" 
                ? "text-red-500" 
                : "text-yellow-500"
          }`} />
        </div>

        {/* Infos principales */}
        <div className="flex-1 min-w-0">
          <div className="flex items-center flex-wrap">
            <h3 className="text-white font-medium text-xs md:text-base mr-2 truncate">{title}</h3>
            <span className={`${
              status === "accepté" || status === "accepter" || status === "réussi" 
                ? "text-vert" 
                : status === "refusé" || status === "refuser" || status === "échoué" 
                  ? "text-red-500" 
                  : "text-yellow-500"
            } text-xs flex items-center whitespace-nowrap`}>
              <FontAwesomeIcon icon={statusIcon} className="mr-1" />
              {isMobile ? "" : (status || "En attente")}
            </span>
          </div>
          <div className="text-gray-400 text-xs mt-1 truncate">ID: {displayId}</div>
          <div className="text-gray-400 text-xs truncate">{isMobile ? date.split(' à')[0] : date}</div>
        </div>

        {/* Montant à droite */}
        <div className="text-right flex-shrink-0 ml-2">
          <div className={`text-sm md:text-lg font-bold ${
            status === "accepté" || status === "accepter" || status === "réussi" 
              ? "text-vert" 
              : status === "refusé" || status === "refuser" || status === "échoué" 
                ? "text-red-500" 
                : "text-yellow-500"
          } whitespace-nowrap`}>
            {displayAmount}
          </div>
          <div className="flex items-center justify-end mt-1 text-bleu text-xs md:text-sm">
            <span className="mr-1">{isMobile ? "" : "Traiter"}</span>
            <FontAwesomeIcon icon={faArrowRight} className="text-xs" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionCard;
