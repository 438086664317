import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FiEye, FiSearch, FiRefreshCw } from "react-icons/fi";
import { FaChevronLeft, FaChevronRight, FaUsers, FaUserFriends, FaUserCheck, FaUserTimes, FaWallet } from "react-icons/fa";
import InfosContext from "../../contexts/InfosAppProvider";

const ListClients = () => {
    // État local
    const [searchInput, setSearchInput] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [clientsPerPage, setClientsPerPage] = useState(10);
    const [filter, setFilter] = useState('tous'); // 'tous', 'actif', 'inactif'
    
    // Contexte global
    const {
        infosAdmin,
        setInfosAdmin,
        userInfos,
        setUserInfos,
        transactions,
        setTransactions,
        listeUser,
        token
    } = useContext(InfosContext);

    // État pour les statistiques
    const [stats, setStats] = useState({
        totalClients: 0,
        clientsActifs: 0,
        clientsInactifs: 0,
        totalSolde: 0
    });

    // Fonction pour formater les montants
    const formatMontant = (montant) => {
        return new Intl.NumberFormat('fr-FR').format(montant);
    };

    // Handler pour la recherche
    const inputHandler = (event) => {
        setSearchInput(event.target.value);
    };

    // Filtrer les utilisateurs pour obtenir uniquement ceux avec le rôle "client"
    const clientsArray = Object.values(listeUser).filter(user => user.role === "client");

    // Calcul des statistiques
    useEffect(() => {
        const actifs = clientsArray.filter(client => client.statut === 'actif');
        const inactifs = clientsArray.filter(client => client.statut === 'inactif');
        const totalSolde = clientsArray.reduce((sum, client) => sum + (parseFloat(client.solde) || 0), 0);
        
        setStats({
            totalClients: clientsArray.length,
            clientsActifs: actifs.length,
            clientsInactifs: inactifs.length,
            totalSolde: totalSolde
        });
    }, [listeUser]);

    // Filtrer les clients selon la recherche et le filtre
    const filteredClients = clientsArray.filter(client => {
        // Filtre par statut
        if (filter === 'actif' && client.statut !== 'actif') return false;
        if (filter === 'inactif' && client.statut !== 'inactif') return false;
        
        // Filtre par recherche
        return (
            client.nom?.toLowerCase().includes(searchInput.toLowerCase()) ||
            client.email?.toLowerCase().includes(searchInput.toLowerCase()) ||
            client.whatsapp?.includes(searchInput)
        );
    });

    // Pagination
    const indexOfLastClient = currentPage * clientsPerPage;
    const indexOfFirstClient = indexOfLastClient - clientsPerPage;
    const currentClients = filteredClients.slice(indexOfFirstClient, indexOfLastClient);
    const totalClients = filteredClients.length;

    // Navigation de pagination
    const paginateNext = () => {
        setCurrentPage(prevPage => prevPage + 1);
    };

    const paginatePrevious = () => {
        setCurrentPage(prevPage => (prevPage > 1 ? prevPage - 1 : 1));
    };

    // Handler pour changer le nombre de clients par page
    const handleClientsPerPageChange = (event) => {
        setClientsPerPage(Number(event.target.value));
        setCurrentPage(1);
    };

    // Réinitialiser les filtres
    const resetFilters = () => {
        setFilter('tous');
        setSearchInput('');
        setCurrentPage(1);
    };

    return (
        <div className="w-full">
            {/* En-tête de la page */}
            <div className="mb-8">
                <h1 className="text-2xl font-bold text-white mb-2">Clients</h1>
                <p className="text-gray-400">Gérez vos clients et leurs comptes</p>
            </div>

            {/* Cartes de statistiques */}
            <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-8">
                <div className="bg-gris rounded-xl p-5 shadow-lg flex items-center justify-between">
                    <div>
                        <p className="text-gray-400 text-sm">Total clients</p>
                        <h3 className="text-white text-2xl font-bold mt-1">{stats.totalClients}</h3>
                    </div>
                    <div className="bg-bleu bg-opacity-20 p-3 rounded-full">
                        <FaUsers className="text-bleu text-xl" />
                    </div>
                </div>

                <div className="bg-gris rounded-xl p-5 shadow-lg flex items-center justify-between">
                    <div>
                        <p className="text-gray-400 text-sm">Clients actifs</p>
                        <h3 className="text-white text-2xl font-bold mt-1">
                            {stats.clientsActifs}
                        </h3>
                    </div>
                    <div className="bg-vert bg-opacity-20 p-3 rounded-full">
                        <FaUserCheck className="text-vert text-xl" />
                    </div>
                </div>

                <div className="bg-gris rounded-xl p-5 shadow-lg flex items-center justify-between">
                    <div>
                        <p className="text-gray-400 text-sm">Clients inactifs</p>
                        <h3 className="text-white text-2xl font-bold mt-1">
                            {stats.clientsInactifs}
                        </h3>
                    </div>
                    <div className="bg-softRed bg-opacity-20 p-3 rounded-full">
                        <FaUserTimes className="text-softRed text-xl" />
                    </div>
                </div>

                <div className="bg-gris rounded-xl p-5 shadow-lg flex items-center justify-between">
                    <div>
                        <p className="text-gray-400 text-sm">Solde total</p>
                        <h3 className="text-white text-2xl font-bold mt-1">
                            {formatMontant(stats.totalSolde)} F
                        </h3>
                    </div>
                    <div className="bg-softPurple bg-opacity-20 p-3 rounded-full">
                        <FaWallet className="text-softPurple text-xl" />
                    </div>
                </div>
            </div>

            {/* Barre de recherche et bouton d'ajout */}
            <div className="flex flex-col md:flex-row gap-4 justify-between mb-6">
                <div className="flex-1">
                    <div className="relative">
                        <input
                            type="text"
                            placeholder="Rechercher un client..."
                            value={searchInput}
                            onChange={inputHandler}
                            className="w-full py-2 pl-10 pr-4 bg-gris border border-gray-700 rounded-lg text-white text-sm focus:outline-none focus:border-bleu"
                        />
                        <div className="absolute left-3 top-2.5 text-gray-400">
                            <FiSearch />
                        </div>
                    </div>
                </div>
                
                <div className="flex gap-2">
                    <button 
                        className={`px-4 py-2 rounded-lg text-sm ${filter === 'tous' ? 'bg-bleu text-white' : 'bg-gris text-gray-300 hover:bg-noir'}`}
                        onClick={() => { setFilter('tous'); setCurrentPage(1); }}
                    >
                        Tous
                    </button>
                    <button 
                        className={`px-4 py-2 rounded-lg text-sm ${filter === 'actif' ? 'bg-vert text-white' : 'bg-gris text-gray-300 hover:bg-noir'}`}
                        onClick={() => { setFilter('actif'); setCurrentPage(1); }}
                    >
                        Actifs
                    </button>
                    <button 
                        className={`px-4 py-2 rounded-lg text-sm ${filter === 'inactif' ? 'bg-softRed text-white' : 'bg-gris text-gray-300 hover:bg-noir'}`}
                        onClick={() => { setFilter('inactif'); setCurrentPage(1); }}
                    >
                        Inactifs
                    </button>
                    
                    <button
                        onClick={resetFilters}
                        className="p-2 bg-gris rounded-lg text-gray-400 hover:text-bleu transition-colors"
                        title="Réinitialiser les filtres"
                    >
                        <FiRefreshCw />
                    </button>
                </div>
            </div>

            {/* Pagination et sélection du nombre par page */}
            <div className="flex items-center justify-between mb-4 bg-gris p-3 rounded-lg">
                <div className="flex items-center gap-2">
                    <span className="text-gray-400 text-sm">Afficher</span>
                    <select
                        value={clientsPerPage}
                        onChange={handleClientsPerPageChange}
                        className="bg-noir border border-gray-700 rounded text-white text-sm py-1 px-2 focus:outline-none focus:border-bleu"
                    >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                    </select>
                    <span className="text-gray-400 text-sm">par page</span>
                </div>
                
                <div className="flex items-center">
                    <span className="text-gray-400 text-sm mr-4">
                        {totalClients === 0 ? '0' : indexOfFirstClient + 1} - {Math.min(indexOfLastClient, totalClients)} sur {totalClients}
                    </span>
                    <div className="flex gap-2">
                        <button
                            onClick={paginatePrevious}
                            disabled={currentPage === 1}
                            className={`p-2 rounded-lg ${currentPage === 1 ? 'text-gray-600 cursor-not-allowed' : 'text-gray-400 hover:bg-noir hover:text-white'}`}
                        >
                            <FaChevronLeft size={14} />
                        </button>
                        <button
                            onClick={paginateNext}
                            disabled={indexOfLastClient >= totalClients}
                            className={`p-2 rounded-lg ${indexOfLastClient >= totalClients ? 'text-gray-600 cursor-not-allowed' : 'text-gray-400 hover:bg-noir hover:text-white'}`}
                        >
                            <FaChevronRight size={14} />
                        </button>
                    </div>
                </div>
            </div>
                
            {/* Tableau des clients */}
            <div className="bg-gris rounded-xl shadow-lg overflow-hidden">
                {totalClients === 0 ? (
                    <div className="p-8 text-center">
                        <FaUserFriends className="text-gray-500 text-4xl mx-auto mb-4" />
                        <p className="text-gray-400 text-lg">Aucun client trouvé avec les critères de recherche actuels.</p>
                        <button 
                            onClick={resetFilters}
                            className="mt-4 px-4 py-2 bg-bleu rounded-lg text-white text-sm hover:bg-blue-600 transition-colors inline-flex items-center gap-2"
                        >
                            <FiRefreshCw size={14} /> Réinitialiser les filtres
                        </button>
                    </div>
                ) : (
                    <div className="overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-700">
                            <thead className="bg-noir">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">#</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Nom</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Contact</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Statut</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Solde</th>
                                    <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">Actions</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-700">
                                {currentClients.map((client, index) => (
                                    <tr key={index} className="hover:bg-noir transition-colors">
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                                            {indexOfFirstClient + index + 1}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-white font-medium">
                                            {client.nom}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                                            <div className="text-gray-300">{client.email}</div>
                                            <div className="text-gray-400 text-xs">{client.whatsapp}</div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                                            <span className={`px-2 py-1 rounded-full text-xs ${
                                                client.statut === 'actif' 
                                                    ? 'bg-vert bg-opacity-10 text-vert' 
                                                    : 'bg-softRed bg-opacity-10 text-softRed'
                                            }`}>
                                                {client.statut === 'actif' ? 'Actif' : 'Inactif'}
                                            </span>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-vert">
                                            {formatMontant(client.solde || 0)} F
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                                            <div className="flex items-center justify-center">
                                                <Link 
                                                    to={`/admin/clientDetails/${client.id}`}
                                                    className="p-2 bg-bleu bg-opacity-10 hover:bg-opacity-20 text-bleu rounded-lg transition-colors"
                                                    title="Détails"
                                                >
                                                    <FiEye size={18} />
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
            
            {/* Pagination mobile (seulement visible sur mobile) */}
            <div className="md:hidden flex justify-center mt-4">
                <div className="flex items-center gap-2 bg-gris p-2 rounded-lg">
                    <button
                        onClick={paginatePrevious}
                        disabled={currentPage === 1}
                        className={`p-2 rounded-lg ${currentPage === 1 ? 'text-gray-600 cursor-not-allowed' : 'text-gray-400 hover:bg-noir hover:text-white'}`}
                    >
                        <FaChevronLeft size={14} />
                    </button>
                    <span className="text-white">
                        Page {currentPage}
                    </span>
                    <button
                        onClick={paginateNext}
                        disabled={indexOfLastClient >= totalClients}
                        className={`p-2 rounded-lg ${indexOfLastClient >= totalClients ? 'text-gray-600 cursor-not-allowed' : 'text-gray-400 hover:bg-noir hover:text-white'}`}
                    >
                        <FaChevronRight size={14} />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ListClients;