import axios from "axios";
const baseURL = process.env.REACT_APP_BASE_URL || "https://31f2-197-234-221-233.ngrok-free.app/api/"; // Utilise la variable d'environnement
export default async function updateTransaction(
  token,
  idUser,
  idTransaction,
  nouveauStatut
) {
  try {
    const response = await axios.get(`${baseURL}update-transaction`, {
      headers: {
        Authorization: `Bearer ${token}`, // Pass the token
      },
      params: {
        idUser, // Pass idUser dynamically
        idTransaction, // Pass idTransaction dynamically
        nouveauStatut, // Pass nouveauStatut dynamically
        mode: "manuel", // Keep mode as "manuel"
      },
    });

    //alert("Réponse du serveur:" + response.data);
    return response.data;
  } catch (error) {
    // alert("Erreur lors de la mise à jour de la transaction:" + error.message);
    throw error;
  }
}
