import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Les service workers sont complètement désactivés
// Si vous souhaitez les réactiver plus tard, consultez la documentation
// https://create-react-app.dev/docs/making-a-progressive-web-app/

// Si vous souhaitez commencer à mesurer les performances de votre application,
// passez une fonction pour enregistrer les résultats
// (par exemple: reportWebVitals(console.log))
// ou envoyez à un point de terminaison d'analyse. En savoir plus: https://bit.ly/CRA-vitals
reportWebVitals();