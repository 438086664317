// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getDatabase,
  ref,
  set,
  push,
  get,
  onValue,
  update,
  child,
} from "firebase/database"; // Import Firebase Realtime Database functions

import { getStorage } from "firebase/storage";
import { getMessaging } from "firebase/messaging";

export const firebaseConfig = {
  apiKey: "AIzaSyDbwoB97-TtVCNICHmXMUAe1aGMfAbFyB0",
  authDomain: "payforbet-584b4.firebaseapp.com",
  databaseURL: "https://payforbet-584b4-default-rtdb.firebaseio.com",
  projectId: "payforbet-584b4",
  storageBucket: "payforbet-584b4.appspot.com",
  messagingSenderId: "591701975605",
  appId: "1:591701975605:web:329f6004b5378ce186077c",
  measurementId: "G-EMZPK7W36G",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const database = getDatabase(app); // Initialize Realtime Database
const storage = getStorage(app);

// Initialiser Firebase Cloud Messaging (uniquement côté navigateur)
let messaging = null;
if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
  try {
    messaging = getMessaging(app);
  } catch (error) {
    console.error("Erreur lors de l'initialisation de Firebase Messaging:", error);
  }
}

export { 
  app, 
  database, 
  ref, 
  set, 
  push, 
  get, 
  onValue, 
  update, 
  storage, 
  child, 
  messaging 
};