import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faArrowDown, 
  faArrowUp, 
  faEye, 
  faEyeSlash,
  faChartLine,
  faExchangeAlt,
  faMoneyBillWave,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

export default function BalanceOverview({ soldeCollection, soldeDisbursement, fullView = false }) {
  const [showBalance, setShowBalance] = React.useState(true);

  const toggleBalance = () => {
    setShowBalance(!showBalance);
  };

  // Calculer le total des deux soldes
  const totalBalance = soldeCollection && soldeDisbursement 
    ? parseFloat(soldeCollection.availableBalance) + parseFloat(soldeDisbursement.availableBalance)
    : 0;

  if (!soldeCollection || !soldeDisbursement) {
    return (
      <div className="mb-8 bg-gris rounded-xl p-5 shadow-lg animate-pulse">
        <div className="h-6 w-32 bg-bleu/20 rounded mb-6"></div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="h-32 bg-noir/40 rounded-lg"></div>
          <div className="h-32 bg-noir/40 rounded-lg"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="mb-8">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">{fullView ? "Détails des soldes" : "Aperçu des soldes"}</h2>
        <div className="flex items-center space-x-3">
          {!fullView && (
            <Link 
              to="/assistant/balance" 
              className="p-2 bg-gris/80 rounded-full hover:bg-gris transition text-gray-400"
            >
              <FontAwesomeIcon icon={faInfoCircle} />
            </Link>
          )}
          <button 
            onClick={toggleBalance}
            className="p-2 bg-gris/80 rounded-full hover:bg-gris transition text-gray-400"
          >
            <FontAwesomeIcon icon={showBalance ? faEye : faEyeSlash} />
          </button>
        </div>
      </div>
      
      {/* Sommaire du solde total */}
      <div className="bg-gradient-to-r from-bleu/20 to-vert/20 rounded-xl mb-6 p-5">
        <div className="flex items-center mb-3">
          <div className="w-10 h-10 rounded-full bg-white/10 flex items-center justify-center mr-3">
            <FontAwesomeIcon icon={faMoneyBillWave} className="text-white" />
          </div>
          <span className="text-white font-medium">Solde total disponible</span>
        </div>
        
        <div className="text-3xl font-bold text-white mb-2">
          {showBalance ? totalBalance.toLocaleString() : '••••••'} XOF
        </div>
        
        <div className="flex items-center justify-between mt-2 text-sm">
          <div className="flex items-center text-white/70">
            <FontAwesomeIcon icon={faChartLine} className="mr-2" />
            <span>MTN Mobile Money</span>
          </div>
          <div className="bg-white/10 px-3 py-1 rounded-full text-white/80">
            <FontAwesomeIcon icon={faExchangeAlt} className="mr-1" />
            <span>2 comptes</span>
          </div>
        </div>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Carte de collecte */}
        <div className="bg-gradient-to-br from-gris to-noir rounded-xl overflow-hidden shadow-lg border border-bleu/20">
          <div className="px-6 py-5">
            <div className="flex justify-between items-center mb-4">
              <div className="flex items-center">
                <div className="w-10 h-10 rounded-full bg-bleu/20 flex items-center justify-center mr-3">
                  <FontAwesomeIcon icon={faArrowDown} className="text-bleu" />
                </div>
                <span className="text-gray-300 font-medium">Collecte (Dépôts)</span>
              </div>
            </div>
            
            <div className="mb-2">
              <div className="text-xs text-gray-500 mb-1">Solde disponible</div>
              <div className="flex items-baseline">
                <span className="text-2xl font-bold text-white mr-2">
                  {showBalance ? soldeCollection.availableBalance : '••••••'}
                </span>
                <span className="text-gray-400 text-sm">{soldeCollection.currency}</span>
              </div>
            </div>
            
            <div className="flex justify-between items-center mt-4 pt-4 border-t border-bleu/10">
              <div className="bg-bleu/10 text-xs text-bleu px-3 py-1 rounded-full">
                MTN Mobile Money
              </div>
              <div className="text-sm text-gray-400">
                Aujourd'hui
              </div>
            </div>
            
            {fullView && (
              <div className="mt-4 pt-4 border-t border-bleu/10">
                <div className="text-sm text-gray-400 mb-2">Informations complémentaires</div>
                <div className="space-y-2 text-sm">
                  <div className="flex justify-between">
                    <span className="text-gray-500">Compte</span>
                    <span className="text-white">Collection</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-500">Type</span>
                    <span className="text-white">MTN Mobile Money</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-500">Dernière mise à jour</span>
                    <span className="text-white">Aujourd'hui</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        
        {/* Carte de déboursement */}
        <div className="bg-gradient-to-br from-gris to-noir rounded-xl overflow-hidden shadow-lg border border-vert/20">
          <div className="px-6 py-5">
            <div className="flex justify-between items-center mb-4">
              <div className="flex items-center">
                <div className="w-10 h-10 rounded-full bg-vert/20 flex items-center justify-center mr-3">
                  <FontAwesomeIcon icon={faArrowUp} className="text-vert" />
                </div>
                <span className="text-gray-300 font-medium">Déboursement (Retraits)</span>
              </div>
            </div>
            
            <div className="mb-2">
              <div className="text-xs text-gray-500 mb-1">Solde disponible</div>
              <div className="flex items-baseline">
                <span className="text-2xl font-bold text-white mr-2">
                  {showBalance ? soldeDisbursement.availableBalance : '••••••'}
                </span>
                <span className="text-gray-400 text-sm">{soldeDisbursement.currency}</span>
              </div>
            </div>
            
            <div className="flex justify-between items-center mt-4 pt-4 border-t border-vert/10">
              <div className="bg-vert/10 text-xs text-vert px-3 py-1 rounded-full">
                MTN Mobile Money
              </div>
              <div className="text-sm text-gray-400">
                Aujourd'hui
              </div>
            </div>
            
            {fullView && (
              <div className="mt-4 pt-4 border-t border-vert/10">
                <div className="text-sm text-gray-400 mb-2">Informations complémentaires</div>
                <div className="space-y-2 text-sm">
                  <div className="flex justify-between">
                    <span className="text-gray-500">Compte</span>
                    <span className="text-white">Disbursement</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-500">Type</span>
                    <span className="text-white">MTN Mobile Money</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-500">Dernière mise à jour</span>
                    <span className="text-white">Aujourd'hui</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      
      {fullView && (
        <div className="mt-8 bg-gris rounded-xl p-6 border border-bleu/10">
          <h3 className="text-lg font-bold mb-4">Informations sur les soldes</h3>
          
          <div className="space-y-4 text-sm">
            <p className="text-gray-300">
              Les soldes affichés représentent les montants disponibles dans vos comptes MTN Mobile Money pour effectuer des opérations.
            </p>
            
            <div className="bg-noir/30 p-4 rounded-lg">
              <h4 className="font-medium text-white mb-2 flex items-center">
                <FontAwesomeIcon icon={faArrowDown} className="text-bleu mr-2" />
                Compte de Collecte (Dépôts)
              </h4>
              <p className="text-gray-400">
                Ce compte est utilisé pour recevoir les paiements des clients. Le solde affiché est le montant disponible pour les transferts vers d'autres comptes.
              </p>
            </div>
            
            <div className="bg-noir/30 p-4 rounded-lg">
              <h4 className="font-medium text-white mb-2 flex items-center">
                <FontAwesomeIcon icon={faArrowUp} className="text-vert mr-2" />
                Compte de Déboursement (Retraits)
              </h4>
              <p className="text-gray-400">
                Ce compte est utilisé pour effectuer des paiements aux clients. Le solde affiché est le montant disponible pour les retraits clients.
              </p>
            </div>
            
            <p className="text-gray-300">
              Les mises à jour des soldes sont effectuées en temps réel après chaque transaction. Pour toute question concernant les soldes, veuillez contacter l'administrateur.
            </p>
          </div>
        </div>
      )}
    </div>
  );
}