import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiArrowLeft, FiUser, FiMail, FiPhone, FiFlag } from 'react-icons/fi';
import addAssistant from '../../requtes/admin/addAssistant';
import { ajoutAgentSucces } from '../alert/ajoutAgentSucces';
import { existeAgent } from '../alert/existeAgent';
import InfosContext from "../../contexts/InfosAppProvider";

const AddAssistant = () => {
    const navigate = useNavigate();
    
    const { token } = useContext(InfosContext);
    
    // États pour les données du formulaire
    const [formData, setFormData] = useState({
        nom: '',
        email: '',
        whatsapp: '',
        pays: ''
    });
    
    // État pour le loader
    const [isLoading, setIsLoading] = useState(false);
    
    // État pour les erreurs
    const [errors, setErrors] = useState({});

    // Gestion des changements dans le formulaire
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
        
        // Effacer l'erreur pour ce champ
        if (errors[name]) {
            setErrors(prev => ({
                ...prev,
                [name]: ''
            }));
        }
    };

    // Validation du formulaire
    const validateForm = () => {
        const newErrors = {};
        
        // Validation du nom
        if (!formData.nom.trim()) {
            newErrors.nom = "Le nom est requis";
        }
        
        // Validation de l'email
        if (!formData.email.trim()) {
            newErrors.email = "L'email est requis";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = "Format d'email invalide";
        }
        
        // Validation du numéro WhatsApp
        if (!formData.whatsapp.trim()) {
            newErrors.whatsapp = "Le numéro WhatsApp est requis";
        }
        
        // Validation du pays
        if (!formData.pays.trim()) {
            newErrors.pays = "Le pays est requis";
        }
        
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Soumission du formulaire
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!validateForm()) {
            return;
        }
        
        setIsLoading(true);
        
        try {
            const result = await addAssistant(
                formData.nom,
                formData.email,
                formData.whatsapp,
                formData.pays,
                token
            );
            
            if (result.success) {
                ajoutAgentSucces();
                navigate('/admin/listAssistants');
            } else {
                if (result.message === "exists") {
                    existeAgent();
                } else {
                    alert(`Erreur: ${result.message}`);
                }
            }
        } catch (error) {
            console.error("Erreur lors de l'ajout de l'assistant:", error);
            alert("Une erreur est survenue. Veuillez réessayer.");
        } finally {
            setIsLoading(false);
        }
    };
    
    return (
        <div className="w-full">
            {/* En-tête */}
            <div className="mb-8 flex items-center gap-4">
                <button
                    onClick={() => navigate(-1)}
                    className="p-2 rounded-full bg-gris hover:bg-noir transition-colors"
                >
                    <FiArrowLeft className="text-white" />
                </button>
                <div>
                    <h1 className="text-2xl font-bold text-white mb-1">Ajouter un assistant</h1>
                    <p className="text-gray-400">Créez un nouveau compte assistant</p>
                </div>
            </div>
            
            {/* Formulaire */}
            <div className="bg-gris rounded-xl shadow-lg p-6">
                <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        {/* Nom complet */}
                        <div>
                            <label className="block text-gray-300 mb-2 text-sm">Nom complet</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <FiUser className="text-gray-500" />
                                </div>
                                <input
                                    type="text"
                                    name="nom"
                                    value={formData.nom}
                                    onChange={handleChange}
                                    className={`w-full bg-noir border ${errors.nom ? 'border-softRed' : 'border-gray-700'} rounded-lg py-3 px-10 text-white placeholder-gray-500 focus:outline-none focus:border-bleu transition-colors`}
                                    placeholder="Entrez le nom complet"
                                />
                            </div>
                            {errors.nom && <p className="text-softRed text-xs mt-1">{errors.nom}</p>}
                        </div>
                        
                        {/* Email */}
                        <div>
                            <label className="block text-gray-300 mb-2 text-sm">Email</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <FiMail className="text-gray-500" />
                                </div>
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className={`w-full bg-noir border ${errors.email ? 'border-softRed' : 'border-gray-700'} rounded-lg py-3 px-10 text-white placeholder-gray-500 focus:outline-none focus:border-bleu transition-colors`}
                                    placeholder="Entrez l'adresse email"
                                />
                            </div>
                            {errors.email && <p className="text-softRed text-xs mt-1">{errors.email}</p>}
                        </div>
                        
                        {/* WhatsApp */}
                        <div>
                            <label className="block text-gray-300 mb-2 text-sm">Numéro WhatsApp</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <FiPhone className="text-gray-500" />
                                </div>
                                <input
                                    type="text"
                                    name="whatsapp"
                                    value={formData.whatsapp}
                                    onChange={handleChange}
                                    className={`w-full bg-noir border ${errors.whatsapp ? 'border-softRed' : 'border-gray-700'} rounded-lg py-3 px-10 text-white placeholder-gray-500 focus:outline-none focus:border-bleu transition-colors`}
                                    placeholder="Ex: +22501234567"
                                />
                            </div>
                            {errors.whatsapp && <p className="text-softRed text-xs mt-1">{errors.whatsapp}</p>}
                        </div>
                        
                        {/* Pays */}
                        <div>
                            <label className="block text-gray-300 mb-2 text-sm">Pays</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <FiFlag className="text-gray-500" />
                                </div>
                                <input
                                    type="text"
                                    name="pays"
                                    value={formData.pays}
                                    onChange={handleChange}
                                    className={`w-full bg-noir border ${errors.pays ? 'border-softRed' : 'border-gray-700'} rounded-lg py-3 px-10 text-white placeholder-gray-500 focus:outline-none focus:border-bleu transition-colors`}
                                    placeholder="Pays de l'assistant"
                                />
                            </div>
                            {errors.pays && <p className="text-softRed text-xs mt-1">{errors.pays}</p>}
                        </div>
                    </div>
                    
                    {/* Bouton de soumission */}
                    <div className="mt-8">
                        <button
                            type="submit"
                            disabled={isLoading}
                            className="w-full bg-bleu hover:bg-blue-600 text-white py-3 rounded-lg font-medium transition-colors disabled:opacity-70"
                        >
                            {isLoading ? "Création en cours..." : "Créer le compte assistant"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddAssistant;