import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import InfosContext from '../contexts/InfosAppProvider';
import DashboardHeader from './components/DashboardHeader';
import BottomNavBar from './components/BottomNavBar';
import BalanceOverview from './dashboard/BalanceOverview';
import RecentTransactions from './dashboard/RecentTransactions';

export default function AssistantIndex() {
  const { userInfos, soldeCollection, soldeDisbursement, momoTransactions, isAuthenticated, id, token, fetchUserInfos } = useContext(InfosContext);
  const navigate = useNavigate();

  // Vérifier que nous sommes authentifiés et forcer le chargement des données si nécessaire
  useEffect(() => {
    console.log("AssistantIndex - Vérification de l'authentification et des données");
    const isUserAssistant = userInfos?.role === "assistant";
    
    // Si authentifié mais pas de données utilisateur ou pas le bon rôle, essayer de les charger
    if (isAuthenticated && id && token && (!userInfos || !isUserAssistant)) {
      console.log("Assistant - Forçage du chargement des données utilisateur");
      fetchUserInfos(id, { forceFetch: true });
    }
  }, [isAuthenticated, id, token, userInfos, fetchUserInfos]);

  if (!userInfos) {
    return (
      <div className="min-h-screen bg-noir flex items-center justify-center">
        <div className="w-16 h-16 border-4 border-bleu border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }
  
  // Vérifier si l'utilisateur a bien le rôle assistant
  if (userInfos.role !== "assistant") {
    console.log("Utilisateur connecté avec le mauvais rôle:", userInfos.role);
    return (
      <div className="min-h-screen bg-noir flex items-center justify-center flex-col">
        <div className="w-16 h-16 border-4 border-softRed border-t-transparent rounded-full animate-spin mb-4"></div>
        <p className="text-white">Vous n'avez pas les droits d'accès à cette section.</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-noir text-white">
      <DashboardHeader user={userInfos} />
      
      <div className="container mx-auto px-4 py-6 md:pl-72 pb-24 md:pb-6">
        <div className="flex items-center mb-8">
          <div className="w-12 h-12 rounded-full bg-bleu/20 flex items-center justify-center mr-4">
            <span className="text-bleu text-xl">👋</span>
          </div>
          <h1 className="text-2xl font-bold">
            Bienvenue, <span className="text-bleu">{userInfos.nom.split(' ')[0]}</span>
          </h1>
        </div>
        
        <BalanceOverview 
          soldeCollection={soldeCollection} 
          soldeDisbursement={soldeDisbursement} 
        />
        
        <RecentTransactions 
          transactions={momoTransactions} 
          onViewAll={() => navigate('/assistant/transactions')} 
        />
      </div>
      
      <BottomNavBar />
    </div>
  );
}