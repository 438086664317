import React, { useState, useEffect, useContext } from "react";
import ReactPaginate from "react-paginate";
import { 
  faArrowUp, 
  faArrowDown,
  faChevronLeft,
  faChevronRight,
  faCalendarAlt,
  faSpinner,
  faClock
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TransactionCard from "../TransactionCard";
import InfosContext from "../../contexts/InfosAppProvider";

// Couleurs personnalisées
const colors = {
  noir: "#1C1C1CFF",
  bleu: "#0087BFFF",
  vert: "#28C281FF",
  softRed: "#ff6f61",
  softYellow: "#f9d57d",
  softPurple: "#b19cd9"
};

const PaginatedTransactions = ({ transactions, itemsPerPage }) => {
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [filterType, setFilterType] = useState("all");
  const [loading, setLoading] = useState(true);
  const { selectedStatuses, searchTerm } = useContext(InfosContext);

  // Format date avec un libellé relatif
  const formatDate = (timestamp) => {
    if (!timestamp) return "";
    
    const date = new Date(timestamp);
    const now = new Date();
    const diffTime = Math.abs(now - date);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    
    if (diffDays === 0) {
      // Aujourd'hui
      return `Aujourd'hui, ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
    } else if (diffDays === 1) {
      // Hier
      return `Hier, ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
    } else if (diffDays < 7) {
      // Cette semaine
      const days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
      return `${days[date.getDay()]}, ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
    } else {
      // Format standard
      return date.toLocaleString("fr-FR", {
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    }
  };

  // Fonction améliorée pour vérifier si un terme de recherche correspond à un champ
  const includesSearchTerm = (value) => {
    if (!searchTerm) return true;
    
    // Nettoyer le terme de recherche
    const cleanSearchTerm = searchTerm.toLowerCase().trim();
    if (cleanSearchTerm === '') return true;
    
    // Vérifier les valeurs numériques
    if (typeof value === "number") {
      return value.toString().toLowerCase().includes(cleanSearchTerm);
    }
    
    // Vérifier les valeurs string
    if (typeof value === "string") {
      return value.toLowerCase().includes(cleanSearchTerm);
    }
    
    // Vérifier les objets et tableaux (conversion en JSON)
    if (value && (typeof value === "object")) {
      try {
        const jsonStr = JSON.stringify(value).toLowerCase();
        return jsonStr.includes(cleanSearchTerm);
      } catch (e) {
        console.error("Erreur lors de la conversion en JSON:", e);
      }
    }
    
    return false;
  };

  useEffect(() => {
    setLoading(true);
    
    // Simule un chargement pour montrer un effet de transition
    setTimeout(() => {
      const filteredTransactions = Object.entries(transactions)
        .filter(([id, transaction]) => {
          if (!transaction) return false;
  
          const typeMatch = filterType === "all" || transaction.type === filterType;
          const statusMatch = selectedStatuses.includes(transaction.statut);
  
          // Recherche améliorée plus complète et robuste
          const searchMatch = (() => {
            // Si aucun terme de recherche, tout correspond
            if (!searchTerm || searchTerm.trim() === '') return true;
            
            try {
              // Tester les champs principaux
              if (includesSearchTerm(transaction.idTransaction)) return true;
              if (includesSearchTerm(transaction.idTransactionMobile)) return true;
              if (includesSearchTerm(transaction.idCaisse)) return true;
              if (includesSearchTerm(transaction.idCompte1xbet)) return true;
              if (includesSearchTerm(transaction.montant)) return true;
              if (includesSearchTerm(transaction.moyenPaiement)) return true;
              if (includesSearchTerm(transaction.statut)) return true;
              if (includesSearchTerm(transaction.type)) return true;
              
              // Tester le champ dateHeure de différentes façons
              if (includesSearchTerm(transaction.dateHeure)) return true;
              
              // Recherche dans la date formatée
              if (transaction.dateHeure) {
                try {
                  const dateObj = new Date(transaction.dateHeure);
                  if (!isNaN(dateObj.getTime())) {
                    // Format complet: "lundi 12 mars 2024, 14:30:45"
                    const fullFormat = dateObj.toLocaleString("fr-FR", {
                      weekday: "long",
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit"
                    });
                    if (fullFormat.toLowerCase().includes(searchTerm.toLowerCase())) return true;
                    
                    // Format abrégé: "12/03/2024"
                    const shortFormat = dateObj.toLocaleDateString("fr-FR");
                    if (shortFormat.includes(searchTerm.toLowerCase())) return true;
                    
                    // Mois seul
                    const monthOnly = dateObj.toLocaleString("fr-FR", { month: "long" });
                    if (monthOnly.toLowerCase().includes(searchTerm.toLowerCase())) return true;
                    
                    // Jour de la semaine
                    const weekday = dateObj.toLocaleString("fr-FR", { weekday: "long" });
                    if (weekday.toLowerCase().includes(searchTerm.toLowerCase())) return true;
                    
                    // Année seule
                    const year = dateObj.getFullYear().toString();
                    if (year.includes(searchTerm)) return true;
                  }
                } catch (e) {
                  console.error("Erreur de traitement de date pour recherche:", e);
                }
              }
              
              // Recherche dans tous les autres champs possibles
              const allFields = Object.entries(transaction).filter(([key, value]) => 
                !["idTransaction", "idTransactionMobile", "idCaisse", "idCompte1xbet", 
                  "montant", "moyenPaiement", "statut", "dateHeure", "type"].includes(key)
              );
              
              return allFields.some(([key, value]) => includesSearchTerm(value));
            } catch (e) {
              console.error("Erreur lors de la recherche:", e);
              return false;
            }
          })();
  
          return typeMatch && statusMatch && searchMatch;
        })
        // Tri par date (plus récent au plus ancien)
        .sort((a, b) => new Date(b[1].dateHeure) - new Date(a[1].dateHeure));
      
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(filteredTransactions.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(filteredTransactions.length / itemsPerPage));
      setLoading(false);
    }, 300);
  }, [
    itemOffset,
    itemsPerPage,
    transactions,
    filterType,
    selectedStatuses,
    searchTerm,
  ]);

  const handlePageClick = (event) => {
    const filteredCount = Object.entries(transactions).filter(([id, transaction]) => {
      const typeMatch = filterType === "all" || transaction.type === filterType;
      const statusMatch = selectedStatuses.includes(transaction.statut);
      
      const searchMatch =
        includesSearchTerm(transaction.idTransaction) ||
        includesSearchTerm(transaction.idTransactionMobile) ||
        includesSearchTerm(transaction.idCaisse) ||
        includesSearchTerm(transaction.idCompte1xbet) ||
        includesSearchTerm(transaction.montant) ||
        includesSearchTerm(transaction.moyenPaiement) ||
        includesSearchTerm(transaction.statut) ||
        includesSearchTerm(transaction.dateHeure) ||
        (searchTerm && 
          new Date(transaction.dateHeure)
            .toLocaleString("fr-FR", { month: "long" })
            .includes(searchTerm.toLowerCase())) ||
        includesSearchTerm(transaction.type);
      
      return typeMatch && statusMatch && searchMatch;
    }).length;
    
    const newOffset = (event.selected * itemsPerPage) % filteredCount;
    setItemOffset(newOffset);
    
    // Défiler en haut de la liste après changement de page
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Composant pour afficher un groupe de transactions par date
  const TransactionGroup = ({ date, items }) => (
    <div className="mb-4">
      <div className="flex items-center mb-2">
        <FontAwesomeIcon icon={faCalendarAlt} className="text-[#0087BFFF] mr-2" />
        <span className="text-gray-400 text-sm">{date}</span>
      </div>
      <div className="space-y-3">
        {items.map(([id, transaction]) => (
          <TransactionCard
            key={id}
            icon={transaction.type === "depot" ? faArrowUp : faArrowDown}
            title={`${transaction.type === "depot" ? "Dépôt" : "Retrait"}`}
            date={formatDate(transaction.dateHeure)}
            amount={`${transaction.montant.toLocaleString()} F CFA`}
            id={transaction.idCompte1xbet || 'N/A'}
            bgColor={
              transaction.statut === "en cours"
                ? "bg-[#f9d57d]"
                : transaction.type === "depot"
                ? "bg-[#28C281FF]"
                : "bg-[#ff6f61]"
            }
            idTran={transaction.idTransaction || id}
            status={transaction.statut}
          />
        ))}
      </div>
    </div>
  );

  // Fonction pour regrouper les transactions par date
  const groupTransactionsByDate = (items) => {
    const groups = {};
    
    items.forEach(item => {
      const transaction = item[1];
      const date = new Date(transaction.dateHeure);
      const dateKey = date.toLocaleDateString('fr-FR');
      
      if (!groups[dateKey]) {
        groups[dateKey] = [];
      }
      
      groups[dateKey].push(item);
    });
    
    return groups;
  };

  // État pour afficher l'absence de résultat
  const hasNoResults = currentItems.length === 0 && !loading;

  return (
    <div className="animate-fadeIn">
      {loading ? (
        <div className="flex flex-col items-center justify-center py-10">
          <FontAwesomeIcon icon={faSpinner} spin className="text-[#0087BFFF] text-3xl mb-2" />
          <p className="text-gray-400">Chargement des transactions...</p>
        </div>
      ) : hasNoResults ? (
        <div className="bg-[#272728FF] rounded-lg p-6 text-center">
          <div className="w-16 h-16 bg-[#333333] rounded-full mx-auto flex items-center justify-center mb-4">
            <FontAwesomeIcon icon={faClock} className="text-gray-400 text-xl" />
          </div>
          <h3 className="text-white text-lg mb-2">Aucune transaction trouvée</h3>
          <p className="text-gray-400 text-sm">
            {searchTerm 
              ? "Aucune transaction ne correspond à votre recherche." 
              : "Aucune transaction disponible pour le moment."}
          </p>
        </div>
      ) : (
        <>
          {/* Affichage groupé par date */}
          {Object.entries(groupTransactionsByDate(currentItems)).map(([date, items]) => (
            <TransactionGroup key={date} date={date} items={items} />
          ))}
    
          {/* Pagination */}
          {pageCount > 1 && (
            <div className="mt-6 mb-8">
              <ReactPaginate
                breakLabel={<span className="px-3 py-1">...</span>}
                nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
                onPageChange={handlePageClick}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                pageCount={pageCount}
                previousLabel={<FontAwesomeIcon icon={faChevronLeft} />}
                renderOnZeroPageCount={null}
                containerClassName="flex justify-center items-center gap-1"
                pageClassName="w-8 h-8 flex items-center justify-center rounded-full bg-[#272728FF] text-white hover:bg-[#333333] transition-colors"
                activeClassName="!bg-[#0087BFFF]"
                previousClassName="w-8 h-8 flex items-center justify-center rounded-full bg-[#272728FF] hover:bg-[#333333] transition-colors"
                nextClassName="w-8 h-8 flex items-center justify-center rounded-full bg-[#272728FF] hover:bg-[#333333] transition-colors"
                disabledClassName="opacity-50 cursor-not-allowed"
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PaginatedTransactions;
