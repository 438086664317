import React, { useContext } from 'react';
import InfosContext from '../../contexts/InfosAppProvider';
import DashboardHeader from '../components/DashboardHeader';
import BottomNavBar from '../components/BottomNavBar';
import BalanceOverview from './BalanceOverview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export default function BalanceDetails() {
  const { userInfos, soldeCollection, soldeDisbursement } = useContext(InfosContext);

  if (!userInfos) {
    return (
      <div className="min-h-screen bg-noir flex items-center justify-center">
        <div className="w-16 h-16 border-4 border-bleu border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-noir text-white">
      <DashboardHeader user={userInfos} />
      
      <div className="container mx-auto px-4 py-6 md:pl-72 pb-24 md:pb-6">
        <BalanceOverview 
          soldeCollection={soldeCollection} 
          soldeDisbursement={soldeDisbursement}
          fullView={true}
        />
      </div>
      
      <BottomNavBar />
    </div>
  );
}