import React, { useContext, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FiArrowLeft, FiUser, FiMail, FiPhone, FiGlobe, FiCalendar, FiDollarSign } from 'react-icons/fi';
import { FaUserCircle, FaUserCheck, FaUserTimes } from 'react-icons/fa';
import InfosContext from '../../contexts/InfosAppProvider';
import { avisbannir } from '../alert/avisbannir';
import { updateStatutAgent } from '../agents/updateStatutAgent';

const AssistantDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    
    const { listeUser, token } = useContext(InfosContext);
    
    // Trouver l'assistant actuel
    const assistant = Object.values(listeUser).find(user => user.id === id && user.role === 'assistant');
    
    // État pour les transactions
    const [transactions, setTransactions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    
    // Fonction pour formater les montants
    const formatMontant = (montant) => {
        return new Intl.NumberFormat('fr-FR').format(montant);
    };
    
    // Fonction pour formater les dates
    const formatDate = (timestamp) => {
        if (!timestamp) return '-';
        const date = new Date(timestamp);
        return new Intl.DateTimeFormat('fr-FR', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        }).format(date);
    };
    
    // Handler pour changer le statut de l'assistant
    const handleChangeStatut = () => {
        avisbannir().then((result) => {
            if (result.isConfirmed) {
                const newStatut = assistant.statut === 'actif' ? 'inactif' : 'actif';
                updateStatutAgent(assistant.id, newStatut, token);
            }
        });
    };
    
    useEffect(() => {
        // Simuler le chargement des transactions
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 1000);
        
        return () => clearTimeout(timer);
    }, []);
    
    if (!assistant) {
        return (
            <div className="flex flex-col items-center justify-center h-64">
                <FaUserCircle className="text-gray-500 text-6xl mb-4" />
                <p className="text-gray-400 text-xl">Assistant non trouvé</p>
                <button
                    onClick={() => navigate('/admin/listAssistants')}
                    className="mt-4 px-4 py-2 bg-bleu text-white rounded-lg hover:bg-blue-600 transition-colors"
                >
                    Retour à la liste
                </button>
            </div>
        );
    }
    
    return (
        <div className="w-full">
            {/* En-tête */}
            <div className="mb-8 flex items-center gap-4">
                <button
                    onClick={() => navigate(-1)}
                    className="p-2 rounded-full bg-gris hover:bg-noir transition-colors"
                >
                    <FiArrowLeft className="text-white" />
                </button>
                <div>
                    <h1 className="text-2xl font-bold text-white mb-1">Détails de l'assistant</h1>
                    <p className="text-gray-400">Informations et activités de {assistant.nom}</p>
                </div>
            </div>
            
            {/* Carte de profil */}
            <div className="bg-gris rounded-xl shadow-lg overflow-hidden mb-8">
                <div className="p-6">
                    <div className="flex flex-col md:flex-row gap-6 items-start">
                        {/* Photo de profil */}
                        <div className="flex flex-col items-center">
                            <div className="w-24 h-24 bg-noir rounded-full flex items-center justify-center mb-3">
                                <FaUserCircle className="text-gray-500 text-6xl" />
                            </div>
                            <span className={`px-3 py-1 rounded-full text-xs ${
                                assistant.statut === 'actif' 
                                ? 'bg-vert bg-opacity-10 text-vert' 
                                : 'bg-softRed bg-opacity-10 text-softRed'
                            }`}>
                                {assistant.statut === 'actif' ? 'Actif' : 'Inactif'}
                            </span>
                        </div>
                        
                        {/* Informations principales */}
                        <div className="flex-1">
                            <h2 className="text-xl font-bold text-white mb-4">
                                {assistant.nom}
                            </h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div className="flex items-center gap-3">
                                    <div className="bg-bleu bg-opacity-10 p-2 rounded-lg">
                                        <FiMail className="text-bleu" />
                                    </div>
                                    <div>
                                        <p className="text-xs text-gray-400">Email</p>
                                        <p className="text-sm text-white">{assistant.email}</p>
                                    </div>
                                </div>
                                
                                <div className="flex items-center gap-3">
                                    <div className="bg-softPurple bg-opacity-10 p-2 rounded-lg">
                                        <FiPhone className="text-softPurple" />
                                    </div>
                                    <div>
                                        <p className="text-xs text-gray-400">WhatsApp</p>
                                        <p className="text-sm text-white">{assistant.whatsapp || '-'}</p>
                                    </div>
                                </div>
                                
                                <div className="flex items-center gap-3">
                                    <div className="bg-softRed bg-opacity-10 p-2 rounded-lg">
                                        <FiGlobe className="text-softRed" />
                                    </div>
                                    <div>
                                        <p className="text-xs text-gray-400">Pays</p>
                                        <p className="text-sm text-white">{assistant.pays || '-'}</p>
                                    </div>
                                </div>
                                
                                <div className="flex items-center gap-3">
                                    <div className="bg-vert bg-opacity-10 p-2 rounded-lg">
                                        <FiCalendar className="text-vert" />
                                    </div>
                                    <div>
                                        <p className="text-xs text-gray-400">Date d'inscription</p>
                                        <p className="text-sm text-white">{formatDate(assistant.createdAt)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        {/* Solde et actions */}
                        <div className="w-full md:w-auto flex flex-col gap-4">
                            <div className="bg-noir p-4 rounded-lg text-center">
                                <div className="flex items-center justify-center gap-2 mb-1">
                                    <FiDollarSign className="text-vert" />
                                    <p className="text-xs text-gray-400">Solde actuel</p>
                                </div>
                                <p className="text-xl font-bold text-vert">{formatMontant(assistant.solde || 0)} F</p>
                            </div>
                            
                            <button
                                onClick={handleChangeStatut}
                                className={`flex items-center justify-center gap-2 px-4 py-2 rounded-lg transition-colors ${
                                    assistant.statut === 'actif' 
                                    ? 'bg-softRed bg-opacity-10 text-softRed hover:bg-opacity-20' 
                                    : 'bg-vert bg-opacity-10 text-vert hover:bg-opacity-20'
                                }`}
                            >
                                {assistant.statut === 'actif' ? (
                                    <>
                                        <FaUserTimes />
                                        <span>Désactiver</span>
                                    </>
                                ) : (
                                    <>
                                        <FaUserCheck />
                                        <span>Activer</span>
                                    </>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* Section des dernières activités/transactions */}
            <div className="mb-8">
                <h2 className="text-lg font-bold text-white mb-4">Dernières activités</h2>
                
                <div className="bg-gris rounded-xl shadow-lg overflow-hidden">
                    {isLoading ? (
                        <div className="p-8 text-center">
                            <div className="animate-pulse flex flex-col items-center">
                                <div className="h-12 w-12 bg-noir rounded-full mb-4"></div>
                                <div className="h-4 bg-noir rounded w-1/2 mb-3"></div>
                                <div className="h-3 bg-noir rounded w-1/3"></div>
                            </div>
                        </div>
                    ) : transactions.length === 0 ? (
                        <div className="p-8 text-center">
                            <FiDollarSign className="text-gray-500 text-4xl mx-auto mb-4" />
                            <p className="text-gray-400 text-lg">Aucune activité récente</p>
                        </div>
                    ) : (
                        <div className="overflow-x-auto">
                            <table className="min-w-full divide-y divide-gray-700">
                                <thead className="bg-noir">
                                    <tr>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">ID</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Date</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Type</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Montant</th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Statut</th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-700">
                                    {transactions.map((transaction, index) => (
                                        <tr key={index} className="hover:bg-noir transition-colors">
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                                                {transaction.id}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                                                {formatDate(transaction.date)}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                                                {transaction.type}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-vert">
                                                {formatMontant(transaction.montant)} F
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm">
                                                <span className={`px-2 py-1 rounded-full text-xs ${
                                                    transaction.statut === 'Complété' 
                                                    ? 'bg-vert bg-opacity-10 text-vert' 
                                                    : transaction.statut === 'En attente'
                                                    ? 'bg-softPurple bg-opacity-10 text-softPurple'
                                                    : 'bg-softRed bg-opacity-10 text-softRed'
                                                }`}>
                                                    {transaction.statut}
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AssistantDetails;