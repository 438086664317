import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AssistantIndex from '../assistant/Index';
import TransactionsList from '../assistant/transactions/TransactionsList';
import SearchTransactions from '../assistant/transactions/SearchTransactions';
import BalanceDetails from '../assistant/dashboard/BalanceDetails';
import IndexParametre from '../assistant/parametre/IndexParametre';

export default function AssistantRoutes() {
  return (
    <Routes>
      <Route path="/" element={<AssistantIndex />} />
      <Route path="/transactions" element={<TransactionsList />} />
      <Route path="/search" element={<SearchTransactions />} />
      <Route path="/balance" element={<BalanceDetails />} />
      <Route path="/setting" element={<IndexParametre />} />
    </Routes>
  );
}