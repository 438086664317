import React, { useState, useEffect } from "react";
import { FaQuestionCircle, FaHeadset, FaBook, FaLifeRing, FaChevronDown, FaChevronUp } from "react-icons/fa";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import Lottie from "lottie-react";

const AideIndex = () => {
  const [activeQuestion, setActiveQuestion] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  
  // Import animation
  const successAnimation = require('../animation/succes.json');

  // Thème de couleurs
  const theme = {
    noir: "#1C1C1CFF",
    bleu: "#0087BFFF",
    vert: "#28C281FF",
    softRed: "#ff6f61",
    softYellow: "#f9d57d",
    softPurple: "#b19cd9",
    black: "#1C1C1CFF",
    gris: "#272728FF",
  };

  // Détection du redimensionnement de la fenêtre
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Help categories with icons
  const helpCategories = [
    {
      icon: <FaQuestionCircle size={24} style={{ color: theme.vert }} />,
      title: "Questions fréquentes",
      description: "Trouvez rapidement des réponses aux questions courantes."
    },
    {
      icon: <FaHeadset size={24} style={{ color: theme.vert }} />,
      title: "Support technique",
      description: "Contactez notre équipe pour résoudre vos problèmes techniques."
    },
    {
      icon: <FaBook size={24} style={{ color: theme.vert }} />,
      title: "Guides d'utilisation",
      description: "Apprenez à utiliser toutes les fonctionnalités de notre plateforme."
    },
    {
      icon: <FaLifeRing size={24} style={{ color: theme.vert }} />,
      title: "Assistance en direct",
      description: "Obtenez de l'aide en temps réel avec nos conseillers."
    }
  ];

  // FAQ questions
  const faqQuestions = [
    {
      question: "Comment puis-je réinitialiser mon mot de passe ?",
      answer: "Pour réinitialiser votre mot de passe, cliquez sur \"Mot de passe oublié\" sur la page de connexion et suivez les instructions envoyées à votre adresse e-mail."
    },
    {
      question: "Comment contacter le service client ?",
      answer: "Vous pouvez contacter notre service client par téléphone, email ou via le chat en direct disponible sur notre site."
    },
    {
      question: "Comment effectuer un dépôt ?",
      answer: "Pour effectuer un dépôt, connectez-vous à votre compte, accédez à la section 'Mon Compte', cliquez sur 'Dépôt' et suivez les instructions."
    },
    {
      question: "Comment retirer mes gains ?",
      answer: "Pour retirer vos gains, allez dans la section 'Mon Compte', cliquez sur 'Retrait', sélectionnez votre méthode de paiement préférée et suivez les instructions."
    },
    {
      question: "Quels sont les délais de traitement des retraits ?",
      answer: "Les délais de traitement varient selon la méthode de paiement choisie. Les retraits par Mobile Money sont généralement traités sous 24h, tandis que les virements bancaires peuvent prendre 2-3 jours ouvrables."
    },
    {
      question: "Comment vérifier l'historique de mes transactions ?",
      answer: "Pour vérifier l'historique de vos transactions, connectez-vous à votre compte et accédez à la section 'Historique des transactions' dans votre tableau de bord."
    }
  ];

  // Toggle FAQ answer visibility
  const toggleQuestion = (index) => {
    setActiveQuestion(activeQuestion === index ? null : index);
  };

  return (
    <div className="w-full" style={{ 
      minHeight: "100vh", 
      backgroundColor: theme.noir,
      width: "100%",
      // On ajoute une classe pour gérer l'espace de la sidebar
      padding: isMobile ? "1rem" : "2rem 2rem 2rem 2rem"
    }}>
      <div style={{ 
        maxWidth: isMobile ? "100%" : "1000px", 
        margin: "0 auto",
        // Important pour que le contenu ne soit pas caché par la sidebar
        paddingLeft: !isMobile ? "260px" : "0"
      }}>
        {/* Header */}
        <motion.div 
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          style={{ 
            textAlign: "center", 
            marginBottom: "2rem" 
          }}
        >
          <motion.h1 
            initial={{ scale: 0.9 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            style={{ 
              fontSize: "clamp(1.75rem, 4vw, 2.5rem)", 
              fontWeight: "bold", 
              color: "white", 
              marginBottom: "1rem" 
            }}
          >
            Centre d'aide
          </motion.h1>
          <motion.p 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            style={{ 
              fontSize: "clamp(0.9rem, 2.5vw, 1.1rem)", 
              color: "rgba(255, 255, 255, 0.8)", 
              maxWidth: "700px", 
              margin: "0 auto" 
            }}
          >
            Nous sommes là pour vous aider à résoudre vos problèmes et répondre à toutes vos questions.
          </motion.p>
        </motion.div>

        {/* Help Categories - Force 4 columns sur PC */}
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.6 }}
          style={{ 
            display: "grid", 
            // Force 4 cards sur la même ligne en desktop
            gridTemplateColumns: isMobile 
              ? "1fr" 
              : "repeat(4, 1fr)",
            gap: "0.75rem", 
            marginBottom: "2rem" 
          }}
        >
          {helpCategories.map((category, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ 
                duration: 0.5, 
                delay: 0.7 + (index * 0.1),
                type: "spring",
                stiffness: 120 
              }}
              whileHover={{ 
                y: -5, 
                boxShadow: "0 10px 15px rgba(0, 0, 0, 0.2)",
                scale: 1.02
              }}
              style={{
                backgroundColor: theme.gris,
                borderRadius: "0.75rem",
                padding: "1rem",
                cursor: "pointer",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                border: `1px solid rgba(${parseInt(theme.bleu.slice(1, 3), 16)}, ${parseInt(theme.bleu.slice(3, 5), 16)}, ${parseInt(theme.bleu.slice(5, 7), 16)}, 0.15)`
              }}
            >
              <motion.div 
                whileHover={{ rotate: 10 }}
                transition={{ 
                  type: "spring", 
                  stiffness: 300,
                  damping: 10
                }}
                style={{
                  width: "45px",
                  height: "45px",
                  borderRadius: "50%",
                  backgroundColor: "rgba(0, 0, 0, 0.2)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "0.75rem"
                }}
              >
                {category.icon}
              </motion.div>
              <h3 style={{ 
                fontSize: "1rem", 
                fontWeight: "600", 
                color: "white", 
                marginBottom: "0.5rem",
                // Force texte à tenir sur une seule ligne
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis"
              }}>
                {category.title}
              </h3>
              <p style={{ 
                color: "rgba(255, 255, 255, 0.7)", 
                fontSize: "0.8rem",
                // Limite hauteur de la description
                height: "2.5rem",
                overflow: "hidden"
              }}>
                {category.description}
              </p>
            </motion.div>
          ))}
        </motion.div>

        {/* Popular Questions */}
        <motion.div 
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 1.2 }}
          style={{
            backgroundColor: theme.gris,
            borderRadius: "1rem",
            padding: isMobile ? "1.25rem" : "1.5rem",
            marginBottom: "2rem",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
            border: `1px solid rgba(${parseInt(theme.bleu.slice(1, 3), 16)}, ${parseInt(theme.bleu.slice(3, 5), 16)}, ${parseInt(theme.bleu.slice(5, 7), 16)}, 0.15)`
          }}
        >
          <div style={{ display: "flex", alignItems: "center", marginBottom: "1.25rem" }}>
            <motion.h2 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 1.3 }}
              style={{ 
                fontSize: isMobile ? "1.3rem" : "1.5rem", 
                fontWeight: "bold", 
                color: "white",
                marginRight: "10px",
                flex: 1
              }}
            >
              Questions populaires
            </motion.h2>
            <motion.div 
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ 
                duration: 0.5, 
                delay: 1.4,
                type: "spring",
                stiffness: 260
              }}
              style={{ width: "50px", height: "50px" }}
            >
              <Lottie 
                animationData={successAnimation} 
                loop={true} 
                style={{ width: "100%", height: "100%" }}
              />
            </motion.div>
          </div>
          
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 1.4 }}
            style={{ display: "flex", flexDirection: "column", gap: "0.75rem" }}
          >
            {faqQuestions.map((faq, index) => (
              <motion.div 
                key={index}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.4, delay: 1.5 + (index * 0.1) }}
                style={{ 
                  borderBottom: "1px solid rgba(255, 255, 255, 0.1)", 
                  paddingBottom: "0.75rem" 
                }}
              >
                <motion.button 
                  whileHover={{ scale: 1.01 }}
                  whileTap={{ scale: 0.99 }}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    textAlign: "left",
                    color: "white",
                    padding: "0.5rem 0",
                    backgroundColor: "transparent",
                    border: "none",
                    cursor: "pointer"
                  }}
                  onClick={() => toggleQuestion(index)}
                >
                  <span style={{ 
                    fontSize: isMobile ? "0.95rem" : "1rem", 
                    fontWeight: "500",
                    paddingRight: "10px"
                  }}>
                    {faq.question}
                  </span>
                  <motion.span
                    animate={{ rotate: activeQuestion === index ? 180 : 0 }}
                    transition={{ duration: 0.3 }}
                    style={{ 
                      color: theme.bleu, 
                      fontSize: "1rem",
                      flexShrink: 0
                    }}
                  >
                    <FaChevronDown />
                  </motion.span>
                </motion.button>
                <AnimatePresence>
                  {activeQuestion === index && (
                    <motion.div
                      key={`answer-${index}`}
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: "auto", opacity: 1 }}
                      exit={{ height: 0, opacity: 0 }}
                      transition={{ duration: 0.3 }}
                      style={{ 
                        marginTop: "0.5rem", 
                        color: "rgba(255, 255, 255, 0.7)",
                        overflow: "hidden",
                        fontSize: isMobile ? "0.85rem" : "0.9rem"
                      }}
                    >
                      {faq.answer}
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            ))}
          </motion.div>
        </motion.div>

        {/* Contact Section */}
        <motion.div 
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.7, delay: 2.0 }}
          style={{
            background: `linear-gradient(to right, ${theme.bleu}, ${theme.vert})`,
            borderRadius: "1rem",
            overflow: "hidden",
            boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)"
          }}
        >
          <div style={{ 
            display: "flex", 
            flexDirection: isMobile ? "column" : "row"
          }}>
            <motion.div 
              initial={{ x: -30, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: 2.1 }}
              style={{ 
                flex: "1", 
                padding: isMobile ? "1.25rem" : "1.5rem"
              }}
            >
              <motion.h2 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 2.2 }}
                style={{ 
                  fontSize: isMobile ? "1.2rem" : "1.3rem", 
                  fontWeight: "bold", 
                  color: "white", 
                  marginBottom: "0.75rem" 
                }}
              >
                Vous n'avez pas trouvé de réponse ?
              </motion.h2>
              <motion.p 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 2.3 }}
                style={{ 
                  color: "rgba(255, 255, 255, 0.9)", 
                  marginBottom: "1.25rem",
                  fontSize: isMobile ? "0.85rem" : "0.9rem" 
                }}
              >
                Notre équipe de support est disponible 24/7 pour vous aider à résoudre tous vos problèmes.
              </motion.p>
              <motion.button 
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 2.4 }}
                whileHover={{ 
                  scale: 1.05,
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)"
                }}
                whileTap={{ scale: 0.95 }}
                style={{
                  backgroundColor: "white",
                  color: theme.bleu,
                  border: "none",
                  padding: "0.6rem 1.25rem",
                  borderRadius: "0.5rem",
                  fontWeight: "500",
                  cursor: "pointer",
                  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                  fontSize: "0.9rem"
                }}
              >
                Contacter le support
              </motion.button>
            </motion.div>
            <motion.div 
              initial={{ x: 30, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: 2.1 }}
              style={{ 
                flex: "1", 
                backgroundColor: "rgba(0, 0, 0, 0.1)", 
                padding: isMobile ? "1.25rem" : "1.5rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <div style={{ textAlign: "center" }}>
                <motion.div 
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ 
                    duration: 0.5, 
                    delay: 2.3,
                    type: "spring",
                    stiffness: 260,
                    damping: 20
                  }}
                  whileHover={{ 
                    rotate: 15,
                    scale: 1.1
                  }}
                  style={{
                    width: isMobile ? "50px" : "60px",
                    height: isMobile ? "50px" : "60px",
                    borderRadius: "50%",
                    backgroundColor: "rgba(0, 0, 0, 0.2)",
                    margin: "0 auto 0.75rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <FaHeadset size={isMobile ? 20 : 24} style={{ color: "white" }} />
                </motion.div>
                <motion.p 
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: 2.4 }}
                  style={{ 
                    color: "white", 
                    fontSize: isMobile ? "1rem" : "1.1rem", 
                    fontWeight: "500", 
                    marginBottom: "0.5rem" 
                  }}
                >
                  Support disponible 24/7
                </motion.p>
                <motion.p 
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: 2.5 }}
                  style={{ 
                    color: "rgba(255, 255, 255, 0.8)",
                    fontSize: isMobile ? "0.8rem" : "0.85rem"
                  }}
                >
                  Temps de réponse moyen: 2 heures
                </motion.p>
              </div>
            </motion.div>
          </div>
        </motion.div>

        {/* Additional Links */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 2.6 }}
          style={{ 
            marginTop: "2rem", 
            display: "flex", 
            justifyContent: "center", 
            flexWrap: "wrap", 
            gap: "1.5rem" 
          }}
        >
          <motion.div whileHover={{ scale: 1.1, y: -2 }}>
            <Link to="/" style={{ 
              color: "rgba(255, 255, 255, 0.7)", 
              textDecoration: "none",
              fontSize: "0.9rem"
            }}
            onMouseOver={(e) => e.currentTarget.style.color = theme.bleu}
            onMouseOut={(e) => e.currentTarget.style.color = "rgba(255, 255, 255, 0.7)"}
            >
              Accueil
            </Link>
          </motion.div>
          <motion.div whileHover={{ scale: 1.1, y: -2 }}>
            <Link to="/faq" style={{ 
              color: "rgba(255, 255, 255, 0.7)", 
              textDecoration: "none",
              fontSize: "0.9rem"
            }}
            onMouseOver={(e) => e.currentTarget.style.color = theme.bleu}
            onMouseOut={(e) => e.currentTarget.style.color = "rgba(255, 255, 255, 0.7)"}
            >
              FAQ complète
            </Link>
          </motion.div>
          <motion.div whileHover={{ scale: 1.1, y: -2 }}>
            <Link to="/contact" style={{ 
              color: "rgba(255, 255, 255, 0.7)", 
              textDecoration: "none",
              fontSize: "0.9rem"
            }}
            onMouseOver={(e) => e.currentTarget.style.color = theme.bleu}
            onMouseOut={(e) => e.currentTarget.style.color = "rgba(255, 255, 255, 0.7)"}
            >
              Contact
            </Link>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default AideIndex;