import React, { useContext } from "react";
import {
  FaPhoneAlt,
  FaMoneyBillWave,
  FaDice,
  FaWhatsapp,
  FaEdit,
  FaUser,
  FaEnvelope,
  FaSignOutAlt,
  FaCog,
  FaShieldAlt,
  FaGift,
  FaTools,
} from "react-icons/fa";
import { Routes, Route, Outlet, Link, useNavigate } from "react-router-dom";

import InfosContext from "../../contexts/InfosAppProvider";
const IndexParametre = () => {
  const {
    infosAdmin,
    setInfosAdmin,
    userInfos,
    setUserInfos,
    transactions,
    setTransactions,
    update,
    setUpdate,
    token,
    fetchUserInfos,
  } = useContext(InfosContext);
  const navigate = useNavigate();
  return (
    <>
      {/* MOBILE VERSION */}
      <div className="min-h-screen bg-noir flex flex-col items-center  px-2 md:hidden">
        <div className="w-full max-w-md">
          {/* HEADER */}
          <div className="flex items-center justify-center mb-6 bg-bleu rounded-t-2xl py-4 px-2 shadow-lg">
            <div className="flex items-center justify-center w-12 h-12 bg-white/10 rounded-full mr-3">
              <FaCog className="text-white text-2xl animate-spin-slow" />
            </div>
            <h2 className="text-2xl font-extrabold text-white tracking-wider">
              PARAMÈTRES
            </h2>
          </div>
          
          {/* PROFILE CARD */}
          <div className="bg-gris w-full rounded-2xl shadow-xl overflow-hidden mb-4 border-l-4 border-vert">
            <div className="bg-noir/40 py-3 px-4 border-b border-gris">
              <h3 className="text-vert text-center font-bold text-lg">
                INFORMATIONS PERSONNELLES
              </h3>
            </div>

            <div className="p-3 space-y-2">
              <InfoRow
                icon={<FaUser />}
                label="Nom"
                value={userInfos.nom}
                iconColor="text-white"
                iconBg="bg-vert"
              />

              <InfoRow
                icon={<FaEnvelope />}
                label="Email"
                value={userInfos.email}
                iconColor="text-white"
                iconBg="bg-bleu"
              />

              <InfoRow
                icon={<FaMoneyBillWave />}
                label="Numéro MoMo"
                value={userInfos.numeroMomo}
                iconColor="text-noir"
                iconBg="bg-softYellow"
              />

              <InfoRow
                icon={<FaWhatsapp />}
                label="WhatsApp"
                value={userInfos.whatsapp}
                iconColor="text-white"
                iconBg="bg-vert"
              />

              <InfoRow
                icon={<FaDice />}
                label="ID 1xBet"
                value={userInfos.idCompte1xbet}
                iconColor="text-white"
                iconBg="bg-softPurple"
              />

              <InfoRow
                icon={<FaMoneyBillWave />}
                label="Nom Momo"
                value={userInfos.nomMomo}
                iconColor="text-noir"
                iconBg="bg-softYellow"
              />
            </div>
          </div>
          
          {/* FONCTIONNALITÉS SECTION - uniquement pour le rôle client */}
          {userInfos && userInfos.role === "client" && (
            <div className="bg-gris w-full rounded-2xl shadow-xl overflow-hidden mb-4 border-l-4 border-softYellow">
              <div className="bg-noir/40 py-3 px-4 border-b border-gris">
                <div className="flex items-center justify-center">
                  <FaTools className="text-softYellow mr-2" />
                  <h3 className="text-softYellow font-bold text-lg">FONCTIONNALITÉS</h3>
                </div>
              </div>
              
              <div className="p-4">
                {/* BUTTONS */}
                <div className="flex flex-col gap-3">
                  <button
                    className="bg-[#f9d57d] text-black font-bold py-3 px-4 rounded-xl shadow-lg flex items-center justify-center gap-2 transition-all duration-300 w-full"
                    onClick={() => navigate("/clients/bonus")}
                  >
                    <FaGift className="h-5 w-5" /> Mes Bonus
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* SECURITY SECTION */}
          <div className="bg-gris w-full rounded-2xl shadow-xl overflow-hidden mb-20 border-l-4 border-bleu">
            <div className="bg-noir/40 py-3 px-4 border-b border-gris">
              <div className="flex items-center justify-center">
                <FaShieldAlt className="text-bleu mr-2" />
                <h3 className="text-bleu font-bold text-lg">SÉCURITÉ</h3>
              </div>
            </div>
            
            <div className="p-4">
              {/* BUTTONS */}
              <div className="flex flex-col gap-3">
                <button
                  className="bg-bleu text-white font-bold py-3 px-4 rounded-xl shadow-lg flex items-center justify-center gap-2 transition-all duration-300 w-full"
                  onClick={() => {
                    let a = 8;
                  }}
                >
                  <FaEdit className="h-5 w-5" /> Éditer mon profil
                </button>

                <button
                  className="bg-softRed text-white font-bold py-3 px-4 rounded-xl shadow-xl flex items-center justify-center gap-2 transition-all duration-300 w-full text-lg"
                  onClick={() => {
                    localStorage.setItem("token", "");
                    localStorage.setItem("isAuthenticated", 0);
                    navigate("/login");
                  }}
                >
                  <FaSignOutAlt className="h-5 w-5" /> Déconnexion
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* DESKTOP VERSION */}
      <div className="min-h-screen bg-noir  hidden md:flex justify-center">
        <div className="w-full max-w-2xl md:mt-8">
          {/* HEADER */}
          <div className="flex items-center justify-center mb-6 bg-bleu rounded-t-2xl py-4 px-2 shadow-lg">
            <div className="flex items-center justify-center w-12 h-12 bg-white/10 rounded-full mr-3">
              <FaCog className="text-white text-2xl animate-spin-slow" />
            </div>
            <h2 className="text-2xl font-extrabold text-white tracking-wider">
              PARAMÈTRES
            </h2>
          </div>
          
          {/* PROFILE CARD */}
          <div className="bg-gris w-full rounded-2xl shadow-xl overflow-hidden mb-4 border-l-4 border-vert">
            <div className="bg-noir/40 py-3 px-4 border-b border-gris">
              <h3 className="text-vert text-center font-bold text-lg">
                INFORMATIONS PERSONNELLES
              </h3>
            </div>

            <div className="p-5 space-y-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <InfoRow
                  icon={<FaUser />}
                  label="Nom"
                  value={userInfos.nom}
                  iconColor="text-white"
                  iconBg="bg-vert"
                />

                <InfoRow
                  icon={<FaEnvelope />}
                  label="Email"
                  value={userInfos.email}
                  iconColor="text-white"
                  iconBg="bg-bleu"
                />

                <InfoRow
                  icon={<FaMoneyBillWave />}
                  label="Numéro MoMo"
                  value={userInfos.numeroMomo}
                  iconColor="text-noir"
                  iconBg="bg-softYellow"
                />

                <InfoRow
                  icon={<FaWhatsapp />}
                  label="WhatsApp"
                  value={userInfos.whatsapp}
                  iconColor="text-white"
                  iconBg="bg-vert"
                />

                <InfoRow
                  icon={<FaDice />}
                  label="ID 1xBet"
                  value={userInfos.idCompte1xbet}
                  iconColor="text-white"
                  iconBg="bg-softPurple"
                />

                <InfoRow
                  icon={<FaMoneyBillWave />}
                  label="Nom Momo"
                  value={userInfos.nomMomo}
                  iconColor="text-noir"
                  iconBg="bg-softYellow"
                />
              </div>
            </div>
          </div>
          
          {/* Pas de FONCTIONNALITÉS SECTION sur PC */}

          {/* SECURITY SECTION */}
          <div className="bg-gris w-full rounded-2xl shadow-xl overflow-hidden mb-6 border-l-4 border-bleu">
            <div className="bg-noir/40 py-3 px-4 border-b border-gris">
              <div className="flex items-center justify-center">
                <FaShieldAlt className="text-bleu mr-2" />
                <h3 className="text-bleu font-bold text-lg">SÉCURITÉ</h3>
              </div>
            </div>
            
            <div className="p-5">
              {/* BUTTONS */}
              <div className="flex flex-col md:flex-row gap-4 justify-center">
                <button
                  className="bg-bleu text-white font-bold py-3 px-8 rounded-xl shadow-lg flex items-center justify-center gap-3 transition-all duration-300 md:w-1/2 w-full"
                  onClick={() => {
                    let a = 88;
                  }}
                >
                  <FaEdit className="h-5 w-5" /> Éditer mon profil
                </button>

                <button
                  className="bg-softRed text-white font-bold py-3 px-8 rounded-xl shadow-lg flex items-center justify-center gap-3 transition-all duration-300 md:w-1/2 w-full"
                  onClick={() => {
                    localStorage.setItem("token", "");
                    localStorage.setItem("isAuthenticated", 0);
                    navigate("/login");
                  }}
                >
                  <FaSignOutAlt className="h-5 w-5" /> Déconnexion
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// Composant InfoRow amélioré - plus compact et avec des couleurs plus vives
const InfoRow = ({ icon, label, value, iconColor, iconBg }) => (
  <div className="flex items-center space-x-3">
    <div
      className={`flex-shrink-0 w-10 h-10 flex items-center justify-center ${iconBg} rounded-full shadow-lg ${iconColor}`}
    >
      {icon}
    </div>
    <div className="flex-grow min-w-0">
      <p className="text-xs font-medium text-gray-400 uppercase">{label}</p>
      <p className="text-sm font-bold text-white truncate">{value || "Non défini"}</p>
    </div>
  </div>
);

export default IndexParametre;
