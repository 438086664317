import React, { useState, useContext } from 'react';
import { FiSave, FiCode, FiPercent, FiDollarSign, FiCreditCard, FiArrowLeft } from "react-icons/fi";
import { Link } from 'react-router-dom';
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import InfosContextAdmin from "../../contexts/InfosAppProvider";
import { ajoutAgentSucces } from '../alert/ajoutAgentSucces';

const ConfigForm = ({ formValues, handleChange, loading, handleSubmit, progress }) => {
  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Code Promo */}
        <div className="bg-noir rounded-lg p-5">
          <div className="flex items-center gap-2 mb-3">
            <FiCode className="text-bleu" />
            <label className="text-gray-300 font-medium">Code Promo</label>
          </div>
          <input
            type="text"
            name="codePromo"
            value={formValues.codePromo}
            onChange={handleChange}
            className="w-full py-2 px-3 bg-gris border border-gray-700 rounded-lg text-white text-sm focus:outline-none focus:border-bleu"
            placeholder="Entrez le code promo"
          />
        </div>

        {/* Mode de traitement */}
        <div className="bg-noir rounded-lg p-5">
          <div className="flex items-center gap-2 mb-3">
            <FiCreditCard className="text-softPurple" />
            <label className="text-gray-300 font-medium">Mode de traitement</label>
          </div>
          <select
            name="mode"
            value={formValues.mode}
            onChange={handleChange}
            className="w-full py-2 px-3 bg-gris border border-gray-700 rounded-lg text-white text-sm focus:outline-none focus:border-bleu"
          >
            <option value="manuel">Manuel</option>
            <option value="semi">Semi-automatique</option>
            <option value="automatique">Automatique</option>
          </select>
        </div>

        {/* Taux d'affiliation (dépôt) */}
        <div className="bg-noir rounded-lg p-5">
          <div className="flex items-center gap-2 mb-3">
            <FiPercent className="text-vert" />
            <label className="text-gray-300 font-medium">Taux d'affiliation (dépôt)</label>
          </div>
          <div className="relative">
            <input
              type="text"
              name="tauxAffiliationDepot"
              value={formValues.tauxAffiliationDepot}
              onChange={handleChange}
              className="w-full py-2 px-3 bg-gris border border-gray-700 rounded-lg text-white text-sm focus:outline-none focus:border-bleu"
              placeholder="Entrez le taux"
            />
            <span className="absolute right-3 top-2 text-gray-400">%</span>
          </div>
        </div>

        {/* Minimum retrait affiliation */}
        <div className="bg-noir rounded-lg p-5">
          <div className="flex items-center gap-2 mb-3">
            <FiDollarSign className="text-softRed" />
            <label className="text-gray-300 font-medium">Minimum retrait affiliation</label>
          </div>
          <div className="relative">
            <input
              type="text"
              name="minimumRetraitAffiliation"
              value={formValues.minimumRetraitAffiliation}
              onChange={handleChange}
              className="w-full py-2 px-3 bg-gris border border-gray-700 rounded-lg text-white text-sm focus:outline-none focus:border-bleu"
              placeholder="Entrez le montant minimum"
            />
            <span className="absolute right-3 top-2 text-gray-400">F</span>
          </div>
        </div>

        {/* Montants disponibles (caché pour l'instant) */}
        <div className="hidden bg-noir rounded-lg p-5">
          <div className="flex items-center gap-2 mb-3">
            <FiDollarSign className="text-softYellow" />
            <label className="text-gray-300 font-medium">Montants disponibles</label>
          </div>
          <input
            type="text"
            name="montant"
            value={formValues.montant}
            onChange={handleChange}
            className="w-full py-2 px-3 bg-gris border border-gray-700 rounded-lg text-white text-sm focus:outline-none focus:border-bleu"
            placeholder="Séparés par des virgules (ex: 1000, 2000, 5000)"
          />
          <p className="text-xs text-gray-500 mt-2">Séparez les différents montants par des virgules</p>
        </div>
      </div>

      <div className="flex gap-4 mt-8">
        <Link 
          to="/admin/configuration" 
          className="px-4 py-3 bg-gris hover:bg-noir text-white rounded-lg flex items-center justify-center transition-colors"
        >
          <FiArrowLeft className="mr-2" /> Retour
        </Link>
        
        <button 
          type="submit"
          className="flex-1 px-4 py-3 bg-bleu hover:bg-blue-600 text-white font-medium rounded-lg flex items-center justify-center transition-colors"
          disabled={loading}
        >
          {loading ? (
            <>
              <div className="w-5 h-5 mr-2">
                <CircularProgressbar
                  value={progress}
                  styles={buildStyles({
                    pathColor: "white",
                    trailColor: "rgba(255,255,255,0.2)",
                    strokeLinecap: "round",
                  })}
                />
              </div>
              <span>Enregistrement...</span>
            </>
          ) : (
            <>
              <FiSave className="mr-2" />
              <span>Enregistrer les modifications</span>
            </>
          )}
        </button>
      </div>
    </form>
  );
};

const PromoEditor = () => {
  const {
    infosAdmin,
    setInfosAdmin,
    userInfos,
    setUserInfos,
    transactions,
    setTransactions,
    listeUser,
    listeCaisses,
    token
  } = useContext(InfosContextAdmin);
  
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(66);
  
  const [formValues, setFormValues] = useState({
    codePromo: infosAdmin.codePromo || "",
    minimumRetraitAffiliation: infosAdmin.minimum_retrait_affiliation || "",
    mode: infosAdmin.mode || "manuel",
    montant: infosAdmin.montant || "",
    tauxAffiliationDepot: infosAdmin.taux_affiliation_depot || "",
    tauxAffiliationRetrait: "10",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const sendRequest = async () => {
    const baseURL = process.env.REACT_APP_BASE_URL || "https://31f2-197-234-221-233.ngrok-free.app/api/";
    const url = `${baseURL}/modifier-infos-admin?minimum_retrait_affiliation=${formValues.minimumRetraitAffiliation}&taux_affiliation_depot=${formValues.tauxAffiliationDepot}&taux_affiliation_retrait=${formValues.tauxAffiliationRetrait}&montant=${encodeURIComponent(formValues.montant)}&mode=${formValues.mode}&codePromo=${formValues.codePromo}`;

    setLoading(true);
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Réponse reçue:", data);

        setLoading(false);
        if (!data.error) {
          ajoutAgentSucces();
        } else {
          alert("Erreur lors de la modification: " + data.message);
        }
      } else {
        alert("Erreur HTTP: " + response.status);
      }
    } catch (error) {
      setLoading(false);
      console.error("Erreur:", error);
      alert("Erreur de connexion au serveur: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendRequest();
  };

  return (
    <div className="w-full">
      {/* En-tête de la page */}
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-white mb-2">Modifier la configuration</h1>
        <p className="text-gray-400">Mettez à jour les paramètres globaux du système</p>
      </div>

      {/* Formulaire principal */}
      <div className="bg-gris rounded-xl shadow-lg overflow-hidden">
        <div className="p-6 border-b border-gray-700">
          <h2 className="text-xl font-semibold text-white">Paramètres du système</h2>
        </div>
        
        <div className="p-6">
          <ConfigForm 
            formValues={formValues}
            handleChange={handleChange}
            loading={loading}
            handleSubmit={handleSubmit}
            progress={progress}
          />
        </div>
      </div>
    </div>
  );
};

export default PromoEditor;