import React, { useState } from 'react';
import { IoMenu, IoSearch, IoNotificationsOutline } from "react-icons/io5";
import { FiUser, FiSettings, FiHelpCircle } from "react-icons/fi";
import logo from "../../ressources/images/logo.png";

const Header = ({ toggleSidebar }) => {
    const [searchTerm, setSearchTerm] = useState("");
    
    return (
        <div className='bg-noir py-3 px-5 flex items-center justify-between w-full border-b-[1px] border-gray-700 m-0 shadow-md'>
            <div className='flex items-center gap-4'>
                <button 
                    className='h-10 w-10 flex items-center justify-center rounded-lg text-white bg-gris hover:bg-bleu transition-colors lg:hidden' 
                    onClick={toggleSidebar}
                >
                    <IoMenu size={22} />
                </button>
                
                <div className='flex items-center'>
                    <img src={logo} alt="PayForBet Logo" className="w-10 h-10 mr-3" />
                    <div>
                        <h1 className='text-white text-lg font-bold'>
                            PAYFORBET
                        </h1>
                        <h2 className='text-gray-400 text-xs'>Espace Administrateur</h2>
                    </div>
                </div>
            </div>
            
            <div className='hidden md:flex items-center relative w-1/3'>
                <div className='absolute left-3 text-gray-400'>
                    <IoSearch />
                </div>
                <input 
                    type="text" 
                    placeholder="Rechercher..." 
                    className='w-full py-2 pl-10 pr-4 bg-gris border border-gray-700 rounded-lg text-white text-sm focus:outline-none focus:border-bleu'
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>
            
            <div className='flex items-center gap-3'>
                <button className='p-2 text-gray-300 hover:text-bleu bg-gris rounded-lg'>
                    <IoNotificationsOutline size={20} />
                </button>
                <button className='p-2 text-gray-300 hover:text-bleu bg-gris rounded-lg'>
                    <FiSettings size={20} />
                </button>
                <button className='p-2 text-gray-300 hover:text-bleu bg-gris rounded-lg'>
                    <FiHelpCircle size={20} />
                </button>
                <div className='ml-2 flex items-center gap-2'>
                    <div className='w-10 h-10 rounded-full bg-bleu flex items-center justify-center text-white'>
                        <FiUser size={18} />
                    </div>
                    <div className='hidden md:block'>
                        <p className='text-white text-sm font-medium'>Admin</p>
                        <p className='text-gray-400 text-xs'>Super Admin</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;