import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiCalendar, FiClock, FiRepeat, FiSave, FiCheck, FiArrowLeft } from 'react-icons/fi';
import InfosContext from "../../../contexts/InfosAppProvider";
import axios from 'axios';

const ScheduleNotification = () => {
  const navigate = useNavigate();
  const { token, userInfos } = useContext(InfosContext);
  
  // État pour les paramètres de planification
  const [scheduleData, setScheduleData] = useState({
    notificationId: '', // ID de la notification existante ou nouvelle
    scheduledDate: '',
    scheduledTime: '12:00',
    repeat: 'none', // none, daily, weekly, monthly
    repeatDays: [], // Pour les répétitions hebdomadaires (0-6, où 0 est dimanche)
    repeatDate: 1, // Pour les répétitions mensuelles (1-31)
    endDate: '', // Date de fin pour les répétitions
    maxSends: 0, // Nombre maximal d'envois (0 pour illimité)
  });
  
  // État pour les notifications existantes (à remplir)
  const [existingNotifications, setExistingNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  
  // Charger les notifications existantes
  React.useEffect(() => {
    // Dans une implémentation réelle, cela viendrait d'une API
    setExistingNotifications([
      {
        id: 'notif1',
        title: 'Maintenance prévue',
        body: 'Une maintenance du système est prévue le 15 juin de 23h à 2h.',
        type: 'alerte',
        target: 'tous'
      },
      {
        id: 'notif2',
        title: 'Promotion du weekend',
        body: 'Profitez de frais réduits ce weekend sur toutes les transactions.',
        type: 'promo',
        target: 'clients'
      },
      {
        id: 'notif3',
        title: 'Nouveaux taux pour les agents',
        body: 'Nous avons mis à jour les taux de commission pour tous les agents.',
        type: 'important',
        target: 'agents'
      }
    ]);
  }, []);
  
  // Gestionnaire de changement pour les champs de formulaire
  const handleChange = (e) => {
    const { name, value } = e.target;
    setScheduleData({
      ...scheduleData,
      [name]: value
    });
    
    // Si on change de notification, afficher les détails
    if (name === 'notificationId' && value) {
      setShowDetails(true);
    }
  };
  
  // Gestionnaire pour les jours de répétition hebdomadaire
  const handleDayToggle = (day) => {
    const days = [...scheduleData.repeatDays];
    
    if (days.includes(day)) {
      // Retirer le jour s'il est déjà présent
      const index = days.indexOf(day);
      days.splice(index, 1);
    } else {
      // Ajouter le jour s'il n'est pas présent
      days.push(day);
    }
    
    setScheduleData({
      ...scheduleData,
      repeatDays: days
    });
  };
  
  // Gestionnaire de soumission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setSuccess(null);
    
    // Validation
    if (!scheduleData.notificationId) {
      setError('Veuillez sélectionner une notification.');
      setIsLoading(false);
      return;
    }
    
    if (!scheduleData.scheduledDate) {
      setError('Veuillez sélectionner une date de programmation.');
      setIsLoading(false);
      return;
    }
    
    // Validation pour les répétitions
    if (scheduleData.repeat === 'weekly' && scheduleData.repeatDays.length === 0) {
      setError('Veuillez sélectionner au moins un jour de la semaine pour la répétition hebdomadaire.');
      setIsLoading(false);
      return;
    }
    
    try {
      // Dans une implémentation réelle, cela enverrait à une API
      // Pour l'exemple, simulons un succès
      
      // Délai simulé
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      // Succès simulé
      setSuccess('Notification programmée avec succès!');
      
      // Après quelques secondes, rediriger vers la liste des notifications
      setTimeout(() => {
        navigate('/admin/notifications');
      }, 2000);
    } catch (err) {
      setError(err.response?.data?.message || err.message || 'Une erreur est survenue lors de la programmation de la notification');
    } finally {
      setIsLoading(false);
    }
  };
  
  // Obtenir les détails de la notification sélectionnée
  const selectedNotification = existingNotifications.find(notif => notif.id === scheduleData.notificationId);
  
  // Formater la date actuelle pour la date minimale
  const today = new Date().toISOString().split('T')[0];
  
  return (
    <div className="w-full -mt-4">
      {/* En-tête de la page */}
      <div className="mb-6">
        <div className="flex items-center gap-4">
          <button 
            onClick={() => navigate('/admin/notifications')}
            className="bg-gris hover:bg-noir text-gray-300 p-2 rounded-lg transition-colors"
          >
            <FiArrowLeft size={20} />
          </button>
          <div>
            <h1 className="text-2xl font-bold text-white mb-1">Programmer une notification</h1>
            <p className="text-gray-400">Planifiez l'envoi de notifications pour plus tard</p>
          </div>
        </div>
      </div>
      
      {/* Formulaire de programmation */}
      <div className="bg-gris rounded-xl shadow-lg p-6">
        <form onSubmit={handleSubmit} className="max-w-2xl mx-auto space-y-5">
          {/* Sélection de la notification à programmer */}
          <div>
            <label htmlFor="notificationId" className="block text-gray-300 mb-1.5 font-medium">
              Sélectionner une notification
            </label>
            <select
              id="notificationId"
              name="notificationId"
              value={scheduleData.notificationId}
              onChange={handleChange}
              className="w-full py-2.5 px-4 bg-noir border border-gray-700 rounded-lg text-white focus:outline-none focus:border-bleu"
              required
            >
              <option value="">-- Choisir une notification --</option>
              {existingNotifications.map(notif => (
                <option key={notif.id} value={notif.id}>
                  {notif.title}
                </option>
              ))}
            </select>
          </div>
          
          {/* Afficher les détails de la notification sélectionnée */}
          {showDetails && selectedNotification && (
            <div className="bg-noir p-4 rounded-lg">
              <div className="flex justify-between items-start">
                <div>
                  <div className={`px-2 py-1 rounded-full text-xs inline-block mb-2 ${
                    selectedNotification.type === 'info' ? 'bg-bleu bg-opacity-10 text-bleu' :
                    selectedNotification.type === 'alerte' ? 'bg-softRed bg-opacity-10 text-softRed' :
                    selectedNotification.type === 'important' ? 'bg-softYellow bg-opacity-10 text-softYellow' :
                    selectedNotification.type === 'promo' ? 'bg-softPurple bg-opacity-10 text-softPurple' :
                    'bg-vert bg-opacity-10 text-vert'
                  }`}>
                    {selectedNotification.type.charAt(0).toUpperCase() + selectedNotification.type.slice(1)}
                  </div>
                  <h4 className="text-white font-medium">{selectedNotification.title}</h4>
                </div>
                <span className="text-gray-400 text-sm">Cible: {selectedNotification.target}</span>
              </div>
              <p className="text-gray-300 mt-2 text-sm">{selectedNotification.body}</p>
            </div>
          )}
          
          {/* Date et heure de programmation */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label htmlFor="scheduledDate" className="block text-gray-300 mb-1.5 font-medium">
                Date d'envoi
              </label>
              <div className="relative">
                <input
                  type="date"
                  id="scheduledDate"
                  name="scheduledDate"
                  value={scheduleData.scheduledDate}
                  onChange={handleChange}
                  min={today}
                  className="w-full py-2.5 px-4 bg-noir border border-gray-700 rounded-lg text-white focus:outline-none focus:border-bleu pl-10"
                  required
                />
                <FiCalendar className="absolute left-3 top-3 text-gray-400" />
              </div>
            </div>
            
            <div>
              <label htmlFor="scheduledTime" className="block text-gray-300 mb-1.5 font-medium">
                Heure d'envoi
              </label>
              <div className="relative">
                <input
                  type="time"
                  id="scheduledTime"
                  name="scheduledTime"
                  value={scheduleData.scheduledTime}
                  onChange={handleChange}
                  className="w-full py-2.5 px-4 bg-noir border border-gray-700 rounded-lg text-white focus:outline-none focus:border-bleu pl-10"
                  required
                />
                <FiClock className="absolute left-3 top-3 text-gray-400" />
              </div>
            </div>
          </div>
          
          {/* Options de répétition */}
          <div>
            <label htmlFor="repeat" className="block text-gray-300 mb-1.5 font-medium">
              Répétition
            </label>
            <div className="relative">
              <select
                id="repeat"
                name="repeat"
                value={scheduleData.repeat}
                onChange={handleChange}
                className="w-full py-2.5 px-4 bg-noir border border-gray-700 rounded-lg text-white focus:outline-none focus:border-bleu pl-10"
              >
                <option value="none">Pas de répétition</option>
                <option value="daily">Quotidien</option>
                <option value="weekly">Hebdomadaire</option>
                <option value="monthly">Mensuel</option>
              </select>
              <FiRepeat className="absolute left-3 top-3 text-gray-400" />
            </div>
          </div>
          
          {/* Options spécifiques à la répétition hebdomadaire */}
          {scheduleData.repeat === 'weekly' && (
            <div className="bg-noir p-4 rounded-lg">
              <label className="block text-gray-300 mb-2 font-medium">
                Jours de la semaine
              </label>
              <div className="flex flex-wrap gap-2">
                {['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'].map((day, index) => (
                  <button
                    key={day}
                    type="button"
                    onClick={() => handleDayToggle(index)}
                    className={`py-1.5 px-3 rounded-full text-sm ${
                      scheduleData.repeatDays.includes(index)
                        ? 'bg-bleu text-white'
                        : 'bg-gris text-gray-300 hover:bg-gray-700'
                    }`}
                  >
                    {day}
                  </button>
                ))}
              </div>
            </div>
          )}
          
          {/* Options spécifiques à la répétition mensuelle */}
          {scheduleData.repeat === 'monthly' && (
            <div className="bg-noir p-4 rounded-lg">
              <label htmlFor="repeatDate" className="block text-gray-300 mb-1.5 font-medium">
                Jour du mois
              </label>
              <select
                id="repeatDate"
                name="repeatDate"
                value={scheduleData.repeatDate}
                onChange={handleChange}
                className="w-full py-2 px-4 bg-gris border border-gray-700 rounded-lg text-white focus:outline-none focus:border-bleu"
              >
                {Array.from({ length: 31 }, (_, i) => i + 1).map(day => (
                  <option key={day} value={day}>{day}</option>
                ))}
              </select>
            </div>
          )}
          
          {/* Options de fin de répétition (si applicable) */}
          {scheduleData.repeat !== 'none' && (
            <div className="bg-noir p-4 rounded-lg space-y-4">
              <div>
                <label htmlFor="endDate" className="block text-gray-300 mb-1.5 font-medium">
                  Date de fin de répétition (optionnel)
                </label>
                <input
                  type="date"
                  id="endDate"
                  name="endDate"
                  value={scheduleData.endDate}
                  onChange={handleChange}
                  min={scheduleData.scheduledDate || today}
                  className="w-full py-2 px-4 bg-gris border border-gray-700 rounded-lg text-white focus:outline-none focus:border-bleu"
                />
              </div>
              
              <div>
                <label htmlFor="maxSends" className="block text-gray-300 mb-1.5 font-medium">
                  Nombre maximal d'envois (0 = illimité)
                </label>
                <input
                  type="number"
                  id="maxSends"
                  name="maxSends"
                  value={scheduleData.maxSends}
                  onChange={handleChange}
                  min="0"
                  className="w-full py-2 px-4 bg-gris border border-gray-700 rounded-lg text-white focus:outline-none focus:border-bleu"
                />
              </div>
            </div>
          )}
          
          {/* Messages d'erreur ou de succès */}
          {error && (
            <div className="p-4 rounded-lg bg-softRed bg-opacity-10 text-softRed border border-softRed border-opacity-20">
              <p className="font-medium">Erreur</p>
              <p className="text-sm mt-1 opacity-90">{error}</p>
            </div>
          )}
          
          {success && (
            <div className="p-4 rounded-lg bg-vert bg-opacity-10 text-vert border border-vert border-opacity-20">
              <p className="font-medium">Succès</p>
              <p className="text-sm mt-1 opacity-90">{success}</p>
            </div>
          )}
          
          {/* Boutons d'action */}
          <div className="pt-3 flex justify-end gap-4">
            <button
              type="button"
              onClick={() => navigate('/admin/notifications')}
              className="py-2.5 px-6 bg-gris hover:bg-noir text-white rounded-lg flex items-center justify-center gap-2 transition-colors"
            >
              Annuler
            </button>
            <button
              type="submit"
              className="py-2.5 px-8 bg-bleu hover:bg-blue-600 text-white rounded-lg flex items-center justify-center gap-2 transition-colors"
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <span className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white"></span>
                  Programmation en cours...
                </>
              ) : (
                <>
                  <FiCheck size={18} /> Programmer la notification
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ScheduleNotification;