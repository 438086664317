import React, { useState, useEffect, useContext } from 'react';
import InfosContext from './InfosAppProvider';
import { requestPermissionAndGetToken } from './FirebaseMessaging';
import { database, ref, set } from './FirebaseConfig';

// Styles pour le modal
import '../index.css';

// Clé VAPID pour les notifications push
const VAPID_KEY = "BGFgOky4BDvlgaCoFiAG1Q2uhc1TSCmwvp0vUN1UXSiuWADXagaNiDT3hRYIlRUQS564BRNL03CxzRr1lxCloE4";

const NotificationPermissionDialog = () => {
  const { userInfos, isAuthenticated } = useContext(InfosContext);
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Vérifier si l'utilisateur est authentifié et si le navigateur prend en charge les notifications
    if (isAuthenticated && 'Notification' in window) {
      // Vérifier si l'utilisateur a déjà répondu à la demande
      const hasResponded = localStorage.getItem('notificationPermissionRequested');
      
      if (!hasResponded && Notification.permission === 'default') {
        // Attendre que les données utilisateur soient chargées
        if (userInfos) {
          // Montrer la boîte de dialogue après un court délai pour éviter de la montrer immédiatement
          const timer = setTimeout(() => {
            setShowDialog(true);
          }, 2000);
          
          return () => clearTimeout(timer);
        }
      }
    }
  }, [isAuthenticated, userInfos]);

  const handleAccept = async () => {
    setLoading(true);
    try {
      const token = await requestPermissionAndGetToken(VAPID_KEY);
      
      if (token) {
        console.log('Notifications activées avec succès');
        // Le token est déjà sauvegardé dans la collection fcm_tokens
        // par la fonction requestPermissionAndGetToken
      }
    } catch (error) {
      console.error('Erreur lors de l\'activation des notifications:', error);
    } finally {
      setLoading(false);
      setShowDialog(false);
      // Marquer que l'utilisateur a déjà répondu à la demande
      localStorage.setItem('notificationPermissionRequested', 'true');
    }
  };

  const handleDecline = () => {
    setShowDialog(false);
    // Marquer que l'utilisateur a déjà répondu à la demande
    localStorage.setItem('notificationPermissionRequested', 'true');
  };

  if (!showDialog) return null;

  return (
    <div className="notification-permission-overlay">
      <div className="notification-permission-dialog">
        <div className="notification-permission-header">
          <img src="/logo192p.png" alt="PayForBet" className="notification-logo" />
          <h3>Activer les notifications</h3>
        </div>
        
        <div className="notification-permission-body">
          <p>Recevez des alertes instantanées pour:</p>
          <ul>
            <li>Vos transactions en cours</li>
            <li>La confirmation de vos dépôts et retraits</li>
            <li>Les promotions et offres spéciales</li>
          </ul>
          <p>Ne manquez aucune mise à jour importante!</p>
        </div>
        
        <div className="notification-permission-footer">
          <button 
            onClick={handleDecline}
            className="notification-permission-decline"
            disabled={loading}
          >
            Plus tard
          </button>
          <button 
            onClick={handleAccept}
            className="notification-permission-accept"
            disabled={loading}
          >
            {loading ? 'Activation...' : 'Activer maintenant'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotificationPermissionDialog;