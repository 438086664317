export default async function addCaisse(idAgent, token) {
  const baseURL = process.env.REACT_APP_BASE_URL || 'https://31f2-197-234-221-233.ngrok-free.app/api/';
  const url = `${baseURL}ajouter-caisse?idAgent=${idAgent}`;

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await response.json();

    if (data.error) {
      throw new Error(data.message || "Erreur lors de l'ajout de la caisse");
    }

    return { success: true, message: data.message };
  } catch (error) {
    return { success: false, message: error.message };
  }
}
