import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExchangeAlt,
  faArrowUp,
  faArrowDown,
  faWallet,
  faChartLine,
  faFilter,
  faSort
} from "@fortawesome/free-solid-svg-icons";
import InfosContext from "../../contexts/InfosAppProvider";
import PaginatedTransactions from "./PaginatedTransactions";
import DepositsWithPagination from "./DepositsWithPagination";
import RetraitsWithPagination from "./RetraitsWithPagination";

// Couleurs personnalisées
const colors = {
  noir: "#1C1C1CFF",
  bleu: "#0087BFFF",
  vert: "#28C281FF",
  softRed: "#ff6f61",
  softYellow: "#f9d57d",
  softPurple: "#b19cd9"
};

const MyTabs = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [isLargeScreen, setIsLargeScreen] = useState(false);
  const { transactions } = useContext(InfosContext);
  
  // Détection de la taille d'écran
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1024);
    };
    
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  // Configuration des onglets avec icônes et couleurs
  const tabs = [
    { 
      id: 'all', 
      label: 'Toutes', 
      icon: faExchangeAlt,
      color: colors.bleu,
      component: <PaginatedTransactions transactions={transactions} itemsPerPage={isLargeScreen ? 10 : 5} />
    },
    { 
      id: 'deposits', 
      label: 'Dépôts', 
      icon: faArrowUp,
      color: colors.vert,
      component: <DepositsWithPagination transactions={transactions} itemsPerPage={isLargeScreen ? 10 : 5} />
    },
    { 
      id: 'withdrawals', 
      label: 'Retraits', 
      icon: faArrowDown,
      color: colors.softRed,
      component: <RetraitsWithPagination transactions={transactions} itemsPerPage={isLargeScreen ? 10 : 5} /> 
    }
  ];

  // Calcule des statistiques pour l'onglet actif
  const getTabStats = (tabId) => {
    if (!transactions || Object.keys(transactions).length === 0) {
      return "0 transaction";
    }

    const transactionsArray = Object.values(transactions);
    
    if (tabId === 'all') {
      return `${transactionsArray.length} transaction${transactionsArray.length > 1 ? 's' : ''}`;
    } else if (tabId === 'deposits') {
      const deposits = transactionsArray.filter(transaction => transaction.type === "depot");
      return `${deposits.length} dépôt${deposits.length > 1 ? 's' : ''}`;
    } else if (tabId === 'withdrawals') {
      const withdrawals = transactionsArray.filter(transaction => transaction.type === "retrait");
      return `${withdrawals.length} retrait${withdrawals.length > 1 ? 's' : ''}`;
    }
    
    return "";
  };

  // Composant pour les onglets Mobile
  const MobileTabs = () => (
    <>
      {/* Statistiques rapides */}
      <div className="grid grid-cols-3 gap-3 mb-6">
        {tabs.map((tab, index) => (
          <div 
            key={tab.id}
            className={`bg-[#272728FF] rounded-lg p-3 shadow-md border-t-2 
              ${activeTab === index ? 'border-[#0087BFFF]' : 'border-transparent'} 
              cursor-pointer transition-all duration-300 transform hover:-translate-y-1`}
            onClick={() => setActiveTab(index)}
          >
            <div className="flex justify-between items-center mb-1">
              <FontAwesomeIcon 
                icon={tab.icon} 
                className={activeTab === index ? 'text-[#0087BFFF]' : 'text-gray-400'} 
              />
              <span className={`text-xs font-medium ${activeTab === index ? 'text-[#0087BFFF]' : 'text-gray-400'}`}>
                {tab.label}
              </span>
            </div>
            <div className="text-white text-sm font-bold">
              {getTabStats(tab.id)}
            </div>
          </div>
        ))}
      </div>

      {/* Onglets visuels */}
      <div className="flex border-b border-[#333333] mb-4">
        {tabs.map((tab, index) => (
          <button
            key={tab.id}
            className={`flex-1 py-3 text-sm font-medium flex flex-col items-center
              ${activeTab === index 
                ? `text-[${tab.color}] border-b-2 border-[${tab.color}]` 
                : 'text-gray-400'
              } transition-all duration-300`}
            onClick={() => setActiveTab(index)}
          >
            <FontAwesomeIcon 
              icon={tab.icon} 
              className={`mb-1 ${activeTab === index ? 'text-[#0087BFFF]' : 'text-gray-500'}`} 
            />
            {tab.label}
          </button>
        ))}
      </div>
    </>
  );
  
  // Composant pour les onglets Desktop
  const DesktopTabs = () => (
    <div className="bg-[#272728FF] rounded-xl shadow-xl mb-6 overflow-hidden">
      <div className="flex items-center border-b border-[#333333]">
        {tabs.map((tab, index) => (
          <button
            key={tab.id}
            className={`py-5 px-8 font-medium flex items-center gap-3 transition-all duration-300
              ${activeTab === index 
                ? 'bg-[#0087BFFF] text-white' 
                : 'text-gray-300 hover:bg-[#333333]'
              }`}
            onClick={() => setActiveTab(index)}
          >
            <div className={`w-8 h-8 rounded-lg flex items-center justify-center 
              ${activeTab === index 
                ? 'bg-white' 
                : 'bg-[#1C1C1CFF] border border-[#444444]'}`}>
              <FontAwesomeIcon 
                icon={tab.icon} 
                className={activeTab === index ? 'text-[#0087BFFF]' : 'text-gray-400'} 
              />
            </div>
            <span>{tab.label}</span>
            <span className="text-xs ml-1 opacity-70">({getTabStats(tab.id).split(' ')[0]})</span>
          </button>
        ))}
        
        <div className="ml-auto flex pr-4">
          <button className="w-8 h-8 flex items-center justify-center rounded-md hover:bg-[#333333] text-gray-400 hover:text-white transition-colors">
            <FontAwesomeIcon icon={faSort} />
          </button>
          <button className="w-8 h-8 flex items-center justify-center rounded-md hover:bg-[#333333] text-gray-400 hover:text-white transition-colors ml-2">
            <FontAwesomeIcon icon={faFilter} />
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div className="mb-16">
      {isLargeScreen ? <DesktopTabs /> : <MobileTabs />}

      {/* Contenu de l'onglet actif */}
      <div className="text-white">
        {tabs[activeTab].component}
      </div>
    </div>
  );
};

export default MyTabs;