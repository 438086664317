import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faExclamationCircle, 
  faCheckCircle,
  faHistory,
  faMoneyBillWave,
  faSyncAlt
} from '@fortawesome/free-solid-svg-icons';

const EmptyTransactions = ({ type = "default", onRefresh }) => {
  let title, description, icon, color;
  
  switch (type) {
    case "pending":
      title = "Aucune transaction en attente";
      description = "Vous n'avez pas de transactions en attente pour le moment.";
      icon = faExclamationCircle;
      color = "text-bleu";
      break;
    case "history":
      title = "Historique vide";
      description = "L'historique de vos transactions apparaîtra ici.";
      icon = faHistory;
      color = "text-gray-500";
      break;
    case "withdrawals":
      title = "Aucun retrait en attente";
      description = "Les demandes de retrait s'afficheront ici lorsqu'elles seront disponibles.";
      icon = faMoneyBillWave;
      color = "text-[#AA1D22FF]";
      break;
    case "approved":
      title = "Aucune transaction approuvée";
      description = "Les transactions approuvées apparaîtront ici.";
      icon = faCheckCircle;
      color = "text-vert";
      break;
    default:
      title = "Aucune transaction";
      description = "Aucune transaction n'est disponible actuellement.";
      icon = faExclamationCircle;
      color = "text-bleu";
  }
  
  return (
    <div className="flex flex-col items-center justify-center py-10 px-4 text-center">
      <div className={`w-16 h-16 ${color} bg-opacity-10 rounded-full flex items-center justify-center mb-4`}>
        <FontAwesomeIcon icon={icon} className={`${color} text-2xl`} />
      </div>
      
      <h3 className="text-white text-xl font-semibold mb-2">{title}</h3>
      <p className="text-gray-400 max-w-sm mb-6">{description}</p>
      
      {onRefresh && (
        <button 
          onClick={onRefresh}
          className="flex items-center px-4 py-2 bg-gris hover:bg-gris/80 text-white rounded-lg transition-colors"
        >
          <FontAwesomeIcon icon={faSyncAlt} className="mr-2" />
          Actualiser
        </button>
      )}
    </div>
  );
};

export default EmptyTransactions;