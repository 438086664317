import React, { useState, useContext } from 'react';
import InfosContext from '../../contexts/InfosAppProvider';
import DashboardHeader from '../components/DashboardHeader';
import BottomNavBar from '../components/BottomNavBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faArrowDown, 
  faArrowUp, 
  faSearch, 
  faCalendarAlt, 
  faFilter, 
  faTimes,
  faCheck,
  faClock,
  faSpinner,
  faAngleDown,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';

export default function SearchTransactions() {
  const { userInfos, momoTransactions } = useContext(InfosContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [searchType, setSearchType] = useState('all');
  const [hasSearched, setHasSearched] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [isAdvancedSearch, setIsAdvancedSearch] = useState(false);
  const [searchStatus, setSearchStatus] = useState('all');

  if (!userInfos || !momoTransactions) {
    return (
      <div className="min-h-screen bg-noir flex items-center justify-center">
        <div className="w-16 h-16 border-4 border-bleu border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  const handleSearch = (e) => {
    e.preventDefault();
    setHasSearched(true);
    
    // Convertir les dates en timestamps pour la comparaison
    const startTimestamp = startDate ? new Date(startDate).getTime() : 0;
    const endTimestamp = endDate ? new Date(endDate + 'T23:59:59').getTime() : Number.MAX_SAFE_INTEGER;
    
    const results = momoTransactions.filter(transaction => {
      // Vérifier le type de transaction si nécessaire
      if (searchType !== 'all' && transaction.type !== searchType) {
        return false;
      }
      
      // Vérifier le statut si nécessaire
      if (searchStatus !== 'all' && transaction.status !== searchStatus) {
        return false;
      }
      
      // Vérifier la plage de dates
      const transactionDate = transaction.createdAt;
      const transactionTimestamp = typeof transactionDate === 'number' 
        ? transactionDate 
        : new Date(transactionDate).getTime();
        
      if (transactionTimestamp < startTimestamp || transactionTimestamp > endTimestamp) {
        return false;
      }
      
      // Recherche par terme (ID, numéro de téléphone, etc.)
      if (searchTerm) {
        const termLower = searchTerm.toLowerCase();
        return (
          transaction.id.toLowerCase().includes(termLower) ||
          transaction.externalId.toLowerCase().includes(termLower) ||
          transaction.partyId.includes(searchTerm) ||
          (transaction.webhookData?.financialTransactionId || '').includes(searchTerm)
        );
      }
      
      return true;
    });
    
    setSearchResults(results);
  };

  const resetSearch = () => {
    setSearchTerm('');
    setStartDate('');
    setEndDate('');
    setSearchType('all');
    setSearchStatus('all');
    setHasSearched(false);
    setSearchResults([]);
    
    // Réinitialiser les éléments du formulaire
    const searchInput = document.querySelector('input[placeholder*="Rechercher"]');
    if (searchInput) searchInput.value = '';
    
    const dateInputs = document.querySelectorAll('input[type="date"]');
    dateInputs.forEach(input => input.value = '');
    
    const selects = document.querySelectorAll('select');
    selects.forEach(select => {
      select.value = 'all';
    });
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'SUCCESSFUL': return faCheck;
      case 'FAILED': return faTimes;
      case 'PENDING': return faClock;
      default: return faSpinner;
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'SUCCESSFUL': return 'text-vert';
      case 'FAILED': return 'text-softRed';
      case 'PENDING': return 'text-softYellow';
      default: return 'text-gray-400';
    }
  };

  const getStatusBgColor = (status) => {
    switch (status) {
      case 'SUCCESSFUL': return 'bg-vert/10';
      case 'FAILED': return 'bg-softRed/10';
      case 'PENDING': return 'bg-softYellow/10';
      default: return 'bg-gray-700';
    }
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const typeOptions = [
    { value: 'all', label: 'Tous les types' },
    { value: 'depot', label: 'Dépôts uniquement' },
    { value: 'retrait', label: 'Retraits uniquement' }
  ];

  const statusOptions = [
    { value: 'all', label: 'Tous les statuts' },
    { value: 'SUCCESSFUL', label: 'Réussis', icon: faCheck, color: 'text-vert' },
    { value: 'FAILED', label: 'Échoués', icon: faTimes, color: 'text-softRed' },
    { value: 'PENDING', label: 'En attente', icon: faClock, color: 'text-softYellow' }
  ];

  return (
    <div className="min-h-screen bg-noir text-white">
      <DashboardHeader user={userInfos} />
      
      <div className="container mx-auto px-4 py-6 md:pl-72 pb-24 md:pb-6">
        <h1 className="text-2xl font-bold mb-6">Recherche de transactions</h1>
        
        <div className="bg-gris rounded-xl overflow-hidden shadow-lg mb-8 border border-bleu/10">
          <div className="px-6 py-4 border-b border-bleu/10">
            <form onSubmit={handleSearch}>
              <div className="flex flex-col md:flex-row md:items-center gap-4">
                <div className="flex-1 relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <FontAwesomeIcon icon={faSearch} className="text-gray-400" />
                  </div>
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Rechercher par ID, téléphone, référence..."
                    className="w-full bg-noir pl-10 pr-4 py-3 rounded-lg text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-bleu/50"
                  />
                </div>
                
                <button
                  type="button"
                  onClick={() => setIsAdvancedSearch(!isAdvancedSearch)}
                  className="md:w-auto flex items-center gap-2 px-4 py-3 bg-noir rounded-lg text-gray-300 hover:bg-noir/80"
                >
                  <FontAwesomeIcon icon={faFilter} />
                  <span>Filtres</span>
                  <FontAwesomeIcon 
                    icon={faAngleDown} 
                    className={`transition-transform ${isAdvancedSearch ? 'rotate-180' : ''}`} 
                  />
                </button>
                
                <button
                  type="submit"
                  className="md:w-auto flex items-center justify-center gap-2 px-6 py-3 bg-bleu rounded-lg text-white hover:bg-bleu/90 transition"
                >
                  <FontAwesomeIcon icon={faSearch} />
                  <span>Rechercher</span>
                </button>
              </div>
              
              {isAdvancedSearch && (
                <div className="mt-4">
                  {/* Section sur mobile: tout en colonne */}
                  <div className="block md:hidden space-y-4">
                    <div>
                      <label className="block text-gray-400 text-sm mb-2">Type de transaction</label>
                      <div className="relative">
                        <select
                          value={searchType}
                          onChange={(e) => setSearchType(e.target.value)}
                          className="w-full bg-noir appearance-none px-4 py-3 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-bleu/50 border border-bleu/20"
                        >
                          {typeOptions.map(option => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <FontAwesomeIcon icon={faAngleDown} className="text-gray-400" />
                        </div>
                      </div>
                    </div>
                    
                    <div>
                      <label className="block text-gray-400 text-sm mb-2">Statut</label>
                      <div className="relative">
                        <select
                          value={searchStatus}
                          onChange={(e) => setSearchStatus(e.target.value)}
                          className="w-full bg-noir appearance-none px-4 py-3 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-bleu/50 border border-bleu/20"
                        >
                          {statusOptions.map(option => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <FontAwesomeIcon icon={faAngleDown} className="text-gray-400" />
                        </div>
                      </div>
                    </div>
                    
                    <div>
                      <label className="block text-gray-400 text-sm mb-2">Plage de dates</label>
                      <div className="flex flex-col space-y-3">
                        <div className="relative">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <FontAwesomeIcon icon={faCalendarAlt} className="text-gray-500" />
                          </div>
                          <input
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            className="w-full bg-noir pl-10 pr-4 py-3 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-bleu/50 border border-bleu/20"
                          />
                          <p className="text-xs text-gray-500 mt-1 ml-2">Date début</p>
                        </div>
                        
                        <div className="flex items-center justify-center">
                          <span className="text-gray-500 text-lg">à</span>
                        </div>
                        
                        <div className="relative">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <FontAwesomeIcon icon={faCalendarAlt} className="text-gray-500" />
                          </div>
                          <input
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            className="w-full bg-noir pl-10 pr-4 py-3 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-bleu/50 border border-bleu/20"
                          />
                          <p className="text-xs text-gray-500 mt-1 ml-2">Date fin</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  {/* Section sur PC: disposition optimisée */}
                  <div className="hidden md:grid md:grid-cols-3 gap-4">
                    <div>
                      <label className="block text-gray-400 text-sm mb-2">Type de transaction</label>
                      <div className="relative">
                        <select
                          value={searchType}
                          onChange={(e) => setSearchType(e.target.value)}
                          className="w-full bg-noir appearance-none px-4 py-3 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-bleu/50 border border-bleu/20"
                        >
                          {typeOptions.map(option => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <FontAwesomeIcon icon={faAngleDown} className="text-gray-400" />
                        </div>
                      </div>
                    </div>
                    
                    <div>
                      <label className="block text-gray-400 text-sm mb-2">Statut</label>
                      <div className="relative">
                        <select
                          value={searchStatus}
                          onChange={(e) => setSearchStatus(e.target.value)}
                          className="w-full bg-noir appearance-none px-4 py-3 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-bleu/50 border border-bleu/20"
                        >
                          {statusOptions.map(option => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <FontAwesomeIcon icon={faAngleDown} className="text-gray-400" />
                        </div>
                      </div>
                    </div>
                    
                    <div>
                      <label className="block text-gray-400 text-sm mb-2">Plage de dates</label>
                      <div className="grid grid-cols-5 gap-2 items-center">
                        <div className="col-span-2 relative">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <FontAwesomeIcon icon={faCalendarAlt} className="text-gray-500" />
                          </div>
                          <input
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            className="w-full bg-noir pl-10 pr-2 py-3 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-bleu/50 border border-bleu/20"
                          />
                          <p className="text-xs text-gray-500 mt-1 ml-2">Date début</p>
                        </div>
                        
                        <div className="col-span-1 flex items-center justify-center">
                          <span className="text-gray-500 text-lg">à</span>
                        </div>
                        
                        <div className="col-span-2 relative">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <FontAwesomeIcon icon={faCalendarAlt} className="text-gray-500" />
                          </div>
                          <input
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            className="w-full bg-noir pl-10 pr-2 py-3 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-bleu/50 border border-bleu/20"
                          />
                          <p className="text-xs text-gray-500 mt-1 ml-2">Date fin</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              
              {isAdvancedSearch && (
                <div className="mt-4 flex justify-between items-center">
                  <div className="text-xs text-gray-500">
                    <FontAwesomeIcon icon={faCalendarAlt} className="mr-1" />
                    Format de date: yyyy-mm-dd (Ex: 2025-03-21)
                  </div>
                  <button
                    type="button"
                    onClick={resetSearch}
                    className="px-4 py-2 bg-noir text-gray-300 hover:text-white rounded-lg border border-gray-600 hover:border-gray-500 transition-colors"
                  >
                    Réinitialiser
                  </button>
                </div>
              )}
            </form>
          </div>
        </div>
        
        {hasSearched && (
          <div className="bg-gris rounded-xl overflow-hidden shadow-lg mb-6 border border-bleu/10">
            <div className="px-6 py-4 border-b border-bleu/10 flex justify-between items-center">
              <h2 className="text-lg font-semibold">Résultats de recherche</h2>
              <div className="text-sm text-gray-400">
                {searchResults.length} transaction{searchResults.length !== 1 ? 's' : ''} trouvée{searchResults.length !== 1 ? 's' : ''}
              </div>
            </div>
            
            {searchResults.length > 0 ? (
              <div className="divide-y divide-bleu/10">
                {searchResults.map(transaction => (
                  <div key={transaction.id} className="p-4 hover:bg-noir/30 transition">
                    <div className="flex items-start">
                      <div className={`w-10 h-10 rounded-full flex items-center justify-center mr-4 mt-1 ${transaction.type === 'depot' ? 'bg-bleu/10' : 'bg-vert/10'}`}>
                        <FontAwesomeIcon 
                          icon={transaction.type === 'depot' ? faArrowDown : faArrowUp} 
                          className={transaction.type === 'depot' ? 'text-bleu' : 'text-vert'} 
                        />
                      </div>
                      
                      <div className="flex-1">
                        <div className="flex justify-between mb-2">
                          <div>
                            <div className="text-white font-medium">
                              {transaction.type === 'depot' ? 'Dépôt' : 'Retrait'} - {transaction.partyId}
                            </div>
                            <div className="text-sm text-gray-400 mb-1">
                              {formatDate(transaction.updatedAt || transaction.createdAt)}
                            </div>
                          </div>
                          
                          <div className="text-right">
                            <div className="text-white font-bold">
                              {transaction.amount} {transaction.webhookData?.currency || 'XOF'}
                            </div>
                            <div className={`inline-flex items-center px-2 py-1 rounded-full text-xs ${getStatusBgColor(transaction.status)} ${getStatusColor(transaction.status)}`}>
                              <FontAwesomeIcon icon={getStatusIcon(transaction.status)} className="mr-1" />
                              {transaction.status}
                            </div>
                          </div>
                        </div>
                        
                        <div className="bg-noir/30 rounded-lg p-3">
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-y-2 gap-x-4 text-sm">
                            <div>
                              <span className="text-gray-500">ID Transaction:</span> 
                              <span className="text-white ml-2">{transaction.externalId}</span>
                            </div>
                            <div>
                              <span className="text-gray-500">ID Système:</span> 
                              <span className="text-white ml-2">{transaction.id}</span>
                            </div>
                            
                            {transaction.webhookData?.financialTransactionId && (
                              <div>
                                <span className="text-gray-500">Réf. Financière:</span> 
                                <span className="text-white ml-2">{transaction.webhookData.financialTransactionId}</span>
                              </div>
                            )}
                            
                            {transaction.webhookData?.reason && (
                              <div>
                                <span className="text-gray-500">Raison:</span> 
                                <span className="text-softRed ml-2">{transaction.webhookData.reason}</span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="py-16 text-center">
                <div className="w-16 h-16 mx-auto bg-softRed/10 rounded-full flex items-center justify-center mb-4">
                  <FontAwesomeIcon icon={faExclamationTriangle} className="text-softRed text-xl" />
                </div>
                <h3 className="text-lg font-medium text-white mb-1">Aucune transaction trouvée</h3>
                <p className="text-gray-400 max-w-md mx-auto">
                  Aucune transaction ne correspond à vos critères de recherche. Essayez d'élargir votre recherche ou de modifier vos filtres.
                </p>
              </div>
            )}
          </div>
        )}
      </div>
      
      <BottomNavBar />
    </div>
  );
}