import { faArrowLeft, faKeyboard, faMoneyBillTransfer, faCreditCard, faUserClock, faMobile, faCheckDouble, faWallet } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useContext, useEffect } from "react";
import InfosContext from "../../contexts/InfosAppProvider";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  faCheck,
  faEnvelope,
  faPaperPlane,
  faChevronDown,
  faCheckCircle,
  faCoins
} from "@fortawesome/free-solid-svg-icons";

import Swal from "sweetalert2";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Link, useNavigate } from "react-router-dom";
import { ajouterTransaction } from "../../requtes/clients/ajouterTransaction";

export default function DepotManuel() {
  // Couleurs personnalisées
  const colors = {
    noir: "#1C1C1CFF",
    bleu: "#0087BFFF",
    vert: "#28C281FF",
    softRed: "#ff6f61",
    softYellow: "#f9d57d",
    softPurple: "#b19cd9",
    black: "#1C1C1CFF",
    gris: "#272728FF",
  };

  const navigate = useNavigate();
  const {
    infosAdmin,
    setInfosAdmin,
    userInfos,
    setUserInfos,
    transactions,
    setTransactions,
    update,
    setUpdate,
    token,
    fetchUserInfos,
  } = useContext(InfosContext);

  // États de base
  const [inputValue, setInputValue] = useState(userInfos.idCompte1xbet || "");
  const [montant, setMontant] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(66);
  const [textToCopy, setTextToCopy] = useState("");
  const [visible, setVisible] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(userInfos.whatsapp || "");

  // Types d'opérations
  const [operationType, setOperationType] = useState("automatique");
  
  // Montants prédéfinis
  const defaultAmounts = ["500", "1000", "2000", "5000"];
  const updatedAmounts = infosAdmin.montant
    ? infosAdmin.montant.split(",").map((value) => parseInt(value.trim()))
    : defaultAmounts;
  
  const [selectedAmount, setSelectedAmount] = useState(null);
  const idUser = localStorage.getItem("idUser");

  // Fonction pour les notifications
  const notifySuccess = () => {
    toast.success("Code copié", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  // Mettre à jour le code USSD à copier lorsque le montant change
  useEffect(() => {
    if (!isNaN(montant) && montant >= 50) {
      setTextToCopy(`*880*41*589170*${montant}#`);
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [montant]);

  // Gestionnaires d'événements
  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleChangeMontant = (event) => {
    setMontant(event.target.value);
  };

  const handleChangeTransactionId = (event) => {
    setTransactionId(event.target.value);
  };

  const handleChangePhoneNumber = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleClickAmount = (amount) => {
    setSelectedAmount(amount);
    setMontant(amount);
  };

  const handleClickPhone = () => {
    if (montant >= 50) {
      const code = `*880*41*589170*${montant}#`;
      window.location.href = `tel:${code}`;
    } else {
      toast.error("Le montant minimum est de 50 FCFA");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    // Vérification du montant minimum
    if (montant < 50) {
      toast.error("Le montant minimum est de 50 FCFA");
      return;
    }

    // Validation des champs
    if (!inputValue) {
      toast.error("Veuillez saisir l'ID de votre compte 1xBet");
      return;
    }
    
    if (operationType === "manuel" && !transactionId) {
      toast.error("Veuillez saisir l'ID de la transaction Mobile Money");
      return;
    }

    // Validation du numéro de téléphone pour les modes automatique et semi-automatique
    if ((operationType === "automatique" || operationType === "semi-automatique") && !phoneNumber) {
      toast.error("Veuillez saisir votre numéro de téléphone MTN pour le paiement");
      return;
    }

    setLoading(true);

    try {
      // Déterminer le mode de paiement
      let modeOperation;
      let moyenPaiement;
      
      if (operationType === "automatique") {
        modeOperation = "automatique";
        moyenPaiement = "MTN"; // MTN et non API pour l'intégration avec l'API MTN
      } else if (operationType === "manuel") {
        modeOperation = "manuel";
        moyenPaiement = "MTN";
      } else {
        modeOperation = "semi";  // "semi" et non "semi-automatique" pour correspondre à ce qu'attend le backend
        moyenPaiement = "MTN";   // MTN et non API pour l'intégration avec l'API MTN
      }

      // Préparer les paramètres pour l'appel à l'API
      const params = {
        type: "depot",
        idUser: idUser,
        idCompte1xbet: inputValue,
        codeRetrait: "rien",
        moyenPaiement: moyenPaiement,
        montant: montant,
        mode: modeOperation,
        idTransactionMobile: transactionId || "automatique",
      };

      // Ajouter le numéro de téléphone pour les modes autres que manuel
      if (operationType === "automatique" || operationType === "semi-automatique") {
        params.numeroMtn = phoneNumber;
      }

      // Appel de la fonction ajouterTransaction avec tous les paramètres
      const res = await ajouterTransaction(
        params.type,
        params.idUser,
        params.idCompte1xbet,
        params.codeRetrait,
        params.moyenPaiement,
        params.montant,
        params.mode,
        params.idTransactionMobile,
        token,
        params.numeroMtn // Passer le numéro MTN comme paramètre supplémentaire
      );

      localStorage.setItem("depotxbet", inputValue);
      localStorage.setItem("depotmontant", montant);
      // D'abord arrêter le chargement et rediriger si la réponse est correcte
      setLoading(false);
      
      if (res && res.error === false) {
        // Stocker l'externalId et l'idTransactionMTN pour la page de confirmation
        localStorage.setItem("externalId", res.externalId || "");
        localStorage.setItem("idTransactionMTN", res.idTransactionMTN || "");
        localStorage.setItem("transactionConfirmationId", res.idTransaction || "");
        
        console.log("Redirection vers confirmation avec externalId:", res.externalId);
        console.log("ID Transaction:", res.idTransaction);
        
        // Mettre fin au chargement avant redirection
        setLoading(false);
        
        // Redirection vers la page de confirmation sans recharger la page 
        // (pour éviter que le splash screen s'affiche)
        navigate(`/clients/depot-confirmation/${res.externalId}/${res.idTransaction}`);
        return; // Sortir pour éviter d'exécuter le reste du code
      } else {
        // Afficher un message d'erreur si la réponse contient une erreur
        throw new Error(res.message || "Erreur lors du traitement de la transaction");
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "Erreur",
        text: error.message || "Une erreur est survenue lors du traitement de votre demande.",
        icon: "error",
        confirmButtonText: "OK",
        background: colors.noir,
        color: "#FFFFFF",
        iconColor: colors.softRed,
      });
    }
  };

  return (
    <>
      <ToastContainer />
      {/* Version Mobile */}
      <div className="bg-gradient-to-b from-[#1C1C1CFF] to-[#272728FF] min-h-screen flex flex-col pb-24 md:hidden">
        <div
          onClick={() => {
            window.history.back();
          }}
          className="text-white h-12 font-albert text-lg px-3 fixed top-0 w-full bg-[#1C1C1CFF]/90 backdrop-blur flex items-center z-10 shadow-md"
        >
          <FontAwesomeIcon icon={faArrowLeft} className="px-3" />
          Retour
        </div>

        <div className="mt-16 px-5">
          <h1 className="text-2xl font-bold text-white mb-1">Recharger mon compte</h1>
          <p className="text-gray-300 text-sm">
            Rechargez votre compte 1xBet rapidement et en toute sécurité.
          </p>
        </div>

        <div className="relative mt-5 mx-4 p-4 bg-[#272728FF]/70 backdrop-blur-sm rounded-xl border border-white/10 shadow-lg">
          <form onSubmit={handleSubmit} className="flex flex-col">
            {/* Type d'opération */}
            <div className="mb-5">
              <label className="block text-gray-300 text-sm font-medium mb-2">
                Type de rechargement
              </label>
              <div className="grid grid-cols-3 gap-2">
                <div
                  onClick={() => setOperationType("automatique")}
                  className={`flex flex-col items-center justify-center p-3 rounded-xl cursor-pointer transition duration-200 ease-in-out
                    ${operationType === "automatique" 
                      ? `bg-[${colors.bleu}] bg-opacity-20 shadow-[${colors.bleu}]/20 shadow-lg border border-[${colors.bleu}]/70` 
                      : "bg-[#272728FF]/80 border border-gray-700/40 hover:bg-[#272728FF]"}`}
                >
                  <FontAwesomeIcon icon={faMobile} className={`text-xl ${operationType === "automatique" ? "text-white" : "text-gray-400"}`} />
                  <span className={`text-xs mt-2 ${operationType === "automatique" ? "text-white" : "text-gray-400"}`}>Automatique</span>
                </div>
                
                <div
                  onClick={() => setOperationType("semi-automatique")}
                  className={`flex flex-col items-center justify-center p-3 rounded-xl cursor-pointer transition duration-200 ease-in-out
                    ${operationType === "semi-automatique" 
                      ? `bg-[${colors.bleu}] bg-opacity-20 shadow-[${colors.bleu}]/20 shadow-lg border border-[${colors.bleu}]/70` 
                      : "bg-[#272728FF]/80 border border-gray-700/40 hover:bg-[#272728FF]"}`}
                >
                  <FontAwesomeIcon icon={faUserClock} className={`text-xl ${operationType === "semi-automatique" ? "text-white" : "text-gray-400"}`} />
                  <span className={`text-xs mt-2 ${operationType === "semi-automatique" ? "text-white" : "text-gray-400"}`}>Semi-auto</span>
                </div>
                
                <div
                  onClick={() => setOperationType("manuel")}
                  className={`flex flex-col items-center justify-center p-3 rounded-xl cursor-pointer transition duration-200 ease-in-out
                    ${operationType === "manuel" 
                      ? `bg-[${colors.bleu}] bg-opacity-20 shadow-[${colors.bleu}]/20 shadow-lg border border-[${colors.bleu}]/70` 
                      : "bg-[#272728FF]/80 border border-gray-700/40 hover:bg-[#272728FF]"}`}
                >
                  <FontAwesomeIcon icon={faMoneyBillTransfer} className={`text-xl ${operationType === "manuel" ? "text-white" : "text-gray-400"}`} />
                  <span className={`text-xs mt-2 ${operationType === "manuel" ? "text-white" : "text-gray-400"}`}>Manuel</span>
                </div>
              </div>
            </div>

            {/* ID du compte 1xBet */}
            <div className="mb-5">
              <div className="flex justify-between items-center mb-1.5">
                <label className="text-gray-300 text-sm font-medium">ID du compte 1xBet</label>
                <span className="text-xs text-[#0087BFFF]">
                  {inputValue ? <FontAwesomeIcon icon={faCheckCircle} className="mr-1" /> : ""}
                  requis
                </span>
              </div>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <FontAwesomeIcon icon={faWallet} className="text-gray-400" />
                </div>
                <input
                  className="w-full h-12 pl-10 pr-3 py-2 bg-[#1C1C1CFF]/80 text-white placeholder-gray-500 border border-gray-700/60 rounded-xl focus:ring-2 focus:ring-[#0087BFFF] focus:border-[#0087BFFF] transition duration-200 outline-none"
                  type="number"
                  pattern="\d*"
                  inputMode="numeric"
                  placeholder="Entrez votre ID 1xBet"
                  value={inputValue}
                  onChange={handleChange}
                  required
                />
              </div>
              <p className="mt-1.5 text-xs text-gray-400">
                Vous pouvez modifier votre ID 1xBet par défaut dans les paramètres.
              </p>
            </div>

            {/* Montant */}
            <div className="mb-5">
              <div className="flex justify-between items-center mb-1.5">
                <label className="text-gray-300 text-sm font-medium">Montant (min. 50 FCFA)</label>
                <span className="text-xs text-[#0087BFFF]">
                  {montant >= 50 ? <FontAwesomeIcon icon={faCheckCircle} className="mr-1" /> : ""}
                  requis
                </span>
              </div>
              
              {/* Montants prédéfinis */}
              <div className="grid grid-cols-4 gap-2 mb-3">
                {updatedAmounts.map((amount, index) => (
                  <div
                    key={index}
                    onClick={() => handleClickAmount(amount)}
                    className={`py-2 px-3 rounded-xl flex items-center justify-center cursor-pointer transition duration-200 text-sm font-medium
                      ${selectedAmount === amount 
                        ? `bg-[${colors.bleu}] text-white border border-[${colors.bleu}]/70 shadow-md` 
                        : "bg-[#1C1C1CFF]/80 text-gray-300 border border-gray-700/60 hover:bg-[#272728FF]"}`}
                  >
                    {amount.toLocaleString()} F
                  </div>
                ))}
              </div>
              
              {/* Input pour montant personnalisé */}
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <FontAwesomeIcon icon={faCoins} className="text-gray-400" />
                </div>
                <input
                  className="w-full h-12 pl-10 pr-3 py-2 bg-[#1C1C1CFF]/80 text-white placeholder-gray-500 border border-gray-700/60 rounded-xl focus:ring-2 focus:ring-[#0087BFFF] focus:border-[#0087BFFF] transition duration-200 outline-none"
                  type="number"
                  pattern="\d*"
                  inputMode="numeric"
                  placeholder="Montant personnalisé"
                  value={montant}
                  onChange={handleChangeMontant}
                  min="50"
                  required
                />
              </div>
              {montant && parseInt(montant) < 50 && (
                <p className="mt-1.5 text-xs text-[#ff6f61]">
                  Le montant minimum est de 50 FCFA
                </p>
              )}
            </div>

            {/* Champ de numéro de téléphone pour paiement automatique */}
            {(operationType === "automatique" || operationType === "semi-automatique") && (
              <div className="mb-5">
                <div className="flex justify-between items-center mb-1.5">
                  <label className="text-gray-300 text-sm font-medium">Numéro de téléphone</label>
                  <span className="text-xs text-[#0087BFFF]">
                    {phoneNumber ? <FontAwesomeIcon icon={faCheckCircle} className="mr-1" /> : ""}
                    requis
                  </span>
                </div>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <FontAwesomeIcon icon={faMobile} className="text-gray-400" />
                  </div>
                  <input
                    className="w-full h-12 pl-10 pr-3 py-2 bg-[#1C1C1CFF]/80 text-white placeholder-gray-500 border border-gray-700/60 rounded-xl focus:ring-2 focus:ring-[#0087BFFF] focus:border-[#0087BFFF] transition duration-200 outline-none"
                    type="text"
                    inputMode="tel"
                    placeholder="Numéro pour le paiement"
                    value={phoneNumber}
                    onChange={handleChangePhoneNumber}
                    required
                  />
                </div>
                <p className="mt-1.5 text-xs text-gray-400">
                  Ce numéro sera utilisé pour le paiement automatique.
                </p>
              </div>
            )}

            {/* Informations pour paiement manuel */}
            {operationType === "manuel" && visible && (
              <div className="mb-5">
                <div className="p-4 rounded-xl bg-[#1C1C1CFF]/80 border border-gray-700/60 mb-4">
                  <div className="flex items-center mb-2">
                    <FontAwesomeIcon icon={faKeyboard} className="text-[#0087BFFF] mr-2" />
                    <h3 className="text-white font-medium">Requête MoMo</h3>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="bg-[#1C1C1CFF] py-2 px-4 rounded-lg text-white text-sm font-medium flex-grow mr-3">
                      *880*41*589170*{montant}#
                    </div>
                    <div className="flex space-x-2">
                      <CopyToClipboard text={textToCopy} onCopy={notifySuccess}>
                        <button
                          type="button"
                          className="py-2 px-3 bg-[#0087BFFF] text-white rounded-lg text-sm font-medium transition hover:bg-[#0087BFFF]/80"
                        >
                          Copier
                        </button>
                      </CopyToClipboard>
                      <button
                        type="button"
                        onClick={handleClickPhone}
                        className="py-2 px-3 bg-[#28C281FF] text-white rounded-lg text-sm font-medium transition hover:bg-[#28C281FF]/80"
                      >
                        Appeler
                      </button>
                    </div>
                  </div>
                </div>

                <div className="flex justify-between items-center mb-1.5">
                  <label className="text-gray-300 text-sm font-medium">ID de transaction MTN</label>
                  <span className="text-xs text-[#0087BFFF]">
                    {transactionId ? <FontAwesomeIcon icon={faCheckCircle} className="mr-1" /> : ""}
                    requis
                  </span>
                </div>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <FontAwesomeIcon icon={faCheckDouble} className="text-gray-400" />
                  </div>
                  <input
                    className="w-full h-12 pl-10 pr-3 py-2 bg-[#1C1C1CFF]/80 text-white placeholder-gray-500 border border-gray-700/60 rounded-xl focus:ring-2 focus:ring-[#0087BFFF] focus:border-[#0087BFFF] transition duration-200 outline-none"
                    type="text"
                    inputMode="numeric"
                    placeholder="Entrez les 10 chiffres"
                    value={transactionId}
                    onChange={handleChangeTransactionId}
                    maxLength={10}
                    onInput={(e) => {
                      if (e.target.value.length > 10) {
                        e.target.value = e.target.value.slice(0, 10);
                      }
                    }}
                    required={operationType === "manuel"}
                  />
                </div>
                <p className="mt-1.5 text-xs text-gray-400">
                  Entrez l'ID de transaction MTN reçu par SMS après votre paiement.
                </p>
              </div>
            )}

            {/* Bouton de soumission */}
            <button
              className="w-full h-14 flex justify-center items-center bg-[#0087BFFF] bg-opacity-20 text-white rounded-xl shadow-lg shadow-[#0087BFFF]/20 transition duration-200 hover:bg-opacity-30 mt-4"
              type="submit"
              disabled={loading}
            >
              {loading ? (
                <div className="flex items-center">
                  <div className="w-6 h-6 mr-2 animate-spin">
                    <CircularProgressbar
                      value={progress}
                      styles={buildStyles({
                        pathColor: "white",
                        trailColor: "rgba(255, 255, 255, 0.2)",
                        strokeLinecap: "round",
                      })}
                    />
                  </div>
                  <span>Traitement...</span>
                </div>
              ) : (
                <span className="flex items-center">
                  <FontAwesomeIcon icon={faCreditCard} className="mr-2" />
                  {operationType === "automatique" ? "Payer maintenant" : operationType === "semi-automatique" ? "Poursuivre" : "Confirmer le paiement"}
                </span>
              )}
            </button>
          </form>
        </div>
        
        {/* Informations sur le processus */}
        
        {/* Espace pour éviter le chevauchement avec la BottomNavBar */}
        <div className="h-24"></div>
      </div>

      {/* Version Desktop */}
      <div className="bg-gradient-to-b from-[#1C1C1CFF] to-[#272728FF] min-h-screen hidden md:flex flex-col pb-20">
        <div className="max-w-4xl mx-auto w-full">
          <h1 className="text-3xl font-bold text-white mb-2 text-center">Recharger mon compte 1xBet</h1>
          <p className="text-gray-300 text-center mb-8">
            Choisissez votre méthode préférée pour recharger votre compte en toute sécurité
          </p>

          <div className="bg-[#272728FF]/70 backdrop-blur-sm rounded-2xl border border-white/10 shadow-xl p-8">
            <form onSubmit={handleSubmit} className="flex flex-col">
              {/* Type d'opération */}
              <div className="mb-6">
                <label className="block text-gray-300 text-sm font-medium mb-3">
                  Type de rechargement
                </label>
                <div className="grid grid-cols-3 gap-4">
                  <div
                    onClick={() => setOperationType("automatique")}
                    className={`flex flex-col items-center justify-center p-4 rounded-xl cursor-pointer transition duration-200 ease-in-out
                      ${operationType === "automatique" 
                        ? "bg-[#0087BFFF] bg-opacity-20 shadow-lg border border-[#0087BFFF]/50" 
                        : "bg-[#272728FF]/80 border border-gray-700/40 hover:bg-[#1C1C1CFF]"}`}
                  >
                    <FontAwesomeIcon icon={faMobile} className={`text-2xl mb-2 ${operationType === "automatique" ? "text-white" : "text-gray-400"}`} />
                    <span className={`font-medium ${operationType === "automatique" ? "text-white" : "text-gray-400"}`}>Automatique</span>
                    <p className={`text-xs mt-1 ${operationType === "automatique" ? "text-[#f9d57d]" : "text-gray-500"}`}>
                      Paiement et dépôt automatisés
                    </p>
                  </div>
                  
                  <div
                    onClick={() => setOperationType("semi-automatique")}
                    className={`flex flex-col items-center justify-center p-4 rounded-xl cursor-pointer transition duration-200 ease-in-out
                      ${operationType === "semi-automatique" 
                        ? "bg-[#0087BFFF] bg-opacity-20 shadow-lg border border-[#0087BFFF]/50" 
                        : "bg-[#272728FF]/80 border border-gray-700/40 hover:bg-[#1C1C1CFF]"}`}
                  >
                    <FontAwesomeIcon icon={faUserClock} className={`text-2xl mb-2 ${operationType === "semi-automatique" ? "text-white" : "text-gray-400"}`} />
                    <span className={`font-medium ${operationType === "semi-automatique" ? "text-white" : "text-gray-400"}`}>Semi-automatique</span>
                    <p className={`text-xs mt-1 ${operationType === "semi-automatique" ? "text-[#f9d57d]" : "text-gray-500"}`}>
                      Paiement auto + confirmation
                    </p>
                  </div>
                  
                  <div
                    onClick={() => setOperationType("manuel")}
                    className={`flex flex-col items-center justify-center p-4 rounded-xl cursor-pointer transition duration-200 ease-in-out
                      ${operationType === "manuel" 
                        ? "bg-[#0087BFFF] bg-opacity-20 shadow-lg border border-[#0087BFFF]/50" 
                        : "bg-[#272728FF]/80 border border-gray-700/40 hover:bg-[#1C1C1CFF]"}`}
                  >
                    <FontAwesomeIcon icon={faMoneyBillTransfer} className={`text-2xl mb-2 ${operationType === "manuel" ? "text-white" : "text-gray-400"}`} />
                    <span className={`font-medium ${operationType === "manuel" ? "text-white" : "text-gray-400"}`}>Manuel</span>
                    <p className={`text-xs mt-1 ${operationType === "manuel" ? "text-[#f9d57d]" : "text-gray-500"}`}>
                      Paiement et confirmation manuels
                    </p>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-2 gap-6">
                <div>
                  {/* ID du compte 1xBet */}
                  <div className="mb-6">
                    <div className="flex justify-between items-center mb-2">
                      <label className="text-gray-300 text-sm font-medium">ID du compte 1xBet</label>
                      <span className="text-xs text-[#0087BFFF]">
                        {inputValue ? <FontAwesomeIcon icon={faCheckCircle} className="mr-1" /> : ""}
                        requis
                      </span>
                    </div>
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <FontAwesomeIcon icon={faWallet} className="text-gray-400" />
                      </div>
                      <input
                        className="w-full h-12 pl-10 pr-3 py-2 bg-[#1C1C1CFF]/80 text-white placeholder-gray-500 border border-gray-700/60 rounded-xl focus:ring-2 focus:ring-[#0087BFFF] focus:border-[#0087BFFF] transition duration-200 outline-none"
                        type="number"
                        pattern="\d*"
                        inputMode="numeric"
                        placeholder="Entrez votre ID 1xBet"
                        value={inputValue}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <p className="mt-1.5 text-xs text-gray-400">
                      Vous pouvez modifier votre ID 1xBet par défaut dans les paramètres.
                    </p>
                  </div>

                  {/* Montant */}
                  <div className="mb-6">
                    <div className="flex justify-between items-center mb-2">
                      <label className="text-gray-300 text-sm font-medium">Montant (min. 50 FCFA)</label>
                      <span className="text-xs text-[#0087BFFF]">
                        {montant >= 50 ? <FontAwesomeIcon icon={faCheckCircle} className="mr-1" /> : ""}
                        requis
                      </span>
                    </div>
                    
                    {/* Montants prédéfinis */}
                    <div className="grid grid-cols-4 gap-2 mb-3">
                      {updatedAmounts.map((amount, index) => (
                        <div
                          key={index}
                          onClick={() => handleClickAmount(amount)}
                          className={`py-2 px-3 rounded-xl flex items-center justify-center cursor-pointer transition duration-200 text-sm font-medium
                            ${selectedAmount === amount 
                              ? `bg-[${colors.bleu}] text-white border border-[${colors.bleu}]/70 shadow-md` 
                              : "bg-[#1C1C1CFF]/80 text-gray-300 border border-gray-700/60 hover:bg-[#272728FF]"}`}
                        >
                          {amount.toLocaleString()} F
                        </div>
                      ))}
                    </div>
                    
                    {/* Input pour montant personnalisé */}
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <FontAwesomeIcon icon={faCoins} className="text-gray-400" />
                      </div>
                      <input
                        className="w-full h-12 pl-10 pr-3 py-2 bg-[#1C1C1CFF]/80 text-white placeholder-gray-500 border border-gray-700/60 rounded-xl focus:ring-2 focus:ring-[#0087BFFF] focus:border-[#0087BFFF] transition duration-200 outline-none"
                        type="number"
                        pattern="\d*"
                        inputMode="numeric"
                        placeholder="Montant personnalisé"
                        value={montant}
                        onChange={handleChangeMontant}
                        min="50"
                        required
                      />
                    </div>
                    {montant && parseInt(montant) < 50 && (
                      <p className="mt-1.5 text-xs text-[#ff6f61]">
                        Le montant minimum est de 50 FCFA
                      </p>
                    )}
                  </div>
                </div>
                
                <div>
                  {/* Champ de numéro de téléphone pour paiement automatique */}
                  {(operationType === "automatique" || operationType === "semi-automatique") && (
                    <div className="mb-6">
                      <div className="flex justify-between items-center mb-2">
                        <label className="text-gray-300 text-sm font-medium">Numéro de téléphone</label>
                        <span className="text-xs text-[#0087BFFF]">
                          {phoneNumber ? <FontAwesomeIcon icon={faCheckCircle} className="mr-1" /> : ""}
                          requis
                        </span>
                      </div>
                      <div className="relative">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <FontAwesomeIcon icon={faMobile} className="text-gray-400" />
                        </div>
                        <input
                          className="w-full h-12 pl-10 pr-3 py-2 bg-[#1C1C1CFF]/80 text-white placeholder-gray-500 border border-gray-700/60 rounded-xl focus:ring-2 focus:ring-[#0087BFFF] focus:border-[#0087BFFF] transition duration-200 outline-none"
                          type="text"
                          inputMode="tel"
                          placeholder="Numéro pour le paiement"
                          value={phoneNumber}
                          onChange={handleChangePhoneNumber}
                          required
                        />
                      </div>
                      <p className="mt-1.5 text-xs text-gray-400">
                        Ce numéro sera utilisé pour le paiement automatique.
                      </p>
                      
                      <div className="mt-8 p-4 bg-[#0087BFFF]/10 rounded-xl border border-[#0087BFFF]/30">
                        <h3 className="text-[#0087BFFF] font-medium mb-2">Paiement {operationType === "automatique" ? "automatique" : "semi-automatique"}</h3>
                        <p className="text-gray-300 text-sm">
                          {operationType === "automatique" 
                            ? "Votre compte sera rechargé automatiquement après confirmation du paiement."
                            : "Après votre paiement, un caissier confirmera votre dépôt sur 1xBet."}
                        </p>
                        
                        <div className="mt-3 flex items-center text-xs text-gray-400">
                          <FontAwesomeIcon icon={faCheck} className="text-[#28C281FF] mr-2" />
                          Rapide et sécurisé
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Informations pour paiement manuel */}
                  {operationType === "manuel" && visible && (
                    <div className="mb-6">
                      <div className="p-4 rounded-xl bg-[#1C1C1CFF]/80 border border-gray-700/60 mb-4">
                        <div className="flex items-center mb-2">
                          <FontAwesomeIcon icon={faKeyboard} className="text-[#0087BFFF] mr-2" />
                          <h3 className="text-white font-medium">Requête MoMo</h3>
                        </div>
                        <div className="flex items-center justify-between">
                          <div className="bg-[#1C1C1CFF] py-2 px-4 rounded-lg text-white text-sm font-medium flex-grow mr-3">
                            *880*41*589170*{montant}#
                          </div>
                          <div className="flex space-x-2">
                            <CopyToClipboard text={textToCopy} onCopy={notifySuccess}>
                              <button
                                type="button"
                                className="py-2 px-3 bg-[#0087BFFF] text-white rounded-lg text-sm font-medium transition hover:bg-[#0087BFFF]/80"
                              >
                                Copier
                              </button>
                            </CopyToClipboard>
                            <button
                              type="button"
                              onClick={handleClickPhone}
                              className="py-2 px-3 bg-[#28C281FF] text-white rounded-lg text-sm font-medium transition hover:bg-[#28C281FF]/80"
                            >
                              Appeler
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="flex justify-between items-center mb-2">
                        <label className="text-gray-300 text-sm font-medium">ID de transaction MTN</label>
                        <span className="text-xs text-[#0087BFFF]">
                          {transactionId ? <FontAwesomeIcon icon={faCheckCircle} className="mr-1" /> : ""}
                          requis
                        </span>
                      </div>
                      <div className="relative">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <FontAwesomeIcon icon={faCheckDouble} className="text-gray-400" />
                        </div>
                        <input
                          className="w-full h-12 pl-10 pr-3 py-2 bg-[#1C1C1CFF]/80 text-white placeholder-gray-500 border border-gray-700/60 rounded-xl focus:ring-2 focus:ring-[#0087BFFF] focus:border-[#0087BFFF] transition duration-200 outline-none"
                          type="text"
                          inputMode="numeric"
                          placeholder="Entrez les 10 chiffres"
                          value={transactionId}
                          onChange={handleChangeTransactionId}
                          maxLength={10}
                          onInput={(e) => {
                            if (e.target.value.length > 10) {
                              e.target.value = e.target.value.slice(0, 10);
                            }
                          }}
                          required={operationType === "manuel"}
                        />
                      </div>
                      <p className="mt-1.5 text-xs text-gray-400">
                        Entrez l'ID de transaction MTN reçu par SMS après votre paiement.
                      </p>
                    </div>
                  )}
                </div>
              </div>

              {/* Bouton de soumission */}
              <button
                className="w-full h-14 flex justify-center items-center bg-[#0087BFFF] bg-opacity-20 text-white rounded-xl shadow-lg shadow-[#0087BFFF]/20 transition duration-200 hover:bg-opacity-30 mt-4 font-medium"
                type="submit"
                disabled={loading}
              >
                {loading ? (
                  <div className="flex items-center">
                    <div className="w-6 h-6 mr-2 animate-spin">
                      <CircularProgressbar
                        value={progress}
                        styles={buildStyles({
                          pathColor: "white",
                          trailColor: "rgba(255, 255, 255, 0.2)",
                          strokeLinecap: "round",
                        })}
                      />
                    </div>
                    <span>Traitement...</span>
                  </div>
                ) : (
                  <span className="flex items-center">
                    <FontAwesomeIcon icon={faCreditCard} className="mr-2" />
                    {operationType === "automatique" ? "Payer maintenant" : operationType === "semi-automatique" ? "Poursuivre" : "Confirmer le paiement"}
                  </span>
                )}
              </button>
              
              <p className="text-center text-gray-400 text-xs mt-4">
                En procédant au paiement, vous acceptez nos conditions générales d'utilisation.
              </p>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}