import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faSearch,
  faArrowUp,
  faArrowDown,
  faBalanceScale,
  faFutbol,
  faWallet,
  faArrowRight,
  faGift,
  faHandHoldingDollar,
  faChartLine,
  faTrophy
} from "@fortawesome/free-solid-svg-icons";
import { TbMoneybag } from "react-icons/tb";
import TransactionCard from "./TransactionCard";
import InfosContext from "../contexts/InfosAppProvider";
import { useNavigate } from "react-router-dom";
export default function Accueil() {
  const [vide, setVide] = useState(false);
  const navigate = useNavigate(); // Utilisation de useNavigate
  
  // Fonction pour formater la date
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString("fr-FR", {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const {
    infosAdmin,
    setInfosAdmin,
    userInfos,
    setUserInfos,
    transactions,
    setTransactions,
    update,
    setUpdate,
    updatek,
  } = useContext(InfosContext);

  // Vérifiez si les transactions sont vides à chaque fois que le composant est monté ou que transactions change
  useEffect(() => {
    if (!transactions || Object.keys(transactions).length === 0) {
      setVide(true);
    } else {
      console.log("Transactions n'est pas vide.");
      setVide(false);
    }
  }, [transactions]); // Ajout de transactions comme dépendance
  const truncateRef = (ref) => {
    return ref.length > 8 ? ref.substring(0, 8) + "..." : ref;
  };
  


  return (
    <>
      <div className="bg-black min-h-screen flex flex-col md:hidden">
        {/* Salutation et solde utilisateur avec design amélioré */}
        <div className="px-5 mb-6">
          <h1 className="text-white text-2xl font-bold mb-2">Bonjour, {userInfos?.nom || 'Client'} 👋</h1>
          <div className="flex">
            <div className="flex items-center bg-gradient-to-r from-bleu to-[#1a64c8] rounded-lg px-4 py-3 shadow-lg">
              <div className="w-10 h-10 bg-white/15 rounded-full flex items-center justify-center mr-3">
                <FontAwesomeIcon icon={faWallet} className="text-white text-lg" />
              </div>
              <div>
                <p className="text-white/80 text-xs uppercase">Solde disponible</p>
                <p className="text-white font-bold text-lg">{userInfos?.solde?.toLocaleString() || 0} F CFA</p>
              </div>
            </div>
          </div>
        </div>

        {/* Barre de recherche améliorée */}
        <div className="bg-[#181818] flex flex-row rounded-xl border border-[#333333] mx-5 shadow-lg">
          <span className="flex items-center pl-4 text-gray-400">
            <FontAwesomeIcon icon={faSearch} />
          </span>

          <input
            className="w-full h-[52px] pl-4 pr-[20px] font-albert-sans text-[17px] font-normal bg-transparent text-white outline-none"
            type="text"
            placeholder="Rechercher une transaction..."
          />
        </div>

        {/* Carte promo avec design amélioré et icône de réception d'argent */}
        <div className="bg-gradient-to-r from-bleu to-[#1a64c8] rounded-[16px] shadow-xl mt-6 mx-5 overflow-hidden relative">
          <div className="absolute top-0 right-0 w-24 h-24 bg-[#ffffff10] rounded-full -mr-8 -mt-8"></div>
          <div className="absolute bottom-0 left-0 w-16 h-16 bg-[#ffffff10] rounded-full -ml-6 -mb-6"></div>
          
          <div className="flex py-5 px-5">
            <div className="flex-1">
              <div className="text-white/80 font-albert text-sm mb-2 uppercase tracking-wider font-medium">
                Offre spéciale
              </div>
              <div className="text-white font-bold mb-3 flex items-center">
                <span className="bg-[#ffffff26] px-4 py-2 rounded-lg text-2xl tracking-wide border border-white/10 shadow-inner">
                  {infosAdmin?.codePromo || "WELCOME"}
                </span>
              </div>
              <div className="text-white/90 font-albert text-sm flex items-center">
                <FontAwesomeIcon icon={faCheck} className="text-green-400 mr-2" />
                Jusqu'à 50% de bonus
              </div>
              <div className="text-white/90 font-albert text-sm flex items-center mt-1">
                <FontAwesomeIcon icon={faCheck} className="text-green-400 mr-2" />
                Avantages VIP
              </div>
            </div>
            <div className="flex items-center justify-center">
              <div className="w-20 h-20 bg-white/15 rounded-full flex items-center justify-center shadow-lg">
                <div className="w-16 h-16 bg-white/20 rounded-full flex items-center justify-center relative">
                  <FontAwesomeIcon 
                    icon={faWallet} 
                    className="text-white text-2xl absolute transform translate-y-1"
                  />
                  <FontAwesomeIcon 
                    icon={faGift} 
                    className="text-green-400 text-lg absolute -top-1 -left-1 transform rotate-12"
                  />
                  <FontAwesomeIcon 
                    icon={faHandHoldingDollar} 
                    className="text-white text-xl absolute -bottom-1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Buttons d'action rapide simplifiés */}
        <div className="grid grid-cols-2 gap-4 mt-6 mx-5">
          <div 
            onClick={() => navigate("/clients/depot-manuel")}
            className="bg-[#181818] hover:bg-[#1d1d1d] transition-all duration-200 rounded-xl p-4 border border-[#333333] cursor-pointer active:scale-95 flex items-center"
          >
            <div className="w-10 h-10 bg-[#28C28115] rounded-full flex justify-center items-center mr-3">
              <FontAwesomeIcon icon={faArrowUp} className="text-[#28C281FF]" />
            </div>
            <div>
              <h3 className="text-white font-medium">Dépôt</h3>
              <p className="text-gray-400 text-xs">Ajouter des fonds</p>
            </div>
          </div>
          
          <div 
            onClick={() => navigate("/clients/retrait-manuel")}
            className="bg-[#181818] hover:bg-[#1d1d1d] transition-all duration-200 rounded-xl p-4 border border-[#333333] cursor-pointer active:scale-95 flex items-center"
          >
            <div className="w-10 h-10 bg-[#AA1D2215] rounded-full flex justify-center items-center mr-3">
              <FontAwesomeIcon icon={faArrowDown} className="text-[#AA1D22FF]" />
            </div>
            <div>
              <h3 className="text-white font-medium">Retrait</h3>
              <p className="text-gray-400 text-xs">Retirer des fonds</p>
            </div>
          </div>
        </div>

        {/* Section des statistiques améliorées */}
        <div className="grid grid-cols-2 gap-4 mt-4 mx-5">
          <div className="bg-[#181818] rounded-xl border border-[#333333] p-4 shadow-lg">
            <div className="flex items-center justify-between mb-2">
              <div className="text-gray-400 text-xs">Dépôts totaux</div>
              <div className="w-8 h-8 rounded-full bg-[#28C28115] flex items-center justify-center">
                <FontAwesomeIcon icon={faArrowUp} className="text-[#28C281FF]" />
              </div>
            </div>
            <div className="text-white text-xl font-bold">{userInfos?.statsDepots?.toLocaleString() || '0'} F</div>
            <div className="mt-1">
              <span className="text-xs text-[#28C281FF] bg-[#28C28110] px-2 py-1 rounded">
                <FontAwesomeIcon icon={faChartLine} className="mr-1" />
                Activité
              </span>
            </div>
          </div>
          
          <div className="bg-[#181818] rounded-xl border border-[#333333] p-4 shadow-lg">
            <div className="flex items-center justify-between mb-2">
              <div className="text-gray-400 text-xs">Retraits totaux</div>
              <div className="w-8 h-8 rounded-full bg-[#AA1D2215] flex items-center justify-center">
                <FontAwesomeIcon icon={faArrowDown} className="text-[#AA1D22FF]" />
              </div>
            </div>
            <div className="text-white text-xl font-bold">{userInfos?.statsRetraits?.toLocaleString() || '0'} F</div>
            <div className="mt-1">
              <span className="text-xs text-[#AA1D22FF] bg-[#AA1D2210] px-2 py-1 rounded">
                <FontAwesomeIcon icon={faWallet} className="mr-1" />
                Rapide
              </span>
            </div>
          </div>
        </div>

        {vide && (
          <div className="bg-[#181818] mt-6 mx-5 rounded-xl border border-[#333333] shadow-lg flex flex-col justify-center items-center py-8 px-4">
            <div className="w-20 h-20 bg-[#1E1E1E] rounded-full flex justify-center items-center mb-4 shadow-inner">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 48 48"
              >
                <defs>
                  <mask id="ipSFolderBlock0">
                    <g fill="none" strokeWidth="4">
                      <path
                        fill="#fff"
                        stroke="#fff"
                        strokeLinejoin="round"
                        d="M5 8a2 2 0 0 1 2-2h12l5 6h17a2 2 0 0 1 2 2v26a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2z"
                      />
                      <circle cx="25" cy="27" r="7" fill="#000" stroke="#000" />
                      <path
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m27 25l-4 4"
                      />
                    </g>
                  </mask>
                </defs>
                <path
                  fill="white"
                  d="M0 0h48v48H0z"
                  mask="url(#ipSFolderBlock0)"
                />
              </svg>
            </div>

            <div className="text-center text-bleu text-2xl font-bold mb-2">
              Aucune transaction
            </div>

            <div className="text-center text-white font-albert mx-2 text-sm opacity-80 max-w-xs">
              Vous n'avez effectué aucune transaction sur Payforbet. Commencez par faire un dépôt.
            </div>
            
            <button 
              onClick={() => navigate("/clients/depot-manuel")}
              className="mt-5 bg-bleu py-3 px-6 rounded-lg text-white font-semibold flex items-center active:scale-95 transition-transform shadow-lg"
            >
              <FontAwesomeIcon icon={faArrowUp} className="mr-2" />
              Faire mon premier dépôt
            </button>
          </div>
        )}

        {!vide && (
          <div className="flex flex-col mt-6 mb-28 px-5">
            <div className="flex items-center justify-between mb-4">
              <div className="text-white font-bold text-lg flex items-center">
                <div className="w-1 h-5 bg-bleu rounded-full mr-2"></div>
                Transactions récentes
              </div>
              <div
                onClick={() => navigate("/clients/menu-transaction")}
                className="text-bleu flex items-center font-medium active:opacity-70 transition-opacity"
              >
                Tout voir
                <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
              </div>
            </div>

            <div className="space-y-3">
              {transactions && Object.entries(transactions)
                .slice(0, 5)
                .map(([id, transaction]) => (
                  <TransactionCard
                    key={id}
                    icon={transaction.type === "depot" ? faArrowUp : faArrowDown}
                    title={`${transaction.type === "depot" ? "Dépôt" : "Retrait"} `}
                    date={formatDate(transaction.dateHeure)}
                    amount={`${transaction.montant.toLocaleString()} F CFA`}
                    id={`id:${transaction.idCompte1xbet}`}
                    idTran={id}
                    bgColor={
                      transaction.statut === "en cours"
                        ? "bg-[#EFB034FF]"
                        : transaction.type === "depot"
                        ? "bg-[#28C281FF]"
                        : "bg-[#AA1D22FF]"
                    }
                  />
                ))}
            </div>
          </div>
        )}
      </div>

      <div className="bg-black min-h-screen hidden md:flex">
        <div className="w-full pl-64">
          {/* Contenu principal */}
          <div className="flex-1 px-8 pb-12 overflow-y-auto">
            {/* Header avec salutation personnalisée et solde */}
            <div className="flex justify-between items-center mb-8 flex-wrap gap-4">
              <div>
                <h1 className="text-white text-3xl font-bold mb-1">Bienvenue, {userInfos?.nom || 'Client'} 👋</h1>
                <p className="text-gray-400">Voici votre tableau de bord personnalisé</p>
              </div>
              <div className="bg-gradient-to-r from-bleu to-[#1a64c8] rounded-lg px-5 py-3 flex items-center shadow-lg">
                <div className="mr-4">
                  <p className="text-white/80 text-xs uppercase font-medium">Solde disponible</p>
                  <p className="text-white font-bold text-xl">{userInfos?.solde?.toLocaleString() || 0} F CFA</p>
                </div>
                <div className="w-12 h-12 bg-white/15 rounded-full flex items-center justify-center">
                  <div className="w-9 h-9 bg-white/20 rounded-full flex items-center justify-center">
                    <FontAwesomeIcon icon={faWallet} className="text-white text-xl" />
                  </div>
                </div>
              </div>
            </div>
            
            {/* Barre de recherche améliorée */}
            <div className="bg-[#181818] flex items-center rounded-xl border border-[#333333] mb-8 shadow-lg hover:border-[#444444] transition-all duration-300">
              <span className="flex items-center pl-4 text-gray-400">
                <FontAwesomeIcon icon={faSearch} />
              </span>
              <input
                className="w-full h-[52px] pl-4 pr-[20px] font-albert-sans text-[17px] font-normal bg-transparent text-white outline-none"
                type="text"
                placeholder="Rechercher une transaction..."
              />
            </div>
            
            {/* Statistiques principales */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-8">
              <div className="bg-[#181818] rounded-xl p-5 border border-[#333333] hover:border-[#444444] hover:translate-y-[-2px] transition-all duration-300">
                <div className="flex justify-between items-center mb-3">
                  <h3 className="text-gray-400">Dépôts totaux</h3>
                  <div className="w-12 h-12 bg-[#28C28118] rounded-full flex items-center justify-center">
                    <FontAwesomeIcon icon={faArrowUp} className="text-[#28C281FF] text-lg" />
                  </div>
                </div>
                <p className="text-white text-2xl font-bold">{userInfos?.statsDepots?.toLocaleString() || '0'} F</p>
                <div className="flex items-center mt-2">
                  <div className="bg-[#28C28110] px-2 py-1 rounded text-[#28C281FF] text-xs font-medium">
                    <FontAwesomeIcon icon={faChartLine} className="mr-1" />
                    <span>Activité positive</span>
                  </div>
                </div>
              </div>
              
              <div className="bg-[#181818] rounded-xl p-5 border border-[#333333] hover:border-[#444444] hover:translate-y-[-2px] transition-all duration-300">
                <div className="flex justify-between items-center mb-3">
                  <h3 className="text-gray-400">Retraits totaux</h3>
                  <div className="w-12 h-12 bg-[#AA1D2215] rounded-full flex items-center justify-center">
                    <FontAwesomeIcon icon={faArrowDown} className="text-[#AA1D22FF] text-lg" />
                  </div>
                </div>
                <p className="text-white text-2xl font-bold">{userInfos?.statsRetraits?.toLocaleString() || '0'} F</p>
                <div className="flex items-center mt-2">
                  <div className="bg-[#AA1D2210] px-2 py-1 rounded text-[#AA1D22FF] text-xs font-medium">
                    <FontAwesomeIcon icon={faWallet} className="mr-1" />
                    <span>Traitements rapides</span>
                  </div>
                </div>
              </div>
              
              <div className="bg-[#181818] rounded-xl p-5 border border-[#333333] hover:border-[#444444] hover:translate-y-[-2px] transition-all duration-300">
                <div className="flex justify-between items-center mb-3">
                  <h3 className="text-gray-400">Transactions</h3>
                  <div className="w-12 h-12 bg-[#1a73e815] rounded-full flex items-center justify-center">
                    <FontAwesomeIcon icon={faBalanceScale} className="text-bleu text-lg" />
                  </div>
                </div>
                <p className="text-white text-2xl font-bold">{transactions ? Object.keys(transactions).length : '0'}</p>
                <div className="flex items-center mt-2">
                  <div className="bg-[#1a73e810] px-2 py-1 rounded text-bleu text-xs font-medium">
                    <FontAwesomeIcon icon={faTrophy} className="mr-1" />
                    <span>Historique complet</span>
                  </div>
                </div>
              </div>
            </div>

            {/* Carte promo redesignée */}
            <div className="bg-gradient-to-r from-bleu to-[#1a64c8] rounded-xl shadow-xl mb-8 overflow-hidden relative">
              <div className="absolute top-0 right-0 w-40 h-40 bg-[#ffffff10] rounded-full -mr-10 -mt-10"></div>
              <div className="absolute bottom-0 left-0 w-32 h-32 bg-[#ffffff10] rounded-full -ml-8 -mb-8"></div>
              
              <div className="flex flex-col sm:flex-row py-8 px-8">
                <div className="flex-1 mb-6 sm:mb-0">
                  <div className="text-white/80 font-albert text-sm mb-3 uppercase tracking-wider font-medium">
                    Offre spéciale pour vous
                  </div>
                  <div className="text-white text-2xl sm:text-3xl font-bold mb-4 flex items-center">
                    <span className="bg-[#ffffff26] px-5 py-3 rounded-lg tracking-wide border border-white/10 shadow-inner">
                      {infosAdmin?.codePromo || "WELCOME"}
                    </span>
                  </div>
                  <div className="text-white/90 font-albert space-y-2">
                    <div className="flex items-center">
                      <FontAwesomeIcon icon={faCheck} className="text-green-400 mr-2" />
                      <span>Jusqu'à 50% de bonus sur votre dépôt</span>
                    </div>
                   
                    <div className="flex items-center">
                      <FontAwesomeIcon icon={faCheck} className="text-green-400 mr-2" />
                      <span>Retraits prioritaires</span>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-center">
                  <div className="w-32 h-32 bg-white/15 rounded-full flex items-center justify-center shadow-lg">
                    <div className="w-24 h-24 bg-white/20 rounded-full flex items-center justify-center relative">
                      <FontAwesomeIcon 
                        icon={faWallet} 
                        className="text-white text-4xl absolute transform translate-y-1"
                      />
                      <FontAwesomeIcon 
                        icon={faGift} 
                        className="text-green-400 text-2xl absolute -top-2 -left-2 transform rotate-12"
                      />
                      <FontAwesomeIcon 
                        icon={faHandHoldingDollar} 
                        className="text-white text-3xl absolute -bottom-2"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Section transactions améliorée */}
            {vide ? (
              <div className="bg-[#181818] rounded-xl p-8 border border-[#333333] shadow-lg flex flex-col items-center justify-center text-center">
                <div className="w-24 h-24 bg-[#1E1E1E] rounded-full flex justify-center items-center mb-4 shadow-inner">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    viewBox="0 0 48 48"
                  >
                    <defs>
                      <mask id="ipSFolderBlock0">
                        <g fill="none" strokeWidth="4">
                          <path
                            fill="#fff"
                            stroke="#fff"
                            strokeLinejoin="round"
                            d="M5 8a2 2 0 0 1 2-2h12l5 6h17a2 2 0 0 1 2 2v26a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2z"
                          />
                          <circle cx="25" cy="27" r="7" fill="#000" stroke="#000" />
                          <path
                            stroke="#000"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m27 25l-4 4"
                          />
                        </g>
                      </mask>
                    </defs>
                    <path
                      fill="white"
                      d="M0 0h48v48H0z"
                      mask="url(#ipSFolderBlock0)"
                    />
                  </svg>
                </div>

                <h3 className="text-bleu text-2xl font-bold mb-2">
                  Aucune transaction effectuée
                </h3>

                <p className="text-white font-albert mb-6 max-w-md opacity-80">
                  Vous n'avez pas encore effectué de transaction sur Payforbet. Commencez par faire un dépôt pour profiter de nos services.
                </p>
                
                <button 
                  onClick={() => navigate("/clients/depot-manuel")}
                  className="bg-bleu py-3 px-6 rounded-lg text-white font-semibold flex items-center hover:bg-opacity-90 transition-colors shadow-lg"
                >
                  <FontAwesomeIcon icon={faArrowUp} className="mr-2" />
                  Faire mon premier dépôt
                </button>
              </div>
            ) : (
              <div className="bg-[#181818] rounded-xl p-6 border border-[#333333] hover:border-[#444444] transition-all duration-300">
                <div className="flex items-center justify-between mb-6 flex-wrap gap-2">
                  <div className="text-white font-bold text-lg flex items-center">
                    <div className="w-1 h-5 bg-bleu rounded-full mr-2"></div>
                    Transactions récentes
                  </div>
                  <div
                    onClick={() => navigate("/clients/menu-transaction")}
                    className="text-bleu flex items-center font-medium cursor-pointer hover:opacity-80 transition-all"
                  >
                    Voir tout l'historique
                    <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                  </div>
                </div>

                <div className="space-y-3">
                  {transactions && Object.entries(transactions)
                    .slice(0, 5)
                    .map(([id, transaction]) => (
                      <TransactionCard
                        key={id}
                        icon={transaction.type === "depot" ? faArrowUp : faArrowDown}
                        title={`${transaction.type === "depot" ? "Dépôt" : "Retrait"} `}
                        date={formatDate(transaction.dateHeure)}
                        amount={`${transaction.montant.toLocaleString()} F CFA`}
                        id={`id:${transaction.idCompte1xbet}`}
                        idTran={id}
                        bgColor={
                          transaction.statut === "en cours"
                            ? "bg-[#EFB034FF]"
                            : transaction.type === "depot"
                            ? "bg-[#28C281FF]"
                            : "bg-[#AA1D22FF]"
                        }
                      />
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
