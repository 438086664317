import React, { useState, useContext } from 'react';
import { FaChevronLeft, FaChevronRight, FaFilter, FaSearch, FaExchangeAlt, FaDownload, FaUpload, FaHourglass, FaCheck, FaInfoCircle, FaTimes } from "react-icons/fa";
import { FiFilter, FiRefreshCw, FiSearch, FiDownload } from "react-icons/fi";
import InfosContext from "../../contexts/InfosAppProvider";

const ListTransactions = () => {
    const [input, setInput] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [transactionsPerPage, setTransactionsPerPage] = useState(10);
    const [filter, setFilter] = useState(''); // État pour le filtre (depot, retrait, enCours, termine, echoue)
    const [selectedTransaction, setSelectedTransaction] = useState(null); // État pour la transaction sélectionnée
    const [isModalOpen, setIsModalOpen] = useState(false); // État pour l'affichage du modal

    // État pour les statistiques
    const [stats, setStats] = useState({
        totalTransactions: 0,
        totalDepots: 0,
        totalRetraits: 0,
        totalEncours: 0,
        totalTermine: 0,
        totalEchoue: 0
    });

    const inputHandler = (event) => {
        setInput(event.target.value);
    };

    const {
        infosAdmin,
        setInfosAdmin,
        userInfos,
        setUserInfos,
        transactions,
        setTransactions,
    } = useContext(InfosContext);

    // Fonction pour formater les montants
    const formatMontant = (montant) => {
        return new Intl.NumberFormat('fr-FR').format(montant);
    };

    // Convertit l'objet d'objets en un tableau
    const transactionsArray = Object.values(transactions);

    // Appliquer le filtre (statut et type) et la recherche
    const filteredTransactions = transactionsArray.filter(transaction => {
        // Filtrer par statut ou type
        if (filter === 'depot' && transaction.type !== 'depot') return false;
        if (filter === 'retrait' && transaction.type !== 'retrait') return false;
        if (filter === 'enCours' && transaction.statut !== 'en cours') return false;
        if (filter === 'termine' && (transaction.statut !== 'terminé' && transaction.statut !== 'réussi')) return false;
        if (filter === 'echoue' && transaction.statut !== 'échoué') return false;

        // Filtrer par recherche
        const searchTerm = input.toLowerCase();
        return (
            transaction.moyenPaiement?.toLowerCase().includes(searchTerm) ||
            transaction.montant?.toString().includes(searchTerm) ||
            transaction.idCompte1xbet?.toString().includes(searchTerm) ||
            transaction.idTransactionMobile?.toString().includes(searchTerm) ||
            (transaction.idTransaction ? transaction.idTransaction.toString().includes(searchTerm) : false) ||
            new Date(transaction.dateHeure).toLocaleString().toLowerCase().includes(searchTerm)
        );
    });

    // Pagination
    const indexOfLastTransaction = currentPage * transactionsPerPage;
    const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
    const currentTransactions = filteredTransactions.slice(indexOfFirstTransaction, indexOfLastTransaction);
    const totalTransactions = filteredTransactions.length;

    // Calcul des statistiques
    React.useEffect(() => {
        const updatedStats = {
            totalTransactions: transactionsArray.length,
            totalDepots: transactionsArray.filter(t => t.type === 'depot').length,
            totalRetraits: transactionsArray.filter(t => t.type === 'retrait').length,
            totalEncours: transactionsArray.filter(t => t.statut === 'en cours').length,
            totalTermine: transactionsArray.filter(t => t.statut === 'terminé' || t.statut === 'réussi').length,
            totalEchoue: transactionsArray.filter(t => t.statut === 'échoué').length
        };
        setStats(updatedStats);
    }, [transactions]);

    // Changer de page
    const paginateNext = () => {
        setCurrentPage(prevPage => prevPage + 1);
    };

    const paginatePrevious = () => {
        setCurrentPage(prevPage => (prevPage > 1 ? prevPage - 1 : 1));
    };

    const handleTransactionsPerPageChange = (event) => {
        setTransactionsPerPage(Number(event.target.value));
        setCurrentPage(1);
    };

    // Réinitialiser les filtres
    const resetFilters = () => {
        setFilter('');
        setInput('');
        setCurrentPage(1);
    };
    
    // Fonction pour ouvrir le modal avec les détails de la transaction
    const openTransactionDetails = (transaction) => {
        setSelectedTransaction(transaction);
        setIsModalOpen(true);
    };
    
    // Fonction pour fermer le modal
    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedTransaction(null);
    };

    return (
        <div className="w-full">
            {/* En-tête de la page */}
            <div className="mb-8">
                <h1 className="text-2xl font-bold text-white mb-2">Transactions</h1>
                <p className="text-gray-400">Gérez et consultez toutes les transactions de la plateforme</p>
            </div>

            {/* Cartes de statistiques */}
            <div className="grid grid-cols-1 md:grid-cols-6 gap-4 mb-8">
                <div className="bg-gris rounded-xl p-4 shadow-lg flex items-center justify-between">
                    <div>
                        <p className="text-gray-400 text-xs">Total</p>
                        <h3 className="text-white text-xl font-bold">{stats.totalTransactions}</h3>
                    </div>
                    <div className="bg-bleu bg-opacity-10 p-2 rounded-lg">
                        <FaExchangeAlt className="text-bleu" />
                    </div>
                </div>
                
                <div className="bg-gris rounded-xl p-4 shadow-lg flex items-center justify-between">
                    <div>
                        <p className="text-gray-400 text-xs">Dépôts</p>
                        <h3 className="text-white text-xl font-bold">{stats.totalDepots}</h3>
                    </div>
                    <div className="bg-vert bg-opacity-10 p-2 rounded-lg">
                        <FaDownload className="text-vert" />
                    </div>
                </div>
                
                <div className="bg-gris rounded-xl p-4 shadow-lg flex items-center justify-between">
                    <div>
                        <p className="text-gray-400 text-xs">Retraits</p>
                        <h3 className="text-white text-xl font-bold">{stats.totalRetraits}</h3>
                    </div>
                    <div className="bg-softRed bg-opacity-10 p-2 rounded-lg">
                        <FaUpload className="text-softRed" />
                    </div>
                </div>
                
                <div className="bg-gris rounded-xl p-4 shadow-lg flex items-center justify-between">
                    <div>
                        <p className="text-gray-400 text-xs">En cours</p>
                        <h3 className="text-white text-xl font-bold">{stats.totalEncours}</h3>
                    </div>
                    <div className="bg-softYellow bg-opacity-10 p-2 rounded-lg">
                        <FaHourglass className="text-softYellow" />
                    </div>
                </div>
                
                <div className="bg-gris rounded-xl p-4 shadow-lg flex items-center justify-between">
                    <div>
                        <p className="text-gray-400 text-xs">Terminées</p>
                        <h3 className="text-white text-xl font-bold">{stats.totalTermine}</h3>
                    </div>
                    <div className="bg-softPurple bg-opacity-10 p-2 rounded-lg">
                        <FaCheck className="text-softPurple" />
                    </div>
                </div>
                
                <div className="bg-gris rounded-xl p-4 shadow-lg flex items-center justify-between">
                    <div>
                        <p className="text-gray-400 text-xs">Échouées</p>
                        <h3 className="text-white text-xl font-bold">{stats.totalEchoue}</h3>
                    </div>
                    <div className="bg-softRed bg-opacity-10 p-2 rounded-lg">
                        <FaCheck className="text-softRed" />
                    </div>
                </div>
            </div>

            {/* Barre de recherche et filtres */}
            <div className="flex flex-col md:flex-row gap-4 justify-between mb-6">
                <div className="flex-1">
                    <div className="relative">
                        <input
                            type="text"
                            placeholder="Rechercher une transaction..."
                            value={input}
                            onChange={inputHandler}
                            className="w-full py-2 pl-10 pr-4 bg-gris border border-gray-700 rounded-lg text-white text-sm focus:outline-none focus:border-bleu"
                        />
                        <div className="absolute left-3 top-2.5 text-gray-400">
                            <FiSearch />
                        </div>
                    </div>
                </div>
                
                <div className="flex gap-2">
                    <button 
                        className={`px-4 py-2 rounded-lg text-sm ${filter === '' ? 'bg-bleu text-white' : 'bg-gris text-gray-300 hover:bg-noir'}`}
                        onClick={() => { setFilter(''); setCurrentPage(1); }}
                    >
                        Tous
                    </button>
                    <button 
                        className={`px-4 py-2 rounded-lg text-sm ${filter === 'depot' ? 'bg-vert text-white' : 'bg-gris text-gray-300 hover:bg-noir'}`}
                        onClick={() => { setFilter('depot'); setCurrentPage(1); }}
                    >
                        Dépôts
                    </button>
                    <button 
                        className={`px-4 py-2 rounded-lg text-sm ${filter === 'retrait' ? 'bg-softRed text-white' : 'bg-gris text-gray-300 hover:bg-noir'}`}
                        onClick={() => { setFilter('retrait'); setCurrentPage(1); }}
                    >
                        Retraits
                    </button>
                    <button 
                        className={`px-4 py-2 rounded-lg text-sm ${filter === 'enCours' ? 'bg-softYellow text-white' : 'bg-gris text-gray-300 hover:bg-noir'}`}
                        onClick={() => { setFilter('enCours'); setCurrentPage(1); }}
                    >
                        En cours
                    </button>
                    <button 
                        className={`px-4 py-2 rounded-lg text-sm ${filter === 'termine' ? 'bg-softPurple text-white' : 'bg-gris text-gray-300 hover:bg-noir'}`}
                        onClick={() => { setFilter('termine'); setCurrentPage(1); }}
                    >
                        Terminé/Réussi
                    </button>
                    <button 
                        className={`px-4 py-2 rounded-lg text-sm ${filter === 'echoue' ? 'bg-softRed text-white' : 'bg-gris text-gray-300 hover:bg-noir'}`}
                        onClick={() => { setFilter('echoue'); setCurrentPage(1); }}
                    >
                        Échoué
                    </button>
                    
                    <button
                        onClick={resetFilters}
                        className="p-2 bg-gris rounded-lg text-gray-400 hover:text-bleu transition-colors"
                        title="Réinitialiser les filtres"
                    >
                        <FiRefreshCw />
                    </button>
                </div>
            </div>

            {/* Pagination et sélection du nombre par page */}
            <div className="flex items-center justify-between mb-4 bg-gris p-3 rounded-lg">
                <div className="flex items-center gap-2">
                    <span className="text-gray-400 text-sm">Afficher</span>
                    <select
                        value={transactionsPerPage}
                        onChange={handleTransactionsPerPageChange}
                        className="bg-noir border border-gray-700 rounded text-white text-sm py-1 px-2 focus:outline-none focus:border-bleu"
                    >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                    </select>
                    <span className="text-gray-400 text-sm">par page</span>
                </div>
                
                <div className="flex items-center">
                    <span className="text-gray-400 text-sm mr-4">
                        {indexOfFirstTransaction + 1} - {Math.min(indexOfLastTransaction, totalTransactions)} sur {totalTransactions}
                    </span>
                    <div className="flex gap-2">
                        <button
                            onClick={paginatePrevious}
                            disabled={currentPage === 1}
                            className={`p-2 rounded-lg ${currentPage === 1 ? 'text-gray-600 cursor-not-allowed' : 'text-gray-400 hover:bg-noir hover:text-white'}`}
                        >
                            <FaChevronLeft size={14} />
                        </button>
                        <button
                            onClick={paginateNext}
                            disabled={indexOfLastTransaction >= totalTransactions}
                            className={`p-2 rounded-lg ${indexOfLastTransaction >= totalTransactions ? 'text-gray-600 cursor-not-allowed' : 'text-gray-400 hover:bg-noir hover:text-white'}`}
                        >
                            <FaChevronRight size={14} />
                        </button>
                    </div>
                </div>
            </div>
                
            {/* Tableau des transactions */}
            <div className="bg-gris rounded-xl shadow-lg overflow-hidden">
                {totalTransactions === 0 ? (
                    <div className="p-8 text-center">
                        <FiFilter className="text-gray-500 text-4xl mx-auto mb-4" />
                        <p className="text-gray-400 text-lg">Aucune transaction trouvée avec les critères de recherche actuels.</p>
                        <button 
                            onClick={resetFilters}
                            className="mt-4 px-4 py-2 bg-bleu rounded-lg text-white text-sm hover:bg-blue-600 transition-colors inline-flex items-center gap-2"
                        >
                            <FiRefreshCw size={14} /> Réinitialiser les filtres
                        </button>
                    </div>
                ) : (
                    <div className="overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-700">
                            <thead className="bg-noir">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">#</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Compte ID</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Transaction ID</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Moyen de Paiement</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Montant</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Date</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Statut</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Type</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-700">
                                {currentTransactions.map((transaction, index) => (
                                    <tr 
                                        key={index} 
                                        className="hover:bg-noir transition-colors cursor-pointer" 
                                        onClick={() => openTransactionDetails(transaction)}
                                    >
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                                            {indexOfFirstTransaction + index + 1}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                                            {transaction.idCompte1xbet || '-'}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                                            {transaction.idTransaction || transaction.idTransactionMobile || '-'}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                                            {transaction.moyenPaiement || '-'}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                                            <span className={transaction.type === 'depot' ? 'text-vert' : 'text-softRed'}>
                                                {formatMontant(transaction.montant)} F
                                            </span>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                                            {new Date(transaction.dateHeure).toLocaleString()}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                                            <span className={`px-3 py-1.5 rounded-full text-xs font-medium ${
                                                transaction.statut === 'en cours' 
                                                    ? 'bg-softYellow bg-opacity-20 text-white border border-softYellow' 
                                                    : transaction.statut === 'terminé' || transaction.statut === 'réussi'
                                                        ? 'bg-vert bg-opacity-20 text-white border border-vert'
                                                        : transaction.statut === 'échoué'
                                                            ? 'bg-softRed bg-opacity-20 text-white border border-softRed'
                                                            : 'bg-softYellow bg-opacity-20 text-white border border-softYellow'
                                            }`}>
                                                {transaction.statut.charAt(0).toUpperCase() + transaction.statut.slice(1)}
                                            </span>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                                            <span className={`px-3 py-1.5 rounded-full text-xs font-medium ${
                                                transaction.type === 'depot' 
                                                    ? 'bg-vert bg-opacity-20 text-white border border-vert' 
                                                    : 'bg-softRed bg-opacity-20 text-white border border-softRed'
                                            }`}>
                                                {transaction.type.charAt(0).toUpperCase() + transaction.type.slice(1)}
                                            </span>
                                            <button className="ml-2 text-bleu hover:text-blue-500 transition-colors">
                                                <FaInfoCircle />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
            
            {/* Pagination mobile (seulement visible sur mobile) */}
            <div className="md:hidden flex justify-center mt-4">
                <div className="flex items-center gap-2 bg-gris p-2 rounded-lg">
                    <button
                        onClick={paginatePrevious}
                        disabled={currentPage === 1}
                        className={`p-2 rounded-lg ${currentPage === 1 ? 'text-gray-600 cursor-not-allowed' : 'text-gray-400 hover:bg-noir hover:text-white'}`}
                    >
                        <FaChevronLeft size={14} />
                    </button>
                    <span className="text-white">
                        Page {currentPage}
                    </span>
                    <button
                        onClick={paginateNext}
                        disabled={indexOfLastTransaction >= totalTransactions}
                        className={`p-2 rounded-lg ${indexOfLastTransaction >= totalTransactions ? 'text-gray-600 cursor-not-allowed' : 'text-gray-400 hover:bg-noir hover:text-white'}`}
                    >
                        <FaChevronRight size={14} />
                    </button>
                </div>
            </div>

            {/* Modal des détails de transaction - calqué sur la structure existante */}
            {isModalOpen && selectedTransaction && (
                <div className="fixed inset-0 z-20 flex items-center justify-center p-4 bg-black bg-opacity-50" onClick={closeModal}>
                    <div className="bg-gris rounded-xl shadow-xl w-full max-w-3xl overflow-hidden" onClick={e => e.stopPropagation()}>
                        {/* En-tête du modal */}
                        <div className="flex justify-between items-center px-6 py-4 border-b border-gray-700">
                            <h2 className="text-xl font-bold text-white flex items-center">
                                <FaInfoCircle className="mr-2 text-bleu" /> 
                                Détails de la transaction
                            </h2>
                            <button 
                                onClick={closeModal}
                                className="text-gray-400 hover:text-white transition-colors"
                            >
                                <FaTimes size={20} />
                            </button>
                        </div>
                        
                        {/* Corps du modal */}
                        <div className="p-6">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                                {/* Infos principales */}
                                <div className="col-span-1 md:col-span-2 bg-noir p-4 rounded-lg flex justify-between items-center">
                                    <div>
                                        <p className="text-gray-400 text-xs">ID Transaction</p>
                                        <p className="text-white font-medium">{selectedTransaction.idTransaction}</p>
                                    </div>
                                    <div>
                                        <span className={`px-3 py-1.5 rounded-full text-xs font-medium ${
                                            selectedTransaction.type === 'depot' 
                                                ? 'bg-vert bg-opacity-20 text-white border border-vert' 
                                                : 'bg-softRed bg-opacity-20 text-white border border-softRed'
                                        }`}>
                                            {selectedTransaction.type.charAt(0).toUpperCase() + selectedTransaction.type.slice(1)}
                                        </span>
                                        <span className={`ml-2 px-3 py-1.5 rounded-full text-xs font-medium ${
                                            selectedTransaction.statut === 'en cours' 
                                                ? 'bg-softYellow bg-opacity-20 text-white border border-softYellow' 
                                                : selectedTransaction.statut === 'terminé' || selectedTransaction.statut === 'réussi'
                                                    ? 'bg-vert bg-opacity-20 text-white border border-vert'
                                                    : selectedTransaction.statut === 'échoué'
                                                        ? 'bg-softRed bg-opacity-20 text-white border border-softRed'
                                                        : 'bg-softYellow bg-opacity-20 text-white border border-softYellow'
                                        }`}>
                                            {selectedTransaction.statut.charAt(0).toUpperCase() + selectedTransaction.statut.slice(1)}
                                        </span>
                                    </div>
                                </div>
                                
                                {/* Colonne gauche */}
                                <div>
                                    <div className="mb-4">
                                        <p className="text-gray-400 text-xs">Montant</p>
                                        <p className={`text-xl font-bold ${selectedTransaction.type === 'depot' ? 'text-vert' : 'text-softRed'}`}>
                                            {formatMontant(selectedTransaction.montant)} F
                                        </p>
                                    </div>
                                    
                                    <div className="mb-4">
                                        <p className="text-gray-400 text-xs">ID Compte 1xbet</p>
                                        <p className="text-white">{selectedTransaction.idCompte1xbet || 'Non renseigné'}</p>
                                    </div>
                                    
                                    <div className="mb-4">
                                        <p className="text-gray-400 text-xs">ID Utilisateur</p>
                                        <p className="text-white">{selectedTransaction.idUser || 'Non renseigné'}</p>
                                    </div>
                                    
                                    <div className="mb-4">
                                        <p className="text-gray-400 text-xs">Moyen de Paiement</p>
                                        <p className="text-white">{selectedTransaction.moyenPaiement || 'Non renseigné'}</p>
                                    </div>
                                    
                                    <div className="mb-4">
                                        <p className="text-gray-400 text-xs">Mode</p>
                                        <p className="text-white">{selectedTransaction.mode || 'Non renseigné'}</p>
                                    </div>
                                </div>
                                
                                {/* Colonne droite */}
                                <div>
                                    <div className="mb-4">
                                        <p className="text-gray-400 text-xs">Date et heure</p>
                                        <p className="text-white">{new Date(selectedTransaction.dateHeure).toLocaleString()}</p>
                                    </div>
                                    
                                    <div className="mb-4">
                                        <p className="text-gray-400 text-xs">ID Caisse</p>
                                        <p className="text-white">{selectedTransaction.idCaisse || 'Non renseigné'}</p>
                                    </div>
                                    
                                    {selectedTransaction.type === 'retrait' && (
                                        <>
                                            <div className="mb-4">
                                                <p className="text-gray-400 text-xs">Montant Retrait</p>
                                                <p className="text-white">{selectedTransaction.montantRetrait ? formatMontant(selectedTransaction.montantRetrait) + ' F' : 'Non renseigné'}</p>
                                            </div>
                                            
                                            <div className="mb-4">
                                                <p className="text-gray-400 text-xs">Nom MTN MoMo</p>
                                                <p className="text-white">{selectedTransaction.nomMtnMomo || 'Non renseigné'}</p>
                                            </div>
                                            
                                            <div className="mb-4">
                                                <p className="text-gray-400 text-xs">Numéro Retrait MoMo</p>
                                                <p className="text-white">{selectedTransaction.numeroRetraitMomo || 'Non renseigné'}</p>
                                            </div>
                                            
                                            <div className="mb-4">
                                                <p className="text-gray-400 text-xs">Code Retrait</p>
                                                <p className="text-white">{selectedTransaction.codeRetrait || 'Non renseigné'}</p>
                                            </div>
                                        </>
                                    )}
                                </div>

                                {/* Bouton de fermeture */}
                                <div className="col-span-1 md:col-span-2 flex justify-end mt-4">
                                    <button 
                                        onClick={closeModal} 
                                        className="bg-bleu text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition"
                                    >
                                        Fermer
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ListTransactions;
