// alert.js
import Swal from "sweetalert2";

export const succes = () => {
  return Swal.fire({
    title: "Succes",
    text: "Votre demande de retrait est en cours",
    icon: "succes",
    showCancelButton: false,
    confirmButtonText: "ok",

    customClass: {
      popup: "max-w-sm p-6 rounded-lg  shadow-lg", // Taille, padding et coins arrondis
      title: "text-xl font-bold text-gray-800", // Style du titre
      content: "text-gray-700", // Style du texte
      confirmButton:
        "bg-blue-600 text-white hover:bg-blue-700 py-2 px-4 rounded-lg transition", // Style du bouton "Continuer"
      cancelButton:
        "bg-gray-600 text-white hover:bg-gray-700 py-2 px-4 rounded-lg transition", // Style du bouton "Se connecter"
    },
    heightAuto: false, // Pour empêcher la hauteur automatique
    backdrop: `rgba(0, 0, 0, 0.5)`, // Arrière-plan semi-transparent
    position: "center", // Positionne la popup au centre
  });
};
