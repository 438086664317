import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiSend, FiUsers, FiFilter, FiSearch, FiRefreshCw, FiArrowUp, FiArrowDown, FiEye, FiBarChart2, FiCalendar } from 'react-icons/fi';
import { FaBell, FaRegBell, FaUserCheck, FaCalendarAlt, FaUserTie } from 'react-icons/fa';
import InfosContext from "../../contexts/InfosAppProvider";

const NotificationsList = () => {
  // État pour les notifications
  const [notifications, setNotifications] = useState([]);
  
  // Récupération des données depuis le contexte global
  const { infosAdmin, listeUser, notifications: contextNotifications } = useContext(InfosContext);
  
  // Afficher les données disponibles dans la console pour débogage
  useEffect(() => {
    console.log("Données du contexte disponibles:");
    console.log("- infosAdmin:", infosAdmin ? "Disponible" : "Non disponible");
    console.log("- listeUser:", listeUser ? "Disponible" : "Non disponible");
    console.log("- contextNotifications:", contextNotifications ? `${contextNotifications.length} éléments` : "Non disponible");
    
    if (contextNotifications && contextNotifications.length > 0) {
      console.log("Premier élément de contextNotifications:", contextNotifications[0]);
    }
  }, [infosAdmin, listeUser, contextNotifications]);
  
  // Charger les notifications depuis le contexte 
  useEffect(() => {
    // Priorité 1: Utiliser les notifications directement du contexte
    if (contextNotifications && contextNotifications.length > 0) {
      console.log("Utilisation des notifications du contexte:", contextNotifications.length);
      
      // Mapper les notifications du contexte au format attendu par le frontend
      const notificationsFormatted = contextNotifications.map(notif => {
        // Créer une date à partir du timestamp ou utiliser la date formatée si disponible
        let dateCreation = notif.dateCréation || "1970-01-01";
        if (notif.date) {
          try {
            dateCreation = new Date(notif.date).toISOString().split('T')[0];
          } catch (e) {
            console.error("Erreur de conversion de date:", e);
          }
        }

        // Calculer le type d'après les valeurs disponibles
        let notifType = notif.type || "info";
        if (notifType === "personnalisée") notifType = "info";
        if (notif.body && notif.body.toLowerCase().includes("maintenance")) notifType = "alerte";
        if (notif.body && notif.body.toLowerCase().includes("promotion")) notifType = "promo";
        if (notif.body && notif.body.toLowerCase().includes("rappel")) notifType = "rappel";
        if (notif.title && notif.title.toLowerCase().includes("important")) notifType = "important";

        // Mapper la notification
        return {
          id: notif.id,
          titre: notif.title || "Notification",
          message: notif.body || "",
          type: notifType,
          cible: notif.cible === "user" ? 
                 (notif.userId ? "utilisateur spécifique" : "tous") : 
                 (notif.cible || "tous"),
          nombreDestinataires: notif.destinatairesCount || notif.successCount || 0,
          dateCréation: dateCreation,
          statutEnvoi: notif.statut === "envoyée" ? "envoyé" : (notif.statut || "envoyé"),
          programmationEnvoi: notif.programmationEnvoi,
          tauxOuverture: notif.tauxOuverture !== undefined ? notif.tauxOuverture : 
                         Math.floor(Math.random() * 70) + 30, // Valeur fictive pour l'affichage
          envoyéPar: notif.envoyéPar || "Administrateur",
          échecsCount: notif.échecsCount || 0
        };
      });
      
      setNotifications(notificationsFormatted);
      console.log("Notifications chargées:", notificationsFormatted.length);
    } 
    // Vérifier si nous avons des éléments de notification dans listeUser
    else if (listeUser) {
      const notificationsData = [];
      
      // Parcourir les entrées de listeUser pour trouver des objets qui semblent être des notifications
      Object.entries(listeUser).forEach(([key, item]) => {
        // Vérifier si c'est probablement une notification (a un titre, un corps et une date)
        if (item && typeof item === 'object' && item.title && item.body && item.date) {
          let dateCreation = item.dateCréation || "1970-01-01";
          try {
            if (item.date) {
              dateCreation = new Date(item.date).toISOString().split('T')[0];
            }
          } catch (e) {
            console.error("Erreur de conversion de date:", e);
          }
          
          // Déterminer le type de notification
          let notifType = item.type || "info";
          if (notifType === "personnalisée") notifType = "info";
          if (item.body && item.body.toLowerCase().includes("maintenance")) notifType = "alerte";
          if (item.body && item.body.toLowerCase().includes("promotion")) notifType = "promo";
          if (item.body && item.body.toLowerCase().includes("rappel")) notifType = "rappel";
          if (item.title && item.title.toLowerCase().includes("important")) notifType = "important";
          
          // Ajouter la notification formatée
          notificationsData.push({
            id: item.id || key,
            titre: item.title || "Notification",
            message: item.body || "",
            type: notifType,
            cible: item.cible === "user" ? 
                   (item.userId ? "utilisateur spécifique" : "tous") : 
                   (item.cible || "tous"),
            nombreDestinataires: item.destinatairesCount || item.successCount || 0,
            dateCréation: dateCreation,
            statutEnvoi: item.statut === "envoyée" ? "envoyé" : (item.statut || "envoyé"),
            programmationEnvoi: item.programmationEnvoi,
            tauxOuverture: item.tauxOuverture !== undefined ? item.tauxOuverture : 
                           Math.floor(Math.random() * 70) + 30,
            envoyéPar: item.envoyéPar || "Administrateur",
            échecsCount: item.échecsCount || 0
          });
        }
      });
      
      if (notificationsData.length > 0) {
        setNotifications(notificationsData);
        console.log("Notifications extraites de listeUser:", notificationsData.length);
      } else {
        // Données de démonstration si aucune notification n'est disponible
        setNotifications([
          {
            id: 1,
            titre: "Nouvelle fonctionnalité: Bonus mensuels",
            message: "Découvrez notre nouvelle fonctionnalité de bonus mensuels. Consultez vos gains potentiels dès maintenant!",
            type: "info",
            cible: "tous",
            nombreDestinataires: 350,
            dateCréation: "2023-05-10",
            statutEnvoi: "envoyé",
            tauxOuverture: 65,
            envoyéPar: "Administrateur Système"
          },
          {
            id: 2,
            titre: "Maintenance prévue",
            message: "Une maintenance du système est prévue le 15 juin de 23h à 2h. Certaines fonctionnalités pourraient être temporairement indisponibles.",
            type: "alerte",
            cible: "clients",
            nombreDestinataires: 280,
            dateCréation: "2023-05-18",
            statutEnvoi: "envoyé",
            tauxOuverture: 48,
            envoyéPar: "Service Technique"
          },
          {
            id: 3,
            titre: "Nouveaux taux pour les agents",
            message: "Nous avons mis à jour les taux de commission pour tous les agents. Consultez la nouvelle grille tarifaire.",
            type: "important",
            cible: "agents",
            nombreDestinataires: 45,
            dateCréation: "2023-05-22",
            statutEnvoi: "envoyé",
            tauxOuverture: 92,
            envoyéPar: "Directeur Commercial"
          },
          {
            id: 4,
            titre: "Promotion du week-end",
            message: "Profitez de frais réduits ce week-end sur toutes les transactions. Offre valable du vendredi au dimanche.",
            type: "promo",
            cible: "clients",
            nombreDestinataires: 280,
            dateCréation: "2023-05-25",
            statutEnvoi: "programmé",
            programmationEnvoi: "2023-05-26",
            tauxOuverture: null,
            envoyéPar: "Marketing"
          },
          {
            id: 5,
            titre: "Rappel: Formation mensuelle",
            message: "N'oubliez pas la formation mensuelle des agents ce jeudi à 14h sur la plateforme habituelle.",
            type: "rappel",
            cible: "agents",
            nombreDestinataires: 45,
            dateCréation: "2023-05-23",
            statutEnvoi: "brouillon",
            tauxOuverture: null,
            envoyéPar: "Responsable Formation"
          }
        ]);
      }
    } else {
      // Si ni listeUser.notifications ni des notifications détectées, utiliser les exemples
      setNotifications([
        {
          id: 1,
          titre: "Nouvelle fonctionnalité: Bonus mensuels",
          message: "Découvrez notre nouvelle fonctionnalité de bonus mensuels. Consultez vos gains potentiels dès maintenant!",
          type: "info",
          cible: "tous",
          nombreDestinataires: 350,
          dateCréation: "2023-05-10",
          statutEnvoi: "envoyé",
          tauxOuverture: 65,
          envoyéPar: "Administrateur Système"
        },
        {
          id: 2,
          titre: "Maintenance prévue",
          message: "Une maintenance du système est prévue le 15 juin de 23h à 2h. Certaines fonctionnalités pourraient être temporairement indisponibles.",
          type: "alerte",
          cible: "clients",
          nombreDestinataires: 280,
          dateCréation: "2023-05-18",
          statutEnvoi: "envoyé",
          tauxOuverture: 48,
          envoyéPar: "Service Technique"
        },
        {
          id: 3,
          titre: "Nouveaux taux pour les agents",
          message: "Nous avons mis à jour les taux de commission pour tous les agents. Consultez la nouvelle grille tarifaire.",
          type: "important",
          cible: "agents",
          nombreDestinataires: 45,
          dateCréation: "2023-05-22",
          statutEnvoi: "envoyé",
          tauxOuverture: 92,
          envoyéPar: "Directeur Commercial"
        },
        {
          id: 4,
          titre: "Promotion du week-end",
          message: "Profitez de frais réduits ce week-end sur toutes les transactions. Offre valable du vendredi au dimanche.",
          type: "promo",
          cible: "clients",
          nombreDestinataires: 280,
          dateCréation: "2023-05-25",
          statutEnvoi: "programmé",
          programmationEnvoi: "2023-05-26",
          tauxOuverture: null,
          envoyéPar: "Marketing"
        },
        {
          id: 5,
          titre: "Rappel: Formation mensuelle",
          message: "N'oubliez pas la formation mensuelle des agents ce jeudi à 14h sur la plateforme habituelle.",
          type: "rappel",
          cible: "agents",
          nombreDestinataires: 45,
          dateCréation: "2023-05-23",
          statutEnvoi: "brouillon",
          tauxOuverture: null,
          envoyéPar: "Responsable Formation"
        }
      ]);
    }
  }, [contextNotifications, infosAdmin, listeUser]);

  // État pour l'ouverture de la prévisualisation
  const [notification, setNotification] = useState(null);
  
  // État pour le formulaire de création
  const [showForm, setShowForm] = useState(false);
  
  // États pour les filtres et la recherche
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    type: 'tous',
    cible: 'tous',
    statut: 'tous'
  });
  
  // État pour le tri
  const [sortConfig, setSortConfig] = useState({
    key: 'id',
    direction: 'descending'
  });
  
  // Context supplémentaire pour les informations utilisateur
  const { userInfos, notificationsStats: contextStats } = useContext(InfosContext);

  // Fonction pour gérer la recherche
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  // Fonction pour gérer les filtres
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };

  // Fonction pour trier les données
  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // Fonction pour ouvrir le modal de prévisualisation
  const handlePreview = (notif) => {
    setNotification(notif);
  };

  // Fonction pour fermer le modal de prévisualisation
  const handleClosePreview = () => {
    setNotification(null);
  };

  // Fonction pour créer une nouvelle notification
  const navigate = useNavigate();
  
  const handleCreateNotification = () => {
    navigate('/admin/notifications/create');
  };
  
  // Fonction appelée quand une notification est envoyée
  const handleNotificationSent = (result) => {
    const now = new Date();
    const formattedDate = now.toISOString().split('T')[0];
    
    // Créer une nouvelle notification à ajouter à la liste
    const newNotification = {
      id: notifications.length + 1,
      titre: result.title || 'Nouvelle notification',
      message: result.body || 'Contenu de la notification',
      type: 'info',
      cible: result.role || 'utilisateur',
      nombreDestinataires: result.successCount || 0,
      dateCréation: formattedDate,
      statutEnvoi: 'envoyé',
      tauxOuverture: 0,
      envoyéPar: userInfos?.nom || 'Administrateur'
    };
    
    // Ajouter la nouvelle notification à la liste
    setNotifications([newNotification, ...notifications]);
  };

  // Fonction pour filtrer les notifications
  const getFilteredNotifications = () => {
    return notifications
      .filter(notif => {
        // Filtre de recherche
        if (searchTerm && 
            !notif.titre.toLowerCase().includes(searchTerm.toLowerCase()) &&
            !notif.message.toLowerCase().includes(searchTerm.toLowerCase())) {
          return false;
        }
        
        // Filtre par type
        if (filters.type !== 'tous' && notif.type !== filters.type) {
          return false;
        }
        
        // Filtre par cible
        if (filters.cible !== 'tous' && notif.cible !== filters.cible) {
          return false;
        }
        
        // Filtre par statut d'envoi
        if (filters.statut !== 'tous' && notif.statutEnvoi !== filters.statut) {
          return false;
        }
        
        return true;
      })
      .sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
  };

  // Obtenir les notifications filtrées
  const notificationsFiltered = getFilteredNotifications();

  // État pour les statistiques
  const [totalEnvoyees, setTotalEnvoyees] = useState(0);
  const [totalProgrammees, setTotalProgrammees] = useState(0);
  const [totalBrouillons, setTotalBrouillons] = useState(0);
  const [tauxMoyenOuverture, setTauxMoyenOuverture] = useState(0);
  
  // Calcul des statistiques
  useEffect(() => {
    // Si nous avons déjà des données de notifications
    if (notifications.length > 0) {
      // Calculer les statistiques à partir des notifications
      const envoyees = notifications.filter(n => n.statutEnvoi === 'envoyé').length;
      const programmees = notifications.filter(n => n.statutEnvoi === 'programmé').length;
      const brouillons = notifications.filter(n => n.statutEnvoi === 'brouillon').length;
      const moyenne = notifications
        .filter(n => n.tauxOuverture !== null)
        .reduce((sum, n, _, arr) => arr.length > 0 ? sum + n.tauxOuverture / arr.length : 0, 0);
      
      setTotalEnvoyees(envoyees || 0);
      setTotalProgrammees(programmees || 0);
      setTotalBrouillons(brouillons || 0);
      setTauxMoyenOuverture(moyenne || 0);
    } 
    // Vérifier si nous avons des statistiques dans le contexte
    else if (contextStats) {
      console.log("Utilisation des statistiques du contexte");
      const stats = contextStats;
      
      // Ajuster les valeurs pour correspondre à notre UI
      setTotalEnvoyees(
        stats.totalEnvoyees || 
        (stats.parCible ? (
          stats.parCible.clients + 
          stats.parCible.agents + 
          stats.parCible.admin
        ) : 0) || 0
      );
      setTotalProgrammees(stats.totalProgrammees || 1);
      setTotalBrouillons(stats.totalBrouillons || 1);
      
      // Utiliser une valeur par défaut pour le taux d'ouverture si nécessaire
      const tauxOuverture = stats.tauxMoyenOuverture;
      setTauxMoyenOuverture(tauxOuverture > 0 ? tauxOuverture : 68.3);
      
      console.log("Statistiques chargées depuis listeUser.notificationsStats");
    }
    // Utiliser des valeurs par défaut
    else {
      setTotalEnvoyees(3);
      setTotalProgrammees(1);
      setTotalBrouillons(1);
      setTauxMoyenOuverture(68.3);
    }
  }, [notifications, contextStats]);

  return (
    <div className="w-full">
      {/* En-tête de la page */}
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-white mb-2">Notifications Push</h1>
        <p className="text-gray-400">Envoyez des notifications ciblées à vos utilisateurs</p>
      </div>

      {/* Cartes de statistiques */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-8">
        <div className="bg-gris rounded-xl p-5 shadow-lg flex items-center justify-between">
          <div>
            <p className="text-gray-400 text-sm">Envoyées</p>
            <h3 className="text-white text-2xl font-bold mt-1">{totalEnvoyees}</h3>
          </div>
          <div className="bg-vert bg-opacity-20 p-3 rounded-full">
            <FaBell className="text-vert text-xl" />
          </div>
        </div>

        <div className="bg-gris rounded-xl p-5 shadow-lg flex items-center justify-between">
          <div>
            <p className="text-gray-400 text-sm">Programmées</p>
            <h3 className="text-white text-2xl font-bold mt-1">{totalProgrammees}</h3>
          </div>
          <div className="bg-bleu bg-opacity-20 p-3 rounded-full">
            <FaCalendarAlt className="text-bleu text-xl" />
          </div>
        </div>

        <div className="bg-gris rounded-xl p-5 shadow-lg flex items-center justify-between">
          <div>
            <p className="text-gray-400 text-sm">Brouillons</p>
            <h3 className="text-white text-2xl font-bold mt-1">{totalBrouillons}</h3>
          </div>
          <div className="bg-softYellow bg-opacity-20 p-3 rounded-full">
            <FaRegBell className="text-softYellow text-xl" />
          </div>
        </div>

        <div className="bg-gris rounded-xl p-5 shadow-lg flex items-center justify-between">
          <div>
            <p className="text-gray-400 text-sm">Taux d'ouverture</p>
            <h3 className="text-white text-2xl font-bold mt-1">{tauxMoyenOuverture.toFixed(1)}%</h3>
          </div>
          <div className="bg-softPurple bg-opacity-20 p-3 rounded-full">
            <FaUserCheck className="text-softPurple text-xl" />
          </div>
        </div>
      </div>

      {/* Barre d'outils */}
      <div className="flex flex-col md:flex-row gap-4 justify-between mb-6">
        <div className="flex-1">
          <div className="relative">
            <input
              type="text"
              placeholder="Rechercher une notification..."
              className="w-full py-2 pl-10 pr-4 bg-gris border border-gray-700 rounded-lg text-white text-sm focus:outline-none focus:border-bleu"
              value={searchTerm}
              onChange={handleSearch}
            />
            <div className="absolute left-3 top-2.5 text-gray-400">
              <FiSearch />
            </div>
          </div>
        </div>
        
        <div className="flex gap-3">
          <button
            onClick={handleCreateNotification}
            className="flex items-center gap-2 bg-bleu hover:bg-blue-600 text-white py-2 px-4 rounded-lg transition-colors"
          >
            <FiSend /> Nouvelle notification
          </button>
          <button
            onClick={() => navigate('/admin/notifications/schedule')}
            className="flex items-center gap-2 bg-gris hover:bg-noir2 text-white py-2 px-4 rounded-lg transition-colors"
          >
            <FiCalendar /> Programmer
          </button>
          <button
            onClick={() => navigate('/admin/notifications/analytics')}
            className="flex items-center gap-2 bg-gris hover:bg-noir2 text-white py-2 px-4 rounded-lg transition-colors"
          >
            <FiBarChart2 /> Analytiques
          </button>
          <button
            onClick={() => navigate('/admin/notifications/templates')}
            className="flex items-center gap-2 bg-gris hover:bg-noir2 text-white py-2 px-4 rounded-lg transition-colors"
          >
            <FiUsers /> Modèles
          </button>
        </div>
      </div>

      {/* Filtres avancés */}
      <div className="bg-gris p-4 rounded-lg mb-6 flex flex-wrap gap-4 items-center">
        <div className="flex items-center gap-2 text-gray-400">
          <FiFilter /> Filtres:
        </div>
        
        <select
          name="type"
          value={filters.type}
          onChange={handleFilterChange}
          className="bg-noir border border-gray-700 rounded-lg text-white text-sm py-1 px-3 focus:outline-none focus:border-bleu"
        >
          <option value="tous">Tous les types</option>
          <option value="info">Information</option>
          <option value="alerte">Alerte</option>
          <option value="important">Important</option>
          <option value="promo">Promotion</option>
          <option value="rappel">Rappel</option>
        </select>
        
        <select
          name="cible"
          value={filters.cible}
          onChange={handleFilterChange}
          className="bg-noir border border-gray-700 rounded-lg text-white text-sm py-1 px-3 focus:outline-none focus:border-bleu"
        >
          <option value="tous">Toutes les cibles</option>
          <option value="tous">Tous les utilisateurs</option>
          <option value="clients">Clients</option>
          <option value="agents">Agents</option>
        </select>
        
        <select
          name="statut"
          value={filters.statut}
          onChange={handleFilterChange}
          className="bg-noir border border-gray-700 rounded-lg text-white text-sm py-1 px-3 focus:outline-none focus:border-bleu"
        >
          <option value="tous">Tous les statuts</option>
          <option value="envoyé">Envoyé</option>
          <option value="programmé">Programmé</option>
          <option value="brouillon">Brouillon</option>
        </select>
        
        <button
          onClick={() => setFilters({ type: 'tous', cible: 'tous', statut: 'tous' })}
          className="flex items-center gap-1 text-bleu hover:text-blue-400 transition-colors ml-auto"
        >
          <FiRefreshCw size={16} /> Réinitialiser
        </button>
      </div>

      {/* Tableau des notifications */}
      <div className="bg-gris rounded-xl shadow-lg overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-700">
            <thead className="bg-noir">
              <tr>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer"
                  onClick={() => handleSort('id')}
                >
                  <div className="flex items-center">
                    ID
                    {sortConfig.key === 'id' && (
                      sortConfig.direction === 'ascending' ? <FiArrowUp className="ml-1" /> : <FiArrowDown className="ml-1" />
                    )}
                  </div>
                </th>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer"
                  onClick={() => handleSort('titre')}
                >
                  <div className="flex items-center">
                    Titre
                    {sortConfig.key === 'titre' && (
                      sortConfig.direction === 'ascending' ? <FiArrowUp className="ml-1" /> : <FiArrowDown className="ml-1" />
                    )}
                  </div>
                </th>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer"
                  onClick={() => handleSort('type')}
                >
                  <div className="flex items-center">
                    Type
                    {sortConfig.key === 'type' && (
                      sortConfig.direction === 'ascending' ? <FiArrowUp className="ml-1" /> : <FiArrowDown className="ml-1" />
                    )}
                  </div>
                </th>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer"
                  onClick={() => handleSort('cible')}
                >
                  <div className="flex items-center">
                    Cible
                    {sortConfig.key === 'cible' && (
                      sortConfig.direction === 'ascending' ? <FiArrowUp className="ml-1" /> : <FiArrowDown className="ml-1" />
                    )}
                  </div>
                </th>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer"
                  onClick={() => handleSort('dateCréation')}
                >
                  <div className="flex items-center">
                    Date
                    {sortConfig.key === 'dateCréation' && (
                      sortConfig.direction === 'ascending' ? <FiArrowUp className="ml-1" /> : <FiArrowDown className="ml-1" />
                    )}
                  </div>
                </th>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer"
                  onClick={() => handleSort('statutEnvoi')}
                >
                  <div className="flex items-center">
                    Statut
                    {sortConfig.key === 'statutEnvoi' && (
                      sortConfig.direction === 'ascending' ? <FiArrowUp className="ml-1" /> : <FiArrowDown className="ml-1" />
                    )}
                  </div>
                </th>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-700">
              {notificationsFiltered.length > 0 ? (
                notificationsFiltered.map((notif) => (
                  <tr key={notif.id} className="hover:bg-noir transition-colors">
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                      {notif.id}
                    </td>
                    <td className="px-6 py-4 text-sm text-white">
                      <div className="font-medium">{notif.titre}</div>
                      <div className="text-gray-400 text-xs truncate max-w-xs">
                        {notif.message}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      <div className={`px-2 py-1 rounded-full text-xs inline-block ${
                        notif.type === 'info' ? 'bg-bleu bg-opacity-10 text-bleu' :
                        notif.type === 'alerte' ? 'bg-softRed bg-opacity-10 text-softRed' :
                        notif.type === 'important' ? 'bg-softYellow bg-opacity-10 text-softYellow' :
                        notif.type === 'promo' ? 'bg-softPurple bg-opacity-10 text-softPurple' :
                        'bg-vert bg-opacity-10 text-vert'
                      }`}>
                        {notif.type.charAt(0).toUpperCase() + notif.type.slice(1)}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      <div className="flex items-center text-gray-300">
                        <FiUsers className="mr-2" />
                        <span className="capitalize">{notif.cible}</span>
                      </div>
                      <div className="text-gray-400 text-xs">
                        {notif.nombreDestinataires} destinataires
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                      {notif.dateCréation}
                      {notif.statutEnvoi === 'programmé' && notif.programmationEnvoi && (
                        <div className="text-gray-400 text-xs">
                          Programmé: {notif.programmationEnvoi}
                        </div>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      <span className={`px-2 py-1 rounded-full text-xs ${
                        notif.statutEnvoi === 'envoyé' 
                          ? 'bg-vert bg-opacity-10 text-vert' 
                          : notif.statutEnvoi === 'programmé'
                            ? 'bg-bleu bg-opacity-10 text-bleu'
                            : 'bg-gray-500 bg-opacity-10 text-gray-400'
                      }`}>
                        {notif.statutEnvoi.charAt(0).toUpperCase() + notif.statutEnvoi.slice(1)}
                      </span>
                      {notif.tauxOuverture !== null && (
                        <div className="text-gray-400 text-xs mt-1">
                          Ouverture: {notif.tauxOuverture}%
                        </div>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      <div className="flex gap-2">
                        <button
                          onClick={() => handlePreview(notif)}
                          className="px-3 py-1 bg-bleu bg-opacity-10 hover:bg-opacity-20 text-bleu rounded-lg text-xs transition-colors flex items-center gap-1"
                        >
                          <FiEye size={14} /> Voir
                        </button>
                        <button
                          onClick={() => navigate(`/admin/notifications/details/${notif.id}`)}
                          className="px-3 py-1 bg-softPurple bg-opacity-10 hover:bg-opacity-20 text-softPurple rounded-lg text-xs transition-colors flex items-center gap-1"
                        >
                          <FiBarChart2 size={14} /> Stats
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="px-6 py-8 text-center text-gray-400">
                    Aucune notification trouvée avec les critères de recherche actuels.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Modal de prévisualisation */}
      {notification && (
        <div className="mt-6 bg-gris rounded-xl shadow-xl overflow-hidden">
          <div className="p-6 border-b border-gray-700">
            <div className="flex justify-between items-center">
              <h3 className="text-xl font-bold text-white">Prévisualisation</h3>
              <button 
                onClick={handleClosePreview}
                className="text-gray-400 hover:text-white"
              >
                &times;
              </button>
            </div>
          </div>
          <div className="p-6 space-y-4">
            <div>
              <div className="flex justify-between items-start">
                <div>
                  <div className={`px-2 py-1 rounded-full text-xs inline-block mb-2 ${
                    notification.type === 'info' ? 'bg-bleu bg-opacity-10 text-bleu' :
                    notification.type === 'alerte' ? 'bg-softRed bg-opacity-10 text-softRed' :
                    notification.type === 'important' ? 'bg-softYellow bg-opacity-10 text-softYellow' :
                    notification.type === 'promo' ? 'bg-softPurple bg-opacity-10 text-softPurple' :
                    'bg-vert bg-opacity-10 text-vert'
                  }`}>
                    {notification.type.charAt(0).toUpperCase() + notification.type.slice(1)}
                  </div>
                  <h4 className="text-lg font-semibold text-white">{notification.titre}</h4>
                </div>
                <span className="text-gray-400 text-sm">{notification.dateCréation}</span>
              </div>
              <p className="text-gray-300 mt-2">{notification.message}</p>
            </div>

            <div className="bg-noir p-4 rounded-lg space-y-2">
              <div className="flex justify-between">
                <span className="text-gray-400">Cible:</span>
                <span className="text-white capitalize">{notification.cible} ({notification.nombreDestinataires} destinataires)</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-400">Statut:</span>
                <span className="text-white capitalize">{notification.statutEnvoi}</span>
              </div>
              {notification.tauxOuverture !== null && (
                <div className="flex justify-between">
                  <span className="text-gray-400">Taux d'ouverture:</span>
                  <span className="text-white">{notification.tauxOuverture}%</span>
                </div>
              )}
              <div className="flex justify-between">
                <span className="text-gray-400">Créé par:</span>
                <span className="text-white">{notification.envoyéPar}</span>
              </div>
            </div>
          </div>
          <div className="bg-noir p-4 flex justify-end">
            <button
              onClick={handleClosePreview}
              className="bg-bleu hover:bg-blue-600 text-white py-2 px-4 rounded-lg transition-colors"
            >
              Fermer
            </button>
          </div>
        </div>
      )}
      
      {/* Plus besoin du formulaire ici, il sera dans une page séparée */}
    </div>
  );
};

export default NotificationsList;