import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FiEye, FiUserPlus, FiSearch, FiRefreshCw, FiEdit } from "react-icons/fi";
import { FaChevronLeft, FaChevronRight, FaUserTie, FaGlobe, FaUsers, FaUsersCog, FaUser, FaUserCheck, FaUserTimes } from "react-icons/fa";
import addCaisse from '../../requtes/admin/addCaisse';
import { ajouteEffectueCaisse } from '../alert/ajouteEffectueCaisse';
import { avisbannir } from '../alert/avisbannir';
import { updateStatutAgent } from './updateStatutAgent';
import InfosContext from "../../contexts/InfosAppProvider";

const ListAgents = () => {
    // État local
    const [searchInput, setSearchInput] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [agentsPerPage, setAgentsPerPage] = useState(10);
    const [filter, setFilter] = useState('tous'); // 'tous', 'actif', 'inactif'
    
    // Récupération du token
    const idUserv = localStorage.getItem("idUser");

    // Contexte global
    const {
        infosAdmin,
        setInfosAdmin,
        userInfos,
        setUserInfos,
        transactions,
        setTransactions,
        listeUser,
        token
    } = useContext(InfosContext);

    // État pour les statistiques
    const [stats, setStats] = useState({
        totalAgents: 0,
        agentsActifs: 0,
        agentsInactifs: 0,
        totalSolde: 0
    });

    // Fonction pour formater les montants
    const formatMontant = (montant) => {
        return new Intl.NumberFormat('fr-FR').format(montant);
    };

    // Handler pour la recherche
    const inputHandler = (event) => {
        setSearchInput(event.target.value);
    };

    // Handler pour ajouter une caisse
    const handleAddCaisse = async (idAgent, nomAgent) => {
        const result = await addCaisse(idAgent, token);
        if (result.success) {
            ajouteEffectueCaisse();
        } else {
            alert(`Erreur: ${result.message}`);
        }
    };

    // Handler pour changer le statut d'un agent
    const handleChangeStatut = (agent) => {
        avisbannir().then((result) => {
            if (result.isConfirmed) {
                const newStatut = agent.statut === 'actif' ? 'inactif' : 'actif';
                updateStatutAgent(agent.id, newStatut, token);
            }
        });
    };

    // Filtrer les utilisateurs pour obtenir uniquement ceux avec le rôle "agent"
    const agentsArray = Object.values(listeUser).filter(user => user.role === "agent");

    // Calcul des statistiques
    useEffect(() => {
        const actifs = agentsArray.filter(agent => agent.statut === 'actif');
        const inactifs = agentsArray.filter(agent => agent.statut === 'inactif');
        const totalSolde = agentsArray.reduce((sum, agent) => sum + (parseFloat(agent.solde) || 0), 0);
        
        setStats({
            totalAgents: agentsArray.length,
            agentsActifs: actifs.length,
            agentsInactifs: inactifs.length,
            totalSolde: totalSolde
        });
    }, [listeUser]);

    // Filtrer les agents selon la recherche et le filtre
    const filteredAgents = agentsArray.filter(agent => {
        // Filtre par statut
        if (filter === 'actif' && agent.statut !== 'actif') return false;
        if (filter === 'inactif' && agent.statut !== 'inactif') return false;
        
        // Filtre par recherche
        return (
            agent.nom?.toLowerCase().includes(searchInput.toLowerCase()) ||
            agent.email?.toLowerCase().includes(searchInput.toLowerCase()) ||
            agent.pays?.toLowerCase().includes(searchInput.toLowerCase()) ||
            agent.whatsapp?.includes(searchInput)
        );
    });

    // Pagination
    const indexOfLastAgent = currentPage * agentsPerPage;
    const indexOfFirstAgent = indexOfLastAgent - agentsPerPage;
    const currentAgents = filteredAgents.slice(indexOfFirstAgent, indexOfLastAgent);
    const totalAgents = filteredAgents.length;

    // Navigation de pagination
    const paginateNext = () => {
        setCurrentPage(prevPage => prevPage + 1);
    };

    const paginatePrevious = () => {
        setCurrentPage(prevPage => (prevPage > 1 ? prevPage - 1 : 1));
    };

    // Handler pour changer le nombre d'agents par page
    const handleAgentsPerPageChange = (event) => {
        setAgentsPerPage(Number(event.target.value));
        setCurrentPage(1);
    };

    // Réinitialiser les filtres
    const resetFilters = () => {
        setFilter('tous');
        setSearchInput('');
        setCurrentPage(1);
    };

    return (
        <div className="w-full">
            {/* En-tête de la page */}
            <div className="mb-8">
                <h1 className="text-2xl font-bold text-white mb-2">Agents</h1>
                <p className="text-gray-400">Gérez vos agents et leurs permissions</p>
            </div>

            {/* Cartes de statistiques */}
            <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-8">
                <div className="bg-gris rounded-xl p-5 shadow-lg flex items-center justify-between">
                    <div>
                        <p className="text-gray-400 text-sm">Total agents</p>
                        <h3 className="text-white text-2xl font-bold mt-1">{stats.totalAgents}</h3>
                    </div>
                    <div className="bg-bleu bg-opacity-20 p-3 rounded-full">
                        <FaUserTie className="text-bleu text-xl" />
                    </div>
                </div>

                <div className="bg-gris rounded-xl p-5 shadow-lg flex items-center justify-between">
                    <div>
                        <p className="text-gray-400 text-sm">Agents actifs</p>
                        <h3 className="text-white text-2xl font-bold mt-1">
                            {stats.agentsActifs}
                        </h3>
                    </div>
                    <div className="bg-vert bg-opacity-20 p-3 rounded-full">
                        <FaUserCheck className="text-vert text-xl" />
                    </div>
                </div>

                <div className="bg-gris rounded-xl p-5 shadow-lg flex items-center justify-between">
                    <div>
                        <p className="text-gray-400 text-sm">Agents inactifs</p>
                        <h3 className="text-white text-2xl font-bold mt-1">
                            {stats.agentsInactifs}
                        </h3>
                    </div>
                    <div className="bg-softRed bg-opacity-20 p-3 rounded-full">
                        <FaUserTimes className="text-softRed text-xl" />
                    </div>
                </div>

                <div className="bg-gris rounded-xl p-5 shadow-lg flex items-center justify-between">
                    <div>
                        <p className="text-gray-400 text-sm">Solde total</p>
                        <h3 className="text-white text-2xl font-bold mt-1">
                            {formatMontant(stats.totalSolde)} F
                        </h3>
                    </div>
                    <div className="bg-softPurple bg-opacity-20 p-3 rounded-full">
                        <FaUsersCog className="text-softPurple text-xl" />
                    </div>
                </div>
            </div>

            {/* Barre de recherche et bouton d'ajout */}
            <div className="flex flex-col md:flex-row gap-4 justify-between mb-6">
                <div className="flex-1">
                    <div className="relative">
                        <input
                            type="text"
                            placeholder="Rechercher un agent..."
                            value={searchInput}
                            onChange={inputHandler}
                            className="w-full py-2 pl-10 pr-4 bg-gris border border-gray-700 rounded-lg text-white text-sm focus:outline-none focus:border-bleu"
                        />
                        <div className="absolute left-3 top-2.5 text-gray-400">
                            <FiSearch />
                        </div>
                    </div>
                </div>
                
                <div className="flex gap-2">
                    <button 
                        className={`px-4 py-2 rounded-lg text-sm ${filter === 'tous' ? 'bg-bleu text-white' : 'bg-gris text-gray-300 hover:bg-noir'}`}
                        onClick={() => { setFilter('tous'); setCurrentPage(1); }}
                    >
                        Tous
                    </button>
                    <button 
                        className={`px-4 py-2 rounded-lg text-sm ${filter === 'actif' ? 'bg-vert text-white' : 'bg-gris text-gray-300 hover:bg-noir'}`}
                        onClick={() => { setFilter('actif'); setCurrentPage(1); }}
                    >
                        Actifs
                    </button>
                    <button 
                        className={`px-4 py-2 rounded-lg text-sm ${filter === 'inactif' ? 'bg-softRed text-white' : 'bg-gris text-gray-300 hover:bg-noir'}`}
                        onClick={() => { setFilter('inactif'); setCurrentPage(1); }}
                    >
                        Inactifs
                    </button>
                    
                    <button
                        onClick={resetFilters}
                        className="p-2 bg-gris rounded-lg text-gray-400 hover:text-bleu transition-colors"
                        title="Réinitialiser les filtres"
                    >
                        <FiRefreshCw />
                    </button>
                    
                    <Link to={'/admin/addAgent'}>
                        <button className="flex items-center gap-2 bg-bleu hover:bg-blue-600 text-white py-2 px-4 rounded-lg transition-colors">
                            <FiUserPlus /> Nouvel agent
                        </button>
                    </Link>
                </div>
            </div>

            {/* Pagination et sélection du nombre par page */}
            <div className="flex items-center justify-between mb-4 bg-gris p-3 rounded-lg">
                <div className="flex items-center gap-2">
                    <span className="text-gray-400 text-sm">Afficher</span>
                    <select
                        value={agentsPerPage}
                        onChange={handleAgentsPerPageChange}
                        className="bg-noir border border-gray-700 rounded text-white text-sm py-1 px-2 focus:outline-none focus:border-bleu"
                    >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                    </select>
                    <span className="text-gray-400 text-sm">par page</span>
                </div>
                
                <div className="flex items-center">
                    <span className="text-gray-400 text-sm mr-4">
                        {totalAgents === 0 ? '0' : indexOfFirstAgent + 1} - {Math.min(indexOfLastAgent, totalAgents)} sur {totalAgents}
                    </span>
                    <div className="flex gap-2">
                        <button
                            onClick={paginatePrevious}
                            disabled={currentPage === 1}
                            className={`p-2 rounded-lg ${currentPage === 1 ? 'text-gray-600 cursor-not-allowed' : 'text-gray-400 hover:bg-noir hover:text-white'}`}
                        >
                            <FaChevronLeft size={14} />
                        </button>
                        <button
                            onClick={paginateNext}
                            disabled={indexOfLastAgent >= totalAgents}
                            className={`p-2 rounded-lg ${indexOfLastAgent >= totalAgents ? 'text-gray-600 cursor-not-allowed' : 'text-gray-400 hover:bg-noir hover:text-white'}`}
                        >
                            <FaChevronRight size={14} />
                        </button>
                    </div>
                </div>
            </div>
                
            {/* Tableau des agents */}
            <div className="bg-gris rounded-xl shadow-lg overflow-hidden">
                {totalAgents === 0 ? (
                    <div className="p-8 text-center">
                        <FaUsers className="text-gray-500 text-4xl mx-auto mb-4" />
                        <p className="text-gray-400 text-lg">Aucun agent trouvé avec les critères de recherche actuels.</p>
                        <button 
                            onClick={resetFilters}
                            className="mt-4 px-4 py-2 bg-bleu rounded-lg text-white text-sm hover:bg-blue-600 transition-colors inline-flex items-center gap-2"
                        >
                            <FiRefreshCw size={14} /> Réinitialiser les filtres
                        </button>
                    </div>
                ) : (
                    <div className="overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-700">
                            <thead className="bg-noir">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">#</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Pays</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Nom</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Contact</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Statut</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Solde</th>
                                    <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">Actions</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-700">
                                {currentAgents.map((agent, index) => (
                                    <tr key={index} className="hover:bg-noir transition-colors">
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                                            {indexOfFirstAgent + index + 1}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                                            <div className="flex items-center gap-2">
                                                <FaGlobe className="text-gray-400" />
                                                <span className="text-gray-300">{agent.pays || '-'}</span>
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-white font-medium">
                                            {agent.nom}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                                            <div className="text-gray-300">{agent.email}</div>
                                            <div className="text-gray-400 text-xs">{agent.whatsapp}</div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                                            <span className={`px-2 py-1 rounded-full text-xs ${
                                                agent.statut === 'actif' 
                                                    ? 'bg-vert bg-opacity-10 text-vert' 
                                                    : 'bg-softRed bg-opacity-10 text-softRed'
                                            }`}>
                                                {agent.statut === 'actif' ? 'Actif' : 'Inactif'}
                                            </span>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-vert">
                                            {formatMontant(agent.solde || 0)} F
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                                            <div className="flex items-center justify-center gap-3">
                                                <Link 
                                                    to={`/admin/agentDetails/${agent.id}`}
                                                    className="p-2 bg-bleu bg-opacity-10 hover:bg-opacity-20 text-bleu rounded-lg transition-colors"
                                                    title="Détails"
                                                >
                                                    <FiEye size={18} />
                                                </Link>
                                                <button
                                                    className={`p-2 rounded-lg transition-colors ${
                                                        agent.statut === 'actif' 
                                                            ? 'bg-softRed bg-opacity-10 hover:bg-opacity-20 text-softRed' 
                                                            : 'bg-vert bg-opacity-10 hover:bg-opacity-20 text-vert'
                                                    }`}
                                                    onClick={() => handleChangeStatut(agent)}
                                                    title={agent.statut === 'actif' ? 'Désactiver' : 'Activer'}
                                                >
                                                    {agent.statut === 'actif' ? <FaUserTimes size={18} /> : <FaUserCheck size={18} />}
                                                </button>
                                                <button
                                                    className="p-2 bg-softPurple bg-opacity-10 hover:bg-opacity-20 text-softPurple rounded-lg transition-colors"
                                                    onClick={() => handleAddCaisse(agent.id, agent.nom)}
                                                    title="Ajouter une caisse"
                                                >
                                                    <FiEdit size={18} />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
            
            {/* Pagination mobile (seulement visible sur mobile) */}
            <div className="md:hidden flex justify-center mt-4">
                <div className="flex items-center gap-2 bg-gris p-2 rounded-lg">
                    <button
                        onClick={paginatePrevious}
                        disabled={currentPage === 1}
                        className={`p-2 rounded-lg ${currentPage === 1 ? 'text-gray-600 cursor-not-allowed' : 'text-gray-400 hover:bg-noir hover:text-white'}`}
                    >
                        <FaChevronLeft size={14} />
                    </button>
                    <span className="text-white">
                        Page {currentPage}
                    </span>
                    <button
                        onClick={paginateNext}
                        disabled={indexOfLastAgent >= totalAgents}
                        className={`p-2 rounded-lg ${indexOfLastAgent >= totalAgents ? 'text-gray-600 cursor-not-allowed' : 'text-gray-400 hover:bg-noir hover:text-white'}`}
                    >
                        <FaChevronRight size={14} />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ListAgents;
