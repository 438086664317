import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWallet, 
  faChartLine,
  faExchangeAlt,
  faUserPlus,
  faBell
} from "@fortawesome/free-solid-svg-icons";
import InfosContext from "../contexts/InfosAppProvider";

const DashboardHeader = () => {
  const [greeting, setGreeting] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [stats, setStats] = useState({
    totalTransactions: 0,
    totalAmount: 0,
    pendingTransactions: 0
  });
  
  const { transactions } = useContext(InfosContext);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    window.addEventListener("resize", handleResize);
    
    // Set greeting based on time of day
    const hour = new Date().getHours();
    if (hour < 12) setGreeting("Bonjour");
    else if (hour < 18) setGreeting("Bon après-midi");
    else setGreeting("Bonsoir");
    
    // Calculate stats if transactions exist
    if (transactions && Object.keys(transactions).length > 0) {
      let total = 0;
      let pending = 0;
      let totalAmount = 0;
      
      Object.values(transactions).forEach(transaction => {
        if (transaction) {
          total++;
          // Convertir la montant en nombre avant addition
          const montant = transaction.montant ? parseFloat(transaction.montant) : 0;
          totalAmount += isNaN(montant) ? 0 : montant;
          if (transaction.statut === "en cours") pending++;
        }
      });
      
      setStats({
        totalTransactions: total,
        totalAmount: totalAmount,
        pendingTransactions: pending
      });
    }
    
    return () => window.removeEventListener("resize", handleResize);
  }, [transactions]);
  
  return (
    <div className="bg-gradient-to-r from-noir to-black border-b border-gris overflow-hidden relative">
      {/* Decorative elements */}
      <div className="absolute top-0 right-0 w-32 h-32 bg-bleu/5 rounded-full blur-3xl"></div>
      <div className="absolute bottom-0 left-0 w-32 h-32 bg-bleu/5 rounded-full blur-3xl"></div>
      
      <div className="relative z-10 p-4 md:p-6">
        {/* Header top section */}
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-xl md:text-2xl font-bold text-white flex items-center">
              {greeting}, <span className="text-bleu ml-2">Agent</span>
            </h1>
            {!isMobile && (
              <p className="text-gray-400 text-sm mt-1">
                {new Date().toLocaleDateString('fr-FR', { 
                  weekday: 'long', 
                  day: 'numeric',
                  month: 'long'
                })}
              </p>
            )}
          </div>
          
          <div className="flex space-x-2">
            <button className="relative bg-gris hover:bg-gris/80 p-2 rounded-full text-gray-300 hover:text-bleu transition-colors">
              <FontAwesomeIcon icon={faBell} className="text-sm" />
              <span className="absolute top-0 right-0 w-2 h-2 bg-bleu rounded-full"></span>
            </button>
          </div>
        </div>
        
        {/* Stats section - desktop only */}
        {!isMobile && (
          <div className="grid grid-cols-3 gap-4 mt-4">
            {/* Transactions total card */}
            <div className="bg-gris rounded-xl p-4 border border-[#333333] hover:border-bleu transition-all duration-300">
              <div className="flex items-center mb-2">
                <div className="bg-bleu/10 text-bleu rounded-full p-2 mr-2">
                  <FontAwesomeIcon icon={faExchangeAlt} />
                </div>
                <span className="text-sm text-gray-400">Transactions</span>
              </div>
              <h3 className="text-xl font-bold text-white">{stats.totalTransactions}</h3>
            </div>
            
            {/* Montant total card */}
            <div className="bg-gris rounded-xl p-4 border border-[#333333] hover:border-vert transition-all duration-300">
              <div className="flex items-center mb-2">
                <div className="bg-vert/10 text-vert rounded-full p-2 mr-2">
                  <FontAwesomeIcon icon={faWallet} />
                </div>
                <span className="text-sm text-gray-400">Montant total</span>
              </div>
              <h3 className="text-xl font-bold text-white">{stats.totalAmount.toLocaleString()} F</h3>
            </div>
            
            {/* En attente card */}
            <div className="bg-gris rounded-xl p-4 border border-[#333333] hover:border-[#AA1D22FF] transition-all duration-300">
              <div className="flex items-center mb-2">
                <div className="bg-[#AA1D22FF]/10 text-[#AA1D22FF] rounded-full p-2 mr-2">
                  <FontAwesomeIcon icon={faBell} />
                </div>
                <span className="text-sm text-gray-400">En attente</span>
              </div>
              <h3 className="text-xl font-bold text-white">{stats.pendingTransactions}</h3>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardHeader;