import React, { useState, useEffect } from 'react';
import { FiPlus, FiEdit, FiTrash2, FiEye, FiSearch, FiFilter, FiArrowUp, FiArrowDown, FiRefreshCw } from 'react-icons/fi';
import { FaUserTie, FaMoneyBillWave, FaUserCheck, FaUserSlash } from 'react-icons/fa';

const CaissierList = () => {
  // État local pour stocker les données des caissiers
  const [caissiers, setCaissiers] = useState([
    { 
      id: 1, 
      nom: "Jean Dupont", 
      email: "jean.dupont@payforbet.com", 
      telephone: "+22997123456", 
      dateCréation: "2023-01-15",
      caisses: ["Caisse Principale"],
      nbTransactions: 1240,
      volume: 45000000,
      statut: "actif" 
    },
    { 
      id: 2, 
      nom: "Marie Martin", 
      email: "marie.martin@payforbet.com", 
      telephone: "+22998654321", 
      dateCréation: "2023-02-18",
      caisses: ["Caisse Express"],
      nbTransactions: 875,
      volume: 28500000,
      statut: "actif" 
    },
    { 
      id: 3, 
      nom: "Paul Robert", 
      email: "paul.robert@payforbet.com", 
      telephone: "+22996543210", 
      dateCréation: "2023-03-22",
      caisses: ["Caisse VIP"],
      nbTransactions: 560,
      volume: 87000000,
      statut: "actif" 
    },
    { 
      id: 4, 
      nom: "Sophie Leclerc", 
      email: "sophie.leclerc@payforbet.com", 
      telephone: "+22999876543", 
      dateCréation: "2023-04-30",
      caisses: ["Caisse Mobile"],
      nbTransactions: 325,
      volume: 12500000,
      statut: "actif" 
    },
    { 
      id: 5, 
      nom: "Thomas Bernard", 
      email: "thomas.bernard@payforbet.com", 
      telephone: "+22997654321", 
      dateCréation: "2023-05-14",
      caisses: [],
      nbTransactions: 0,
      volume: 0,
      statut: "inactif" 
    }
  ]);

  // État pour la recherche
  const [searchTerm, setSearchTerm] = useState('');
  
  // État pour le tri
  const [sortConfig, setSortConfig] = useState({
    key: 'id',
    direction: 'ascending'
  });

  // État pour les filtres
  const [filters, setFilters] = useState({
    statut: 'tous',
    avecCaisse: 'tous'
  });

  // État pour le caissier en cours d'édition
  const [caissierEnEdition, setCaissierEnEdition] = useState(null);

  // Fonction pour formater les montants
  const formatMontant = (montant) => {
    return new Intl.NumberFormat('fr-FR').format(montant);
  };

  // Fonction pour trier les données
  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // Fonction pour gérer la recherche
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  // Fonction pour gérer les filtres
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };

  // Fonction pour ajouter un nouveau caissier
  const handleAjouterCaissier = () => {
    // Logique pour ajouter un caissier
    alert('Formulaire d\'ajout de caissier à implémenter');
  };

  // Fonction pour éditer un caissier
  const handleEditerCaissier = (caissier) => {
    setCaissierEnEdition(caissier);
    // Ouvrir modal d'édition
    alert('Modifier le caissier: ' + caissier.nom);
  };

  // Fonction pour consulter un caissier
  const handleConsulterCaissier = (caissier) => {
    // Rediriger vers la page de détails
    alert('Consulter les détails du caissier: ' + caissier.nom);
  };

  // Fonction pour supprimer un caissier
  const handleSupprimerCaissier = (id) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer ce caissier ?')) {
      setCaissiers(caissiers.filter(caissier => caissier.id !== id));
    }
  };

  // Fonction pour appliquer les filtres et la recherche
  const getFilteredCaissiers = () => {
    return caissiers
      .filter(caissier => {
        // Filtre de recherche
        if (searchTerm && 
            !caissier.nom.toLowerCase().includes(searchTerm.toLowerCase()) &&
            !caissier.email.toLowerCase().includes(searchTerm.toLowerCase())) {
          return false;
        }
        
        // Filtre par statut
        if (filters.statut !== 'tous' && caissier.statut !== filters.statut) {
          return false;
        }
        
        // Filtre par attribution de caisse
        if (filters.avecCaisse === 'avec' && caissier.caisses.length === 0) {
          return false;
        }
        if (filters.avecCaisse === 'sans' && caissier.caisses.length > 0) {
          return false;
        }
        
        return true;
      })
      .sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
  };

  // Obtenir les caissiers filtrés
  const caissiersFiltered = getFilteredCaissiers();

  // Rendu du composant
  return (
    <div className="w-full">
      {/* En-tête de la page */}
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-white mb-2">Gestion des caissiers</h1>
        <p className="text-gray-400">Gérez votre équipe de caissiers et leurs attributions</p>
      </div>

      {/* Carte de statistiques */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-8">
        <div className="bg-gris rounded-xl p-5 shadow-lg flex items-center justify-between">
          <div>
            <p className="text-gray-400 text-sm">Total caissiers</p>
            <h3 className="text-white text-2xl font-bold mt-1">{caissiers.length}</h3>
          </div>
          <div className="bg-bleu bg-opacity-20 p-3 rounded-full">
            <FaUserTie className="text-bleu text-xl" />
          </div>
        </div>

        <div className="bg-gris rounded-xl p-5 shadow-lg flex items-center justify-between">
          <div>
            <p className="text-gray-400 text-sm">Total transactions</p>
            <h3 className="text-white text-2xl font-bold mt-1">
              {formatMontant(caissiers.reduce((sum, caissier) => sum + caissier.nbTransactions, 0))}
            </h3>
          </div>
          <div className="bg-softYellow bg-opacity-20 p-3 rounded-full">
            <FaMoneyBillWave className="text-softYellow text-xl" />
          </div>
        </div>

        <div className="bg-gris rounded-xl p-5 shadow-lg flex items-center justify-between">
          <div>
            <p className="text-gray-400 text-sm">Caissiers actifs</p>
            <h3 className="text-white text-2xl font-bold mt-1">
              {caissiers.filter(caissier => caissier.statut === 'actif').length}
            </h3>
          </div>
          <div className="bg-vert bg-opacity-20 p-3 rounded-full">
            <FaUserCheck className="text-vert text-xl" />
          </div>
        </div>

        <div className="bg-gris rounded-xl p-5 shadow-lg flex items-center justify-between">
          <div>
            <p className="text-gray-400 text-sm">Caissiers inactifs</p>
            <h3 className="text-white text-2xl font-bold mt-1">
              {caissiers.filter(caissier => caissier.statut === 'inactif').length}
            </h3>
          </div>
          <div className="bg-softRed bg-opacity-20 p-3 rounded-full">
            <FaUserSlash className="text-softRed text-xl" />
          </div>
        </div>
      </div>

      {/* Barre d'outils */}
      <div className="flex flex-col md:flex-row gap-4 justify-between mb-6">
        <div className="flex-1">
          <div className="relative">
            <input
              type="text"
              placeholder="Rechercher un caissier..."
              className="w-full py-2 pl-10 pr-4 bg-gris border border-gray-700 rounded-lg text-white text-sm focus:outline-none focus:border-bleu"
              value={searchTerm}
              onChange={handleSearch}
            />
            <div className="absolute left-3 top-2.5 text-gray-400">
              <FiSearch />
            </div>
          </div>
        </div>
        
        <div className="flex gap-3">
          <select
            name="statut"
            value={filters.statut}
            onChange={handleFilterChange}
            className="bg-gris border border-gray-700 rounded-lg text-white text-sm py-2 px-4 focus:outline-none focus:border-bleu"
          >
            <option value="tous">Tous les statuts</option>
            <option value="actif">Actif</option>
            <option value="inactif">Inactif</option>
          </select>
          
          <button
            onClick={handleAjouterCaissier}
            className="flex items-center gap-2 bg-bleu hover:bg-blue-600 text-white py-2 px-4 rounded-lg transition-colors"
          >
            <FiPlus /> Nouveau caissier
          </button>
        </div>
      </div>

      {/* Filtres avancés */}
      <div className="bg-gris p-4 rounded-lg mb-6 flex flex-wrap gap-4 items-center">
        <div className="flex items-center gap-2 text-gray-400">
          <FiFilter /> Filtres avancés:
        </div>
        
        <div className="flex items-center gap-2">
          <label className="text-white text-sm">Attribution caisse:</label>
          <select
            name="avecCaisse"
            value={filters.avecCaisse}
            onChange={handleFilterChange}
            className="bg-noir border border-gray-700 rounded-lg text-white text-sm py-1 px-3 w-32 focus:outline-none focus:border-bleu"
          >
            <option value="tous">Tous</option>
            <option value="avec">Avec caisse</option>
            <option value="sans">Sans caisse</option>
          </select>
        </div>
        
        <button
          onClick={() => setFilters({ statut: 'tous', avecCaisse: 'tous' })}
          className="flex items-center gap-1 text-bleu hover:text-blue-400 transition-colors ml-auto"
        >
          <FiRefreshCw size={16} /> Réinitialiser
        </button>
      </div>

      {/* Tableau des caissiers */}
      <div className="bg-gris rounded-xl shadow-lg overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-700">
            <thead className="bg-noir">
              <tr>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer"
                  onClick={() => handleSort('id')}
                >
                  <div className="flex items-center">
                    ID
                    {sortConfig.key === 'id' && (
                      sortConfig.direction === 'ascending' ? <FiArrowUp className="ml-1" /> : <FiArrowDown className="ml-1" />
                    )}
                  </div>
                </th>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer"
                  onClick={() => handleSort('nom')}
                >
                  <div className="flex items-center">
                    Nom
                    {sortConfig.key === 'nom' && (
                      sortConfig.direction === 'ascending' ? <FiArrowUp className="ml-1" /> : <FiArrowDown className="ml-1" />
                    )}
                  </div>
                </th>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                >
                  Contact
                </th>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer"
                >
                  <div className="flex items-center">
                    Caisses attribuées
                  </div>
                </th>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer"
                  onClick={() => handleSort('nbTransactions')}
                >
                  <div className="flex items-center">
                    Transactions
                    {sortConfig.key === 'nbTransactions' && (
                      sortConfig.direction === 'ascending' ? <FiArrowUp className="ml-1" /> : <FiArrowDown className="ml-1" />
                    )}
                  </div>
                </th>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer"
                  onClick={() => handleSort('statut')}
                >
                  <div className="flex items-center">
                    Statut
                    {sortConfig.key === 'statut' && (
                      sortConfig.direction === 'ascending' ? <FiArrowUp className="ml-1" /> : <FiArrowDown className="ml-1" />
                    )}
                  </div>
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-700">
              {caissiersFiltered.length > 0 ? (
                caissiersFiltered.map((caissier) => (
                  <tr key={caissier.id} className="hover:bg-noir transition-colors">
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                      {caissier.id}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                      {caissier.nom}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                      <div>
                        {caissier.email}
                      </div>
                      <div className="text-gray-400">
                        {caissier.telephone}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      {caissier.caisses.length > 0 ? (
                        <div className="flex flex-wrap gap-1">
                          {caissier.caisses.map((caisse, index) => (
                            <span 
                              key={index}
                              className="inline-block px-2 py-1 bg-bleu bg-opacity-10 text-bleu text-xs rounded-full"
                            >
                              {caisse}
                            </span>
                          ))}
                        </div>
                      ) : (
                        <span className="text-gray-400">Aucune caisse</span>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                      <div>{formatMontant(caissier.nbTransactions)}</div>
                      <div className="text-gray-400 text-xs">
                        {formatMontant(caissier.volume)} F CFA
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      <span className={`px-2 py-1 rounded-full text-xs ${
                        caissier.statut === 'actif' 
                          ? 'bg-vert bg-opacity-10 text-vert' 
                          : 'bg-softRed bg-opacity-10 text-softRed'
                      }`}>
                        {caissier.statut === 'actif' ? 'Actif' : 'Inactif'}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400">
                      <div className="flex gap-2">
                        <button 
                          onClick={() => handleConsulterCaissier(caissier)}
                          className="p-1 hover:text-vert transition-colors"
                          title="Consulter"
                        >
                          <FiEye />
                        </button>
                        <button 
                          onClick={() => handleEditerCaissier(caissier)}
                          className="p-1 hover:text-bleu transition-colors"
                          title="Éditer"
                        >
                          <FiEdit />
                        </button>
                        <button 
                          onClick={() => handleSupprimerCaissier(caissier.id)}
                          className="p-1 hover:text-softRed transition-colors"
                          title="Supprimer"
                        >
                          <FiTrash2 />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="px-6 py-8 text-center text-gray-400">
                    Aucun caissier trouvé avec les critères de recherche actuels.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CaissierList;