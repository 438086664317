import React, { useState, useContext, useEffect } from "react";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faArrowUp,
  faArrowDown,
  faWallet,
  faFutbol,
  faGift,
  faCogs
} from "@fortawesome/free-solid-svg-icons";
import { TbMoneybag } from "react-icons/tb";

// Import Material UI icons
import HomeIcon from "@mui/icons-material/Home";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import SettingsIcon from "@mui/icons-material/Settings";

// Import resources
import check from "../ressources/images/check.png";
import logout from "../ressources/images/logout.png";

// Import context if needed
import InfosContext from "../contexts/InfosAppProvider";

const BottomNavBar = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  
  // Get user info from context if available
  const { userInfos } = useContext(InfosContext) || { userInfos: null };

  const handleNavigation = (newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        navigate("/clients/espace"); // Accueil
        break;
      case 1:
        navigate("/clients/menu-transaction"); // Transactions
        break;
      case 2:
        navigate("/clients/aide"); // Assistance
        break;
      case 3:
        navigate("/clients/setting"); // Paramètres
        break;
      default:
        break;
    }
  };

  return (
    <div>
      {/* Mobile Bottom Navigation */}
      <div className="md:hidden">
        <BottomNavigation
          value={value}
          className="bg-black text-white md:hidden"
          onChange={(event, newValue) => handleNavigation(newValue)}
          showLabels
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "#1C1C1CFF",
            border: "4px solid #272728FF",
            borderRadius: "4px",
          }}
        >
          <BottomNavigationAction
            label="Accueil"
            icon={<HomeIcon />}
            sx={{
              color: "gray",
            }}
          />
          <BottomNavigationAction
            sx={{
              color: value === 0 ? "gray" : "gray",
            }}
            label="Transactions"
            icon={<SwapHorizIcon />}
          />
          <BottomNavigationAction
            sx={{
              color: value === 0 ? "gray" : "gray",
            }}
            label="Assistance"
            icon={<HelpOutlineIcon />}
          />
          <BottomNavigationAction
            sx={{
              color: value === 0 ? "gray" : "gray",
            }}
            label="Paramètres"
            icon={<SettingsIcon />}
          />
        </BottomNavigation>
      </div>

      {/* Top navigation bar for desktop */}
     

      {/* Desktop Sidebar */}
      <div className="hidden md:block fixed top-0 left-0 h-full z-40">
        <div className="w-64 bg-[#131313] h-full border-r border-[#333333] pt-24">
          <div className="px-6">
            <h1 className="text-white text-2xl font-bold mb-8">Payforbet</h1>
            
            <div className="mb-8">
              <p className="text-gray-500 text-xs mb-2 pl-2">MENU</p>
              <div className="space-y-1">
                <div 
                  onClick={() => navigate("/clients/espace")}
                  className="flex items-center text-white font-medium bg-bleu bg-opacity-20 px-3 py-2 rounded-md cursor-pointer">
                  <FontAwesomeIcon icon={faWallet} className="mr-3 text-bleu" />
                  <span>Tableau de bord</span>
                </div>
                <div 
                  onClick={() => navigate("/clients/depot-manuel")} 
                  className="flex items-center text-gray-400 hover:text-white px-3 py-2 rounded-md transition-colors cursor-pointer">
                  <FontAwesomeIcon icon={faArrowUp} className="mr-3" />
                  <span>Dépôts</span>
                </div>
                <div 
                  onClick={() => navigate("/clients/retrait-manuel")}
                  className="flex items-center text-gray-400 hover:text-white px-3 py-2 rounded-md transition-colors cursor-pointer">
                  <FontAwesomeIcon icon={faArrowDown} className="mr-3" />
                  <span>Retraits</span>
                </div>
                <div 
                  onClick={() => navigate("/clients/parrainage")}
                  className="flex items-center text-gray-400 hover:text-white px-3 py-2 rounded-md transition-colors cursor-pointer">
                  <TbMoneybag className="mr-3" />
                  <span>Affiliation</span>
                </div>
                <div 
                  onClick={() => navigate("/clients/pronos")}
                  className="flex items-center text-gray-400 hover:text-white px-3 py-2 rounded-md transition-colors cursor-pointer">
                  <FontAwesomeIcon icon={faFutbol} className="mr-3" />
                  <span>Pronos</span>
                </div>
                {userInfos && userInfos.role === "client" && (
                  <div 
                    onClick={() => navigate("/clients/bonus")}
                    className="flex items-center text-gray-400 hover:text-white px-3 py-2 rounded-md transition-colors cursor-pointer">
                    <FontAwesomeIcon icon={faGift} className="mr-3 text-[#f9d57d]" />
                    <span>Mes Bonus</span>
                  </div>
                )}
                <div 
                  onClick={() => navigate("/clients/setting")}
                  className="flex items-center text-gray-400 hover:text-white px-3 py-2 rounded-md transition-colors cursor-pointer">
                  <FontAwesomeIcon icon={faCogs} className="mr-3 text-softPurple" />
                  <span>Paramètres</span>
                </div>
              </div>
            </div>
            
            <div className="mb-8">
              <p className="text-gray-500 text-xs mb-2 pl-2">AIDE</p>
              <div className="space-y-1">
                <div 
                  onClick={() => navigate("/clients/aide")}
                  className="flex items-center text-gray-400 hover:text-white px-3 py-2 rounded-md transition-colors cursor-pointer">
                  <FontAwesomeIcon icon={faEnvelope} className="mr-3" />
                  <span>Support</span>
                </div>
              </div>
            </div>
          </div>
          
          {/* Profil utilisateur en bas du sidebar */}
          <div className="absolute bottom-0 left-0 right-0 border-t border-[#333333] p-4">
            <div className="flex items-center">
              <div className="w-10 h-10 rounded-full bg-bleu flex items-center justify-center text-white font-semibold mr-2">
                {userInfos?.nom?.charAt(0) || 'C'}
              </div>
              <div className="overflow-hidden">
                <p className="text-white font-medium truncate">{userInfos?.nom || 'Client'}</p>
                <p className="text-gray-400 text-xs">{userInfos?.email || 'client@example.com'}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomNavBar;
