import React, { useContext, useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { 
  FaMoneyBillWave, FaWallet, FaExchangeAlt, FaRegSadCry, 
  FaArrowLeft, FaDownload, FaUpload, FaHourglass, FaCheck,
  FaChevronLeft, FaChevronRight, FaFilter, FaSearch
} from 'react-icons/fa';
import { FiFilter, FiRefreshCw, FiSearch } from "react-icons/fi";
import { MdError } from 'react-icons/md';

import InfosContext from "../../contexts/InfosAppProvider";

const ClientDetails = () => {
  const { id } = useParams();  
  const [totalDepot, setTotalDepot] = useState(0);
  const [totalRetrait, setTotalRetrait] = useState(0);
  const [nombreTransactions, setNombreTransactions] = useState(0);
  const [transactionsEnCours, setTransactionsEnCours] = useState(0);
  const [transactionsTerminees, setTransactionsTerminees] = useState(0);
  const [transactionsEchoue, setTransactionsEchoue] = useState(0);
  const [transactionsAgent, setTransactionsAgent] = useState([]);

  const { 
    listeUser, transactions 
  } = useContext(InfosContext);



  // Trouver le client correspondant à l'ID de l'URL
  const client = listeUser ? Object.values(listeUser).find(user => user.id === id) : null;

useEffect(() => {
  console.log("transactions:", transactions);

  if (client && transactions) {
    // Convertir l'objet transactions en tableau
    const transactionsArray = Object.values(transactions);
    
    // Filtrer les transactions du client
    const clientTransactions = transactionsArray.filter(
      (transaction) => Number(transaction.idUser) === Number(client.id)
    );
    setTransactionsAgent(clientTransactions);
    
    // Calculer le total des dépôts
    const totalD = clientTransactions
      .filter((t) => t.type === 'depot')
      .reduce((total, t) => total + (isNaN(Number(t.montant)) ? 0 : Number(t.montant)), 0);
    setTotalDepot(totalD);
    
    // Calculer le total des retraits
    const totalR = clientTransactions
      .filter((t) => t.type === 'retrait')
      .reduce((total, t) => total + (isNaN(Number(t.montant)) ? 0 : Number(t.montant)), 0);
    setTotalRetrait(totalR);

    // Calculer le nombre total de transactions
    setNombreTransactions(clientTransactions.length);

    // Transactions en cours
    const enCours = clientTransactions.filter((t) => t.statut === 'en cours').length;
    setTransactionsEnCours(enCours);

    // Transactions terminées
    const terminees = clientTransactions.filter((t) => (t.statut === 'terminé' || t.statut === 'réussi')).length;
    setTransactionsTerminees(terminees);

    // Transactions échouées
    const echouees = clientTransactions.filter((t) => t.statut === 'échoué').length;
    setTransactionsEchoue(echouees);
  }
}, [client, transactions]); // Exécuter à chaque changement de `client` ou `transactions`


  if (!client || !transactions) return <div className="text-white text-center py-10">Client non trouvé ou données non disponibles</div>;

  // Fonction pour formater les montants
  const formatMontant = (montant) => {
    return new Intl.NumberFormat('fr-FR').format(montant);
  };

  return (
    <div className="w-full">
      {/* En-tête de la page */}
      <div className="mb-8 flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-white mb-2">Détails du client: {client.nom}</h1>
          <p className="text-gray-400">Consultez les informations et transactions de ce client</p>
        </div>
        <Link to="/admin/listClients">
          <button className="flex items-center gap-2 bg-bleu text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition">
            <FaArrowLeft /> Retour à la liste
          </button>
        </Link>
      </div>

      {/* Cartes de statistiques */}
      <div className="grid grid-cols-1 md:grid-cols-6 gap-4 mb-8">
        <div className="bg-gris rounded-xl p-4 shadow-lg flex items-center justify-between">
          <div>
            <p className="text-gray-400 text-xs">Total Transactions</p>
            <h3 className="text-white text-xl font-bold">{nombreTransactions}</h3>
          </div>
          <div className="bg-bleu bg-opacity-10 p-2 rounded-lg">
            <FaExchangeAlt className="text-bleu" />
          </div>
        </div>
        
        <div className="bg-gris rounded-xl p-4 shadow-lg flex items-center justify-between">
          <div>
            <p className="text-gray-400 text-xs">Dépôts</p>
            <h3 className="text-white text-xl font-bold">{formatMontant(totalDepot)}</h3>
          </div>
          <div className="bg-vert bg-opacity-10 p-2 rounded-lg">
            <FaDownload className="text-vert" />
          </div>
        </div>
        
        <div className="bg-gris rounded-xl p-4 shadow-lg flex items-center justify-between">
          <div>
            <p className="text-gray-400 text-xs">Retraits</p>
            <h3 className="text-white text-xl font-bold">{formatMontant(totalRetrait)}</h3>
          </div>
          <div className="bg-softRed bg-opacity-10 p-2 rounded-lg">
            <FaUpload className="text-softRed" />
          </div>
        </div>
        
        <div className="bg-gris rounded-xl p-4 shadow-lg flex items-center justify-between">
          <div>
            <p className="text-gray-400 text-xs">En cours</p>
            <h3 className="text-white text-xl font-bold">{transactionsEnCours}</h3>
          </div>
          <div className="bg-softYellow bg-opacity-10 p-2 rounded-lg">
            <FaHourglass className="text-softYellow" />
          </div>
        </div>
        
        <div className="bg-gris rounded-xl p-4 shadow-lg flex items-center justify-between">
          <div>
            <p className="text-gray-400 text-xs">Terminées</p>
            <h3 className="text-white text-xl font-bold">{transactionsTerminees}</h3>
          </div>
          <div className="bg-softPurple bg-opacity-10 p-2 rounded-lg">
            <FaCheck className="text-softPurple" />
          </div>
        </div>
        
        <div className="bg-gris rounded-xl p-4 shadow-lg flex items-center justify-between">
          <div>
            <p className="text-gray-400 text-xs">Échouées</p>
            <h3 className="text-white text-xl font-bold">{transactionsEchoue}</h3>
          </div>
          <div className="bg-softRed bg-opacity-10 p-2 rounded-lg">
            <MdError className="text-softRed" />
          </div>
        </div>
      </div>

      {/* Informations client */}
      <div className="mb-8 bg-gris rounded-xl p-6 shadow-lg">
        <h2 className="text-xl font-bold text-white mb-4">Informations du client</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div>
            <p className="text-gray-400 text-sm">Nom</p>
            <p className="text-white font-medium">{client.nom || 'Non renseigné'}</p>
          </div>
          <div>
            <p className="text-gray-400 text-sm">Téléphone</p>
            <p className="text-white font-medium">{client.tel || 'Non renseigné'}</p>
          </div>
          <div>
            <p className="text-gray-400 text-sm">Email</p>
            <p className="text-white font-medium">{client.email || 'Non renseigné'}</p>
          </div>
          <div>
            <p className="text-gray-400 text-sm">ID du client</p>
            <p className="text-white font-medium">{client.id || 'Non renseigné'}</p>
          </div>
          <div>
            <p className="text-gray-400 text-sm">Date d'inscription</p>
            <p className="text-white font-medium">{client.dateCreationCompte ? new Date(client.dateCreationCompte).toLocaleDateString() : 'Non renseigné'}</p>
          </div>
        </div>
      </div>

      <TransactionTable transactions={transactionsAgent} formatMontant={formatMontant} />
    </div>
  );
};

// Component for Transaction Table
const TransactionTable = ({ transactions, formatMontant }) => (
  <div className="bg-gris rounded-xl shadow-lg overflow-hidden">
    <div className="px-6 py-4 border-b border-gray-700">
      <h2 className="text-xl font-bold text-white">
        Transactions du client
      </h2>
    </div>

    {transactions.length > 0 ? (
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-700">
          <thead className="bg-noir">
            <tr>
              {['#', 'ID Transaction', 'Compte ID', 'Moyen de Paiement', 'Montant', 'Date', 'Statut', 'Type'].map((header) => (
                <th key={header} className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-700">
            {transactions.map((transaction, index) => (
              <tr key={transaction.idTransaction} className="hover:bg-noir transition-colors">
                <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                  {index + 1}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                  {transaction.idTransaction || '-'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                  {transaction.idCompte1xbet || '-'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                  {transaction.moyenPaiement || '-'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                  <span className={transaction.type === 'depot' ? 'text-vert' : 'text-softRed'}>
                    {formatMontant(transaction.montant)} F
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                  {new Date(transaction.dateHeure).toLocaleString()}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  <span className={`px-3 py-1.5 rounded-full text-xs font-medium ${
                    transaction.statut === 'en cours' 
                        ? 'bg-softYellow bg-opacity-20 text-white border border-softYellow' 
                        : transaction.statut === 'terminé' || transaction.statut === 'réussi'
                            ? 'bg-vert bg-opacity-20 text-white border border-vert'
                            : transaction.statut === 'échoué'
                                ? 'bg-softRed bg-opacity-20 text-white border border-softRed'
                                : 'bg-softYellow bg-opacity-20 text-white border border-softYellow'
                  }`}>
                    {transaction.statut.charAt(0).toUpperCase() + transaction.statut.slice(1)}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  <span className={`px-3 py-1.5 rounded-full text-xs font-medium ${
                    transaction.type === 'depot' 
                        ? 'bg-vert bg-opacity-20 text-white border border-vert' 
                        : 'bg-softRed bg-opacity-20 text-white border border-softRed'
                  }`}>
                    {transaction.type.charAt(0).toUpperCase() + transaction.type.slice(1)}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ) : (
      <div className="flex flex-col items-center justify-center gap-2 text-center py-10">
        <FaRegSadCry className="text-4xl text-gray-500" />
        <p className="text-lg text-gray-400">
          Aucune transaction trouvée pour ce client.
        </p>
      </div>
    )}
  </div>
);

export default ClientDetails;
