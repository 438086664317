import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faArrowUp,
  faArrowDown,
  faWallet,
  faHomeAlt,
  faFilter,
  faExchangeAlt,
  faHistory,
  faSliders,
  faTimes
} from "@fortawesome/free-solid-svg-icons";

import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";

import Tabs from "./MyTabs";
import InfosContext from "../../contexts/InfosAppProvider";
import Filtres from "./Filtres";
import Filtrespc from "./Filtrespc";
import { ReactComponent as Coin } from "../../animation/filter.svg";
import { useNavigate } from "react-router-dom";

// Couleurs personnalisées
const colors = {
  noir: "#1C1C1CFF",
  bleu: "#0087BFFF",
  vert: "#28C281FF",
  softRed: "#ff6f61",
  softYellow: "#f9d57d",
  softPurple: "#b19cd9",
  black: "#1C1C1CFF",
  gris: "#272728FF",
};

export default function IndexTransaction() {
  const [vide, setVide] = useState(false);
  const navigate = useNavigate();
  const [isLargeScreen, setIsLargeScreen] = useState(false);
  const [animateHeader, setAnimateHeader] = useState(false);

  // Effet pour animer le header au chargement
  useEffect(() => {
    setAnimateHeader(true);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1024); 
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const {
    userInfos,
    transactions,
    open,
    setOpen,
    searchTerm,
    setSearchTerm,
  } = useContext(InfosContext);

  useEffect(() => {
    if (!transactions || Object.keys(transactions).length === 0) {
      setVide(true);
    } else {
      console.log("Transactions n'est pas vide.");
      setVide(false);
    }
  }, [transactions]);

  const handleDismiss = () => {
    setOpen(false);
  };

  // Composant pour l'état vide
  const EmptyState = ({ isMobile = true }) => (
    <div className={`mt-10 ${isMobile ? "px-6" : "max-w-xl mx-auto"}`}>
      <div className="bg-gradient-to-br from-[#1C1C1CFF] to-[#272728FF] rounded-xl shadow-xl p-8 flex flex-col items-center text-center">
        <div className="w-20 h-20 bg-[#0087BFFF] rounded-full flex items-center justify-center mb-6 shadow-lg">
          <FontAwesomeIcon icon={faExchangeAlt} className="text-white text-2xl" />
        </div>
        
        <h2 className="text-[#0087BFFF] text-2xl font-bold mb-3">
          Aucune transaction
        </h2>
        
        <p className="text-gray-300 mb-6">
          Vous n'avez pas encore effectué de transaction sur votre compte Payforbet.
        </p>
        
        <button 
          onClick={() => navigate("/clients/espace")}
          className="px-6 py-3 bg-[#0087BFFF] text-white rounded-lg flex items-center justify-center gap-2 hover:bg-[#0075a5] transition-all duration-300 shadow-md"
        >
          <FontAwesomeIcon icon={faHomeAlt} />
          <span>Retour à l'accueil</span>
        </button>
      </div>
    </div>
  );

  // Composant Header
  const Header = () => (
    <div className={`px-4 py-5 bg-gradient-to-r from-[#1C1C1CFF] to-[#272728FF] mb-4 ${animateHeader ? 'animate-fadeIn' : ''}`}>
      <div className="max-w-xl mx-auto">
        <div className="flex justify-between items-center mb-4">
          <div>
            <h1 className="text-white text-2xl font-bold">Transactions</h1>
            <p className="text-gray-400 text-sm">
              {!vide 
                ? `${Object.keys(transactions).length} transaction${Object.keys(transactions).length > 1 ? 's' : ''}`
                : "Aucune transaction"
              }
            </p>
          </div>
          
          <div className="flex gap-3">
            <button 
              onClick={() => setOpen(true)}
              className="w-10 h-10 bg-[#0087BFFF] rounded-lg flex items-center justify-center shadow-md hover:bg-[#0075a5] transition-all duration-300"
            >
              <FontAwesomeIcon icon={faSliders} className="text-white" />
            </button>
            
            <button 
              className="w-10 h-10 bg-[#272728FF] rounded-lg flex items-center justify-center border border-[#333333] hover:border-[#0087BFFF] transition-all duration-300"
            >
              <FontAwesomeIcon icon={faHistory} className="text-white" />
            </button>
          </div>
        </div>
        
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <FontAwesomeIcon icon={faSearch} className="text-gray-500" />
          </div>
          
          <input
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="bg-[#272728FF] w-full pl-10 pr-4 py-3 rounded-xl border border-[#333333] text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-[#0087BFFF] transition-all"
            placeholder="Rechercher une transaction..."
            type="text"
          />
          
          {searchTerm && (
            <button 
              onClick={() => setSearchTerm('')}
              className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500 hover:text-white"
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          )}
        </div>
      </div>
    </div>
  );

  // Composant pour le filtre mobile
  const MobileFilterSheet = () => (
    <BottomSheet
      open={open}
      onDismiss={handleDismiss}
      snapPoints={({ minHeight }) => [minHeight, 335]}
    >
      <div className="bg-[#1C1C1CFF] p-4 rounded-t-xl">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-white text-lg font-bold">Filtrer les transactions</h2>
          <button 
            onClick={handleDismiss}
            className="w-8 h-8 flex items-center justify-center rounded-full bg-[#272728FF]"
          >
            <FontAwesomeIcon icon={faTimes} className="text-white" />
          </button>
        </div>
        <Filtres onClose={handleDismiss} />
      </div>
    </BottomSheet>
  );
  
  // Composant pour le filtre desktop
  const DesktopFilter = () => (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80">
      <div className="bg-[#1C1C1CFF] max-w-2xl w-full mx-4 rounded-xl shadow-2xl p-6 animate-fadeIn">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-white text-xl font-bold">Filtrer les transactions</h2>
          <button 
            onClick={handleDismiss}
            className="w-8 h-8 flex items-center justify-center rounded-full bg-[#272728FF] hover:bg-[#333333] transition-colors"
          >
            <FontAwesomeIcon icon={faTimes} className="text-white" />
          </button>
        </div>
        <Filtrespc />
      </div>
    </div>
  );

  return (
    <div className="bg-[#1C1C1CFF] min-h-screen pb-16 md:pl-5 lg:pl-10">
      {/* En-tête différent selon taille d'écran */}
      {isLargeScreen ? (
        <div className="bg-gradient-to-r from-[#1C1C1CFF] via-[#272728FF] to-[#1C1C1CFF] py-8 mb-8">
          <div className="w-full max-w-5xl mx-auto px-6">
            <div className="flex flex-wrap items-center justify-between gap-8">
              <div className="max-w-lg">
                <h1 className="text-white text-3xl font-bold mb-2">Historique des transactions</h1>
                <p className="text-gray-400">
                  {!vide 
                    ? `${Object.keys(transactions).length} transaction${Object.keys(transactions).length > 1 ? 's' : ''} disponible${Object.keys(transactions).length > 1 ? 's' : ''}`
                    : "Aucune transaction disponible pour le moment"}
                </p>
              </div>
              
              <div className="flex-grow max-w-xl">
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                    <FontAwesomeIcon icon={faSearch} className="text-gray-500" />
                  </div>
                  <input
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="bg-[#1C1C1CFF] w-full pl-12 pr-12 py-4 rounded-xl border border-[#333333] text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-[#0087BFFF] transition-all text-lg"
                    placeholder="Rechercher une transaction..."
                    type="text"
                  />
                  {searchTerm ? (
                    <button 
                      onClick={() => setSearchTerm('')}
                      className="absolute inset-y-0 right-0 flex items-center pr-4 text-gray-500 hover:text-white"
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  ) : (
                    <button 
                      onClick={() => setOpen(true)}
                      className="absolute inset-y-0 right-0 flex items-center pr-4 text-[#0087BFFF] hover:text-white"
                    >
                      <FontAwesomeIcon icon={faSliders} />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Header />
      )}
      
      {vide ? (
        <EmptyState isMobile={!isLargeScreen} />
      ) : (
        <div className={isLargeScreen ? "md:max-w-4xl lg:max-w-5xl mx-auto px-6" : "max-w-xl mx-auto px-4"}>
          {isLargeScreen && (
            <div className="bg-gradient-to-br from-[#272728FF] to-[#1C1C1CFF] p-6 rounded-xl border border-[#333333] mb-6 shadow-lg">
              <div className="grid grid-cols-3 gap-6">
                <div className="bg-[#1C1C1CFF] rounded-lg p-4 border-l-4 border-[#0087BFFF]">
                  <div className="flex items-center gap-4">
                    <div className="w-12 h-12 bg-[#0087BFFF] rounded-lg flex items-center justify-center">
                      <FontAwesomeIcon icon={faExchangeAlt} className="text-white" />
                    </div>
                    <div>
                      <h3 className="text-white font-medium">Toutes</h3>
                      <p className="text-gray-400 text-sm">{Object.keys(transactions).length} transactions</p>
                    </div>
                  </div>
                </div>
                
                <div className="bg-[#1C1C1CFF] rounded-lg p-4 border-l-4 border-[#28C281FF]">
                  <div className="flex items-center gap-4">
                    <div className="w-12 h-12 bg-[#28C281FF] rounded-lg flex items-center justify-center">
                      <FontAwesomeIcon icon={faArrowDown} className="text-white" />
                    </div>
                    <div>
                      <h3 className="text-white font-medium">Dépôts</h3>
                      <p className="text-gray-400 text-sm">
                        {Object.values(transactions).filter(t => t.type === "depot").length} dépôts
                      </p>
                    </div>
                  </div>
                </div>
                
                <div className="bg-[#1C1C1CFF] rounded-lg p-4 border-l-4 border-[#ff6f61]">
                  <div className="flex items-center gap-4">
                    <div className="w-12 h-12 bg-[#ff6f61] rounded-lg flex items-center justify-center">
                      <FontAwesomeIcon icon={faArrowUp} className="text-white" />
                    </div>
                    <div>
                      <h3 className="text-white font-medium">Retraits</h3>
                      <p className="text-gray-400 text-sm">
                        {Object.values(transactions).filter(t => t.type === "retrait").length} retraits
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          
          <Tabs />
        </div>
      )}
      
      {open && (
        isLargeScreen ? <DesktopFilter /> : <MobileFilterSheet />
      )}
      
      {/* Bas de page avec informations utiles */}
      {!vide && (
        <div className={isLargeScreen ? "md:max-w-4xl lg:max-w-5xl mx-auto px-6 mt-8 mb-16" : "max-w-xl mx-auto px-4 mt-6 mb-16"}>
          <div className="bg-gradient-to-r from-[#272728FF] to-[#1C1C1CFF] p-4 rounded-lg border border-[#333333] shadow-md">
            <div className="flex items-start gap-3">
              <FontAwesomeIcon icon={faWallet} className="text-[#0087BFFF] mt-1" />
              <div>
                <h3 className="text-[#0087BFFF] font-medium mb-1">Information</h3>
                <p className="text-gray-400 text-sm">
                  Cliquez sur une transaction pour en voir tous les détails. 
                  Utilisez les filtres pour affiner votre recherche par type, date ou statut.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}