import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { 
  HomeIcon, 
  CogIcon, 
  ArrowPathIcon, 
  QuestionMarkCircleIcon,
  UserCircleIcon,
  ChevronLeftIcon
} from '@heroicons/react/24/outline';

const DesktopSidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeItem, setActiveItem] = useState('/agents/');
  const [collapsed, setCollapsed] = useState(false);
  const [userInfo, setUserInfo] = useState({
    name: "Agent",
    role: "Agent de service",
    avatar: null
  });

  useEffect(() => {
    setActiveItem(location.pathname);
  }, [location.pathname]);

  const menuItems = [
    {
      icon: <HomeIcon className="w-6 h-6" />,
      label: "Accueil",
      path: "/agents/",
    },
    {
      icon: <ArrowPathIcon className="w-6 h-6" />,
      label: "Transactions",
      path: "/agents/transactions",
    },
    {
      icon: <QuestionMarkCircleIcon className="w-6 h-6" />,
      label: "Aide",
      path: "/agents/aide",
    },
    {
      icon: <CogIcon className="w-6 h-6" />,
      label: "Paramètres",
      path: "/agents/setting",
    },
  ];

  return (
    <div className={`fixed left-0 top-0 h-full bg-noir border-r border-gris transition-all duration-300 ease-in-out z-50 ${collapsed ? "w-20" : "w-64"}`}>
      {/* Toggle collapse button */}
      <button 
        onClick={() => setCollapsed(!collapsed)}
        className="absolute right-0 top-20 bg-gris rounded-l-md p-1 transform translate-x-1/2"
      >
        <ChevronLeftIcon className={`w-5 h-5 text-gray-400 transition-transform ${collapsed ? "rotate-180" : ""}`} />
      </button>
      
      {/* Profile section */}
      <div className="flex items-center p-4 border-b border-gris">
        {userInfo.avatar ? (
          <img src={userInfo.avatar} alt="Profile" className="w-10 h-10 rounded-full" />
        ) : (
          <UserCircleIcon className="w-10 h-10 text-gray-400" />
        )}
        
        {!collapsed && (
          <div className="ml-3">
            <p className="font-medium text-white">{userInfo.name}</p>
            <p className="text-sm text-gray-400">{userInfo.role}</p>
          </div>
        )}
      </div>
      
      {/* Menu items */}
      <div className="py-4">
        {menuItems.map((item, index) => (
          <button
            key={index}
            onClick={() => {
              navigate(item.path);
              setActiveItem(item.path);
            }}
            className={`flex items-center w-full p-3 ${collapsed ? "justify-center" : "px-6"} ${
              activeItem === item.path 
                ? "bg-gris text-bleu border-l-2 border-bleu" 
                : "text-gray-400 hover:bg-gris hover:bg-opacity-50"
            }`}
          >
            <div>{item.icon}</div>
            {!collapsed && <span className="ml-3">{item.label}</span>}
          </button>
        ))}
      </div>
      
      {/* App version or branding at the bottom */}
      <div className="absolute bottom-4 left-0 right-0 text-center text-gray-500 text-xs">
        {!collapsed && "PayForBet v2.0"}
      </div>
    </div>
  );
};

export default DesktopSidebar;