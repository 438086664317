import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import InfosContext from "../../contexts/InfosAppProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGift, faCheck, faClock, faExclamationTriangle, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import axios from "axios";
import Swal from "sweetalert2";

const BonusList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(66);
  const [bonusReclames, setBonusReclames] = useState({});
  
  const { historiqueBonus, userInfos, token, fetchUserInfos, id } = useContext(InfosContext);
  
  // Pour afficher le mois en texte
  const getMonthName = (monthNumber) => {
    const months = [
      "Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
      "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"
    ];
    return months[monthNumber - 1] || "";
  };
  
  // Formater la date
  const formatDate = (timestamp) => {
    if (!timestamp) return "N/A";
    const date = new Date(timestamp);
    return `${date.getDate()} ${getMonthName(date.getMonth() + 1)} ${date.getFullYear()}`;
  };
  
  // Fonction pour faire une demande de bonus
  const demanderBonus = async (bonusId = null, userId = null) => {
    setLoading(true);
    console.log("demanderBonus appelé avec bonusId:", bonusId, "userId:", userId);
    
    // Si un ID de bonus spécifique est fourni, c'est une réclamation d'un bonus existant
    // Sinon, c'est une demande normale de bonus
    const estReclamation = bonusId !== null;
    
    // Pour une demande normale, vérifie si déjà une demande en cours
    if (!estReclamation) {
      // Vérifier si l'utilisateur a déjà une demande en cours
      const demandeEnCours = historiqueBonus && historiqueBonus.some(
        bonus => bonus.idUser === userInfos.id && bonus.statut !== "payé" && bonus.statut !== "refusé"
      );
      
      if (demandeEnCours) {
        Swal.fire({
          title: "Demande déjà en cours",
          text: "Vous avez déjà une demande de bonus en cours de traitement.",
          icon: "warning",
          confirmButtonText: "OK",
          confirmButtonColor: "#0087BFFF"
        });
        setLoading(false);
        return;
      }
    }
    
    try {
      const baseURL = process.env.REACT_APP_BASE_URL;
      // Utiliser le chemin complet avec v1/client/bonus
      const endpoint = estReclamation ? "reclamer-bonus" : "demande-bonus";

      // Utiliser l'ID utilisateur passé en paramètre ou chercher dans d'autres sources si non disponible
      const clientId = userId || id || (userInfos && userInfos.id) || (userInfos && userInfos._id);
      console.log("ID utilisateur utilisé:", clientId);
      console.log("URL:", `${baseURL}${endpoint}`, "Data:", { idBonus: bonusId, idUser: clientId });
      
      const postData = estReclamation 
        ? { idBonus: bonusId, idUser: clientId }
        : { idUser: clientId };
      
      const response = await axios.post(`${baseURL}${endpoint}`, postData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      if (response.data && !response.data.error) {
        Swal.fire({
          title: estReclamation ? "Bonus réclamé" : "Demande envoyée",
          text: estReclamation 
            ? "Votre bonus a été réclamé avec succès et sera reversé sur votre compte Mobile Money!" 
            : "Votre demande de bonus a été envoyée avec succès!",
          icon: "success",
          confirmButtonText: "OK",
          confirmButtonColor: "#28C281FF"
        });
        
        // Mettre à jour instantanément l'interface pour améliorer l'expérience utilisateur
        if (estReclamation && bonusId) {
          // Mettre à jour l'état local pour que l'interface se rafraîchisse immédiatement
          setBonusReclames(prev => ({
            ...prev,
            [bonusId]: true
          }));
          console.log(`Bonus ${bonusId} marqué comme réclamé dans l'interface`);
        }

        // Recharger les données utilisateur complètes pour mettre à jour la liste des bonus
        const idToUse = clientId || (userInfos && userInfos.id);
        console.log("Rafraîchissement des données utilisateur avec ID:", idToUse);
        fetchUserInfos(idToUse, { forceFetch: true });
      } else {
        Swal.fire({
          title: "Erreur",
          text: response.data.message || "Une erreur est survenue lors de la demande.",
          icon: "error",
          confirmButtonText: "OK",
          confirmButtonColor: "#ff6f61"
        });
      }
    } catch (error) {
      console.error("Erreur lors de la demande de bonus:", error);
      Swal.fire({
        title: "Erreur",
        text: "Une erreur est survenue lors de la demande. Veuillez réessayer plus tard.",
        icon: "error",
        confirmButtonText: "OK",
        confirmButtonColor: "#ff6f61"
      });
    } finally {
      setLoading(false);
    }
  };
  
  // Obtenir le statut et la couleur correspondante
  const getStatusInfo = (status) => {
    switch (status) {
      case "payé":
        return { 
          color: "white", 
          bgColor: "bg-[#28C281FF]", 
          icon: faCheck,
          text: "Payé"
        };
      case "en attente":
        return { 
          color: "black", 
          bgColor: "bg-[#f9d57d]", 
          icon: faClock,
          text: "En attente"
        };
      case "refusé":
        return { 
          color: "white", 
          bgColor: "bg-[#ff6f61]", 
          icon: faExclamationTriangle,
          text: "Refusé"
        };
      default:
        return { 
          color: "white", 
          bgColor: "bg-[#0087BFFF]", 
          icon: faClock,
          text: status
        };
    }
  };
  
  // Vérifier si l'utilisateur a une demande en cours
  const demandeEnCours = historiqueBonus && historiqueBonus.some(
    bonus => bonus.idUser === userInfos?.id && bonus.statut !== "payé" && bonus.statut !== "refusé"
  );

  return (
    <>
      {/* Version Mobile */}
      <div className="bg-[#1C1C1CFF] min-h-screen flex flex-col pt-4 px-4 pb-20 md:hidden">
        <div className="flex items-center justify-center mb-6">
          <div className="bg-[#f9d57d] bg-opacity-20 p-2 rounded-full">
            <FontAwesomeIcon icon={faGift} className="text-[#f9d57d] text-xl" />
          </div>
          <h1 className="text-white text-2xl font-bold ml-3">Mes Bonus</h1>
        </div>
        
        {/* Carte Info */}
        <div className="bg-[#272728FF] rounded-xl p-5 mb-6 shadow-lg">
          <h2 className="text-white text-lg font-medium mb-3">Comment ça marche ?</h2>
          <p className="text-gray-300 text-sm mb-3">
            Chaque mois, un bonus est calculé sur vos dépôts. Si l'administrateur valide ce bonus, 
            vous pouvez le réclamer ici pour qu'il soit reversé sur votre compte Mobile Money défini à l'inscription.
          </p>
          <div className="flex items-center mt-4 text-sm">
            <FontAwesomeIcon icon={faCheck} className="text-[#28C281FF] mr-2" />
            <p className="text-gray-300">Réclamez votre bonus mensuel quand il est disponible</p>
          </div>
        </div>
        
        {/* Bouton de demande */}
        <div className="mb-6">
          <button
            onClick={demanderBonus}
            disabled={loading || demandeEnCours}
            className={`w-full py-3 px-4 rounded-xl font-medium flex items-center justify-center text-white ${
              demandeEnCours ? "bg-gray-600" : "bg-gradient-to-r from-[#0087BFFF] to-[#f9d57d]"
            }`}
          >
            {loading ? (
              <div className="w-5 h-5 mr-2 animate-spin">
                <CircularProgressbar
                  value={progress}
                  styles={buildStyles({
                    pathColor: "white",
                    trailColor: "rgba(255,255,255,0.2)",
                    strokeLinecap: "round",
                  })}
                />
              </div>
            ) : (
              <FontAwesomeIcon icon={demandeEnCours ? faClock : faPaperPlane} className="mr-2" />
            )}
            <span>
              {loading ? "Traitement..." : demandeEnCours ? "Demande déjà en cours" : "Réclamer mon bonus"}
            </span>
          </button>
        </div>
        
        {/* Liste des bonus */}
        <h2 className="text-white text-lg font-medium mb-4">Historique des bonus </h2>
        
        {!historiqueBonus || historiqueBonus.length === 0 ? (
          <div className="bg-[#272728FF] rounded-xl p-5 text-center">
            <p className="text-gray-400">Aucun historique de bonus disponible</p>
          </div>
        ) : (
          <div className="space-y-4">
            {historiqueBonus.map((bonus) => {
              const statusInfo = getStatusInfo(bonus.statut);
              
              return (
                <div key={bonus.id} className="bg-[#272728FF] rounded-xl p-4 shadow-lg">
                  <div className="flex justify-between items-center mb-3">
                    <div className="flex items-center">
                      <FontAwesomeIcon icon={faGift} className="text-[#f9d57d] mr-2" />
                      <span className="text-white font-medium">
                        Bonus {getMonthName(bonus.mois)} {bonus.annee}
                      </span>
                    </div> 
                    {bonus.statut === "en attente" && bonus.demandeFaitParClient === false && !bonusReclames[bonus.id] ? (
                      <button 
                        onClick={() => demanderBonus(bonus.id, bonus.idUser || userInfos.id || id)}
                        className="bg-[#28C281FF] px-3 py-1 rounded-full flex items-center text-white text-xs font-medium"
                      >
                        <FontAwesomeIcon icon={faGift} className="text-xs mr-2" />
                        Réclamer
                      </button>
                    ) : (
                      <div className={`${statusInfo.bgColor} px-3 py-1 rounded-full flex items-center`}>
                        <FontAwesomeIcon icon={statusInfo.icon} className={`text-xs mr-2`} style={{ color: statusInfo.color }} />
                        <span className="text-xs font-medium" style={{ color: statusInfo.color }}>{statusInfo.text}</span>
                      </div>
                    )}
                  </div>
                  
                  <div className="grid grid-cols-2 gap-3 text-sm">
                    <div>
                      <p className="text-gray-400">Montant</p>
                      <p className="text-white font-medium">{bonus.montant} F CFA {bonus.id} hh</p>
                    </div>
                    {bonus.dateApprobation && (
                      <div>
                        <p className="text-gray-400">Date de paiement</p>
                        <p className="text-white">{formatDate(bonus.dateApprobation)}</p>
                      </div>
                    )}
                    <div>
                      <p className="text-gray-400">Date de demande</p>
                      <p className="text-white">{formatDate(bonus.dateCreation)}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      
      {/* Version Desktop */}
      <div className="bg-[#1C1C1CFF] min-h-screen hidden md:flex flex-col py-10 pl-72 pr-8">
        <div className="flex items-center mb-8">
          <div className="bg-[#f9d57d] bg-opacity-20 p-3 rounded-full">
            <FontAwesomeIcon icon={faGift} className="text-[#f9d57d] text-2xl" />
          </div>
          <h1 className="text-white text-3xl font-bold ml-3">Mes Bonus</h1>
        </div>
        
        <div className="grid grid-cols-3 gap-6 mb-8">
          {/* Carte Info */}
          <div className="col-span-2 bg-gradient-to-br from-[#272728FF] to-[#1C1C1CFF] rounded-2xl p-6 shadow-lg border border-gray-800">
            <h2 className="text-white text-xl font-medium mb-4">Comment fonctionnent les bonus ?</h2>
            <p className="text-gray-300 mb-4">
              À la fin de chaque mois, un bonus est calculé automatiquement en fonction de vos dépôts.
              Si l'administrateur valide ce bonus, vous pourrez le réclamer ici
              pour qu'il soit immédiatement reversé sur votre compte Mobile Money enregistré lors de votre inscription.
            </p>
            
            <div className="grid grid-cols-2 gap-4 mt-6">
              <div className="flex items-center">
                <div className="w-8 h-8 rounded-full bg-[#28C281FF] bg-opacity-10 flex items-center justify-center mr-3">
                  <FontAwesomeIcon icon={faCheck} className="text-[#28C281FF]" />
                </div>
                <p className="text-gray-300">Bonus basé sur vos dépôts mensuels</p>
              </div>
              <div className="flex items-center">
                <div className="w-8 h-8 rounded-full bg-[#0087BFFF] bg-opacity-10 flex items-center justify-center mr-3">
                  <FontAwesomeIcon icon={faClock} className="text-[#0087BFFF]" />
                </div>
                <p className="text-gray-300">Transfert sur votre compte Mobile Money</p>
              </div>
            </div>
          </div>
          
          {/* Carte Action */}
          <div className="bg-gradient-to-br from-[#272728FF] to-[#1C1C1CFF] rounded-2xl p-6 shadow-lg border border-gray-800 flex flex-col justify-between">
            <div>
              <h2 className="text-white text-xl font-medium mb-3">Réclamer mon bonus</h2>
              <p className="text-gray-400 text-sm mb-6">
                {demandeEnCours 
                  ? "Vous avez déjà une demande en cours. Veuillez attendre qu'elle soit traitée."
                  : "Réclamez votre bonus validé pour qu'il soit reversé sur votre compte Mobile Money."}
              </p>
            </div>
            
            <button
              onClick={()=>{
                //demanderBonus
              }}
              disabled={loading || demandeEnCours}
              className={`py-3 px-4 rounded-xl font-medium flex items-center justify-center text-white ${
                demandeEnCours ? "bg-gray-600" : "bg-gradient-to-r from-[#0087BFFF] to-[#f9d57d]"
              }`}
            >
              {loading ? (
                <div className="w-5 h-5 mr-2 animate-spin">
                  <CircularProgressbar
                    value={progress}
                    styles={buildStyles({
                      pathColor: "white",
                      trailColor: "rgba(255,255,255,0.2)",
                      strokeLinecap: "round",
                    })}
                  />
                </div>
              ) : (
                <FontAwesomeIcon icon={demandeEnCours ? faClock : faPaperPlane} className="mr-2" />
              )}
              <span>
                {loading ? "Traitement..." : demandeEnCours ? "Demande en cours" : "Réclamer mon bonus"}
              </span>
            </button>
          </div>
        </div>
        
        {/* Historique des bonus */}
        <div className="bg-gradient-to-br from-[#272728FF] to-[#1C1C1CFF] rounded-2xl p-6 shadow-lg border border-gray-800">
          <h2 className="text-white text-xl font-medium mb-6">Historique des bonus</h2>
          
          {!historiqueBonus || historiqueBonus.length === 0 ? (
            <div className="text-center py-8">
              <FontAwesomeIcon icon={faGift} className="text-gray-600 text-5xl mb-4" />
              <p className="text-gray-400">Aucun historique de bonus disponible</p>
            </div>
          ) : (
            <div className="overflow-x-auto">
              <table className="w-full text-left">
                <thead>
                  <tr className="border-b border-gray-700">
                    <th className="pb-4 text-gray-400 font-medium">Période</th>
                    <th className="pb-4 text-gray-400 font-medium">Montant ici</th>
                    <th className="pb-4 text-gray-400 font-medium">Date de demande</th>
                    <th className="pb-4 text-gray-400 font-medium">Date de paiement</th>
                    <th className="pb-4 text-gray-400 font-medium">Statut</th>
                  </tr>
                </thead>
                <tbody>
                  {historiqueBonus.map((bonus) => {
                    const statusInfo = getStatusInfo(bonus.statut);
                    
                    return (
                      <tr key={bonus.id} className="border-b border-gray-800">
                        <td className="py-4 text-white">
                          {getMonthName(bonus.mois)} {bonus.annee}
                        </td>
                        <td className="py-4 text-white font-medium">
                          {bonus.montant}  F CFA
                        </td>
                        <td className="py-4 text-gray-300">
                          {formatDate(bonus.dateCreation)}
                        </td>
                        <td className="py-4 text-gray-300">
                          {bonus.dateApprobation ? formatDate(bonus.dateApprobation) : "—"}
                        </td>
                        <td className="py-4">
                          {bonus.statut === "en attente" && bonus.demandeFaitParClient === false && !bonusReclames[bonus.id] ? (
                            <button 
                              onClick={() => demanderBonus(bonus.id, bonus.idUser || userInfos.id || id)}
                              className="bg-[#28C281FF] inline-flex items-center px-3 py-1.5 rounded-full shadow-sm text-white text-xs font-medium"
                            >
                              <FontAwesomeIcon icon={faGift} className="text-xs mr-2" />
                              Réclamer
                            </button>
                          ) : (
                            <div className={`${statusInfo.bgColor} inline-flex items-center px-3 py-1.5 rounded-full shadow-sm`}>
                              <FontAwesomeIcon icon={statusInfo.icon} className="text-xs mr-2" style={{ color: statusInfo.color }} />
                              <span className="text-xs font-medium" style={{ color: statusInfo.color }}>{statusInfo.text}</span>
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BonusList;