import React, { useState, useEffect } from 'react';
import { FiX, FiCheck, FiAlertCircle, FiDownload, FiSearch, FiFilter } from 'react-icons/fi';
import { FaCoins, FaUsersCog, FaSpinner, FaMoneyBillWave, FaUsers, FaCalendarAlt } from 'react-icons/fa';
import { useContext } from 'react';
import InfosContext from '../../contexts/InfosAppProvider';
// Si InfosContext n'existe pas, nous pouvons essayer avec AdminInfosAppProvider
// import AdminInfosContext from '../../contexts/admin/AdminInfosAppProvider';

const BonusPreview = ({ previewData, onApprove, onCancel, loading, approvalLoading }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [minAmount, setMinAmount] = useState(0);
  
  // Utiliser try/catch pour gérer le cas où InfosContext n'est pas disponible
  let userInfos = null;
  try {
    const ctx = useContext(InfosContext);
    userInfos = ctx?.userInfos;
  } catch (error) {
    console.warn("InfosContext non disponible:", error);
  }
  
  // État pour la pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  
  // Fonction pour formater les montants
  const formatMontant = (montant) => {
    if (!montant && montant !== 0) return '0';
    return new Intl.NumberFormat('fr-FR').format(montant);
  };
  
  // Si les données ne sont pas disponibles, afficher un message
  if (!previewData) {
    return (
      <div className="bg-gris rounded-xl p-8 text-center">
        <FaSpinner className="animate-spin text-bleu text-4xl mx-auto mb-4" />
        <div className="text-xl text-white">Chargement des données...</div>
        <div className="text-gray-400 mt-2">Veuillez patienter pendant le calcul des bonus</div>
      </div>
    );
  }
  
  // Filtrer les utilisateurs selon la recherche et le montant minimum
  const filteredUsers = previewData.detailsUtilisateurs
    .filter(user => 
      (user.nom.toLowerCase().includes(searchTerm.toLowerCase()) || 
      (user.numeroMomo && user.numeroMomo.includes(searchTerm))) &&
      user.montantBonus >= minAmount
    );
  
  // Obtenir les utilisateurs pour la page actuelle
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstItem, indexOfLastItem);
  
  // Calcul du nombre de pages
  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);
  
  // Fonction pour changer de page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  
  return (
    <div className="bg-gris rounded-xl shadow-lg overflow-hidden">
      {/* En-tête avec résumé */}
      <div className="p-6 border-b border-gray-700">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold text-white">
            Aperçu des bonus à approuver - {previewData.mois}/{previewData.annee}
          </h2>
          <button 
            onClick={onCancel}
            className="text-gray-400 hover:text-white p-2 rounded-full hover:bg-gray-700"
          >
            <FiX size={20} />
          </button>
        </div>
        
        <div className="bg-noir rounded-lg p-4 mb-6 border-l-4 border-softYellow">
          <div className="flex items-center text-softYellow">
            <FiAlertCircle size={20} className="mr-2" />
            <span className="font-semibold">Important</span>
          </div>
          <p className="text-gray-300 text-sm mt-1">
            Cette prévisualisation vous permet de vérifier les bonus avant leur approbation. Une fois approuvés, les clients pourront demander le retrait de ces montants.
          </p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
          <div className="bg-noir rounded-lg p-4 flex items-center justify-between">
            <div>
              <div className="text-gray-400 text-xs">Clients éligibles</div>
              <div className="text-white text-xl font-bold">{previewData.totalUtilisateursAvecTransactions}</div>
              <div className="text-gray-500 text-xs">{previewData.totalUtilisateursActifs} clients actifs au total</div>
            </div>
            <div className="bg-bleu bg-opacity-20 p-3 rounded-full">
              <FaUsers className="text-bleu" />
            </div>
          </div>
          
          <div className="bg-noir rounded-lg p-4 flex items-center justify-between">
            <div>
              <div className="text-gray-400 text-xs">Volume des dépôts</div>
              <div className="text-white text-xl font-bold">{formatMontant(previewData.totalDepots)} F</div>
              <div className="text-gray-500 text-xs">Dépôts du mois {previewData.mois}/{previewData.annee}</div>
            </div>
            <div className="bg-softYellow bg-opacity-20 p-3 rounded-full">
              <FaMoneyBillWave className="text-softYellow" />
            </div>
          </div>
          
          <div className="bg-noir rounded-lg p-4 flex items-center justify-between">
            <div>
              <div className="text-gray-400 text-xs">Taux de bonus</div>
              <div className="text-white text-xl font-bold">{previewData.tauxBonus}%</div>
              <div className="text-gray-500 text-xs">Taux appliqué sur les dépôts</div>
            </div>
            <div className="bg-softPurple bg-opacity-20 p-3 rounded-full">
              <FaCalendarAlt className="text-softPurple" />
            </div>
          </div>
          
          <div className="bg-noir rounded-lg p-4 flex items-center justify-between border-l-4 border-vert">
            <div>
              <div className="text-gray-400 text-xs">Total des bonus</div>
              <div className="text-white text-xl font-bold">{formatMontant(previewData.totalBonusCalcule)} F</div>
              <div className="text-vert text-xs">Montant à approuver</div>
            </div>
            <div className="bg-vert bg-opacity-20 p-3 rounded-full">
              <FaCoins className="text-vert" />
            </div>
          </div>
        </div>
        
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 mb-4">
          <div className="flex-1 flex flex-col md:flex-row gap-4">
            <div className="relative flex-1">
              <input
                type="text"
                placeholder="Rechercher un client par nom ou numéro..."
                className="w-full py-2 pl-10 pr-4 bg-noir border border-gray-700 rounded-lg text-white text-sm focus:outline-none focus:border-bleu"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <FiSearch className="absolute left-3 top-2.5 text-gray-400" />
            </div>
            
            <div className="flex items-center gap-2">
              <FiFilter className="text-gray-400" />
              <span className="text-gray-400 text-sm whitespace-nowrap">Montant min:</span>
              <input
                type="number"
                min="0"
                step="100"
                value={minAmount}
                onChange={(e) => setMinAmount(Number(e.target.value))}
                className="w-24 py-2 px-3 bg-noir border border-gray-700 rounded-lg text-white text-sm focus:outline-none focus:border-bleu"
              />
            </div>
          </div>
          
          <div className="flex gap-3">
            <button
              onClick={onCancel}
              className="px-4 py-2 bg-gray-700 text-white rounded-lg flex items-center gap-2 hover:bg-gray-600 transition-colors"
              disabled={loading || approvalLoading}
            >
              <FiX /> Annuler
            </button>
            <button
              onClick={onApprove}
              className="px-4 py-2 bg-vert text-white rounded-lg flex items-center gap-2 hover:bg-opacity-80 transition-colors"
              disabled={loading || approvalLoading}
            >
              {approvalLoading ? (
                <>
                  <FaSpinner className="animate-spin" /> Approbation en cours...
                </>
              ) : (
                <>
                  <FiCheck /> Approuver tous les bonus ({formatMontant(previewData.totalBonusCalcule)} F)
                </>
              )}
            </button>
          </div>
        </div>
      </div>
      
      {/* Liste des utilisateurs et leurs bonus */}
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-700">
          <thead className="bg-noir">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Client</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Numéro</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Dépôts</th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-400 uppercase tracking-wider">Montant bonus</th>
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">Détails</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-700 bg-gris">
            {currentUsers.length > 0 ? (
              currentUsers.map((user, index) => (
                <tr key={user.idUser} className={index % 2 === 0 ? "bg-noir bg-opacity-30" : ""}>
                  <td className="px-6 py-3 whitespace-nowrap text-sm text-white">
                    {user.nom}
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-300">
                    {user.numeroMomo || 'Non spécifié'}
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap text-sm">
                    <div className="text-white">{formatMontant(user.totalDepots)} F</div>
                    <div className="text-gray-400 text-xs">{user.nombreDepots} dépôt(s)</div>
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap text-sm text-right font-semibold text-vert">
                    {formatMontant(user.montantBonus)} F
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap text-sm text-center">
                    <span className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-bleu bg-opacity-10 text-bleu">
                      {previewData.tauxBonus}% appliqué
                    </span>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="px-6 py-8 text-center text-gray-400">
                  {searchTerm || minAmount > 0 ? 'Aucun client ne correspond à votre recherche' : 'Aucun client avec des bonus à approuver'}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      
      {/* Pagination */}
      {filteredUsers.length > itemsPerPage && (
        <div className="bg-noir px-6 py-3 flex justify-between items-center">
          <div className="text-gray-400 text-sm">
            Affichage de {indexOfFirstItem + 1}-{Math.min(indexOfLastItem, filteredUsers.length)} sur {filteredUsers.length} résultats
          </div>
          <div className="flex gap-1">
            <button
              onClick={() => paginate(Math.max(1, currentPage - 1))}
              disabled={currentPage === 1}
              className="px-3 py-1 bg-gray-800 text-white rounded hover:bg-gray-700 disabled:opacity-50"
            >
              Précédent
            </button>
            {[...Array(Math.min(5, totalPages)).keys()].map(i => {
              // Calculer le numéro de page à afficher en fonction de la page actuelle
              let pageToShow;
              if (totalPages <= 5) {
                pageToShow = i + 1;
              } else if (currentPage <= 3) {
                pageToShow = i + 1;
              } else if (currentPage >= totalPages - 2) {
                pageToShow = totalPages - 4 + i;
              } else {
                pageToShow = currentPage - 2 + i;
              }
              
              return (
                <button
                  key={pageToShow}
                  onClick={() => paginate(pageToShow)}
                  className={`px-3 py-1 rounded ${
                    currentPage === pageToShow 
                      ? 'bg-bleu text-white' 
                      : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
                  }`}
                >
                  {pageToShow}
                </button>
              );
            })}
            <button
              onClick={() => paginate(Math.min(totalPages, currentPage + 1))}
              disabled={currentPage === totalPages}
              className="px-3 py-1 bg-gray-800 text-white rounded hover:bg-gray-700 disabled:opacity-50"
            >
              Suivant
            </button>
          </div>
        </div>
      )}
      
      {/* Avertissement et approbation finale */}
      <div className="p-4 bg-softYellow bg-opacity-10 border-t border-softYellow text-white flex items-center justify-between">
        <div className="flex items-center gap-2 text-softYellow">
          <FiAlertCircle />
          <span className="text-sm">L'approbation est définitive. Les clients pourront demander le retrait de leurs bonus.</span>
        </div>
        
        <button
          onClick={onApprove}
          className="px-4 py-2 bg-vert text-white rounded-lg flex items-center gap-2 hover:bg-opacity-80 transition-colors"
          disabled={loading || approvalLoading}
        >
          {approvalLoading ? (
            <>
              <FaSpinner className="animate-spin" /> Approbation...
            </>
          ) : (
            <>
              <FiCheck /> Approuver
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default BonusPreview;