import React, { useContext, useEffect, useState } from "react";
import { FaCheck, FaTimes, FaCheckDouble, FaExclamationTriangle } from "react-icons/fa";
import { FiX } from "react-icons/fi";
//import InfosContextAdmin from "../../contexts/admin/AdminInfosAppProvider";

import InfosContext from "../../contexts/InfosAppProvider";

import { updateRetraitAffiliation } from "./updateRetraitAffiliation";
import { useParams, Link } from "react-router-dom";

import { action } from "./action";
const RetraitDetail = () => {
  const { id } = useParams();
  const { listeUser, token, allRetraitAffiliations } = useContext(InfosContext);
  const agent = Object.values(listeUser).find((user) => user.id == id);
  
  // Rechercher le retrait correspondant à cet agent
  const retrait = allRetraitAffiliations ? 
    Object.values(allRetraitAffiliations).find(r => r.idUtilisateur == id) : null;
  
  // État pour stocker le statut
  const [status, setStatus] = useState(retrait ? retrait.statut : "");
  // Notification
  const [notification, setNotification] = useState(null);
  
  // Fonction pour afficher une notification
  const showNotification = (type, message) => {
    setNotification({ type, message });
    // Fermer automatiquement après 5 secondes
    setTimeout(() => setNotification(null), 5000);
  };
  
  useEffect(() => {
    if (retrait) {
      setStatus(retrait.statut);
    }
  }, [retrait]);

  return (
    <div className="max-w-3xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden mt-1 relative">
      {/* Notification */}
      {notification && (
        <div className={`fixed top-4 right-4 z-50 p-4 rounded-lg shadow-lg flex items-center justify-between ${
          notification.type === 'success' ? 'bg-green-500' : 
          notification.type === 'error' ? 'bg-red-500' : 
          notification.type === 'warning' ? 'bg-yellow-500' : 'bg-blue-500'
        } text-white max-w-md animate-fade-in-down`}>
          <div className="flex items-center">
            {notification.type === 'success' && <FaCheckDouble className="mr-3 text-xl" />}
            {notification.type === 'error' && <FaExclamationTriangle className="mr-3 text-xl" />}
            {notification.type === 'warning' && <FaExclamationTriangle className="mr-3 text-xl" />}
            <p>{notification.message}</p>
          </div>
          <button 
            onClick={() => setNotification(null)}
            className="ml-4 p-1 rounded-full hover:bg-white hover:bg-opacity-20 transition-colors"
          >
            <FiX className="text-white" />
          </button>
        </div>
      )}
      
      <div className="bg-gradient-to-r from-blue-500 to-blue-600 ">
        <h2 className="text-white text-3xl font-bold text-center">
          Détails de l'Agent
        </h2>
      </div>

      <div className="p-8 space-y-2">
        <DetailRow label="Nom" value={agent.nom} />
        <DetailRow label="Email" value={agent.email} />
        <DetailRow label="WhatsApp" value={agent.whatsapp} />
        <DetailRow label="Pays" value={agent.pays} />

        <DetailRow
          label="Solde Disponible"
          value={`${agent.soldeDisponible} FCFA`}
        />

        <DetailRow
          label="Numéro MoMo Réception"
          value={agent.numeroMomo || "Non fourni"}
        />

        <DetailRow label="Nom MoMo" value={agent.nomMoMo || "Non fourni"} />
        
        {retrait && (
          <DetailRow 
            label="Statut actuel" 
            value={
              <span className={`px-2 py-1 rounded-full text-xs ${
                status === "terminé" 
                  ? 'bg-green-100 text-green-600' 
                  : 'bg-yellow-100 text-yellow-600'
              }`}>
                {status === "terminé" ? 'Terminé' : status || 'En attente'}
              </span>
            } 
          />
        )}
      </div>

      <div className="bg-gray-50 p-6 flex justify-evenly">
        {status !== "terminé" && status !== "rejeté" && (
          <ActionButton
            label="Approuver"
            icon={<FaCheck />}
            color="bg-green-500"
            hoverColor="hover:bg-green-600"
            onClick={() => {
              // Afficher un état de chargement
              showNotification('warning', "Approbation en cours...");
              
              // Pour le débogage, afficher tous les champs disponibles du retrait
              console.log("Tous les champs du retrait:", retrait);
              
              // Utiliser l'ID du retrait et non l'ID de l'utilisateur
              // Le retrait est l'objet qui contient le retrait d'affiliation
              const idRetrait = retrait ? retrait.id : null;
              
              console.log("Début de l'approbation, Détails:", {
                idDuRetrait: idRetrait,
                idUtilisateur: retrait ? retrait.idUtilisateur : id,
                statut: retrait ? retrait.statut : null
              });
              
              if (!idRetrait) {
                showNotification('error', "Impossible de trouver l'ID du retrait. Veuillez réessayer.");
                return;
              }
              
              updateRetraitAffiliation(idRetrait, "terminé", token)
                .then((response) => {
                  console.log("Réponse reçue:", response);
                  
                  if (response && response.error) {
                    // Si l'API renvoie une erreur
                    showNotification('error', response.message || "Erreur lors de l'approbation du retrait.");
                  } else {
                    setStatus("terminé");
                    showNotification('success', "Retrait approuvé avec succès!");
                  }
                  action();
                })
                .catch((err) => {
                  console.error("Erreur catch lors de l'approbation:", err);
                  showNotification('error', "Erreur lors de la communication avec le serveur. Veuillez réessayer.");
                  action();
                });
            }}
          />
        )}

        {status !== "rejeté" && (
          <ActionButton
            label="Rejeter"
            icon={<FaTimes />}
            color="bg-red-500"
            hoverColor="hover:bg-red-600"
            onClick={() => {
              // Afficher un état de chargement
              showNotification('warning', "Rejet en cours...");
              
              // Pour le débogage, afficher tous les champs disponibles du retrait
              console.log("Tous les champs du retrait:", retrait);
              
              // Utiliser l'ID du retrait et non l'ID de l'utilisateur
              const idRetrait = retrait ? retrait.id : null;
              
              console.log("Début du rejet, Détails:", {
                idDuRetrait: idRetrait,
                idUtilisateur: retrait ? retrait.idUtilisateur : id,
                statut: retrait ? retrait.statut : null
              });
              
              if (!idRetrait) {
                showNotification('error', "Impossible de trouver l'ID du retrait. Veuillez réessayer.");
                return;
              }
              
              updateRetraitAffiliation(idRetrait, "rejeté", token)
                .then((response) => {
                  console.log("Réponse reçue:", response);
                  
                  if (response && response.error) {
                    // Si l'API renvoie une erreur
                    showNotification('error', response.message || "Erreur lors du rejet du retrait.");
                  } else {
                    setStatus("rejeté");
                    showNotification('success', "Retrait rejeté avec succès!");
                  }
                  action();
                })
                .catch((err) => {
                  console.error("Erreur catch lors du rejet:", err);
                  showNotification('error', "Erreur lors de la communication avec le serveur. Veuillez réessayer.");
                  action();
                });
            }}
          />
        )}
      </div>
    </div>
  );
};

const DetailRow = ({ label, value }) => (
  <div className="flex justify-between border-b pb-4">
    <span className="text-gray-500 font-medium">{label} :</span>
    <span className="text-gray-900">{value}</span>
  </div>
);

const ActionButton = ({ label, icon, color, hoverColor, onClick }) => (
  <button
    className={`${color} ${hoverColor} text-white px-8 py-1 rounded-lg flex items-center gap-2 transition-all duration-300`}
    onClick={onClick}
  >
    {icon}
    {label}
  </button>
);

export default RetraitDetail;
