import React, { useState, useEffect, useContext, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faEnvelope,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";

import checks from "../ressources/images/check.png";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css"; // Importation des styles

import sendCodeToEmail from "../requtes/visiteurs/sendCodeToEmail";
import authentification from "../requtes/visiteurs/authentification";

import { codeInvalide } from "./alert/codeInvalide";

import { Link, useNavigate } from "react-router-dom";

import InfosContext from "../contexts/InfosAppProvider";

import logo from "../ressources/images/logo.png";

const Authentification = () => {
  const navigate = useNavigate();
  const [inputFocused, setInputFocused] = useState(false);
  const [loading, setLoading] = useState(false); // Etat pour indiquer si le chargement est en cours
  const [progress, setProgress] = useState(66);
  const [minutes, setMinutes] = useState(3); // Par exemple 1 minute
  const [seconds, setSeconds] = useState(0); // Par exemple 30 secondes
  const [isTimeUp, setIsTimeUp] = useState(false); // Pour savoir si le temps est écoulé
  const [valide, setValide] = useState(false);
  
  const [otp, setOtp] = useState(["", "", "", "", "", ""]); // État contenant le code
  const inputsRef = useRef([]);

  // Référence pour savoir si une soumission automatique a déjà été effectuée
  const hasAutoSubmittedDesktop = useRef(false);
  const codeCompleteDesktop = useRef(false);

  useEffect(() => {
    const completeCode = otp.join(""); // Joindre les chiffres pour obtenir le code complet
    
    if (completeCode.length === 6) {
      console.log("Code complet saisi desktop:", completeCode);
      setValide(true);
      
      // Si code complet et pas déjà marqué comme complet
      if (!codeCompleteDesktop.current) {
        codeCompleteDesktop.current = true;
        
        // Soumettre automatiquement le formulaire UNIQUEMENT si pas déjà fait
        if (!isTimeUp && !loading && !hasAutoSubmittedDesktop.current) {
          hasAutoSubmittedDesktop.current = true; // Marquer comme déjà soumis
          console.log("Soumission automatique desktop programmée");
          
          // Utiliser handleSubmitpc directement au lieu de déclencher un événement de formulaire
          setTimeout(() => {
            if (!loading) {
              console.log("Exécution soumission desktop");
              // Créer un faux événement
              const fakeEvent = { preventDefault: () => {} };
              handleSubmitpc(fakeEvent);
            }
          }, 300);
        }
      }
    } else {
      setValide(false);
      codeCompleteDesktop.current = false; // Réinitialiser si le code change
    }
  }, [otp, isTimeUp, loading]);

  // Gère le changement de chaque input
  const handleChangepc = (index, value) => {
    if (/^\d?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp); // Met à jour l'état avec le nouveau tableau

      if (value && index < 5) {
        inputsRef.current[index + 1].focus(); // Passe au champ suivant si un chiffre est saisi
      }
    }
  };

  // Gère le collage de l'ensemble du code
  const handlePastepc = (event) => {
    const pastedData = event.clipboardData.getData("text").trim();
    if (/^\d{6}$/.test(pastedData)) {
      // Vérifie que le code est bien 6 chiffres
      const newOtp = pastedData.split("");
      setOtp(newOtp); // Met à jour l'état avec les nouveaux chiffres

      // Place le focus sur le dernier champ rempli
      inputsRef.current[5].focus();
    }
  };

  const handleKeyDownpc = (index, event) => {
    if (event.key === "Backspace" && otp[index] === "" && index > 0) {
      inputsRef.current[index - 1].focus(); // Retour au champ précédent
    }
  };

  const email = localStorage.getItem("userEmail");

  const idUser = localStorage.getItem("idUser");

  const {
    infosAdmin,
    setInfosAdmin,
    userInfos,
    setUserInfos,
    transactions,
    setTransactions,
    setListeUser,
    listeCaisses,
    setListeCaisses,
    allRetraitAffiliations,
    setAllRetraitAffiliations,
    soldeCollection,
    setSoldeCollection,
    soldeDisbursement,
    setSoldeDisbursement,
    momoTransactions,
    setMomoTransactions,
  } = useContext(InfosContext);

  const [inputValues, setInputValues] = useState(["", "", "", "", "", ""]);
  // État pour stocker la chaîne concaténée
  const [concatenatedValue, setConcatenatedValue] = useState("");

  // Référence pour savoir si une soumission automatique a déjà été effectuée
  const hasAutoSubmittedMobile = useRef(false);
  const codeCompleteMobile = useRef(false);
  
  useEffect(() => {
    // Vérification si concatenatedValue contient exactement 6 chiffres
    const isSixDigits = /^\d{6}$/.test(concatenatedValue);

    if (isSixDigits) {
      console.log("concatenatedValue contient exactement six chiffres.");
      setValide(true);
      
      // Si code complet et pas déjà marqué comme complet
      if (!codeCompleteMobile.current) {
        codeCompleteMobile.current = true;
        
        // Soumettre automatiquement le formulaire UNIQUEMENT si pas déjà fait
        if (!isTimeUp && !loading && !hasAutoSubmittedMobile.current) {
          hasAutoSubmittedMobile.current = true; // Marquer comme déjà soumis
          console.log("Soumission automatique mobile programmée");
          
          // Utiliser handleSubmit directement au lieu de déclencher un événement de formulaire
          setTimeout(() => {
            if (!loading) {
              console.log("Exécution soumission mobile");
              // Créer un faux événement
              const fakeEvent = { preventDefault: () => {} };
              handleSubmit(fakeEvent);
            }
          }, 300);
        }
      }
    } else {
      console.log("concatenatedValue ne contient pas exactement six chiffres.");
      setValide(false);
      codeCompleteMobile.current = false; // Réinitialiser si le code change
    }
  }, [concatenatedValue, isTimeUp, loading]); // Déclenchement à chaque changement de concatenatedValue

  // Gestion de la saisie d'un chiffre et déplacement vers l'input suivant
  const handleInputChange = (value, index) => {
    if (value.length === 1) {
      // Met à jour la valeur de l'input actuel
      const newInputValues = [...inputValues];
      newInputValues[index] = value;
      setInputValues(newInputValues);

      // Met à jour la valeur concaténée
      setConcatenatedValue(newInputValues.join(""));

      // Déplace le focus vers l'input suivant s'il existe
      if (index < inputValues.length - 1) {
        document.getElementById(`input-${index + 1}`).focus();
      }
    }
  };

  useEffect(() => {
    // Décrémente le compteur chaque seconde
    const timer = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else if (minutes > 0) {
        setMinutes(minutes - 1);
        setSeconds(59);
      } else {
        clearInterval(timer);
        setIsTimeUp(true); // Le temps est écoulé
      }
    }, 1000);

    // Nettoie l'intervalle à la fin
    return () => clearInterval(timer);
  }, [minutes, seconds]);

  /*
  const handlePaste = (event) => {
    const pastedData = event.clipboardData.getData("text").trim();
    if (/^\d{6}$/.test(pastedData)) {
      // Vérifie que le code est bien 6 chiffres
      const newOtp = pastedData.split("");
      //  setOtp(newOtp); // Met à jour l'état avec les nouveaux chiffres
      setInputValues(newOtp);
      // Place le focus sur le dernier champ rempli
      inputsRef.current[5].focus();
    }
  };
  */

  // Gestion de la suppression pour déplacer le focus vers l'input précédent
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      const newInputValues = [...inputValues];
      // Si l'input actuel est vide, aller à l'input précédent
      if (inputValues[index] === "" && index > 0) {
        document.getElementById(`input-${index - 1}`).focus();
      } else {
        // Sinon, on supprime la valeur de l'input actuel
        newInputValues[index] = "";
        setInputValues(newInputValues);
        setConcatenatedValue(newInputValues.join(""));
      }
    }
  };
  const handlePaste = (e, startIndex) => {
    e.preventDefault();
    const pasteData = e.clipboardData
      .getData("text")
      .slice(0, inputValues.length - startIndex);

    // Répartir les chiffres collés dans les champs d'entrée
    const updatedValues = [...inputValues];
    for (let i = 0; i < pasteData.length; i++) {
      updatedValues[startIndex + i] = pasteData[i];
    }

    setInputValues(updatedValues);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Vérifier et éviter les soumissions multiples
    if (loading) {
      console.log("Soumission déjà en cours, abandon");
      return;
    }
    
    console.log("Début de handleSubmit (mobile)");
    
    // Faire la requête API avec les données du formulaire
    setLoading(true);
    
    // Réutiliser cette référence pour éviter des soumissions multiples
    const submissionId = Date.now();
    window.lastSubmissionId = submissionId;

    if (isTimeUp) {
      sendCodeToEmail(email)
        .then((result) => {
          setLoading(false);
          if (result) {
            console.log("Code renvoyé avec succès");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error("Erreur lors de l'envoi du code:", error);
        });
    } else {
      console.log("Authentification avec le code:", concatenatedValue);
      
      try {
        // Utiliser await pour mieux contrôler le flux
        const result = await authentification(idUser, concatenatedValue);
        
        // Vérifier si cette soumission est toujours pertinente
        if (window.lastSubmissionId !== submissionId) {
          console.log("Soumission abandonnée car une nouvelle a été démarrée");
          return;
        }
        
        setLoading(false);
        // Réinitialiser les flags
        hasAutoSubmittedMobile.current = false;
        codeCompleteMobile.current = false;
        
        if (result == null || result.error) {
          // Une seule alerte de code invalide
          await codeInvalide();
        } else {
          localStorage.setItem("token", result.token);
          localStorage.setItem("isAuthenticated", 1);

          // InfosContext
          const role = result.data.userInfos.role;
          if (role == "client") {
            localStorage.setItem("userRole", "client");
            setInfosAdmin(result.data.infosAdmin);
            setUserInfos(result.data.userInfos);
            setTransactions(result.data.transactions);
            navigate("/clients/espace");
          } else if (role == "agent") {
            localStorage.setItem("userRole", "agent");
            navigate("/agents/");
          } else if (role == "admin") {
            localStorage.setItem("userRole", "admin");
            setInfosAdmin(result.data.infosAdmin);
            setUserInfos(result.data.userInfos);
            setTransactions(result.data.transactions);
            setListeUser(result.data.listeUser);
            setListeCaisses(result.data.listeCaisses);
            setAllRetraitAffiliations(result.data.allRetraitAffiliations);
            navigate("/admin/");
          } else if (role == "assistant") {
            console.log("Authentification assistant (mobile): début du traitement");
            setUserInfos(result.data.userInfos);
            
            // Sauvegarder le rôle dans localStorage pour la persistance
            localStorage.setItem("userRole", "assistant");
            
            // Récupérer les données spécifiques à l'assistant
            if (result.data.soldeCollection) {
              setSoldeCollection(result.data.soldeCollection);
              console.log("Mobile: solde collection chargé");
            }
            if (result.data.soldeDisbursement) {
              setSoldeDisbursement(result.data.soldeDisbursement);
              console.log("Mobile: solde disbursement chargé");
            }
            if (result.data.momoTransactions) {
              setMomoTransactions(result.data.momoTransactions);
              console.log("Mobile: transactions chargées");
            }
            
            // Utiliser setTimeout pour s'assurer que la redirection se produit après le traitement des états
            // Délai plus long pour s'assurer que tout est bien chargé avant la redirection
            setTimeout(() => {
              console.log("Mobile: redirection vers /assistant/");
              navigate("/assistant/");
            }, 300);
          }
        }
      } catch (error) {
        // Vérifier si cette soumission est toujours pertinente
        if (window.lastSubmissionId !== submissionId) {
          console.log("Erreur ignorée car soumission abandonnée");
          return;
        }
        
        setLoading(false);
        // Réinitialiser les flags
        hasAutoSubmittedMobile.current = false;
        codeCompleteMobile.current = false;
        
        // Une seule alerte d'erreur
        codeInvalide();
        console.error("Erreur d'authentification:", error);
      }
    }
  };

  const handleSubmitpc = async (e) => {
    e.preventDefault();
    
    // Vérifier et éviter les soumissions multiples
    if (loading) {
      console.log("Soumission PC déjà en cours, abandon");
      return;
    }
    
    console.log("Début de handleSubmitpc (desktop)");
    
    // Faire la requête API avec les données du formulaire
    setLoading(true);
    
    // Réutiliser cette référence pour éviter des soumissions multiples
    const submissionId = Date.now();
    window.lastSubmissionId = submissionId;

    if (isTimeUp) {
      sendCodeToEmail(email)
        .then((result) => {
          setLoading(false);
          if (result) {
            console.log("Code renvoyé avec succès (desktop)");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error("Erreur lors de l'envoi du code (desktop):", error);
        });
    } else {
      const completeCode = otp.join("");
      console.log("Authentification desktop avec le code:", completeCode);
      
      try {
        // Utiliser await pour mieux contrôler le flux
        const result = await authentification(idUser, completeCode);
        
        // Vérifier si cette soumission est toujours pertinente
        if (window.lastSubmissionId !== submissionId) {
          console.log("Soumission desktop abandonnée car une nouvelle a été démarrée");
          return;
        }
        
        setLoading(false);
        // Réinitialiser les flags
        hasAutoSubmittedDesktop.current = false;
        codeCompleteDesktop.current = false;
        
        if (result == null || result.error) {
          // Une seule alerte de code invalide
          await codeInvalide();
        } else {
          localStorage.setItem("token", result.token);
          localStorage.setItem("isAuthenticated", 1);

          // InfosContext
          const role = result.data.userInfos.role;
          if (role == "client") {
            localStorage.setItem("userRole", "client");
            setInfosAdmin(result.data.infosAdmin);
            setUserInfos(result.data.userInfos);
            setListeUser(result.data.listeUser);

            let transformedData = result.data.transactions;
            let reversedTransactions = {};
            let keys = Object.keys(transformedData).reverse();

            keys.forEach((key) => {
              reversedTransactions[key] = transformedData[key];
            });

            setTransactions(reversedTransactions);
            navigate("/clients/espace");
          } else if (role == "agent") {
            localStorage.setItem("userRole", "agent");
            setUserInfos(result.data.userInfos);
            setListeUser(result.data.listeUser);

            let transformedData = {};
            Object.keys(result.data.transactions).forEach((idCaisse) => {
              result.data.transactions[idCaisse].forEach((transaction) => {
                const idTransaction = transaction.idTransaction;
                transformedData[idTransaction] = { ...transaction };
              });
            });

            let reversedTransactions = {};
            let keys = Object.keys(transformedData).reverse();

            keys.forEach((key) => {
              reversedTransactions[key] = transformedData[key];
            });

            setTransactions(reversedTransactions);
            navigate("/agents/");
          } else if (role == "admin") {
            localStorage.setItem("userRole", "admin");
            setInfosAdmin(result.data.infosAdmin);
            setUserInfos(result.data.userInfos);
            setTransactions(result.data.transactions);
            setListeUser(result.data.listeUser);
            setListeCaisses(result.data.listeCaisses);
            setAllRetraitAffiliations(result.data.allRetraitAffiliations);
            navigate("/admin/");
          } else if (role == "assistant") {
            console.log("Authentification assistant (desktop): début du traitement");
            setUserInfos(result.data.userInfos);
            
            // Sauvegarder le rôle dans localStorage pour la persistance
            localStorage.setItem("userRole", "assistant");
            
            // Récupérer les données spécifiques à l'assistant
            if (result.data.soldeCollection) {
              setSoldeCollection(result.data.soldeCollection);
              console.log("Desktop: solde collection chargé");
            }
            if (result.data.soldeDisbursement) {
              setSoldeDisbursement(result.data.soldeDisbursement);
              console.log("Desktop: solde disbursement chargé");
            }
            if (result.data.momoTransactions) {
              setMomoTransactions(result.data.momoTransactions);
              console.log("Desktop: transactions chargées");
            }
            
            // Utiliser setTimeout pour s'assurer que la redirection se produit après le traitement des états
            setTimeout(() => {
              console.log("Desktop: redirection vers /assistant/");
              navigate("/assistant/");
            }, 300);
          }
        }
      } catch (error) {
        // Vérifier si cette soumission est toujours pertinente
        if (window.lastSubmissionId !== submissionId) {
          console.log("Erreur desktop ignorée car soumission abandonnée");
          return;
        }
        
        setLoading(false);
        // Réinitialiser les flags
        hasAutoSubmittedDesktop.current = false;
        codeCompleteDesktop.current = false;
        
        // Une seule alerte d'erreur
        codeInvalide();
        console.error("Erreur d'authentification desktop:", error);
      }
    }
  };

  // Composant bouton réutilisable
  const ActionButton = ({ onClick, isTimeUp, isSubmit = false, className = '' }) => (
    <button
      type={isSubmit ? "submit" : "button"}
      onClick={onClick}
      disabled={!isTimeUp && !valide && !loading}
      className={`h-14 w-full max-w-xs mx-auto flex justify-center items-center text-white rounded-xl transition-all duration-300 ${className} ${
        !isTimeUp && !valide && !loading
          ? "bg-gray-600 cursor-not-allowed opacity-70"
          : "bg-bleu shadow-lg shadow-bleu/20 hover:bg-blue transform hover:translate-y-[-2px]"
      }`}
    >
      {loading ? (
        <div className="w-8 h-8 mr-2">
          <CircularProgressbar
            value={progress}
            styles={buildStyles({
              pathColor: "white",
              trailColor: "rgba(255,255,255,0.2)",
              strokeLinecap: "round",
            })}
          />
        </div>
      ) : isTimeUp ? (
        <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
      ) : (
        <FontAwesomeIcon
          icon={faCheck}
          className="mr-2"
        />
      )}

      <span className="text-white font-medium">
        {loading 
          ? "Vérification..." 
          : isTimeUp 
            ? "Renvoyer le code" 
            : "Valider le code"
        }
      </span>
    </button>
  );

  // Fonction pour gérer le renvoi du code
  const handleResendCode = () => {
    setLoading(true);
    sendCodeToEmail(email)
      .then((result) => {
        setLoading(false);
        setMinutes(3);
        setSeconds(0);
        setIsTimeUp(false);
      })
      .catch((error) => {
        setLoading(false);
        setMinutes(3);
        setSeconds(0);
        setIsTimeUp(false);
        console.error("Erreur lors du renvoi du code:", error);
      });
  };

  return (
    <div className="min-h-screen bg-noir flex items-center justify-center p-4">
      <div className="w-full max-w-md">
        {/* VERSION MOBILE */}
        <div className="md:hidden w-full">
          <div className="bg-gris rounded-2xl shadow-xl overflow-hidden">
            {/* HEADER */}
            <div className="bg-bleu p-5 flex flex-col items-center relative overflow-hidden">
              <div className="absolute top-0 right-0 w-32 h-32 bg-vert/10 rounded-full -translate-x-10 -translate-y-10 z-0"></div>
              <div className="absolute bottom-0 left-0 w-24 h-24 bg-softPurple/10 rounded-full translate-x-4 translate-y-4 z-0"></div>
              
              <div className="w-20 h-20 bg-white/10 rounded-full p-3 mb-3 flex items-center justify-center z-10">
                <img
                  src={logo}
                  alt="Logo Payforbet"
                  className="w-full h-full object-contain"
                />
              </div>
              <h1 className="text-white text-xl font-bold mb-1 z-10">Vérification</h1>
              <p className="text-white/80 text-sm text-center z-10">
                Un code de vérification a été envoyé à <span className="font-bold text-white">{email || "votre email"}</span>
              </p>
            </div>
            
            {/* CONTENU */}
            <div className="p-5">
              <form id="mobile-form" onSubmit={handleSubmit}>
                <div className="mb-6">
                  <p className="text-white text-center text-sm mb-4">
                    Entrez le code à 6 chiffres reçu par email. Vérifiez aussi votre dossier Spams.
                  </p>
                  
                  {/* TIMER */}
                  <div className={`flex justify-center items-center gap-2 mb-6`}>
                    <div className={`px-4 py-2 ${isTimeUp ? "bg-softRed/20" : "bg-vert/20"} rounded-lg`}>
                      <span className={`font-mono font-bold text-lg ${isTimeUp ? "text-softRed" : "text-vert"}`}>
                        {isTimeUp
                          ? "Expiré"
                          : `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`}
                      </span>
                    </div>
                  </div>
                  
                  {/* INPUTS */}
                  <div className="flex justify-center gap-2 mb-8">
                    {inputValues.map((value, index) => (
                      <div key={index}>
                        <input
                          required
                          id={`input-${index}`}
                          className="w-12 h-14 text-center bg-noir text-white font-bold text-xl rounded-lg border-2 border-bleu/30 focus:border-bleu focus:ring-2 focus:ring-bleu/20 shadow-inner outline-none transition-all"
                          type="number"
                          inputMode="numeric"
                          pattern="\d*"
                          value={value}
                          maxLength={1}
                          onChange={(e) => handleInputChange(e.target.value, index)}
                          onKeyDown={(e) => handleKeyDown(e, index)}
                          onPaste={(e) => handlePaste(e, index)}
                        />
                      </div>
                    ))}
                  </div>
                  
                  {/* BOUTON */}
                  {isTimeUp 
                    ? <ActionButton onClick={handleResendCode} isTimeUp={true} />
                    : <ActionButton isSubmit={true} isTimeUp={false} />
                  }
                </div>
              </form>
            </div>
          </div>
        </div>
        
        {/* VERSION DESKTOP */}
        <div className="hidden md:block bg-gris rounded-2xl shadow-2xl overflow-hidden border-t-4 border-bleu">
          <div className="p-8">
            <div className="flex flex-col items-center mb-8">
              <div className="w-24 h-24 bg-bleu/10 rounded-full p-4 mb-4 flex items-center justify-center relative">
                <div className="absolute inset-0 bg-bleu/10 rounded-full animate-pulse"></div>
                <img
                  src={logo}
                  alt="Logo"
                  className="w-full h-full object-contain z-10"
                />
              </div>
              
              <h1 className="text-2xl font-bold text-white mb-2">Vérification de sécurité</h1>
              <p className="text-gray-400 text-center max-w-sm">
                Un code à 6 chiffres a été envoyé à <span className="text-white font-semibold">{email || "votre email"}</span>. 
                Veuillez le saisir ci-dessous.
              </p>
            </div>
            
            <form id="desktop-form" onSubmit={handleSubmitpc} className="space-y-8">
              {/* TIMER */}
              <div className="flex justify-center">
                <div className={`flex items-center justify-center ${isTimeUp ? "bg-softRed/10" : "bg-vert/10"} px-6 py-2 rounded-full`}>
                  <span className={`font-mono text-lg font-bold ${isTimeUp ? "text-softRed" : "text-vert"}`}>
                    {isTimeUp
                      ? "Code expiré"
                      : `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`}
                  </span>
                </div>
              </div>
              
              {/* INPUTS */}
              <div className="flex justify-center gap-4">
                {otp.map((value, index) => (
                  <div key={index}>
                    <input
                      type="text"
                      maxLength="1"
                      value={value}
                      onChange={(e) => handleChangepc(index, e.target.value)}
                      onKeyDown={(e) => handleKeyDownpc(index, e)}
                      onPaste={handlePastepc}
                      ref={(el) => (inputsRef.current[index] = el)}
                      className="w-14 h-16 text-center bg-noir text-white text-2xl font-bold border-2 border-bleu/30 focus:border-bleu focus:ring-2 focus:ring-bleu/20 rounded-lg outline-none transition-all shadow-inner"
                    />
                  </div>
                ))}
              </div>
              
              {/* BOUTON */}
              <div className="flex justify-center">
                {isTimeUp 
                  ? <ActionButton 
                      onClick={handleResendCode} 
                      isTimeUp={true}
                      className="px-8"
                    />
                  : <ActionButton 
                      isSubmit={true} 
                      isTimeUp={false}
                      className="px-8"
                    />
                }
              </div>
              
              <div className="text-center text-gray-500 text-sm">
                Si vous n'avez pas reçu de code, vérifiez votre dossier spam ou attendez l'expiration du délai pour demander un nouveau code.
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Authentification;
