import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { firebaseConfig } from "./FirebaseConfig";
import { database, ref, set } from "./FirebaseConfig";

// Initialiser Firebase s'il n'est pas déjà initialisé
let firebaseApp;
try {
  firebaseApp = initializeApp(firebaseConfig);
} catch (error) {
  // Si Firebase est déjà initialisé, utilisez l'instance existante
  console.log("Firebase déjà initialisé");
}

// Initialiser Firebase Cloud Messaging
const messaging = getMessaging(firebaseApp);

// Fonction pour demander la permission et obtenir le token FCM
export const requestPermissionAndGetToken = async (vapidKey) => {
  try {
    // Demander la permission de notification au navigateur
    const permission = await Notification.requestPermission();
    
    if (permission === "granted") {
      try {
        // Obtenir le token FCM
        const currentToken = await getToken(messaging, {
          vapidKey: vapidKey, // VAPID key de la console Firebase
        });

        if (currentToken) {
          console.log("Token FCM obtenu:", currentToken);
          
          // Vous pouvez stocker ce token dans votre base de données pour envoyer des notifications
          saveTokenToDatabase(currentToken);
          
          return currentToken;
        } else {
          console.log("Impossible d'obtenir le token");
          return null;
        }
      } catch (tokenError) {
        console.error("Erreur lors de l'obtention du token:", tokenError);
        return null;
      }
    } else {
      console.log("Permission de notification refusée");
      return null;
    }
  } catch (error) {
    console.error("Erreur lors de la demande de permission:", error);
    return null;
  }
};

// Fonction pour sauvegarder le token dans Firebase
const saveTokenToDatabase = async (token) => {
  try {
    // Récupérer l'ID de l'utilisateur connecté
    const userId = localStorage.getItem("idUser");
    
    if (userId) {
      // Générer un ID unique pour le token basé sur un hachage simple
      const tokenHash = hashToken(token);
      
      // Sauvegarder le token sous l'ID utilisateur dans la collection "fcm"
      await set(ref(database, `fcm/${userId}/${tokenHash}`), {
        token: token,
        platform: getPlatform(),
        device: getDeviceInfo(),
        timestamp: Date.now(),
        active: true
      });
      
      console.log(`Token FCM sauvegardé pour l'utilisateur: ${userId}`);
    } else {
      console.log("Utilisateur non connecté, token non sauvegardé");
    }
  } catch (error) {
    console.error("Erreur lors de la sauvegarde du token:", error);
  }
};

// Fonction pour créer un hash simple du token pour l'utiliser comme clé
const hashToken = (token) => {
  // Prendre une portion du token et remplacer les caractères non-alphanumériques
  return token.substring(token.length - 30)
    .replace(/[^a-zA-Z0-9]/g, '')
    .substring(0, 20);
};

// Obtenir des informations sur la plateforme
const getPlatform = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
  // Détecter mobile ou desktop
  if (/android/i.test(userAgent)) {
    return "android";
  }
  
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "ios";
  }
  
  return "web";
};

// Obtenir des informations basiques sur l'appareil
const getDeviceInfo = () => {
  const userAgent = navigator.userAgent;
  const browserInfo = getBrowserInfo();
  
  return {
    userAgent: userAgent,
    browser: browserInfo.browser,
    browserVersion: browserInfo.version,
    language: navigator.language,
    screenSize: `${window.screen.width}x${window.screen.height}`
  };
};

// Obtenir des informations sur le navigateur
const getBrowserInfo = () => {
  const userAgent = navigator.userAgent;
  let browser = "unknown";
  let version = "unknown";
  
  // Détecter Chrome
  if (/chrome|chromium|crios/i.test(userAgent)) {
    browser = "chrome";
    const chromeMatch = userAgent.match(/(?:chrome|chromium|crios)\/(\d+)/i);
    if (chromeMatch) version = chromeMatch[1];
  } 
  // Détecter Firefox
  else if (/firefox|fxios/i.test(userAgent)) {
    browser = "firefox";
    const firefoxMatch = userAgent.match(/(?:firefox|fxios)\/(\d+)/i);
    if (firefoxMatch) version = firefoxMatch[1];
  }
  // Détecter Safari
  else if (/safari/i.test(userAgent) && !/chrome|chromium|crios/i.test(userAgent)) {
    browser = "safari";
    const safariMatch = userAgent.match(/version\/(\d+)/i);
    if (safariMatch) version = safariMatch[1];
  }
  // Détecter Edge
  else if (/edg/i.test(userAgent)) {
    browser = "edge";
    const edgeMatch = userAgent.match(/edg\/(\d+)/i);
    if (edgeMatch) version = edgeMatch[1];
  }
  
  return { browser, version };
};

// Gérer les messages FCM reçus lorsque l'application est au premier plan
export const onMessageListener = () => {
  // Cette fonction retourne une promesse qui se résout avec le prochain message
  return new Promise((resolve, reject) => {
    try {
      // Vérifier que messaging est disponible
      if (!messaging) {
        reject(new Error("Firebase Messaging n'est pas initialisé"));
        return;
      }

      // Utiliser onMessage pour capturer le prochain message et résoudre la promesse
      const unsubscribe = onMessage(messaging, (payload) => {
        console.log("Message reçu:", payload);
        // Résoudre la promesse avec le payload du message
        resolve(payload);
      });
    } catch (error) {
      console.error("Erreur dans onMessageListener:", error);
      reject(error);
    }
  });
};

// Fonction pour afficher une notification
export const showNotification = (title, body, icon = "/logo192p.png", clickAction = "/") => {
  if (!("Notification" in window)) {
    console.log("Ce navigateur ne prend pas en charge les notifications");
    return;
  }

  if (Notification.permission === "granted") {
    const notification = new Notification(title, {
      body: body,
      icon: icon,
    });

    notification.onclick = function() {
      window.open(clickAction, "_blank");
    };
  }
};