import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faBars, faTimes, faUser } from '@fortawesome/free-solid-svg-icons';

export default function DashboardHeader({ user }) {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem('idUser');
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('token');
    localStorage.removeItem('userRole');
    navigate('/login');
  };

  return (
    <header className="bg-gris py-4 px-4 shadow-lg">
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo et titre */}
        <div className="flex items-center space-x-3">
          <div className="w-10 h-10 rounded-full bg-bleu/20 flex items-center justify-center">
            <span className="text-bleu text-lg font-bold">PB</span>
          </div>
          <div>
            <h1 className="text-white font-bold">Assistant Dashboard</h1>
            <span className="text-xs text-bleu">Gestion de transactions</span>
          </div>
        </div>

        {/* Menu desktop */}
        <div className="hidden md:flex items-center space-x-6">
          <div className="relative group">
            <div className="flex items-center cursor-pointer space-x-2">
              <div className="w-8 h-8 bg-bleu/20 rounded-full flex items-center justify-center">
                <FontAwesomeIcon icon={faUser} className="text-bleu" />
              </div>
              <span className="text-white font-medium">{user.nom.split(' ')[0]}</span>
            </div>
            
            <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-gris border border-bleu/20 invisible group-hover:visible transition-all opacity-0 group-hover:opacity-100">
              <div className="px-4 py-2 text-sm text-gray-400">
                {user.email}
              </div>
              <div className="px-4 py-2 border-t border-bleu/10">
                <button 
                  onClick={handleLogout} 
                  className="flex items-center text-white hover:text-bleu"
                >
                  <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
                  Déconnexion
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Bouton menu mobile */}
        <button 
          className="md:hidden text-white focus:outline-none" 
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <FontAwesomeIcon icon={menuOpen ? faTimes : faBars} />
        </button>
      </div>

      {/* Menu mobile */}
      {menuOpen && (
        <div className="md:hidden absolute top-16 left-0 right-0 bg-gris border-t border-bleu/20 p-4 z-50">
          <div className="flex items-center space-x-3 mb-4">
            <div className="w-10 h-10 bg-bleu/20 rounded-full flex items-center justify-center">
              <FontAwesomeIcon icon={faUser} className="text-bleu" />
            </div>
            <div>
              <p className="text-white font-medium">{user.nom}</p>
              <p className="text-xs text-gray-400">{user.email}</p>
            </div>
          </div>
          
          <button 
            onClick={handleLogout} 
            className="flex items-center w-full py-2 px-3 rounded-md bg-bleu/10 text-white"
          >
            <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
            Déconnexion
          </button>
        </div>
      )}
    </header>
  );
}