import React, { useState, useContext, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faArrowUp,
  faArrowDown,
  faSyncAlt,
  faChartPie,
  faExchangeAlt,
  faFilter,
  faTimes
} from "@fortawesome/free-solid-svg-icons";

import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";

import TransactionCard from "../TransactionCard";
import Tabs from "./MyTabs";
import InfosContext from "../../contexts/InfosAppProvider";
import Filtres from "./Filtres";
import DashboardHeader from "../DashboardHeader";

import { ReactComponent as Coin } from "../../animation/filter.svg";

export default function AgentTransactions() {
  const [vide, setVide] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isLoading, setIsLoading] = useState(true);
  const filterModalRef = useRef(null);

  const {
    transactions,
    setTransactions,
    open,
    setOpen,
    searchTerm,
    setSearchTerm,
    selectedStatuses,
  } = useContext(InfosContext);

  // Effet pour le chargement initial uniquement
  useEffect(() => {
    // Simuler un chargement uniquement au montage initial du composant
    setIsLoading(true);
    const timer = setTimeout(() => {
      if (!transactions || Object.keys(transactions).length === 0) {
        setVide(true);
      } else {
        console.log("Transactions n'est pas vide.");
        setVide(false);
      }
      setIsLoading(false);
    }, 500); // Réduit à 500ms pour une meilleure réactivité
    
    return () => {
      clearTimeout(timer);
    };
    // Dépendance vide [] pour que cela ne s'exécute qu'une seule fois au chargement
  }, []); 
  
  // Effet séparé pour les mises à jour de données
  useEffect(() => {
    // Mise à jour de l'état sans animation de chargement
    if (transactions) {
      setVide(Object.keys(transactions).length === 0);
    }
  }, [transactions]);
  
  // Effet séparé pour la gestion de redimensionnement et des clics
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    
    // Ajouter un gestionnaire de clic global pour fermer la modal desktop
    const handleClickOutside = (event) => {
      if (filterModalRef.current && !filterModalRef.current.contains(event.target) && !isMobile && open) {
        setOpen(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    
    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMobile, open]);

  const handleDismiss = () => {
    setOpen(false);
  };

  const handleRefresh = () => {
    setIsLoading(true);
    // Simuler un rafraîchissement
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  // Desktop filter modal
  const DesktopFilterModal = () => (
    <div className="fixed inset-0 z-50 overflow-y-auto flex items-start justify-center animate-fadeIn" style={{ top: '140px' }}>
      <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm animate-fadeIn" onClick={handleDismiss}></div>
      <div 
        ref={filterModalRef}
        className="relative bg-noir border border-gris rounded-xl shadow-xl w-full max-w-lg mx-auto transform transition-all duration-300 animate-slideDown"
      >
        <div className="flex justify-between items-center p-4 border-b border-gris">
          <h2 className="text-white text-lg font-medium flex items-center">
            <FontAwesomeIcon icon={faFilter} className="mr-2 text-bleu" />
            Filtrer les transactions
          </h2>
          <button 
            onClick={handleDismiss}
            className="text-gray-400 hover:text-white transition-colors h-8 w-8 rounded-full flex items-center justify-center hover:bg-gris/50"
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <div className="p-5">
          <Filtres onClose={handleDismiss} />
        </div>
      </div>
    </div>
  );

  const EmptyState = () => (
    <div className="relative mt-6 overflow-hidden">
      {/* Gradient background effects */}
      <div className="absolute inset-0 bg-gradient-to-br from-bleu/5 to-transparent rounded-3xl"></div>
      <div className="absolute -top-20 -right-20 w-40 h-40 rounded-full bg-bleu/5 blur-3xl"></div>
      <div className="absolute -bottom-10 -left-10 w-40 h-40 rounded-full bg-bleu/5 blur-3xl"></div>
      
      <div className="h-64 mx-auto max-w-md opacity-100 flex flex-col justify-center items-center text-center relative z-10">
        <div className="w-20 h-20 bg-noir rounded-full flex justify-center items-center border-4 border-gris shadow-lg mb-6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 24 24"
            className="text-bleu"
          >
            <path
              fill="currentColor"
              fillRule="evenodd"
              d="M2.07 5.258C2 5.626 2 6.068 2 6.95V14c0 3.771 0 5.657 1.172 6.828S6.229 22 10 22h4c3.771 0 5.657 0 6.828-1.172S22 17.771 22 14v-2.202c0-2.632 0-3.949-.77-4.804a3 3 0 0 0-.224-.225C20.151 6 18.834 6 16.202 6h-.374c-1.153 0-1.73 0-2.268-.153a4 4 0 0 1-.848-.352C12.224 5.224 11.816 4.815 11 4l-.55-.55c-.274-.274-.41-.41-.554-.53a4 4 0 0 0-2.18-.903C7.53 2 7.336 2 6.95 2c-.883 0-1.324 0-1.692.07A4 4 0 0 0 2.07 5.257M13.713 16C14.977 16 16 15.052 16 13.882c0-.927-.643-1.714-1.538-2.001C14.335 10.823 13.366 10 12.191 10c-1.263 0-2.286.948-2.286 2.118c0 .258.05.506.142.735a2 2 0 0 0-.333-.03c-.946 0-1.714.712-1.714 1.589S8.768 16 9.714 16z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <h2 className="text-center text-white text-3xl font-bold mb-2">
          Transactions d'Agent
        </h2>
        <div className="text-center text-gray-300 font-albert mx-2 mb-6">
          Toutes vos transactions d'agent seront listées ici
        </div>
        <button 
          onClick={handleRefresh}
          className="bg-bleu h-12 px-8 flex flex-row justify-center items-center text-center text-white rounded-full border border-[#333333] hover:bg-bleu/90 transition-all duration-300 shadow-lg shadow-bleu/20">
          <FontAwesomeIcon icon={faSyncAlt} className="mr-3" />
          <span className="text-white font-medium">Rafraîchir</span>
        </button>
      </div>
      
      {/* Features section */}
      <div className="max-w-4xl mx-auto mt-12 px-4 pb-12">
        <h3 className="text-white text-xl font-bold mb-6 text-center">Fonctionnalités disponibles</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="bg-gris rounded-xl p-5 text-center hover:bg-gris/80 transition-all duration-300 border border-[#333333] hover:border-vert">
            <div className="bg-vert/10 w-12 h-12 mx-auto rounded-full flex items-center justify-center mb-3">
              <FontAwesomeIcon icon={faExchangeAlt} className="text-vert text-xl" />
            </div>
            <h4 className="text-white font-medium mb-1">Traitez les transactions</h4>
            <p className="text-gray-400 text-sm">Acceptez les dépôts et retraits rapidement et en toute sécurité</p>
          </div>
          
          <div className="bg-gris rounded-xl p-5 text-center hover:bg-gris/80 transition-all duration-300 border border-[#333333] hover:border-bleu">
            <div className="bg-bleu/10 w-12 h-12 mx-auto rounded-full flex items-center justify-center mb-3">
              <FontAwesomeIcon icon={faChartPie} className="text-bleu text-xl" />
            </div>
            <h4 className="text-white font-medium mb-1">Suivez vos statistiques</h4>
            <p className="text-gray-400 text-sm">Consultez vos performances en temps réel</p>
          </div>
          
          <div className="bg-gris rounded-xl p-5 text-center hover:bg-gris/80 transition-all duration-300 border border-[#333333] hover:border-[#AA1D22FF]">
            <div className="bg-[#AA1D22FF]/10 w-12 h-12 mx-auto rounded-full flex items-center justify-center mb-3">
              <FontAwesomeIcon icon={faFilter} className="text-[#AA1D22FF] text-xl" />
            </div>
            <h4 className="text-white font-medium mb-1">Filtres intelligents</h4>
            <p className="text-gray-400 text-sm">Recherchez et filtrez les transactions selon vos besoins</p>
          </div>
        </div>
      </div>
    </div>
  );

  const SearchBar = () => (
    <div className={`bg-gris flex items-center rounded-xl border border-[#333333] opacity-100 ${isMobile ? 'w-full' : 'w-full max-w-md'}`}>
      <span className="pl-3 text-gray-500">
        <FontAwesomeIcon icon={faSearch} />
      </span>

      <input
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="h-12 flex-grow px-3 font-albert-sans text-base font-normal bg-transparent text-white outline-none"
        type="text"
        placeholder="Rechercher une transaction..."
      />

      <button
        onClick={() => setOpen(true)}
        className={`px-4 h-full flex items-center text-white hover:text-bleu transition-all duration-300 group ${selectedStatuses.length < 3 ? 'filter-active' : ''}`}
      >
        <div className="relative">
          <Coin
            className="w-5 h-5 transition-transform group-hover:scale-110"
            style={{ fill: "#0087BFFF" }}
          />
          {selectedStatuses.length < 3 && (
            <span className="absolute -top-1 -right-1 w-2 h-2 bg-bleu rounded-full animate-pulse"></span>
          )}
        </div>
      </button>
    </div>
  );

  // Loading animation
  const LoadingState = () => (
    <div className="flex flex-col items-center justify-center py-16">
      <div className="w-16 h-16 border-4 border-bleu border-t-transparent rounded-full animate-spin mb-4"></div>
      <p className="text-white text-lg font-medium">Chargement en cours...</p>
    </div>
  );

  return (
    <div className="bg-black min-h-screen pb-20 md:pb-0">
      {/* Search and Filters Bar */}
      <div className="bg-noir border-b border-gris px-4 py-4 pt-6">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-white text-2xl font-bold mb-4">Transactions</h2>
          <SearchBar />
        </div>
      </div>

      {/* Content Area */}
      <div className="max-w-6xl mx-auto">
        {isLoading ? (
          <LoadingState />
        ) : vide ? (
          <EmptyState />
        ) : (
          <div className="p-4 md:p-6">
            {/* Indicateur de filtres actifs (visible seulement si des filtres sont appliqués) */}
            {!isMobile && selectedStatuses.length < 3 && (
              <div className="mb-4 px-4 py-3 bg-bleu/10 border border-bleu rounded-lg flex items-center">
                <FontAwesomeIcon icon={faFilter} className="text-bleu mr-2" />
                <div className="text-white font-medium">Filtres actifs:</div>
                <div className="flex ml-2 space-x-2">
                  {selectedStatuses.map(status => (
                    <span 
                      key={status} 
                      className={`px-2 py-1 rounded-md text-xs font-medium ${
                        status === "réussi" 
                          ? "bg-vert/20 text-vert border border-vert/50" 
                          : status === "en cours" 
                            ? "bg-bleu/20 text-bleu border border-bleu/50" 
                            : "bg-red-500/20 text-red-500 border border-red-500/50"
                      }`}
                    >
                      {status.charAt(0).toUpperCase() + status.slice(1)}
                    </span>
                  ))}
                </div>
              </div>
            )}
            
            <Tabs />
            
            {/* Afficher la bottom sheet sur mobile uniquement */}
            {isMobile ? (
              <BottomSheet
                open={open}
                onDismiss={handleDismiss}
                snapPoints={({ minHeight }) => [minHeight, 500]}
                defaultSnap={({ snapPoints }) => snapPoints[1]}
                expandOnContentDrag={true}
              >
                <Filtres onClose={handleDismiss} />
              </BottomSheet>
            ) : (
              // Afficher la modal sur desktop uniquement
              open && <DesktopFilterModal />
            )}
          </div>
        )}
      </div>
    </div>
  );
}