import {
  faSyncAlt,
  faInfoCircle,
  faCopy,
  faHome,
  faCheckCircle,
  faSpinner,
  faCircleNotch
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect, useContext } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";
import InfosContext from "../../contexts/InfosAppProvider";
import { database, ref, onValue, set, get } from "../../contexts/FirebaseConfig";

export default function DepotConfirmation() {
  const navigate = useNavigate();
  // Récupérer l'externalId et l'idTransaction depuis l'URL
  const { externalId, idTransaction } = useParams();
  
  // Contexte pour les infos utilisateur
  const { fetchUserInfos } = useContext(InfosContext);
  
  // Récupérer les informations stockées
  const depotXbet = localStorage.getItem("depotxbet");
  const depotMontant = localStorage.getItem("depotmontant");
  
  // État pour suivre le statut de confirmation
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  
  // Fonction pour confirmer manuellement (déclenche une vérification)
  const confirmPayment = () => {
    setIsProcessing(true);
    
    // Vérification immédiate de l'état dans Firebase
    const transactionPath = `transactions-mtn-api/${externalId}`;
    const transactionRef = ref(database, transactionPath);
    
    get(transactionRef)
      .then((snapshot) => {
        const transactionData = snapshot.val();
        
        if (transactionData && transactionData.statut === "SUCCESSFUL") {
          // Si déjà confirmé dans Firebase
          setIsConfirmed(true);
          
          // Rafraîchir les infos utilisateur
          const idUser = localStorage.getItem("idUser");
          if (idUser) {
            fetchUserInfos(idUser, { showSplash: false, forceFetch: true });
          }
          
          toast.success("Paiement confirmé avec succès!", {
            position: "top-right",
            autoClose: 3000
          });
          
          // Rediriger vers la page de succès après un court délai
          setTimeout(() => {
            navigate(`/clients/depot-manuel-succes/${externalId}`, { replace: true });
          }, 2000);
        } else {
          // Si pas encore confirmé, notifier l'utilisateur et arrêter le traitement
          toast.info("Paiement en attente de confirmation. Veuillez attendre la validation de votre opérateur.", {
            position: "top-right",
            autoClose: 5000
          });
        }
        
        setIsProcessing(false);
      })
      .catch((error) => {
        console.error("Erreur lors de la vérification:", error);
        toast.error("Impossible de vérifier le statut du paiement.", {
          position: "top-right",
          autoClose: 3000
        });
        setIsProcessing(false);
      });
  };
  
  // Notification de copie
  const notifySuccess = () => {
    toast.success("Référence copiée", {
      position: "top-right",
      autoClose: 2000,
    });
  };
  
  // Récupération des données initiales et préparation
  useEffect(() => {
    // Si on n'a pas d'externalId dans l'URL mais qu'on l'a en localStorage, on l'utilise
    if ((!externalId || externalId === "undefined") && localStorage.getItem("externalId")) {
      const storedExternalId = localStorage.getItem("externalId");
      const storedTransactionId = localStorage.getItem("transactionConfirmationId");
      
      navigate(`/clients/depot-confirmation/${storedExternalId}/${storedTransactionId}`, { replace: true });
    }
    
    // S'assurer que les données de base sont présentes
    if (!depotXbet || !depotMontant) {
      setTimeout(() => {
        navigate("/clients/espace");
      }, 1000);
    }
  }, [externalId, idTransaction, navigate, depotXbet, depotMontant]);
  
  // Écoute des changements de statut dans Firebase pour cette transaction
  useEffect(() => {
    if (isConfirmed || !externalId || externalId === "undefined") return;
    
    console.log("Mise en place de l'écouteur Firebase pour:", externalId);
    
    // Chemin vers la transaction dans Firebase
    const transactionPath = `transactions-mtn-api/${externalId}`;
    const transactionRef = ref(database, transactionPath);
    
    // Écouteur de changement pour la transaction
    const unsubscribe = onValue(transactionRef, (snapshot) => {
      const transactionData = snapshot.val();
      console.log("Données de transaction reçues:", transactionData);
      
      if (transactionData && transactionData.statut) {
        console.log("Statut actuel:", transactionData.statut);
        
        // Si le statut est SUCCESSFUL, marquer comme confirmé et naviguer vers la page de succès
        if (transactionData.statut === "SUCCESSFUL") {
          setIsConfirmed(true);
          
          // Rafraîchir les infos utilisateur sans splash
          const idUser = localStorage.getItem("idUser");
          if (idUser) {
            fetchUserInfos(idUser, { showSplash: false, forceFetch: true });
          }
          
          // Notification et redirection (avec délai pour voir la confirmation)
          toast.success("Paiement confirmé avec succès!", {
            position: "top-right",
            autoClose: 3000
          });
          
          // Rediriger vers la page de succès après un court délai
          // Utiliser navigate avec replace: true pour éviter d'ajouter à l'historique
          // et éviter problèmes quand l'utilisateur retourne en arrière
          setTimeout(() => {
            navigate(`/clients/depot-manuel-succes/${externalId}`, { replace: true });
          }, 2000);
        }
      }
    });
    
    // Nettoyage de l'écouteur lors du démontage du composant
    return () => unsubscribe();
  }, [externalId, isConfirmed, navigate, fetchUserInfos]);

  return (
    <>
      <ToastContainer />
      
      <div className="bg-gradient-to-b from-[#0F172A] to-[#1E293B] min-h-screen flex flex-col items-center justify-center p-4">
        <div className="max-w-md w-full mx-auto bg-white/5 backdrop-blur-sm rounded-2xl border border-white/10 shadow-xl overflow-hidden">
          {/* Icône et titre */}
          <div className="pt-8 pb-4 text-center">
            <div className="flex justify-center mb-4">
              {isConfirmed ? (
                <FontAwesomeIcon icon={faCheckCircle} className="text-green-500 text-5xl" />
              ) : (
                <FontAwesomeIcon icon={faCircleNotch} className="text-indigo-500 text-5xl animate-spin" />
              )}
            </div>
            
            <h1 className="text-indigo-400 font-bold text-2xl mb-2">
              {isConfirmed ? "Paiement confirmé !" : "Confirmation de paiement"}
            </h1>
            
            <p className="text-white px-6">
              {isConfirmed 
                ? "Votre dépôt a été confirmé et sera traité rapidement."
                : "Veuillez confirmer votre paiement"}
            </p>
          </div>
          
          {/* Informations de paiement */}
          <div className="p-5 border-t border-white/10">
            {/* Référence de paiement */}
            <div className="mb-5">
              <div className="text-gray-300 text-sm font-medium mb-2 text-center">Référence de paiement</div>
              <div className="flex justify-between items-center">
                <div className="bg-[#0F172A] py-2 px-3 rounded-lg text-indigo-300 text-sm font-mono flex-grow mr-2 truncate text-center">
                  {externalId || "Non disponible"}
                </div>
                <CopyToClipboard text={externalId || ""} onCopy={notifySuccess}>
                  <button className="p-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition">
                    <FontAwesomeIcon icon={faCopy} />
                  </button>
                </CopyToClipboard>
              </div>
            </div>
            
            {/* Montant */}
            <div className="bg-[#0F172A]/70 p-4 rounded-lg mb-5 text-center">
              <div className="text-gray-400 text-xs mb-1">Montant</div>
              <div className="text-white font-medium text-xl">{depotMontant} FCFA</div>
            </div>
            
            {/* Bouton de confirmation */}
            {!isConfirmed && (
              <button 
                onClick={confirmPayment}
                disabled={isProcessing}
                className="w-full py-3 bg-gradient-to-r from-green-600 to-emerald-600 text-white rounded-xl font-medium shadow-lg flex items-center justify-center mb-4"
              >
                {isProcessing ? (
                  <>
                    <FontAwesomeIcon icon={faSpinner} className="mr-2 animate-spin" />
                    Traitement...
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
                    J'ai payé - Confirmer
                  </>
                )}
              </button>
            )}
            
            {/* Retour à l'accueil */}
            <button 
              onClick={() => navigate("/clients/espace")}
              className="w-full py-3 bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-xl font-medium shadow-lg"
            >
              <FontAwesomeIcon icon={faHome} className="mr-2" />
              Retour à l'accueil
            </button>
            
            {/* Note d'information */}
            {!isConfirmed && (
              <div className="mt-4 flex items-start">
                <FontAwesomeIcon icon={faInfoCircle} className="text-indigo-400 mt-1 mr-2" />
                <p className="text-gray-300 text-xs">
                  Confirmez votre paiement dans l'application MTN MoMo ou utilisez le bouton "J'ai payé".
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}