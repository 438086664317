/**
 * Utilitaires de formatage pour l'interface utilisateur
 */

/**
 * Formater une date à partir d'un timestamp
 * @param {number} timestamp - Le timestamp à formater
 * @param {Object} options - Options de formatage
 * @returns {string} La date formatée
 */
export const formatDate = (timestamp, options = {}) => {
  if (!timestamp) return 'N/A';
  
  const date = new Date(timestamp);
  
  const defaultOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  };
  
  const formatOptions = { ...defaultOptions, ...options };
  
  return date.toLocaleDateString('fr-FR', formatOptions);
};

/**
 * Formater un montant en devise
 * @param {number|string} amount - Le montant à formater
 * @param {string} currency - La devise (par défaut: 'FCFA')
 * @returns {string} Le montant formaté
 */
export const formatCurrency = (amount, currency = 'F') => {
  if (amount === undefined || amount === null) return 'N/A';
  
  // Convertir en nombre si c'est une chaîne
  const numAmount = typeof amount === 'string' ? parseFloat(amount) : amount;
  
  // Formater avec séparateur de milliers et 2 décimales
  return `${numAmount.toLocaleString('fr-FR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })} ${currency}`;
};

/**
 * Tronquer un texte à une longueur spécifique
 * @param {string} text - Le texte à tronquer
 * @param {number} maxLength - Longueur maximale
 * @returns {string} Le texte tronqué
 */
export const truncateText = (text, maxLength = 50) => {
  if (!text) return '';
  if (text.length <= maxLength) return text;
  
  return text.substring(0, maxLength) + '...';
};

/**
 * Formater un numéro de téléphone
 * @param {string} phoneNumber - Le numéro à formater
 * @returns {string} Le numéro formaté
 */
export const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return 'N/A';
  
  // Supprimer tous les caractères non numériques
  const cleaned = phoneNumber.replace(/\D/g, '');
  
  // Format: XX XX XX XX XX (pour les numéros à 10 chiffres)
  if (cleaned.length === 10) {
    return cleaned.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5');
  }
  
  // Format: +XXX XX XX XX XX XX (pour les numéros internationaux)
  if (cleaned.length > 10) {
    const countryCode = cleaned.substring(0, cleaned.length - 10);
    const number = cleaned.substring(cleaned.length - 10);
    return `+${countryCode} ${number.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5')}`;
  }
  
  // Retourner tel quel si format inconnu
  return phoneNumber;
};

/**
 * Formater un statut
 * @param {string} status - Le statut à formater
 * @returns {Object} Objet contenant le texte et la classe CSS
 */
export const formatStatus = (status) => {
  const statusMap = {
    'en attente': { text: 'En attente', className: 'bg-yellow-100 text-yellow-800' },
    'approuvé': { text: 'Approuvé', className: 'bg-green-100 text-green-800' },
    'payé': { text: 'Payé', className: 'bg-green-100 text-green-800' },
    'rejeté': { text: 'Rejeté', className: 'bg-red-100 text-red-800' },
    'pending': { text: 'En attente', className: 'bg-yellow-100 text-yellow-800' },
    'success': { text: 'Succès', className: 'bg-green-100 text-green-800' },
    'failed': { text: 'Échec', className: 'bg-red-100 text-red-800' },
    'error': { text: 'Erreur', className: 'bg-red-100 text-red-800' },
    'actif': { text: 'Actif', className: 'bg-green-100 text-green-800' },
    'inactif': { text: 'Inactif', className: 'bg-red-100 text-red-800' },
    'completed': { text: 'Terminé', className: 'bg-green-100 text-green-800' },
    'cancelled': { text: 'Annulé', className: 'bg-red-100 text-red-800' },
    'demandé': { text: 'Demandé', className: 'bg-blue-100 text-blue-800' },
  };
  
  // Convertir en minuscules pour correspondre aux clés
  const statusLower = status ? status.toLowerCase() : '';
  
  return statusMap[statusLower] || { text: status || 'Inconnu', className: 'bg-gray-100 text-gray-800' };
};