import { FaCoins, FaUsers, FaChartLine, FaLink, FaClipboardCheck, FaMoneyBillWave, FaHandshake, FaStar } from 'react-icons/fa'; 
import { MdDashboard, MdGroups } from 'react-icons/md';
import { BsCashCoin } from 'react-icons/bs';
import InfosContext from "../../contexts/InfosAppProvider";
import React, { useState, useContext, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Swal from "sweetalert2";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import axios from "axios";
import { erreur } from "./erreur";
import { succes } from "./succes";

export default function IndexParrainage() {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(66);
  const idUserv = localStorage.getItem("idUser");
  const [textToCopy, setTextToCopy] = useState(`https://${window.location.hostname}?ref=${idUserv}`);
  const [compteur, setCompteur] = useState(0);

  const notifySuccess = () => {
    toast.success("Lien copié avec succès", {
      position: "top-right",
      autoClose: 2000,
      icon: <FaClipboardCheck className="text-white" />,
      style: { background: "#0087BFFF", color: "white" }
    });
  };
  
  const {
    userInfos,
    listeUser,
    token
  } = useContext(InfosContext);

  useEffect(() => {
    let k = 0;
    for (const key in listeUser) {
      if (listeUser[key].idParrain == idUserv) {
        k++;
      }
    }
    setCompteur(k);
  }, [listeUser, idUserv]);

  async function demande(numeroMoMo) {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const endpoint = 'demande-retrait-affiliation';
    const params = {
      idUser:idUserv,
      numeroRetrait:numeroMoMo,
      mode: 'manuel'
     
    };

    const tokens = token;
    const config = {
      headers: {
        'Authorization': `Bearer ${tokens}`
      },
      params
    };

    console.log("token est ",numeroMoMo)
    axios.get(`${baseURL}${endpoint}`, config)
      .then((response) => {
        setLoading(false);
        if (response.data.error === false) {
          console.log("Succès:", response.data.message);
          succes();
        } else {
          console.error("Erreur:", response.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        erreur();
        console.error("Erreur lors de la requête:", error);
      });
  }

  return (
    <>
      <ToastContainer />
      
      {/* VERSION MOBILE */}
      <div className="bg-[#1C1C1CFF] min-h-screen flex flex-col pt-5 px-4 pb-20 overflow-auto md:hidden">
        <div className="flex items-center justify-center mb-8">
          <div className="bg-[#0087BFFF] bg-opacity-20 p-2 rounded-full">
            <FaHandshake className="text-[#0087BFFF] text-2xl" />
          </div>
          <h1 className="text-white text-2xl font-bold ml-3">Programme d'Affiliation</h1>
        </div>
        
        {/* Stats Cards */}
        <div className="relative mb-8">
          {/* Card 1 - Solde */}
          <div className="bg-[#272728FF] rounded-2xl p-5 mb-5 shadow-[0_10px_25px_-5px_rgba(0,135,191,0.3)]">
            <div className="flex justify-between items-center">
              <div>
                <p className="text-gray-400 text-sm mb-1">Solde Affiliation</p>
                <h2 className="text-white text-2xl font-bold">{userInfos.solde} <span className="text-sm font-normal">F CFA</span></h2>
              </div>
              <div className="bg-[#0087BFFF] w-12 h-12 rounded-full flex items-center justify-center">
                <BsCashCoin className="text-white text-2xl" />
              </div>
            </div>
            
            <div className="mt-4 pt-4 border-t border-gray-700">
              <div className="flex items-center">
                <div className="bg-[#28C281FF] bg-opacity-20 p-1 rounded-full">
                  <FaStar className="text-[#28C281FF] text-xs" />
                </div>
                <p className="text-gray-400 text-xs ml-2">Gagnez 1% de commission sur les dépôts des filleuls</p>
              </div>
            </div>
          </div>
          
          {/* Card 2 - Filleuls */}
          <div className="flex space-x-4 mb-5">
            <div className="bg-[#272728FF] rounded-2xl p-5 flex-1 shadow-lg relative overflow-hidden">
              <div className="flex flex-col justify-between h-full relative z-10">
                <div className="mb-1">
                  <p className="text-gray-400 text-xs">Filleuls</p>
                </div>
                <div className="flex items-center">
                  <h2 className="text-white text-xl font-bold">{compteur}</h2>
                  <MdGroups className="text-[#0087BFFF] text-lg ml-2" />
                </div>
              </div>
              <div className="absolute top-2 right-2 opacity-10">
                <FaUsers className="text-[#0087BFFF] text-5xl" />
              </div>
            </div>
            
            {/* Card 3 - Total retiré */}
            <div className="bg-[#272728FF] rounded-2xl p-5 flex-1 shadow-lg relative overflow-hidden">
              <div className="flex flex-col justify-between h-full relative z-10">
                <div className="mb-1">
                  <p className="text-gray-400 text-xs">Total retiré</p>
                </div>
                <div className="flex items-center">
                  <h2 className="text-white text-xl font-bold">{userInfos.soldeTotal}</h2>
                  <FaChartLine className="text-[#ff6f61] text-sm ml-2" />
                </div>
              </div>
              <div className="absolute top-2 right-2 opacity-10">
                <FaChartLine className="text-[#ff6f61] text-5xl" />
              </div>
            </div>
          </div>
        </div>
        
        {/* Lien de Parrainage */}
        <div className="bg-[#272728FF] rounded-2xl shadow-lg p-5 mb-8">
          <div className="flex items-center mb-4">
            <div className="bg-[#0087BFFF] bg-opacity-20 p-2 rounded-full">
              <FaLink className="text-[#0087BFFF] text-sm" />
            </div>
            <h2 className="text-white text-lg font-medium ml-3">Votre Lien de Parrainage</h2>
          </div>
          
          <div className="bg-[#1C1C1CFF] p-4 rounded-xl mb-4 border border-gray-800">
            <p className="text-gray-300 text-sm break-all">{textToCopy}</p>
          </div>
          
          <div className="flex items-center justify-between">
            <p className="text-gray-400 text-xs">Partagez votre lien pour gagner des commissions!</p>
            <CopyToClipboard text={textToCopy} onCopy={notifySuccess}>
              <button className="bg-[#0087BFFF] hover:bg-opacity-90 text-white font-medium py-2 px-4 rounded-lg transition duration-300 flex items-center text-sm">
                <FaClipboardCheck className="mr-2" />
                Copier
              </button>
            </CopyToClipboard>
          </div>
        </div>
        
        {/* Bouton de retrait */}
        <div className="px-4">
          <button
            onClick={() => {
              setLoading(true);
              demande(userInfos.numeroMomo);
            }}
            className="bg-gradient-to-r from-[#28C281FF] to-[#0087BFFF] hover:opacity-95 text-white font-bold py-4 px-6 rounded-xl transition duration-300 flex items-center justify-center w-full shadow-lg"
            type="submit"
            disabled={loading}
          >
            {loading ? (
              <div className="w-5 h-5 mr-2 animate-spin">
                <CircularProgressbar
                  value={progress}
                  styles={buildStyles({
                    pathColor: "white",
                    trailColor: "rgba(255,255,255,0.2)",
                    strokeLinecap: "round",
                  })}
                />
              </div>
            ) : (
              <FaMoneyBillWave className="mr-2 text-xl" />
            )}
            <span className="text-white text-lg">
              {loading ? "Traitement..." : "Demander un Retrait"}
            </span>
          </button>
        </div>
      </div>

      {/* VERSION DESKTOP */}
      <div className="bg-[#1C1C1CFF] min-h-screen hidden flex-col py-10 pl-72 pr-8 overflow-auto md:flex">
        <div className="flex items-center justify-center mb-12">
          <div className="bg-[#0087BFFF] bg-opacity-20 p-3 rounded-full">
            <FaHandshake className="text-[#0087BFFF] text-3xl" />
          </div>
          <h1 className="text-white text-3xl font-bold ml-3">Programme d'Affiliation</h1>
        </div>
        
        <div className="max-w-7xl mx-auto w-full mb-10">
          {/* Top Section - Main Stats */}
          <div className="grid grid-cols-3 gap-6 mb-8">
            {/* Solde Card */}
            <div className="bg-gradient-to-br from-[#272728FF] to-[#1C1C1CFF] rounded-2xl p-6 shadow-[0_15px_30px_-5px_rgba(0,135,191,0.3)] relative overflow-hidden border border-gray-800">
              <div className="flex justify-between items-center mb-4">
                <div className="bg-[#0087BFFF] bg-opacity-20 p-3 rounded-full">
                  <BsCashCoin className="text-[#0087BFFF] text-2xl" />
                </div>
                <div className="bg-[#0087BFFF] bg-opacity-10 px-3 py-1 rounded-full">
                  <p className="text-[#0087BFFF] text-xs font-medium">Actif</p>
                </div>
              </div>
              
              <h2 className="text-white text-3xl font-bold mb-1">{userInfos.solde} <span className="text-lg">F CFA</span></h2>
              <p className="text-gray-400 mb-4">Solde d'affiliation actuel</p>
              
              <div className="mt-4 pt-4 border-t border-gray-800 flex justify-between items-center">
                <div className="flex items-center">
                  <div className="bg-[#28C281FF] bg-opacity-20 p-1 rounded-full">
                    <FaStar className="text-[#28C281FF] text-xs" />
                  </div>
                  <p className="text-gray-400 text-xs ml-2">Gagnez 1% de commission sur les dépôts des filleuls</p>
                </div>
                <div className="flex items-center">
                  <FaChartLine className="text-[#0087BFFF] text-sm mr-1" />
                  <p className="text-[#0087BFFF] text-xs">+25%</p>
                </div>
              </div>
              
              <div className="absolute -bottom-8 -right-8 opacity-5">
                <BsCashCoin className="text-white text-[180px]" />
              </div>
            </div>
            
            {/* Filleuls Card */}
            <div className="bg-gradient-to-br from-[#272728FF] to-[#1C1C1CFF] rounded-2xl p-6 shadow-lg relative overflow-hidden border border-gray-800">
              <div className="flex justify-between items-center mb-4">
                <div className="bg-[#b19cd9] bg-opacity-20 p-3 rounded-full">
                  <MdGroups className="text-[#b19cd9] text-2xl" />
                </div>
                <div className="flex items-center">
                  <span className="inline-block w-2 h-2 rounded-full bg-[#28C281FF] mr-2"></span>
                  <p className="text-gray-400 text-xs">En hausse</p>
                </div>
              </div>
              
              <div className="flex items-baseline mb-1">
                <h2 className="text-white text-3xl font-bold">{compteur}</h2>
                <p className="text-gray-400 text-sm ml-2">inscrits</p>
              </div>
              <p className="text-gray-400 mb-4">Nombre de filleuls total</p>
              
              <div className="mt-4 pt-4 border-t border-gray-800 flex justify-between items-center">
                <p className="text-gray-400 text-xs"></p>
                <div className="bg-[#b19cd9] bg-opacity-10 px-3 py-1 rounded-full">
                  <p className="text-[#b19cd9] text-xs font-medium">Détails</p>
                </div>
              </div>
              
              <div className="absolute -bottom-8 -right-8 opacity-5">
                <FaUsers className="text-white text-[180px]" />
              </div>
            </div>
            
            {/* Total Retiré Card */}
            <div className="bg-gradient-to-br from-[#272728FF] to-[#1C1C1CFF] rounded-2xl p-6 shadow-lg relative overflow-hidden border border-gray-800">
              <div className="flex justify-between items-center mb-4">
                <div className="bg-[#f9d57d] bg-opacity-20 p-3 rounded-full">
                  <FaChartLine className="text-[#f9d57d] text-2xl" />
                </div>
                <div className="flex items-center">
                  <span className="inline-block w-2 h-2 rounded-full bg-[#ff6f61] mr-2"></span>
                  <p className="text-gray-400 text-xs">Historique</p>
                </div>
              </div>
              
              <h2 className="text-white text-3xl font-bold mb-1">{userInfos.soldeTotal} <span className="text-lg">F CFA</span></h2>
              <p className="text-gray-400 mb-4">Montant total retiré</p>
              
              <div className="mt-4 pt-4 border-t border-gray-800 flex justify-between items-center">
                <p className="text-gray-400 text-xs">Dernière opération le 15 Mar 2025</p>
                <div className="bg-[#ff6f61] bg-opacity-10 px-3 py-1 rounded-full">
                  <p className="text-[#ff6f61] text-xs font-medium">Historique</p>
                </div>
              </div>
              
              <div className="absolute -bottom-8 -right-8 opacity-5">
                <FaChartLine className="text-white text-[180px]" />
              </div>
            </div>
          </div>
          
          {/* Bottom Section - Lien et Retrait */}
          <div className="grid grid-cols-2 gap-6">
            {/* Lien de Parrainage */}
            <div className="bg-gradient-to-br from-[#272728FF] to-[#1C1C1CFF] rounded-2xl p-6 shadow-lg border border-gray-800">
              <div className="flex items-center mb-5">
                <div className="bg-[#0087BFFF] bg-opacity-20 p-3 rounded-full">
                  <FaLink className="text-[#0087BFFF] text-xl" />
                </div>
                <h2 className="text-white text-xl font-medium ml-3">Votre Lien de Parrainage</h2>
              </div>
              
              <div className="bg-[#1C1C1CFF] p-5 rounded-xl mb-5 border border-gray-800">
                <p className="text-gray-300 break-all">{textToCopy}</p>
              </div>
              
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-white font-medium mb-1">Comment ça marche ?</p>
                  <p className="text-gray-400 text-sm">Partagez ce lien et gagnez 1% de commission sur les dépôts des filleuls</p>
                </div>
                <CopyToClipboard text={textToCopy} onCopy={notifySuccess}>
                  <button className="bg-[#0087BFFF] hover:bg-opacity-90 text-white font-medium py-3 px-5 rounded-lg transition duration-300 flex items-center">
                    <FaClipboardCheck className="mr-2" />
                    Copier le Lien
                  </button>
                </CopyToClipboard>
              </div>
            </div>
            
            {/* Demande de Retrait */}
            <div className="bg-gradient-to-br from-[#272728FF] to-[#1C1C1CFF] rounded-2xl p-6 shadow-lg border border-gray-800 flex flex-col justify-between">
              <div>
                <div className="flex items-center mb-5">
                  <div className="bg-[#28C281FF] bg-opacity-20 p-3 rounded-full">
                    <FaMoneyBillWave className="text-[#28C281FF] text-xl" />
                  </div>
                  <h2 className="text-white text-xl font-medium ml-3">Demander un Retrait</h2>
                </div>
                
                <div className="bg-[#1C1C1CFF] rounded-xl p-5 mb-5 border border-gray-800">
                  <div className="flex justify-between items-center mb-3">
                    <p className="text-gray-400">Solde disponible</p>
                    <p className="text-white font-bold">{userInfos.solde} F CFA</p>
                  </div>
                  <div className="flex justify-between items-center mb-3">
                    <p className="text-gray-400">Seuil minimum</p>
                    <p className="text-white">1000 F CFA</p>
                  </div>
                  <div className="flex justify-between items-center">
                    <p className="text-gray-400">Délai de traitement</p>
                    <p className="text-white">24-48h</p>
                  </div>
                </div>
              </div>
              
              <button
                onClick={() => {
                  setLoading(true);
                  demande(userInfos.numeroMomo);
                }}
                className="bg-gradient-to-r from-[#28C281FF] to-[#0087BFFF] hover:opacity-95 text-white font-bold py-4 px-6 rounded-xl transition duration-300 flex items-center justify-center w-full shadow-lg"
                type="submit"
                disabled={loading}
              >
                {loading ? (
                  <div className="w-5 h-5 mr-2 animate-spin">
                    <CircularProgressbar
                      value={progress}
                      styles={buildStyles({
                        pathColor: "white",
                        trailColor: "rgba(255,255,255,0.2)",
                        strokeLinecap: "round",
                      })}
                    />
                  </div>
                ) : (
                  <FaMoneyBillWave className="mr-2 text-xl" />
                )}
                <span className="text-white text-lg">
                  {loading ? "Traitement..." : "Demander un Retrait"}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
