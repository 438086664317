import React from 'react';

const Button = ({ title, icon }) => {
  return (
    <button className='py-2 px-4 mb-1 flex items-center w-full rounded-lg gap-3 text-gray-300 hover:text-white hover:bg-noir transition-all duration-150 text-sm font-medium'>
      {icon && <span className="text-bleu text-lg">{icon}</span>}
      <span>{title}</span>
    </button>
  );
}

export default Button;
