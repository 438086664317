import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { 
  HomeIcon, 
  CogIcon, 
  ArrowPathIcon, 
  QuestionMarkCircleIcon 
} from '@heroicons/react/24/outline';

const MobileBottomBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeItem, setActiveItem] = useState('/agents/');

  useEffect(() => {
    setActiveItem(location.pathname);
  }, [location.pathname]);

  const menuItems = [
    {
      icon: <HomeIcon className="w-6 h-6" />,
      label: "Accueil",
      path: "/agents/",
    },
    {
      icon: <ArrowPathIcon className="w-6 h-6" />,
      label: "Transactions",
      path: "/agents/transactions",
    },
    {
      icon: <QuestionMarkCircleIcon className="w-6 h-6" />,
      label: "Aide",
      path: "/agents/aide",
    },
    {
      icon: <CogIcon className="w-6 h-6" />,
      label: "Paramètres",
      path: "/agents/setting",
    },
  ];

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-noir border-t border-gris z-50">
      <div className="flex justify-around py-2">
        {menuItems.map((item, index) => (
          <button
            key={index}
            onClick={() => {
              navigate(item.path);
              setActiveItem(item.path);
            }}
            className="flex flex-col items-center justify-center p-2"
          >
            <div className={`${activeItem === item.path ? "text-bleu" : "text-gray-500"}`}>
              {item.icon}
            </div>
            <span className={`text-xs mt-1 ${activeItem === item.path ? "text-bleu" : "text-gray-500"}`}>
              {item.label}
            </span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default MobileBottomBar;