// AuthContext.js
import React, { createContext, useState, useEffect, useContext } from "react";
import { Routes, Route, Outlet, Link, useNavigate } from "react-router-dom";

import getUserInfos from "../requtes/clients/getUserInfos";

//import AuthContext from "./AuthContext";
import Splash from "./Splash";
import { database, ref, onValue, set, get } from "./FirebaseConfig";

const InfosContext = createContext();

export function InfosAppProvider({ children }) {
  // const { isSplashVisible, setSplashVisible } = useContext(AuthContext);

  const idUserv = localStorage.getItem("idUser");

  const navigate = useNavigate(); // Utilisation de useNavigate

  const [searchTerm, setSearchTerm] = useState("");

  const [open, setOpen] = useState(false);

  const [listeUser, setListeUser] = useState(null);

  const [listeCaisses, setListeCaisses] = useState(null);
  const [update, setUpdate] = useState(0);
  const [updatek, setUpdatek] = useState(0);

  const [allRetraitAffiliations, setAllRetraitAffiliations] = useState(null);

  const [selectedStatuses, setSelectedStatuses] = useState([
    "en cours",
    "réussi",
    "échoué",
  ]); // Tous les statuts sont sélectionnés par défaut

  // Fonction pour retirer un statut
  const removeStatus = (statusToRemove) => {
    setSelectedStatuses((prevStatuses) =>
      prevStatuses.filter((status) => status !== statusToRemove)
    );
  };

  // Fonction pour ajouter un statut
  const addStatus = (newStatus) => {
    setSelectedStatuses((prevStatuses) => {
      if (!prevStatuses.includes(newStatus)) {
        return [...prevStatuses, newStatus];
      }
      return prevStatuses; // Ne rien faire si le statut est déjà présent
    });
  };

  const [userInfos, setUserInfos] = useState(null);
  const [infosAdmin, setInfosAdmin] = useState(null);
  // Le splash est toujours visible au chargement de la page, quelle que soit la route
  // Il sera masqué une fois les données chargées
  const [isSplashVisible, setSplashVisible] = useState(true);

  const [transactions, setTransactions] = useState(null);
  const [id, setId] = useState(null);
  const [token, setToken] = useState(null);
  
  // Données pour les notifications
  const [notifications, setNotifications] = useState([]);
  const [notificationsStats, setNotificationsStats] = useState(null);
  
  // Données spécifiques pour les assistants
  const [soldeCollection, setSoldeCollection] = useState(null);
  const [soldeDisbursement, setSoldeDisbursement] = useState(null);
  const [momoTransactions, setMomoTransactions] = useState([]);
  
  // Historique des bonus pour les clients
  const [historiqueBonus, setHistoriqueBonus] = useState([]);

  //const [update, setUpatde] = useState(null);

  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => { 
    const idUser = localStorage.getItem("idUser");
    const isAuthenticatedlocal = localStorage.getItem("isAuthenticated");
    const tokenlocal = localStorage.getItem("token");

    //alert("idUser est " + idUser);

    // S'assurer que localStorage a bien la bonne valeur
    // localStorage.setItem("isAuthenticated", "1");

    //console.log("Valeur récupérée depuis localStorage:", isAuthenticatedlocal);

    // Mettre à jour l'état correctement si isAuthenticatedlocal est "1"
    if (isAuthenticatedlocal == "1") {
      setIsAuthenticated(true); // Utiliser un booléen pour plus de clarté
      setToken(tokenlocal);
      setId(idUser);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  // useEffect dépendant de isAuthenticated
  useEffect(() => {
    if (isAuthenticated !== null) {
      if (isAuthenticated) {
        // Appeler une fonction pour récupérer les infos de l'utilisateur si on a un id et token
        if (id && token) {
          // Utiliser le splash seulement au premier chargement de l'application
          const isFirstLoad = sessionStorage.getItem('dataLoaded') !== 'true';
          
          fetchUserInfos(id, { 
            showSplash: isFirstLoad, 
            isInitialLoad: isFirstLoad 
          });
          
          // Marquer que les données ont été chargées une première fois
          if (isFirstLoad) {
            sessionStorage.setItem('dataLoaded', 'true');
          }
        }
      } else {
        // Vérifier si on est déjà sur la page d'inscription pour éviter la navigation inutile
        const currentPath = window.location.pathname;
        if (!currentPath.includes("/signup") && !currentPath.includes("/login")) {
          navigate("/signup");
        }
        setSplashVisible(false);
      }
    }
  }, [isAuthenticated, id, token]);

  const fetchUserInfos = (id, options = {}) => {
    // Options par défaut
    const { 
      showSplash = false,  // Par défaut, ne pas montrer le splash (changé de true à false)
      forceFetch = false,  // Option pour forcer la récupération même si récente
      isInitialLoad = false // Indique s'il s'agit du chargement initial
    } = options;
    
    // Vérifier si on a déjà les données utilisateur et si la dernière requête était récente
    const lastFetchTime = parseInt(sessionStorage.getItem('lastUserFetch') || '0');
    const currentTime = Date.now();
    const minInterval = 1000; // 1 seconde minimum entre les requêtes
    
    // Éviter les requêtes trop fréquentes si on a déjà les données, sauf si forceFetch=true
    if (!forceFetch && userInfos && currentTime - lastFetchTime < minInterval) {
      return;
    }
    
    sessionStorage.setItem('lastUserFetch', currentTime.toString());
    
    // Ne pas modifier l'état du splash ici
    // Le splash sera caché automatiquement après le chargement des données
    
    getUserInfos(id, token)
      .then((response) => {
        if (!response.error) {
          // Masquer le splash seulement après le chargement réussi des données
          // Cela garantit que le splash s'affiche à chaque rechargement
          console.log("Données utilisateur chargées, masquage du splash");
          setSplashVisible(false);
          
          const role = response.infos.userInfos.role;
          
          // Sauvegarder les données communes à tous les rôles
          setUserInfos(response.infos.userInfos);
          
          // Sauvegarder les données supplémentaires si disponibles
          if (response.infos.infosAdmin) {
            setInfosAdmin(response.infos.infosAdmin);
          }
          
          if (response.infos.listeUser) {
            setListeUser(response.infos.listeUser);
          }
          
          if (response.infos.listeCaisses) {
            setListeCaisses(response.infos.listeCaisses);
          }
          
          if (response.infos.allRetraitAffiliations) {
            setAllRetraitAffiliations(response.infos.allRetraitAffiliations);
          }
          
          // Stocker les données spécifiques pour les assistants
          if (response.infos.soldeCollection) {
            setSoldeCollection(response.infos.soldeCollection);
          }
          
          if (response.infos.soldeDisbursement) {
            setSoldeDisbursement(response.infos.soldeDisbursement);
          }
          
          if (response.infos.momoTransactions) {
            setMomoTransactions(response.infos.momoTransactions);
          }
          
          // Traitement des notifications
          if (response.infos.notifications) {
            setNotifications(response.infos.notifications);
            console.log("Notifications chargées depuis l'API:", response.infos.notifications.length);
          }
          
          // Statistiques des notifications
          if (response.infos.notificationsStats) {
            setNotificationsStats(response.infos.notificationsStats);
            console.log("Statistiques des notifications chargées depuis l'API");
          }
          
          // Traitement des transactions selon le rôle
          if (role === "client") {
            // Inverser l'ordre des transactions pour les clients
            let transformedData = response.infos.transactions;
            let reversedTransactions = {};
            let keys = Object.keys(transformedData).reverse();
            
            keys.forEach((key) => {
              reversedTransactions[key] = transformedData[key];
            });
            
            setTransactions(reversedTransactions);
            
            // Récupérer l'historique des bonus si disponible
            if (response.infos.historiqueBonus) {
              setHistoriqueBonus(response.infos.historiqueBonus);
              console.log("Historique des bonus chargé:", response.infos.historiqueBonus);
            }
            
            setSplashVisible(false);
            
            // Vérifier la page actuelle avant de rediriger
            const currentPath = window.location.pathname;
            if (!currentPath.includes("/clients/") && 
                !currentPath.includes("/depot-manuel-succes/")) {
              navigate("/clients/espace");
            }
          }
          else if (role === "agent") {
            // Transformer le format des transactions pour les agents
            let transformedData = {};
            
            Object.keys(response.infos.transactions).forEach((idCaisse) => {
              response.infos.transactions[idCaisse].forEach((transaction) => {
                const idTransaction = transaction.idTransaction;
                transformedData[idTransaction] = { ...transaction };
              });
            });
            
            let reversedTransactions = {};
            let keys = Object.keys(transformedData).reverse();
            
            keys.forEach((key) => {
              reversedTransactions[key] = transformedData[key];
            });
            
            setTransactions(reversedTransactions);
            setSplashVisible(false);
            
            // Vérifier la page actuelle avant de rediriger
            const currentPath = window.location.pathname;
            if (!currentPath.includes("/agents/")) {
              navigate("/agents/");
            }
          }
          else if (role === "admin") {
            // Définir les transactions directement pour l'admin
            setTransactions(response.infos.transactions);
            setSplashVisible(false);
            
            // Vérifier la page actuelle avant de rediriger
            const currentPath = window.location.pathname;
            if (!currentPath.includes("/admin/")) {
              navigate("/admin/");
            }
          }
          else if (role === "assistant") {
            // Stocker les informations MTN Mobile Money pour l'assistant
            console.log("Traitement des données pour l'assistant...");
            
            // Stocker explicitement les données de l'assistant
            setUserInfos(response.infos.userInfos);
            
            // Récupérer spécifiquement les données pour l'assistant
            if (response.infos.soldeCollection) {
              setSoldeCollection(response.infos.soldeCollection);
            }
            
            if (response.infos.soldeDisbursement) {
              setSoldeDisbursement(response.infos.soldeDisbursement);
            }
            
            if (response.infos.momoTransactions) {
              setMomoTransactions(response.infos.momoTransactions);
            }
            
            setSplashVisible(false);
            
            // Vérifier la page actuelle avant de rediriger
            const currentPath = window.location.pathname;
            console.log("Chemin actuel:", currentPath);
            
            // Force la redirection vers la page d'accueil assistant après un court délai
            // pour s'assurer que tous les états sont mis à jour
            setTimeout(() => {
              const currentPathAfterUpdate = window.location.pathname;
              console.log("Après délai - Chemin actuel:", currentPathAfterUpdate);
              if (!currentPathAfterUpdate.includes("/assistant/")) {
                console.log("Redirection vers /assistant/");
                navigate("/assistant/");
              }
            }, 300);
          }
        } else {
          console.error("Erreur dans la réponse:", response.error);
          setSplashVisible(false);
        }
      })
      .catch((error) => {
        // Même en cas d'erreur, masquer le splash pour éviter qu'il reste bloqué
        setSplashVisible(false);
        console.error("Erreur lors de la récupération des informations utilisateur:", error);
      });
  };

  // Combiner tous les écouteurs Firebase en un seul useEffect pour éviter des rendus multiples
  useEffect(() => {
    if (!isAuthenticated) return;

    // Créer un tableau des chemins à surveiller
    const pathsToWatch = [
      { path: 'infos-admin', name: 'Infos Admin' },
      { path: 'users', name: 'Users' },
      { path: 'transactions', name: 'Transactions' }
    ];
    
    // Créer et stocker tous les écouteurs
    const unsubscribers = pathsToWatch.map(refInfo => {
      const dataRef = ref(database, refInfo.path);
      
      return onValue(dataRef, (snapshot) => {
        // Utiliser un timestamp comme marqueur de mise à jour
        const timestampUnix = Math.floor(Date.now() / 1000);
        setUpdatek(timestampUnix);
        
        // Recharger les données utilisateur uniquement si nous avons l'ID et token
        if (id && token) {
          // Limiter les appels trop fréquents (debounce basique)
          const lastFetchTime = parseInt(sessionStorage.getItem('lastFetchTime') || '0');
          const currentTime = Date.now();
          const minInterval = 2000; // 2 secondes minimum entre les requêtes
          
          if (currentTime - lastFetchTime > minInterval) {
            sessionStorage.setItem('lastFetchTime', currentTime.toString());
            fetchUserInfos(id, { 
              showSplash: false,
              isInitialLoad: false
            });
          }
        }
        
        console.log(`${refInfo.name} changed:`, snapshot.val());
      });
    });
    
    // Nettoyer tous les écouteurs lors du démontage
    return () => {
      unsubscribers.forEach(unsubscribe => unsubscribe());
    };
  }, [isAuthenticated, id, token]);

  return (
    <InfosContext.Provider
      value={{
        id,
        setId,
        token,
        setToken,
        infosAdmin,
        setInfosAdmin,
        userInfos,
        setUserInfos,
        transactions,
        setTransactions,
        isSplashVisible,
        setSplashVisible,
        selectedStatuses,
        removeStatus,
        addStatus,
        open,
        setOpen,
        searchTerm,
        setSearchTerm,

        searchTerm,
        setSearchTerm,
        listeUser,
        setListeUser,
        listeCaisses,
        setListeCaisses,
        allRetraitAffiliations,
        setAllRetraitAffiliations,
        update,
        setUpdate,
        isAuthenticated,
        setIsAuthenticated,
        fetchUserInfos,
        updatek,
        setUpdatek,
        soldeCollection,
        setSoldeCollection,
        soldeDisbursement,
        setSoldeDisbursement,
        momoTransactions,
        setMomoTransactions,
        notifications,
        setNotifications,
        notificationsStats,
        setNotificationsStats,
        historiqueBonus,
        setHistoriqueBonus
      }}
    >
      {isSplashVisible ? <Splash /> : children}
    </InfosContext.Provider>
  );
}

export default InfosContext;
