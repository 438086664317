import React, { useState, useEffect } from 'react';
import { FiPlus, FiEdit, FiTrash2, FiRefreshCw, FiSearch, FiFilter, FiArrowUp, FiArrowDown } from 'react-icons/fi';
import { FaCashRegister, FaCoins, FaMoneyBillWave, FaUserTie } from 'react-icons/fa';

const CaisseList = () => {
  // État local pour stocker les données des caisses
  const [caisses, setCaisses] = useState([
    { 
      id: 1, 
      nom: "Caisse Principale", 
      solde: 1500000, 
      limite: 2000000, 
      seuilAlerte: 500000,
      caissier: "Jean Dupont",
      statut: "actif", 
      dateCreation: "2023-01-15"
    },
    { 
      id: 2, 
      nom: "Caisse Express", 
      solde: 850000, 
      limite: 1000000, 
      seuilAlerte: 200000,
      caissier: "Marie Martin",
      statut: "actif", 
      dateCreation: "2023-02-20"
    },
    { 
      id: 3, 
      nom: "Caisse VIP", 
      solde: 3200000, 
      limite: 5000000, 
      seuilAlerte: 1000000,
      caissier: "Paul Robert",
      statut: "actif", 
      dateCreation: "2023-03-05"
    },
    { 
      id: 4, 
      nom: "Caisse Régionale", 
      solde: 120000, 
      limite: 800000, 
      seuilAlerte: 200000,
      caissier: "Non attribuée",
      statut: "inactif", 
      dateCreation: "2023-04-10"
    },
    { 
      id: 5, 
      nom: "Caisse Mobile", 
      solde: 450000, 
      limite: 600000, 
      seuilAlerte: 150000,
      caissier: "Sophie Leclerc",
      statut: "actif", 
      dateCreation: "2023-05-22"
    }
  ]);

  // État pour la recherche
  const [searchTerm, setSearchTerm] = useState('');
  
  // État pour le tri
  const [sortConfig, setSortConfig] = useState({
    key: 'id',
    direction: 'ascending'
  });

  // État pour les filtres
  const [filters, setFilters] = useState({
    statut: 'tous',
    soldeMin: '',
    soldeMax: ''
  });

  // État pour la caisse en cours d'édition
  const [caisseEnEdition, setCaisseEnEdition] = useState(null);

  // Fonction pour formater les montants
  const formatMontant = (montant) => {
    return new Intl.NumberFormat('fr-FR').format(montant);
  };

  // Fonction pour trier les données
  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // Fonction pour gérer la recherche
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  // Fonction pour gérer les filtres
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };

  // Fonction pour ajouter une nouvelle caisse
  const handleAjouterCaisse = () => {
    // Logique pour ajouter une caisse
    alert('Formulaire d\'ajout de caisse à implémenter');
  };

  // Fonction pour éditer une caisse
  const handleEditerCaisse = (caisse) => {
    setCaisseEnEdition(caisse);
    // Ouvrir modal d'édition
  };

  // Fonction pour supprimer une caisse
  const handleSupprimerCaisse = (id) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cette caisse ?')) {
      setCaisses(caisses.filter(caisse => caisse.id !== id));
    }
  };

  // Fonction pour appliquer les filtres et la recherche
  const getFilteredCaisses = () => {
    return caisses
      .filter(caisse => {
        // Filtre de recherche
        if (searchTerm && !caisse.nom.toLowerCase().includes(searchTerm.toLowerCase())) {
          return false;
        }
        
        // Filtre par statut
        if (filters.statut !== 'tous' && caisse.statut !== filters.statut) {
          return false;
        }
        
        // Filtre par solde minimum
        if (filters.soldeMin && caisse.solde < parseFloat(filters.soldeMin)) {
          return false;
        }
        
        // Filtre par solde maximum
        if (filters.soldeMax && caisse.solde > parseFloat(filters.soldeMax)) {
          return false;
        }
        
        return true;
      })
      .sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
  };

  // Obtenir les caisses filtrées
  const caissesFiltered = getFilteredCaisses();

  // Rendu du composant
  return (
    <div className="w-full">
      {/* En-tête de la page */}
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-white mb-2">Gestion des caisses</h1>
        <p className="text-gray-400">Gérez toutes vos caisses et leurs paramètres</p>
      </div>

      {/* Carte de statistiques */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-8">
        <div className="bg-gris rounded-xl p-5 shadow-lg flex items-center justify-between">
          <div>
            <p className="text-gray-400 text-sm">Total des caisses</p>
            <h3 className="text-white text-2xl font-bold mt-1">{caisses.length}</h3>
          </div>
          <div className="bg-bleu bg-opacity-20 p-3 rounded-full">
            <FaCashRegister className="text-bleu text-xl" />
          </div>
        </div>

        <div className="bg-gris rounded-xl p-5 shadow-lg flex items-center justify-between">
          <div>
            <p className="text-gray-400 text-sm">Solde total</p>
            <h3 className="text-white text-2xl font-bold mt-1">
              {formatMontant(caisses.reduce((sum, caisse) => sum + caisse.solde, 0))} F
            </h3>
          </div>
          <div className="bg-vert bg-opacity-20 p-3 rounded-full">
            <FaCoins className="text-vert text-xl" />
          </div>
        </div>

        <div className="bg-gris rounded-xl p-5 shadow-lg flex items-center justify-between">
          <div>
            <p className="text-gray-400 text-sm">Caisses actives</p>
            <h3 className="text-white text-2xl font-bold mt-1">
              {caisses.filter(caisse => caisse.statut === 'actif').length}
            </h3>
          </div>
          <div className="bg-softPurple bg-opacity-20 p-3 rounded-full">
            <FaMoneyBillWave className="text-softPurple text-xl" />
          </div>
        </div>

        <div className="bg-gris rounded-xl p-5 shadow-lg flex items-center justify-between">
          <div>
            <p className="text-gray-400 text-sm">Caisses attribuées</p>
            <h3 className="text-white text-2xl font-bold mt-1">
              {caisses.filter(caisse => caisse.caissier !== "Non attribuée").length}
            </h3>
          </div>
          <div className="bg-softYellow bg-opacity-20 p-3 rounded-full">
            <FaUserTie className="text-softYellow text-xl" />
          </div>
        </div>
      </div>

      {/* Barre d'outils */}
      <div className="flex flex-col md:flex-row gap-4 justify-between mb-6">
        <div className="flex-1">
          <div className="relative">
            <input
              type="text"
              placeholder="Rechercher une caisse..."
              className="w-full py-2 pl-10 pr-4 bg-gris border border-gray-700 rounded-lg text-white text-sm focus:outline-none focus:border-bleu"
              value={searchTerm}
              onChange={handleSearch}
            />
            <div className="absolute left-3 top-2.5 text-gray-400">
              <FiSearch />
            </div>
          </div>
        </div>
        
        <div className="flex gap-3">
          <select
            name="statut"
            value={filters.statut}
            onChange={handleFilterChange}
            className="bg-gris border border-gray-700 rounded-lg text-white text-sm py-2 px-4 focus:outline-none focus:border-bleu"
          >
            <option value="tous">Tous les statuts</option>
            <option value="actif">Actif</option>
            <option value="inactif">Inactif</option>
          </select>
          
          <button
            onClick={handleAjouterCaisse}
            className="flex items-center gap-2 bg-bleu hover:bg-blue-600 text-white py-2 px-4 rounded-lg transition-colors"
          >
            <FiPlus /> Nouvelle caisse
          </button>
        </div>
      </div>

      {/* Filtres avancés */}
      <div className="bg-gris p-4 rounded-lg mb-6 flex flex-wrap gap-4 items-center">
        <div className="flex items-center gap-2 text-gray-400">
          <FiFilter /> Filtres avancés:
        </div>
        
        <div className="flex items-center gap-2">
          <label className="text-white text-sm">Solde min:</label>
          <input
            type="number"
            name="soldeMin"
            value={filters.soldeMin}
            onChange={handleFilterChange}
            className="bg-noir border border-gray-700 rounded-lg text-white text-sm py-1 px-3 w-32 focus:outline-none focus:border-bleu"
          />
        </div>
        
        <div className="flex items-center gap-2">
          <label className="text-white text-sm">Solde max:</label>
          <input
            type="number"
            name="soldeMax"
            value={filters.soldeMax}
            onChange={handleFilterChange}
            className="bg-noir border border-gray-700 rounded-lg text-white text-sm py-1 px-3 w-32 focus:outline-none focus:border-bleu"
          />
        </div>
        
        <button
          onClick={() => setFilters({ statut: 'tous', soldeMin: '', soldeMax: '' })}
          className="flex items-center gap-1 text-bleu hover:text-blue-400 transition-colors ml-auto"
        >
          <FiRefreshCw size={16} /> Réinitialiser
        </button>
      </div>

      {/* Tableau des caisses */}
      <div className="bg-gris rounded-xl shadow-lg overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-700">
            <thead className="bg-noir">
              <tr>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer"
                  onClick={() => handleSort('id')}
                >
                  <div className="flex items-center">
                    ID
                    {sortConfig.key === 'id' && (
                      sortConfig.direction === 'ascending' ? <FiArrowUp className="ml-1" /> : <FiArrowDown className="ml-1" />
                    )}
                  </div>
                </th>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer"
                  onClick={() => handleSort('nom')}
                >
                  <div className="flex items-center">
                    Nom
                    {sortConfig.key === 'nom' && (
                      sortConfig.direction === 'ascending' ? <FiArrowUp className="ml-1" /> : <FiArrowDown className="ml-1" />
                    )}
                  </div>
                </th>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer"
                  onClick={() => handleSort('solde')}
                >
                  <div className="flex items-center">
                    Solde
                    {sortConfig.key === 'solde' && (
                      sortConfig.direction === 'ascending' ? <FiArrowUp className="ml-1" /> : <FiArrowDown className="ml-1" />
                    )}
                  </div>
                </th>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer"
                  onClick={() => handleSort('limite')}
                >
                  <div className="flex items-center">
                    Limite
                    {sortConfig.key === 'limite' && (
                      sortConfig.direction === 'ascending' ? <FiArrowUp className="ml-1" /> : <FiArrowDown className="ml-1" />
                    )}
                  </div>
                </th>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer"
                  onClick={() => handleSort('caissier')}
                >
                  <div className="flex items-center">
                    Caissier
                    {sortConfig.key === 'caissier' && (
                      sortConfig.direction === 'ascending' ? <FiArrowUp className="ml-1" /> : <FiArrowDown className="ml-1" />
                    )}
                  </div>
                </th>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer"
                  onClick={() => handleSort('statut')}
                >
                  <div className="flex items-center">
                    Statut
                    {sortConfig.key === 'statut' && (
                      sortConfig.direction === 'ascending' ? <FiArrowUp className="ml-1" /> : <FiArrowDown className="ml-1" />
                    )}
                  </div>
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-700">
              {caissesFiltered.length > 0 ? (
                caissesFiltered.map((caisse) => (
                  <tr key={caisse.id} className="hover:bg-noir transition-colors">
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                      {caisse.id}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                      {caisse.nom}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      <span className={`font-semibold ${caisse.solde < caisse.seuilAlerte ? 'text-softRed' : 'text-vert'}`}>
                        {formatMontant(caisse.solde)} F
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                      {formatMontant(caisse.limite)} F
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                      {caisse.caissier}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      <span className={`px-2 py-1 rounded-full text-xs ${
                        caisse.statut === 'actif' 
                          ? 'bg-vert bg-opacity-10 text-vert' 
                          : 'bg-softRed bg-opacity-10 text-softRed'
                      }`}>
                        {caisse.statut === 'actif' ? 'Actif' : 'Inactif'}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400">
                      <div className="flex gap-2">
                        <button 
                          onClick={() => handleEditerCaisse(caisse)}
                          className="p-1 hover:text-bleu transition-colors"
                          title="Éditer"
                        >
                          <FiEdit />
                        </button>
                        <button 
                          onClick={() => handleSupprimerCaisse(caisse.id)}
                          className="p-1 hover:text-softRed transition-colors"
                          title="Supprimer"
                        >
                          <FiTrash2 />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="px-6 py-8 text-center text-gray-400">
                    Aucune caisse trouvée avec les critères de recherche actuels.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CaisseList;