import axios from "axios";

export default async function ajouterdepot(
  type,
  idUser,
  idCompte1xbet,
  codeRetrait,
  moyenPaiement,
  montant,
  mode,
  numeroRetraitMomo,
  nomMtnMomo,
  token, // Token passé en paramètre
  numeroMtn = null // Nouveau paramètre pour les paiements automatiques/semi-automatiques
) {
  try {
    // Utiliser la variable d'environnement pour l'URL de base
    const baseURL = process.env.REACT_APP_BASE_URL;
    
    // Construire l'URL avec les paramètres
    // Utiliser un endpoint différent selon le type de transaction
    const endpoint = type === "retrait" 
      ? `${baseURL}ajouter-transactions-retrait` 
      : `${baseURL}ajouter-transactions`;

    // Construire l'objet de paramètres de base
    const params = {
      type: type,
      idUser: idUser,
      idCompte1xbet: idCompte1xbet,
      codeRetrait: codeRetrait,
      moyenPaiement: moyenPaiement,
      montant: montant,
      mode: mode,
      numeroRetraitMomo: numeroRetraitMomo,
      nomMtnMomo: nomMtnMomo,
    };

    // Si numeroMtn est fourni, l'ajouter aux paramètres
    // Ce paramètre est requis pour les modes automatique et semi-automatique
    if (numeroMtn) {
      params.numeroMtn = numeroMtn;
    }

    // Configurer les headers pour inclure le token
    const headers = {
      Authorization: `Bearer ${token}`, // Assurez-vous que le token est bien formaté avec "Bearer"
    };

    console.log(`Envoi de la requête de ${type} avec paramètres:`, params);

    // Effectuer la requête GET avec les paramètres dans l'URL et les headers
    const response = await axios.get(endpoint, {
      params,
      headers, // Ajout des headers ici
    });

    // Traiter la réponse
    console.log("Réponse du serveur:", response.data);
    return response.data;
  } catch (error) {
    // Journaliser l'erreur complète pour faciliter le débogage
    console.error("Erreur lors de la requête:", error);
    if (error.response) {
      // La requête a été faite et le serveur a répondu avec un code d'état
      console.error("Données de réponse d'erreur:", error.response.data);
      console.error("Code d'état:", error.response.status);
    } else if (error.request) {
      // La requête a été faite mais aucune réponse n'a été reçue
      console.error("Requête sans réponse:", error.request);
    } else {
      // Une erreur s'est produite lors de la configuration de la requête
      console.error("Erreur de configuration:", error.message);
    }
    throw error; // Propager l'erreur pour gérer correctement dans le composant
  }
}
