import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiSend, FiArrowLeft } from 'react-icons/fi';
import { FaUsers, FaUserTie, FaUser } from 'react-icons/fa';
import InfosContext from "../../../contexts/InfosAppProvider";
import axios from 'axios';

const CreateNotification = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: '',
    body: '',
    targetType: 'user', // 'user', 'role'
    userId: '',
    role: 'client' // 'client', 'agent', 'admin'
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  // Contexte global
  const { listeUser, token, userInfos } = useContext(InfosContext);

  // Liste des utilisateurs (clients et agents)
  const users = Object.values(listeUser || {}).filter(user => 
    user.role === 'client' || user.role === 'agent' || user.role === 'admin'
  );

  // Handler pour les changements de champs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Handler pour soumettre le formulaire
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const baseURL = process.env.REACT_APP_BASE_URL || 'https://b2a2-197-234-221-167.ngrok-free.app/api/';
      
      let endpoint, payload;
      
      if (formData.targetType === 'user') {
        endpoint = `${baseURL}send-push-notification-admin`;
        payload = {
          userId: parseInt(formData.userId),
          title: formData.title,
          body: formData.body
        };
      } else {
        endpoint = `${baseURL}send-push-notification-by-role`;
        payload = {
          role: formData.role,
          title: formData.title,
          body: formData.body
        };
      }

      const response = await axios.post(endpoint, payload, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.data && !response.data.error) {
        setSuccess(response.data.message);
        // Réinitialiser le formulaire après succès
        setFormData({
          title: '',
          body: '',
          targetType: 'user',
          userId: '',
          role: 'client'
        });
        
        // Attendre un moment puis rediriger vers la liste des notifications
        setTimeout(() => {
          navigate('/admin/notifications');
        }, 2000);
      } else {
        setError(response.data.message || 'Une erreur est survenue lors de l\'envoi de la notification');
      }
    } catch (err) {
      setError(err.response?.data?.message || err.message || 'Une erreur est survenue');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full -mt-4">
      {/* En-tête de la page */}
      <div className="mb-6">
        <div className="flex items-center gap-4">
          <button 
            onClick={() => navigate('/admin/notifications')}
            className="bg-gris hover:bg-noir text-gray-300 p-2 rounded-lg transition-colors"
          >
            <FiArrowLeft size={20} />
          </button>
          <div>
            <h1 className="text-2xl font-bold text-white mb-1">Créer une notification</h1>
            <p className="text-gray-400">Envoyez une notification push aux utilisateurs</p>
          </div>
        </div>
      </div>

      {/* Formulaire de création */}
      <div className="bg-gris rounded-xl shadow-lg p-6">
        <form onSubmit={handleSubmit} className="max-w-2xl mx-auto space-y-5">
          {/* Titre de la notification */}
          <div>
            <label htmlFor="title" className="block text-gray-300 mb-1.5 font-medium">
              Titre de la notification
            </label>
            <input
              type="text"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              className="w-full py-2 px-4 bg-noir border border-gray-700 rounded-lg text-white focus:outline-none focus:border-bleu"
              placeholder="Ex: Nouvelle fonctionnalité disponible"
              required
            />
          </div>
          
          {/* Contenu de la notification */}
          <div>
            <label htmlFor="body" className="block text-gray-300 mb-1.5 font-medium">
              Message
            </label>
            <textarea
              id="body"
              name="body"
              value={formData.body}
              onChange={handleChange}
              className="w-full py-2 px-4 bg-noir border border-gray-700 rounded-lg text-white focus:outline-none focus:border-bleu"
              placeholder="Contenu détaillé de votre notification..."
              rows="4"
              required
            />
          </div>
          
          {/* Type de cible */}
          <div>
            <label className="block text-gray-300 mb-2 font-medium">
              Destinataires
            </label>
            <div className="flex gap-4 mb-3">
              <button
                type="button"
                className={`flex-1 py-2.5 px-4 rounded-lg flex items-center justify-center gap-2 ${
                  formData.targetType === 'user' ? 'bg-bleu text-white' : 'bg-noir text-gray-300 hover:bg-noir2'
                }`}
                onClick={() => setFormData({...formData, targetType: 'user'})}
              >
                <FaUser size={18} /> Utilisateur spécifique
              </button>
              <button
                type="button"
                className={`flex-1 py-2.5 px-4 rounded-lg flex items-center justify-center gap-2 ${
                  formData.targetType === 'role' ? 'bg-bleu text-white' : 'bg-noir text-gray-300 hover:bg-noir2'
                }`}
                onClick={() => setFormData({...formData, targetType: 'role'})}
              >
                <FaUsers size={18} /> Par groupe d'utilisateurs
              </button>
            </div>
          </div>
          
          {/* Sélection de l'utilisateur ou du rôle */}
          {formData.targetType === 'user' ? (
            <div className="bg-noir p-4 rounded-lg">
              <label htmlFor="userId" className="block text-gray-300 mb-1.5 font-medium">
                Sélectionnez un utilisateur
              </label>
              <select
                id="userId"
                name="userId"
                value={formData.userId}
                onChange={handleChange}
                className="w-full py-2.5 px-4 bg-gris border border-gray-700 rounded-lg text-white focus:outline-none focus:border-bleu"
                required
              >
                <option value="">-- Choisir un utilisateur --</option>
                {users.map(user => (
                  <option key={user.id} value={user.id}>
                    {user.nom} ({user.role === 'client' ? 'Client' : user.role === 'agent' ? 'Agent' : 'Administrateur'})
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <div className="bg-noir p-4 rounded-lg">
              <label className="block text-gray-300 mb-1.5 font-medium">
                Sélectionnez un groupe d'utilisateurs
              </label>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <button
                  type="button"
                  className={`py-3 px-4 rounded-lg flex items-center justify-center gap-3 ${
                    formData.role === 'client' ? 'bg-vert text-white' : 'bg-gris text-gray-300 hover:bg-noir2'
                  }`}
                  onClick={() => setFormData({...formData, role: 'client'})}
                >
                  <FaUsers size={20} /> 
                  <div className="text-left">
                    <div className="font-medium">Clients</div>
                    <div className="text-xs opacity-80">Tous les clients</div>
                  </div>
                </button>
                <button
                  type="button"
                  className={`py-3 px-4 rounded-lg flex items-center justify-center gap-3 ${
                    formData.role === 'agent' ? 'bg-softPurple text-white' : 'bg-gris text-gray-300 hover:bg-noir2'
                  }`}
                  onClick={() => setFormData({...formData, role: 'agent'})}
                >
                  <FaUserTie size={20} />
                  <div className="text-left">
                    <div className="font-medium">Agents</div>
                    <div className="text-xs opacity-80">Tous les agents</div>
                  </div>
                </button>
                <button
                  type="button"
                  className={`py-3 px-4 rounded-lg flex items-center justify-center gap-3 ${
                    formData.role === 'admin' ? 'bg-softRed text-white' : 'bg-gris text-gray-300 hover:bg-noir2'
                  }`}
                  onClick={() => setFormData({...formData, role: 'admin'})}
                >
                  <FaUserTie size={20} />
                  <div className="text-left">
                    <div className="font-medium">Administrateurs</div>
                    <div className="text-xs opacity-80">Tous les admins</div>
                  </div>
                </button>
              </div>
            </div>
          )}
          
          {/* Messages d'erreur ou de succès */}
          {error && (
            <div className="p-4 rounded-lg bg-softRed bg-opacity-10 text-softRed border border-softRed border-opacity-20">
              <p className="font-medium">Erreur lors de l'envoi</p>
              <p className="text-sm mt-1 opacity-90">{error}</p>
            </div>
          )}
          
          {success && (
            <div className="p-4 rounded-lg bg-vert bg-opacity-10 text-vert border border-vert border-opacity-20">
              <p className="font-medium">Notification envoyée avec succès</p>
              <p className="text-sm mt-1 opacity-90">{success}</p>
            </div>
          )}
          
          {/* Bouton d'envoi */}
          <div className="pt-3 flex justify-end gap-4">
            <button
              type="button"
              onClick={() => navigate('/admin/notifications')}
              className="py-2.5 px-6 bg-gris hover:bg-noir text-white rounded-lg flex items-center justify-center gap-2 transition-colors"
            >
              Annuler
            </button>
            <button
              type="submit"
              className="py-2.5 px-8 bg-bleu hover:bg-blue-600 text-white rounded-lg flex items-center justify-center gap-2 transition-colors"
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <span className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white"></span>
                  Envoi en cours...
                </>
              ) : (
                <>
                  <FiSend size={18} /> Envoyer la notification
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateNotification;