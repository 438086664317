import React, { useContext, useState } from 'react';
import InfosContext from '../../contexts/InfosAppProvider';
import DashboardHeader from '../components/DashboardHeader';
import BottomNavBar from '../components/BottomNavBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faUser, 
  faShield, 
  faBell, 
  faLanguage, 
  faQuestionCircle, 
  faSignOutAlt,
  faAngleRight,
  faMoon,
  faCheck
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

export default function IndexParametre() {
  const { userInfos } = useContext(InfosContext);
  const [darkMode, setDarkMode] = useState(true);
  const [notifications, setNotifications] = useState(true);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('idUser');
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('token');
    localStorage.removeItem('userRole');
    navigate('/login');
  };

  if (!userInfos) {
    return (
      <div className="min-h-screen bg-noir flex items-center justify-center">
        <div className="w-16 h-16 border-4 border-bleu border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  const settings = [
    {
      id: 'profile',
      title: 'Profil',
      icon: faUser,
      description: 'Informations personnelles',
      action: () => {},
      rightElement: <FontAwesomeIcon icon={faAngleRight} className="text-gray-500" />
    },
    {
      id: 'security',
      title: 'Sécurité',
      icon: faShield,
      description: 'Mot de passe et authentification',
      action: () => {},
      rightElement: <FontAwesomeIcon icon={faAngleRight} className="text-gray-500" />
    },
    {
      id: 'notifications',
      title: 'Notifications',
      icon: faBell,
      description: 'Alertes et notifications',
      action: () => setNotifications(!notifications),
      rightElement: (
        <div className={`w-12 h-6 rounded-full flex items-center p-1 ${notifications ? 'bg-vert' : 'bg-gray-700'} transition-colors`}>
          <div className={`w-4 h-4 rounded-full bg-white shadow-md transform transition-transform ${notifications ? 'translate-x-6' : 'translate-x-0'}`}></div>
        </div>
      )
    },
    {
      id: 'appearance',
      title: 'Apparence',
      icon: faMoon,
      description: 'Mode sombre',
      action: () => setDarkMode(!darkMode),
      rightElement: (
        <div className={`w-12 h-6 rounded-full flex items-center p-1 ${darkMode ? 'bg-bleu' : 'bg-gray-700'} transition-colors`}>
          <div className={`w-4 h-4 rounded-full bg-white shadow-md transform transition-transform ${darkMode ? 'translate-x-6' : 'translate-x-0'}`}></div>
        </div>
      )
    },
    {
      id: 'language',
      title: 'Langue',
      icon: faLanguage,
      description: 'Français',
      action: () => {},
      rightElement: <FontAwesomeIcon icon={faCheck} className="text-vert" />
    },
    {
      id: 'help',
      title: 'Aide',
      icon: faQuestionCircle,
      description: 'Centre d\'assistance',
      action: () => {},
      rightElement: <FontAwesomeIcon icon={faAngleRight} className="text-gray-500" />
    }
  ];

  return (
    <div className="min-h-screen bg-noir text-white">
      <DashboardHeader user={userInfos} />
      
      <div className="container mx-auto px-4 py-6 md:pl-72 pb-24 md:pb-6">
        <h1 className="text-2xl font-bold mb-6">Paramètres</h1>
        
        {/* Profil */}
        <div className="bg-gris rounded-xl p-5 mb-6 shadow-lg">
          <div className="flex items-center">
            <div className="w-16 h-16 rounded-full bg-bleu/20 flex items-center justify-center mr-4">
              <span className="text-white text-xl font-bold">
                {userInfos.nom.split(' ').map(name => name[0]).join('')}
              </span>
            </div>
            <div>
              <h2 className="text-lg font-bold">{userInfos.nom}</h2>
              <p className="text-gray-400">{userInfos.email}</p>
              <span className="inline-block px-3 py-1 bg-bleu/20 text-bleu text-xs rounded-full mt-2">
                {userInfos.role.charAt(0).toUpperCase() + userInfos.role.slice(1)}
              </span>
            </div>
          </div>
        </div>
        
        {/* Paramètres */}
        <div className="bg-gris rounded-xl overflow-hidden shadow-lg mb-6">
          <div className="divide-y divide-bleu/10">
            {settings.map(setting => (
              <div 
                key={setting.id}
                className="p-4 hover:bg-noir/30 transition cursor-pointer"
                onClick={setting.action}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="w-10 h-10 rounded-full bg-noir/30 flex items-center justify-center mr-4">
                      <FontAwesomeIcon icon={setting.icon} className="text-gray-300" />
                    </div>
                    <div>
                      <h3 className="font-medium">{setting.title}</h3>
                      <p className="text-sm text-gray-400">{setting.description}</p>
                    </div>
                  </div>
                  {setting.rightElement}
                </div>
              </div>
            ))}
          </div>
        </div>
        
        {/* Déconnexion */}
        <div 
          className="bg-gris rounded-xl p-4 mb-6 shadow-lg flex items-center justify-between cursor-pointer hover:bg-gray-800 transition"
          onClick={handleLogout}
        >
          <div className="flex items-center">
            <div className="w-10 h-10 rounded-full bg-softRed/20 flex items-center justify-center mr-4">
              <FontAwesomeIcon icon={faSignOutAlt} className="text-softRed" />
            </div>
            <span className="text-softRed font-medium">Déconnexion</span>
          </div>
          <FontAwesomeIcon icon={faAngleRight} className="text-gray-500" />
        </div>
        
        <div className="text-center text-gray-500 text-sm mt-8">
          <p>Version 1.0.0</p>
          <p>© 2025 PayForBet. Tous droits réservés.</p>
        </div>
      </div>
      
      <BottomNavBar />
    </div>
  );
}