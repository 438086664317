import React, { useState, useEffect } from 'react';
import '../index.css';

function InstallPWA() {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallBanner, setShowInstallBanner] = useState(false);

  useEffect(() => {
    // Détectez si l'application est déjà installée
    const isAppInstalled = window.matchMedia('(display-mode: standalone)').matches;
    
    // Écoutez l'événement beforeinstallprompt
    window.addEventListener('beforeinstallprompt', (e) => {
      // Empêchez Chrome de montrer automatiquement l'invite d'installation
      e.preventDefault();
      // Stockez l'événement pour pouvoir le déclencher plus tard
      setDeferredPrompt(e);
      // Montrez votre propre bannière d'installation
      setShowInstallBanner(true);
    });

    // Si l'utilisateur installe l'application, masquez la bannière
    window.addEventListener('appinstalled', () => {
      setShowInstallBanner(false);
      setDeferredPrompt(null);
      console.log('Application installée avec succès!');
    });
  }, []);

  const handleInstallClick = () => {
    if (!deferredPrompt) return;
    
    // Montrez l'invite d'installation natif
    deferredPrompt.prompt();
    
    // Attendez que l'utilisateur réponde à l'invite
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('L\'utilisateur a accepté l\'installation');
      } else {
        console.log('L\'utilisateur a refusé l\'installation');
      }
      // Réinitialisez l'événement prompt
      setDeferredPrompt(null);
    });
    
    // Masquez notre bannière d'installation
    setShowInstallBanner(false);
  };

  const handleCloseBanner = () => {
    setShowInstallBanner(false);
  };

  // Si l'application est déjà installée ou si le navigateur ne prend pas en charge l'installation
  // ou si l'utilisateur a fermé la bannière, ne montrez rien
  if (!showInstallBanner) return null;

  return (
    <div className="pwa-install-banner">
      <div className="pwa-install-content">
        <p>Installez cette application sur votre appareil pour un accès plus rapide!</p>
        <div className="pwa-install-buttons">
          <button onClick={handleInstallClick} className="pwa-install-button">
            Installer
          </button>
          <button onClick={handleCloseBanner} className="pwa-close-button">
            Plus tard
          </button>
        </div>
      </div>
    </div>
  );
}

export default InstallPWA;