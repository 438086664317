import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";

import VisiteursRoutes from "./routes/VisiteursRoutes";

import ClientsRoutes from "./routes/ClientsRoutes";

import AdminRoutes from "./routes/AdminRoutes"

import AgentsRoutes from  "./routes/AgentsRoutes"

import AssistantRoutes from "./routes/AssistantRoutes"

import { InfosAppProvider } from "./contexts/InfosAppProvider";

import { ChangementProvider } from "./contexts/ChangementProvider";

import ProtectedRoute from './ProtectedRoute'; // Le composant que tu as créé
import NotificationPermissionDialog from "./contexts/NotificationPermissionDialog";

export default function Navigator() {
  // Écouter les changements de route pour masquer le splash lors des navigations internes
  const handleRouteChange = () => {
    // Cette fonction sera appelée à chaque changement de route
    // Elle sera utilisée par les composants qui veulent masquer le splash
    console.log("Route changée - splash devrait être caché pour les navigations");
  };

  return (
    <BrowserRouter>
    <InfosAppProvider >
      <ChangementProvider> 
      <div style={{ backgroundColor: "#1C1C1C", minHeight: "100vh", width: "100%", margin: 0, padding: 0, position: "relative", top: 0, left: 0 }}>
        <NotificationPermissionDialog />
        
        <Routes onChange={handleRouteChange}>
        {
          //<Route path="/" element={<Splash />} />
        }

        {/* Routes utilisateurs */}
        <Route path="/*" element={<VisiteursRoutes />} />
        {/* Routes protégées : accessibles seulement si l'utilisateur est authentifié */}
      <Route
        path="/clients/*"
        element={
          <ProtectedRoute>
            <ClientsRoutes />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/*"
        element={
          <ProtectedRoute>
            <AdminRoutes />
          </ProtectedRoute>
        }
      />
      <Route
        path="/agents/*"
        element={
          <ProtectedRoute>
            <AgentsRoutes />
          </ProtectedRoute>
        }
      />
      <Route
        path="/assistant/*"
        element={
          <ProtectedRoute>
            <AssistantRoutes />
          </ProtectedRoute>
        }
      />
      </Routes>
      </div>
      </ChangementProvider>
      </InfosAppProvider>
    </BrowserRouter>
  );
}
