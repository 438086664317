import React, { useState, useEffect } from 'react';
import { FiSearch, FiRefreshCw, FiArrowUp, FiArrowDown } from 'react-icons/fi';
import { FaHistory, FaCoins, FaSpinner } from 'react-icons/fa';
import bonusService from '../../services/bonusService';
import axios from 'axios';
import { toast } from 'react-toastify';

// Configuration de l'API
const API_URL = process.env.REACT_APP_BASE_URL || 'https://b2a2-197-234-221-167.ngrok-free.app/api';

const BonusPaidList = () => {
  // État pour les données
  const [historique, setHistorique] = useState([]);
  const [loading, setLoading] = useState(true);
  
  // États pour la recherche et le filtrage
  const [recherche, setRecherche] = useState('');
  const [filtreMois, setFiltreMois] = useState('tous');
  
  // État pour le tri
  const [tri, setTri] = useState({
    key: 'datePaiement',
    direction: 'descending'
  });

  // Charger les données initiales
  useEffect(() => {
    fetchData();
  }, []);

  // Fonction pour récupérer les données depuis l'API
  const fetchData = async () => {
    setLoading(true);
    try {
      console.log("URL de l'API utilisée:", API_URL);
      
      // Récupérer directement tous les bonus payés depuis l'API alternative
      let bonusPayes = [];
      try {
        // Essayer d'abord avec l'API admin/retraits
        const bonusResponse = await axios.get(`${API_URL}/v1/admin/retraits/historique`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        
        console.log("Réponse historique:", bonusResponse.data);
        
        if (bonusResponse.data && bonusResponse.data.historique) {
          bonusPayes = bonusResponse.data.historique.filter(bonus => 
            bonus.statut === 'payé' || bonus.statut === 'à payer' || bonus.statut === 'approuvé'
          );
          console.log("Bonus payés récupérés (alternatif):", bonusPayes);
        }
      } catch (err) {
        console.error("Erreur lors de la récupération directe des bonus payés:", err);
        // Essayer avec le service standard
        try {
          bonusPayes = await bonusService.getBonusPayes();
          console.log("Bonus payés récupérés (service):", bonusPayes);
        } catch (serviceErr) {
          console.error("Erreur service getBonusPayes:", serviceErr);
        }
      }
      
      // Récupérer les statistiques des bonus (pour les données globales)
      let statsData = null;
      try {
        statsData = await bonusService.getStatistiques();
        console.log("Statistiques récupérées:", statsData);
      } catch (statsErr) {
        console.error("Erreur lors de la récupération des statistiques:", statsErr);
      }
      
      // Si aucun bonus payé n'est trouvé directement, faire une recherche approfondie
      if (!bonusPayes || bonusPayes.length === 0) {
        console.log("Aucun bonus payé trouvé directement, tentative de recherche approfondie...");
        
        try {
          // Récupérer tout l'historique des retraits
          const historicResponse = await axios.get(`${API_URL}/v1/admin/retraits/historique`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
          console.log("Historique complet récupéré:", historicResponse.data);
          
          if (historicResponse.data.historique) {
            const bonusPayesFromHistory = historicResponse.data.historique.filter(
              b => b.statut === 'payé' || b.statut === 'à payer' || b.statut === 'approuvé'
            );
            console.log("Bonus payés trouvés dans l'historique:", bonusPayesFromHistory);
          }
        } catch (error) {
          console.error("Erreur lors de la recherche approfondie:", error);
        }
      }
      
      // Convertir les données en format utilisable pour l'historique
      const historiqueData = [];
      
      // Récupérer uniquement les bonus payés depuis les statistiques globales
      if (statsData && statsData.success && statsData.statistiques && statsData.statistiques.periodes) {
        statsData.statistiques.periodes.forEach(periode => {
          if (periode.nombrePayes > 0) {
            historiqueData.push({
              id: `periode-${periode.mois}-${periode.annee}-paye`,
              utilisateur: { id: 'global', nom: 'Tous les utilisateurs', type: 'global' },
              montant: periode.montantPaye,
              pourcentage: 1.0,
              volumeReference: periode.montantPaye * 100,
              mois: `${getNomMois(periode.mois)} ${periode.annee}`,
              datePaiement: new Date(Date.now()).toISOString().split('T')[0],
              statut: 'payé',
              nombreUtilisateurs: periode.nombrePayes
            });
          }
        });
      }
      
      // Ajouter les bonus individuels payés
      if (bonusPayes && bonusPayes.length > 0) {
        bonusPayes.forEach(bonus => {
          // Vérifier que bonus est un objet valide
          if (bonus && typeof bonus === 'object') {
            historiqueData.push({
              id: bonus.id || `bonus-${Math.random().toString(36).substring(7)}`,
              utilisateur: { 
                id: bonus.idUser || 'unknown', 
                nom: bonus.nomUser || 'Client inconnu', 
                type: 'client' 
              },
              montant: bonus.montant || 0,
              pourcentage: 1.0,
              volumeReference: (bonus.montant || 0) * 100,
              mois: bonus.mois && bonus.annee 
                ? `${getNomMois(parseInt(bonus.mois))} ${bonus.annee}` 
                : 'Date inconnue',
              datePaiement: bonus.dateApprobation || bonus.datePaiement 
                ? new Date(bonus.dateApprobation || bonus.datePaiement).toISOString().split('T')[0]
                : new Date().toISOString().split('T')[0],
              statut: 'payé',
              numeroReception: bonus.numeroReception || 'Non spécifié',
              demandeParClient: bonus.demandeFaitParClient === true
            });
          } else {
            console.warn("Bonus invalide ignoré:", bonus);
          }
        });
      }
      
      console.log("Historique des données préparé:", historiqueData); // Débogage
      setHistorique(historiqueData);
      
    } catch (error) {
      console.error('Erreur lors du chargement des données:', error);
      toast.error('Erreur lors du chargement des données');
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour obtenir le nom du mois
  const getNomMois = (numeroMois) => {
    const mois = [
      'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
      'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
    ];
    return mois[numeroMois - 1] || 'Inconnu';
  };

  // Fonction pour formater les montants
  const formatMontant = (montant) => {
    if (!montant && montant !== 0) return '0';
    return new Intl.NumberFormat('fr-FR').format(montant);
  };

  // Fonction pour gérer le tri
  const handleTri = (key) => {
    let direction = 'ascending';
    if (tri.key === key && tri.direction === 'ascending') {
      direction = 'descending';
    }
    setTri({ key, direction });
  };

  // Mois uniques pour le filtre
  const moisUniques = [...new Set(historique.map(bonus => bonus.mois))];

  // Filtrer et trier l'historique
  const historiqueFiltree = historique
    .filter(bonus => {
      // Filtre de recherche
      if (recherche && !bonus.utilisateur.nom.toLowerCase().includes(recherche.toLowerCase())) {
        return false;
      }
      
      // Filtre par mois
      if (filtreMois !== 'tous' && bonus.mois !== filtreMois) {
        return false;
      }
      
      return true;
    })
    .sort((a, b) => {
      let aValue, bValue;
      
      if (tri.key === 'utilisateur') {
        aValue = a.utilisateur.nom;
        bValue = b.utilisateur.nom;
      } else if (tri.key === 'mois') {
        // On trie par date (plus récent d'abord par défaut)
        const [aMois, aAnnee] = a.mois.split(' ');
        const [bMois, bAnnee] = b.mois.split(' ');
        const moisOrdre = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
        
        if (aAnnee !== bAnnee) {
          aValue = parseInt(aAnnee);
          bValue = parseInt(bAnnee);
        } else {
          aValue = moisOrdre.indexOf(aMois);
          bValue = moisOrdre.indexOf(bMois);
        }
      } else {
        aValue = a[tri.key];
        bValue = b[tri.key];
      }
      
      if (aValue < bValue) {
        return tri.direction === 'ascending' ? -1 : 1;
      }
      if (aValue > bValue) {
        return tri.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

  // Calculer les statistiques
  const stats = {
    total: historiqueFiltree.length,
    montantTotal: historiqueFiltree.reduce((sum, bonus) => sum + (parseFloat(bonus.montant) || 0), 0),
    demandeFaitParClient: historiqueFiltree.filter(bonus => bonus.demandeParClient === true).length,
    montantDemandeClient: historiqueFiltree
      .filter(bonus => bonus.demandeParClient === true)
      .reduce((sum, bonus) => sum + (parseFloat(bonus.montant) || 0), 0)
  };

  return (
    <div className="w-full">
      {/* En-tête de la page */}
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-white mb-2">Historique des Bonus Payés</h1>
        <p className="text-gray-400">Consultez l'historique des bonus déjà payés aux clients</p>
      </div>

      {/* Cartes de statistiques */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        <div className="bg-gris rounded-xl p-5 shadow-lg flex items-center justify-between">
          <div>
            <p className="text-gray-400 text-sm">Total des bonus payés</p>
            <h3 className="text-white text-2xl font-bold mt-1">
              {loading ? <FaSpinner className="animate-spin text-white" /> : `${formatMontant(stats.montantTotal)} F`}
            </h3>
          </div>
          <div className="bg-vert bg-opacity-20 p-3 rounded-full">
            <FaCoins className="text-vert text-xl" />
          </div>
        </div>

        <div className="bg-gris rounded-xl p-5 shadow-lg flex items-center justify-between">
          <div>
            <p className="text-gray-400 text-sm">Bonus demandés par les clients</p>
            <h3 className="text-white text-2xl font-bold mt-1">
              {loading ? <FaSpinner className="animate-spin text-white" /> : `${stats.demandeFaitParClient}`}
            </h3>
          </div>
          <div className="bg-jaune bg-opacity-20 p-3 rounded-full">
            <FaCoins className="text-jaune text-xl" />
          </div>
        </div>

        <div className="bg-gris rounded-xl p-5 shadow-lg flex items-center justify-between">
          <div>
            <p className="text-gray-400 text-sm">Montant des demandes clients</p>
            <h3 className="text-white text-2xl font-bold mt-1">
              {loading ? <FaSpinner className="animate-spin text-white" /> : `${formatMontant(stats.montantDemandeClient)} F`}
            </h3>
          </div>
          <div className="bg-bleu bg-opacity-20 p-3 rounded-full">
            <FaCoins className="text-bleu text-xl" />
          </div>
        </div>
      </div>

      {/* Filtres et recherche */}
      <div className="flex flex-col md:flex-row gap-4 justify-between mb-6">
        <div className="flex-1">
          <div className="relative">
            <input
              type="text"
              placeholder="Rechercher un client..."
              className="w-full py-2 pl-10 pr-4 bg-gris border border-gray-700 rounded-lg text-white text-sm focus:outline-none focus:border-bleu"
              value={recherche}
              onChange={(e) => setRecherche(e.target.value)}
            />
            <div className="absolute left-3 top-2.5 text-gray-400">
              <FiSearch />
            </div>
          </div>
        </div>
        
        <div className="flex flex-wrap gap-3">
          <select
            value={filtreMois}
            onChange={(e) => setFiltreMois(e.target.value)}
            className="bg-gris border border-gray-700 rounded-lg text-white text-sm py-2 px-3 focus:outline-none focus:border-bleu"
          >
            <option value="tous">Tous les mois</option>
            {moisUniques.map((mois, index) => (
              <option key={index} value={mois}>{mois}</option>
            ))}
          </select>
          
          <button
            onClick={() => {
              setRecherche('');
              setFiltreMois('tous');
            }}
            className="flex items-center gap-1 text-bleu hover:text-blue-400 transition-colors"
          >
            <FiRefreshCw size={16} /> Réinitialiser
          </button>
          
          <button
            onClick={fetchData}
            className="flex items-center gap-1 bg-gris border border-gray-700 rounded-lg text-white text-sm py-2 px-3 hover:bg-gray-800 transition-colors"
          >
            <FiRefreshCw size={16} /> Actualiser
          </button>
        </div>
      </div>

      {/* Tableau de l'historique */}
      <div className="bg-gris rounded-xl shadow-lg overflow-hidden">
        <div className="flex items-center gap-2 p-4 border-b border-gray-700">
          <FaHistory className="text-bleu" />
          <h2 className="text-lg font-bold text-white">Historique des bonus payés</h2>
        </div>
        
        {loading ? (
          <div className="flex justify-center items-center py-10">
            <FaSpinner className="animate-spin text-bleu text-3xl" />
          </div>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-700">
              <thead className="bg-noir">
                <tr>
                  <th 
                    className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleTri('id')}
                  >
                    <div className="flex items-center">
                      ID
                      {tri.key === 'id' && (
                        tri.direction === 'ascending' ? <FiArrowUp className="ml-1" /> : <FiArrowDown className="ml-1" />
                      )}
                    </div>
                  </th>
                  <th 
                    className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleTri('utilisateur')}
                  >
                    <div className="flex items-center">
                      Bénéficiaire
                      {tri.key === 'utilisateur' && (
                        tri.direction === 'ascending' ? <FiArrowUp className="ml-1" /> : <FiArrowDown className="ml-1" />
                      )}
                    </div>
                  </th>
                  <th 
                    className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleTri('mois')}
                  >
                    <div className="flex items-center">
                      Période
                      {tri.key === 'mois' && (
                        tri.direction === 'ascending' ? <FiArrowUp className="ml-1" /> : <FiArrowDown className="ml-1" />
                      )}
                    </div>
                  </th>
                  <th 
                    className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleTri('montant')}
                  >
                    <div className="flex items-center">
                      Montant
                      {tri.key === 'montant' && (
                        tri.direction === 'ascending' ? <FiArrowUp className="ml-1" /> : <FiArrowDown className="ml-1" />
                      )}
                    </div>
                  </th>
                  <th 
                    className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleTri('datePaiement')}
                  >
                    <div className="flex items-center">
                      Date de paiement
                      {tri.key === 'datePaiement' && (
                        tri.direction === 'ascending' ? <FiArrowUp className="ml-1" /> : <FiArrowDown className="ml-1" />
                      )}
                    </div>
                  </th>
                  <th 
                    className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider"
                  >
                    Demande client
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-700">
                {historiqueFiltree.length > 0 ? (
                  historiqueFiltree.map((bonus) => (
                    <tr key={bonus.id} className="hover:bg-noir transition-colors">
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                        {bonus.id}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        <div className="text-white">{bonus.utilisateur.nom}</div>
                        <div className="text-gray-400 text-xs capitalize">
                          {bonus.utilisateur.type === 'global' 
                            ? (bonus.nombreUtilisateurs > 0 ? `${bonus.nombreUtilisateurs} utilisateurs` : 'Tous')
                            : bonus.utilisateur.type
                          }
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                        {bonus.mois}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        <div className="text-white font-medium">{formatMontant(bonus.montant)} F</div>
                        {bonus.numeroReception && (
                          <div className="text-gray-400 text-xs">
                            MoMo: {bonus.numeroReception}
                          </div>
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                        {bonus.datePaiement}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        <span className={`px-2 py-1 rounded-full text-xs ${
                          bonus.demandeParClient === true
                            ? 'bg-vert bg-opacity-10 text-vert'
                            : 'bg-gray-500 bg-opacity-10 text-gray-400'
                        }`}>
                          {bonus.demandeParClient === true ? 'Oui' : 'Non'}
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="px-6 py-8 text-center text-gray-400">
                      {loading ? 'Chargement des données...' : 'Aucun bonus trouvé avec les critères de recherche actuels.'}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default BonusPaidList;