import React, { useState, useEffect, useContext } from "react";
import ReactPaginate from "react-paginate";
import { faArrowUp, faArrowDown, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TransactionCard from "../TransactionCard";
import InfosContext from "../../contexts/InfosAppProvider";

const PaginatedTransactions = ({ transactions, itemsPerPage }) => {
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [filterType, setFilterType] = useState("all");
  const [loading, setLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  
  const { selectedStatuses, searchTerm } = useContext(InfosContext);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const truncateRef = (ref) =>
    ref.length > 8 ? ref.substring(0, 8) + "..." : ref;

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString("fr-FR", {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  // Check if a search term matches a field
  const includesSearchTerm = (value) => {
    if (!searchTerm) return true;
    if (typeof value === "number") {
      return value.toString().includes(searchTerm);
    }
    if (typeof value === "string") {
      return value.toLowerCase().includes(searchTerm.toLowerCase());
    }
    return false;
  };

  useEffect(() => {
    // Ne définir loading que si nous avons un vrai changement de données
    const isRealDataChange = itemOffset === 0;
    
    if (isRealDataChange) {
      setLoading(true);
    }
    
    // Filter transactions based on criteria
    const filteredTransactions = Object.entries(transactions).filter(
      ([id, transaction]) => {
        if (!transaction) return false;

        const typeMatch =
          filterType === "all" || transaction.type === filterType;
          
        const statusMatch = selectedStatuses.includes(transaction.statut);

        const searchMatch =
          includesSearchTerm(transaction.idTransaction) ||
          includesSearchTerm(transaction.idTransactionMobile) ||
          includesSearchTerm(transaction.idCaisse) ||
          includesSearchTerm(transaction.idCompte1xbet) ||
          includesSearchTerm(transaction.montant) ||
          includesSearchTerm(transaction.moyenPaiement) ||
          includesSearchTerm(transaction.statut) ||
          includesSearchTerm(transaction.dateHeure) ||
          (searchTerm &&
            new Date(transaction.dateHeure)
              .toLocaleString("fr-FR", { month: "long" })
              .includes(searchTerm.toLowerCase())) ||
          includesSearchTerm(transaction.type);

        return typeMatch && statusMatch && searchMatch;
      }
    );

    // Sort transactions by date (newest first)
    filteredTransactions.sort((a, b) => {
      return new Date(b[1].dateHeure) - new Date(a[1].dateHeure);
    });

    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(filteredTransactions.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(filteredTransactions.length / itemsPerPage));
    
    // Désactiver le chargement immédiatement
    setLoading(false);
  }, [
    itemOffset,
    itemsPerPage,
    transactions,
    filterType,
    selectedStatuses,
    searchTerm,
  ]);

  const handlePageClick = (event) => {
    const filteredCount = Object.entries(transactions).filter(([id, transaction]) => {
      if (!transaction) return false;

      const typeMatch =
        filterType === "all" || transaction.type === filterType;

      const statusMatch = selectedStatuses.includes(transaction.statut);

      const searchMatch =
        includesSearchTerm(transaction.idTransaction) ||
        includesSearchTerm(transaction.idTransactionMobile) ||
        includesSearchTerm(transaction.idCaisse) ||
        includesSearchTerm(transaction.idCompte1xbet) ||
        includesSearchTerm(transaction.montant) ||
        includesSearchTerm(transaction.moyenPaiement) ||
        includesSearchTerm(transaction.statut) ||
        includesSearchTerm(transaction.dateHeure) ||
        (searchTerm &&
          new Date(transaction.dateHeure)
            .toLocaleString("fr-FR", { month: "long" })
            .includes(searchTerm.toLowerCase())) ||
        includesSearchTerm(transaction.type);

      return typeMatch && statusMatch && searchMatch;
    }).length;

    const newOffset = (event.selected * itemsPerPage) % filteredCount;
    setItemOffset(newOffset);
  };

  // Conversion des statuts entre l'API et l'affichage
  const mapStatutToStatus = (statut) => {
    switch(statut) {
      case "réussi":
      case "accepté":
      case "accepter":
        return "réussi";
      case "échoué":
      case "refusé":
      case "refuser":
        return "échoué";
      case "en cours":
      default:
        return "en cours";
    }
  };

  return (
    <div className="pb-4">
      {loading ? (
        // Loading state
        <div className="flex justify-center items-center py-12">
          <div className="animate-pulse bg-gris w-12 h-12 rounded-full flex items-center justify-center">
            <div className="w-8 h-8 border-t-2 border-b-2 border-bleu rounded-full animate-spin"></div>
          </div>
        </div>
      ) : currentItems.length > 0 ? (
        // Transactions list
        <div className="space-y-4">
          {currentItems.map(([id, transaction]) => (
            <div 
              key={id} 
              className="hover-scale transition-all"
            >
              <TransactionCard
                icon={transaction.type === "depot" ? faArrowUp : faArrowDown}
                title={`${
                  transaction.type === "depot" ? "Dépôt" : "Retrait"
                } ref ${truncateRef(transaction.idTransaction)}`}
                date={formatDate(transaction.dateHeure)}
                amount={`${transaction.montant.toLocaleString()} F CFA`}
                id={`${transaction.idCompte1xbet}`}
                bgColor={
                  transaction.type === "depot" ? "bg-[#28C281FF]" : "bg-[#AA1D22FF]"
                }
                type={transaction.type}
                idTran={id}
                status={mapStatutToStatus(transaction.statut || "en cours")}
              />
            </div>
          ))}

          {/* Pagination */}
          <div className="mt-8 flex justify-center">
            <ReactPaginate
              breakLabel="..."
              nextLabel={
                <div className="flex items-center">
                  <span className="mr-1 hidden md:inline">Suivant</span>
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
              }
              onPageChange={handlePageClick}
              pageRangeDisplayed={isMobile ? 1 : 2}
              marginPagesDisplayed={isMobile ? 1 : 2}
              pageCount={pageCount}
              previousLabel={
                <div className="flex items-center">
                  <FontAwesomeIcon icon={faChevronLeft} />
                  <span className="ml-1 hidden md:inline">Précédent</span>
                </div>
              }
              renderOnZeroPageCount={null}
              containerClassName="flex items-center space-x-1 md:space-x-2"
              pageClassName="px-3 py-1.5 text-sm rounded-md bg-gris hover:bg-opacity-80 transition-colors"
              pageLinkClassName="text-gray-300"
              activeClassName="!bg-bleu !text-white"
              previousClassName="px-3 py-1.5 rounded-md bg-gris hover:bg-opacity-80 transition-colors text-gray-300 text-sm"
              nextClassName="px-3 py-1.5 rounded-md bg-gris hover:bg-opacity-80 transition-colors text-gray-300 text-sm"
              breakClassName="px-3 py-1.5 text-sm text-gray-400"
              disabledClassName="opacity-50 cursor-not-allowed"
            />
          </div>
        </div>
      ) : (
        // Empty state
        <div className="text-center py-12">
          <div className="w-16 h-16 bg-gris rounded-full mx-auto flex items-center justify-center mb-4">
            <FontAwesomeIcon icon={faArrowDown} className="text-gray-400 text-xl" />
          </div>
          <h3 className="text-lg font-medium text-white mb-2">Aucune transaction trouvée</h3>
          <p className="text-gray-400 text-sm max-w-md mx-auto">
            Essayez de modifier vos filtres ou votre recherche pour voir plus de résultats
          </p>
        </div>
      )}
    </div>
  );
};

export default PaginatedTransactions;
