import {
  faCheck,
  faArrowLeft,
  faMoneyBillTransfer,
  faCreditCard,
  faUserClock,
  faMobile,
  faWallet,
  faCoins,
  faMoneyBill,
  faExchangeAlt,
  faCheckCircle,
  faInfoCircle,
  faUser,
  faIdCard,
  faCalendarWeek,
  faLock,
  faShieldAlt
} from "@fortawesome/free-solid-svg-icons";

import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect, useContext } from "react";
import InfosRetrait from "./InfosRetrait";
import InfosRetraitpc from "./InfosRetraitPc";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Link, useNavigate } from "react-router-dom";
import ajouterdepot from "../../requtes/clients/ajouterdepot.js";
import InfosContext from "../../contexts/InfosAppProvider";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function RetraitManuel() {
  // Couleurs personnalisées
  const colors = {
    noir: "#1C1C1CFF",
    bleu: "#0087BFFF",
    vert: "#28C281FF",
    softRed: "#ff6f61",
    softYellow: "#f9d57d",
    softPurple: "#b19cd9",
    black: "#1C1C1CFF",
    gris: "#272728FF",
  };

  const navigate = useNavigate();
  const {
    infosAdmin,
    setInfosAdmin,
    userInfos,
    setUserInfos,
    token,
    fetchUserInfos,
  } = useContext(InfosContext);

  // États de base
  const [inputValue, setInputValue] = useState(userInfos.idCompte1xbet || "");
  const [nom, setNom] = useState(userInfos.nomMomo || "");
  const [numero, setNumero] = useState(userInfos.numeroMomo || "");
  const [montant, setMontant] = useState("");
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(66);
  const [visible, setVisible] = useState(false);
  const [mon, setMon] = useState(0);
  const [open, setOpen] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(false);
  const [operationType, setOperationType] = useState("manuel");

  // ID utilisateur
  const idUser = localStorage.getItem("idUser");

  // Montants prédéfinis
  const defaultAmounts = ["1000", "2000", "5000", "10000"];
  const updatedAmounts = infosAdmin.montant
    ? infosAdmin.montant.split(",").map((value) => parseInt(value.trim()))
    : defaultAmounts;
  
  const [selectedAmount, setSelectedAmount] = useState(null);

  // Détection de la taille de l'écran
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1024);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Mise à jour du montant réellement reçu (après déduction des frais)
  useEffect(() => {
    if (montant >= 900) {
      setVisible(true);
      // Calcul du montant à recevoir (montant - 100 FCFA de frais)
      setMon(montant - 100);
    } else {
      setVisible(false);
    }
  }, [montant]);

  // Gestionnaires d'événements
  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleChangeMontant = (event) => {
    setMontant(event.target.value);
  };

  const handleChangeCode = (event) => {
    setCode(event.target.value);
  };

  const handleChangeNom = (event) => {
    setNom(event.target.value);
  };

  const handleChangeNumero = (event) => {
    setNumero(event.target.value);
  };

  const handleClickAmount = (amount) => {
    setSelectedAmount(amount);
    setMontant(amount);
  };

  // Gestion du BottomSheet
  const canDismiss = () => true;
  const handleDismiss = () => {
    if (canDismiss()) {
      setOpen(false);
    }
  };

  // Soumission du formulaire
  const handleSubmit = async (event) => {
    event.preventDefault();
    
    // Vérification du montant minimum
    if (montant < 900) {
      toast.error("Le montant minimum de retrait est de 900 FCFA");
      return;
    }

    // Validation des champs
    if (!inputValue) {
      toast.error("Veuillez saisir l'ID de votre compte 1xBet");
      return;
    }
    
    if (!code || code.length !== 4) {
      toast.error("Le code de retrait 1xBet doit contenir 4 caractères");
      return;
    }

    if (!numero) {
      toast.error("Veuillez saisir votre numéro MTN MoMo");
      return;
    }

    if (!nom) {
      toast.error("Veuillez saisir le nom associé à votre compte MTN MoMo");
      return;
    }

    setLoading(true);

    try {
      // Préparation des paramètres selon le type d'opération (manuel ou automatique)
      const params = {
        type: "retrait",
        idUser: idUser,
        idCompte1xbet: inputValue,
        codeRetrait: code,
        moyenPaiement: "MTN",
        montant: montant,
        mode: operationType,
        numeroRetraitMomo: numero,
        nomMtnMomo: nom
      };
      
      // Pour les retraits automatiques, ajouter le numéro MTN comme paramètre supplémentaire
      // Ce paramètre est requis par l'API comme indiqué dans la route admin.js
      if (operationType === "automatique") {
        params.numeroMtn = numero;
      }
      
      // Appel de la fonction pour ajouter la transaction
      const res = await ajouterdepot(
        params.type,
        params.idUser,
        params.idCompte1xbet,
        params.codeRetrait,
        params.moyenPaiement,
        params.montant,
        params.mode,
        params.numeroRetraitMomo,
        params.nomMtnMomo,
        token,
        params.numeroMtn // Passage du paramètre supplémentaire
      );
      
      console.log("type est ", operationType);

      // Stocker les informations pour la page de succès
      localStorage.setItem("depotxbet", inputValue);
      localStorage.setItem("depotmontant", montant);
      localStorage.setItem("depotnumero", numero);
      localStorage.setItem("depotnom", nom);
      
      // Rafraîchir les informations de l'utilisateur
      fetchUserInfos(idUser);
      
      setLoading(false);
      
      // Si c'est un retrait automatique et que nous avons un externalId, stocker cette information
      if (operationType === "automatique" && res.externalId) {
        localStorage.setItem("externalId", res.externalId);
        localStorage.setItem("idTransactionMTN", res.idTransactionMTN);
      }
      
      navigate(`/clients/retrait-manuel-succes/${res.idTransaction}`);
    } catch (error) {
      setLoading(false);
      
      // Afficher un message d'erreur plus précis si disponible
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(`Erreur: ${error.response.data.message}`);
        
        // Si nous avons des détails supplémentaires, les afficher également
        if (error.response.data.details) {
          console.error("Détails de l'erreur:", error.response.data.details);
          toast.error(`Détails: ${error.response.data.details}`, { delay: 1000 });
        }
      } else {
        toast.error("Une erreur est survenue lors du traitement de votre demande de retrait");
      }
      
      console.error("Erreur complète:", error);
    }
  };

  return (
    <>
      <ToastContainer position="top-right" autoClose={3000} />

      {/* Version Mobile */}
      <div className="bg-gradient-to-b from-[#1C1C1CFF] to-[#272728FF] min-h-screen flex flex-col pb-24 md:hidden">
        <div
          onClick={() => {
            window.history.back();
          }}
          className="text-white h-12 font-albert text-lg px-3 fixed top-0 w-full bg-[#1C1C1CFF]/90 backdrop-blur flex items-center z-10 shadow-md"
        >
          <FontAwesomeIcon icon={faArrowLeft} className="px-3" />
          Retour
        </div>

        <div className="mt-16 px-5">
          <h1 className="text-2xl font-bold text-white mb-1">Retrait d'argent</h1>
          <p className="text-gray-300 text-sm">
            Retirez de l'argent de votre compte 1xBet en toute simplicité.
          </p>
        </div>

        <div className="relative mt-5 mx-4 p-4 bg-[#272728FF]/70 backdrop-blur-sm rounded-xl border border-white/10 shadow-lg">
          <form onSubmit={handleSubmit} className="flex flex-col">
            {/* Bouton d'information */}
            <div
              onClick={() => setOpen(true)}
              className="bg-[#0087BFFF]/20 backdrop-blur-sm mb-5 py-3 px-4 rounded-xl flex items-center justify-center cursor-pointer border border-[#0087BFFF]/30 transition hover:bg-[#0087BFFF]/30"
            >
              <FontAwesomeIcon icon={faInfoCircle} className="text-[#0087BFFF] mr-2" />
              <span className="text-white text-sm font-medium">Voir la procédure de retrait</span>
            </div>

            {/* Type d'opération */}
            <div className="mb-5">
              <label className="block text-gray-300 text-sm font-medium mb-2">
                Type de retrait
              </label>
              <div className="grid grid-cols-2 gap-2">
                <div
                  onClick={() => setOperationType("automatique")}
                  className={`flex flex-col items-center justify-center p-3 rounded-xl cursor-pointer transition duration-200 ease-in-out
                    ${operationType === "automatique" 
                      ? `bg-[${colors.bleu}] bg-opacity-20 shadow-[${colors.bleu}]/20 shadow-lg border border-[${colors.bleu}]/70` 
                      : "bg-[#272728FF]/80 border border-gray-700/40 hover:bg-[#272728FF]"}`}
                >
                  <FontAwesomeIcon icon={faExchangeAlt} className={`text-xl ${operationType === "automatique" ? "text-white" : "text-gray-400"}`} />
                  <span className={`text-xs mt-2 ${operationType === "automatique" ? "text-white" : "text-gray-400"}`}>Automatique</span>
                </div>
                
                <div
                  onClick={() => setOperationType("manuel")}
                  className={`flex flex-col items-center justify-center p-3 rounded-xl cursor-pointer transition duration-200 ease-in-out
                    ${operationType === "manuel" 
                      ? `bg-[${colors.bleu}] bg-opacity-20 shadow-[${colors.bleu}]/20 shadow-lg border border-[${colors.bleu}]/70` 
                      : "bg-[#272728FF]/80 border border-gray-700/40 hover:bg-[#272728FF]"}`}
                >
                  <FontAwesomeIcon icon={faUserClock} className={`text-xl ${operationType === "manuel" ? "text-white" : "text-gray-400"}`} />
                  <span className={`text-xs mt-2 ${operationType === "manuel" ? "text-white" : "text-gray-400"}`}>Manuel</span>
                </div>
              </div>
            </div>

            {/* ID du compte 1xBet */}
            <div className="mb-5">
              <div className="flex justify-between items-center mb-1.5">
                <label className="text-gray-300 text-sm font-medium">ID du compte 1xBet</label>
                <span className="text-xs text-[#0087BFFF]">
                  {inputValue ? <FontAwesomeIcon icon={faCheckCircle} className="mr-1" /> : ""}
                  requis
                </span>
              </div>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <FontAwesomeIcon icon={faIdCard} className="text-gray-400" />
                </div>
                <input
                  className="w-full h-12 pl-10 pr-3 py-2 bg-[#1C1C1CFF]/80 text-white placeholder-gray-500 border border-gray-700/60 rounded-xl focus:ring-2 focus:ring-[#0087BFFF] focus:border-[#0087BFFF] transition duration-200 outline-none"
                  type="number"
                  pattern="\d*"
                  inputMode="numeric"
                  placeholder="ID 1xBet"
                  value={inputValue}
                  onChange={handleChange}
                  required
                />
              </div>
              <p className="mt-1.5 text-xs text-gray-400">
                Vous pouvez modifier votre ID 1xBet par défaut dans les paramètres.
              </p>
            </div>

            {/* Code de retrait 1xBet */}
            <div className="mb-5">
              <div className="flex justify-between items-center mb-1.5">
                <label className="text-gray-300 text-sm font-medium">Code de retrait 1xBet</label>
                <span className="text-xs text-[#0087BFFF]">
                  {code && code.length === 4 ? <FontAwesomeIcon icon={faCheckCircle} className="mr-1" /> : ""}
                  requis
                </span>
              </div>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <FontAwesomeIcon icon={faLock} className="text-gray-400" />
                </div>
                <input
                  className="w-full h-12 pl-10 pr-3 py-2 bg-[#1C1C1CFF]/80 text-white placeholder-gray-500 border border-gray-700/60 rounded-xl focus:ring-2 focus:ring-[#0087BFFF] focus:border-[#0087BFFF] transition duration-200 outline-none"
                  type="text"
                  placeholder="Code 1xBet (4 caractères)"
                  value={code}
                  onChange={handleChangeCode}
                  maxLength={4}
                  required
                />
              </div>
              <p className="mt-1.5 text-xs text-gray-400">
                Code à 4 caractères reçu sur votre compte 1xBet pour autoriser le retrait.
              </p>
            </div>

            {/* Montant */}
            <div className="mb-5">
              <div className="flex justify-between items-center mb-1.5">
                <label className="text-gray-300 text-sm font-medium">Montant (min. 900 FCFA)</label>
                <span className="text-xs text-[#0087BFFF]">
                  {montant >= 900 ? <FontAwesomeIcon icon={faCheckCircle} className="mr-1" /> : ""}
                  requis
                </span>
              </div>
              
              {/* Montants prédéfinis */}
              <div className="grid grid-cols-4 gap-2 mb-3">
                {updatedAmounts.map((amount, index) => (
                  <div
                    key={index}
                    onClick={() => handleClickAmount(amount)}
                    className={`py-2 px-3 rounded-xl flex items-center justify-center cursor-pointer transition duration-200 text-sm font-medium
                      ${selectedAmount === amount 
                        ? `bg-[${colors.bleu}] text-white border border-[${colors.bleu}]/70 shadow-md` 
                        : "bg-[#1C1C1CFF]/80 text-gray-300 border border-gray-700/60 hover:bg-[#272728FF]"}`}
                  >
                    {amount.toLocaleString()} F
                  </div>
                ))}
              </div>
              
              {/* Input pour montant personnalisé */}
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <FontAwesomeIcon icon={faCoins} className="text-gray-400" />
                </div>
                <input
                  className="w-full h-12 pl-10 pr-3 py-2 bg-[#1C1C1CFF]/80 text-white placeholder-gray-500 border border-gray-700/60 rounded-xl focus:ring-2 focus:ring-[#0087BFFF] focus:border-[#0087BFFF] transition duration-200 outline-none"
                  type="number"
                  pattern="\d*"
                  inputMode="numeric"
                  placeholder="Montant personnalisé"
                  value={montant}
                  onChange={handleChangeMontant}
                  min="900"
                  required
                />
              </div>
              {montant && parseInt(montant) < 900 ? (
                <p className="mt-1.5 text-xs text-[#ff6f61]">
                  Le montant minimum est de 900 FCFA
                </p>
              ) : null}
            </div>

            {/* Affichage du montant à recevoir */}
            {visible && (
              <div className="mb-5">
                <div className="p-3 bg-[#0087BFFF]/10 rounded-xl border border-[#0087BFFF]/30 flex items-center">
                  <div className="p-2 bg-[#0087BFFF]/30 rounded-full mr-3">
                    <FontAwesomeIcon icon={faMoneyBill} className="text-[#0087BFFF]" />
                  </div>
                  <div>
                    <p className="text-[#0087BFFF] text-sm font-medium">Montant à recevoir</p>
                    <p className="text-white text-lg font-semibold">{mon.toLocaleString()} FCFA</p>
                  </div>
                </div>
              </div>
            )}

            {/* Numéro de retrait MTN MoMo */}
            <div className="mb-5">
              <div className="flex justify-between items-center mb-1.5">
                <label className="text-gray-300 text-sm font-medium">Numéro MTN MoMo</label>
                <span className="text-xs text-[#0087BFFF]">
                  {numero ? <FontAwesomeIcon icon={faCheckCircle} className="mr-1" /> : ""}
                  requis
                </span>
              </div>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <FontAwesomeIcon icon={faMobile} className="text-gray-400" />
                </div>
                <input
                  className="w-full h-12 pl-10 pr-3 py-2 bg-[#1C1C1CFF]/80 text-white placeholder-gray-500 border border-gray-700/60 rounded-xl focus:ring-2 focus:ring-[#0087BFFF] focus:border-[#0087BFFF] transition duration-200 outline-none"
                  type="text"
                  inputMode="tel"
                  placeholder="Numéro MTN MoMo"
                  value={numero}
                  onChange={handleChangeNumero}
                  required
                />
              </div>
              <p className="mt-1.5 text-xs text-gray-400">
                Ce numéro recevra le montant retiré.
              </p>
            </div>

            {/* Nom et prénom MTN MoMo */}
            <div className="mb-5">
              <div className="flex justify-between items-center mb-1.5">
                <label className="text-gray-300 text-sm font-medium">Nom et prénom MTN MoMo</label>
                <span className="text-xs text-[#0087BFFF]">
                  {nom ? <FontAwesomeIcon icon={faCheckCircle} className="mr-1" /> : ""}
                  requis
                </span>
              </div>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <FontAwesomeIcon icon={faUser} className="text-gray-400" />
                </div>
                <input
                  className="w-full h-12 pl-10 pr-3 py-2 bg-[#1C1C1CFF]/80 text-white placeholder-gray-500 border border-gray-700/60 rounded-xl focus:ring-2 focus:ring-[#0087BFFF] focus:border-[#0087BFFF] transition duration-200 outline-none"
                  type="text"
                  placeholder="Nom et prénom MTN MoMo"
                  value={nom}
                  onChange={handleChangeNom}
                  required
                />
              </div>
            </div>

            {/* Bouton de soumission */}
            <button
              className="w-full h-14 flex justify-center items-center bg-[#0087BFFF] bg-opacity-20 text-white rounded-xl shadow-lg shadow-[#0087BFFF]/20 transition duration-200 hover:bg-opacity-30 mt-4"
              type="submit"
              disabled={loading}
            >
              {loading ? (
                <div className="flex items-center">
                  <div className="w-6 h-6 mr-2 animate-spin">
                    <CircularProgressbar
                      value={progress}
                      styles={buildStyles({
                        pathColor: "white",
                        trailColor: "rgba(255, 255, 255, 0.2)",
                        strokeLinecap: "round",
                      })}
                    />
                  </div>
                  <span>Traitement...</span>
                </div>
              ) : (
                <span className="flex items-center">
                  <FontAwesomeIcon icon={faMoneyBillTransfer} className="mr-2" />
                  {operationType === "automatique" ? "Effectuer le retrait automatique" : "Soumettre la demande"}
                </span>
              )}
            </button>
          </form>
        </div>

        {!isLargeScreen && (
          <BottomSheet
            open={open}
            onDismiss={handleDismiss}
            snapPoints={({ minHeight }) => [minHeight, 490]}
          >
            <InfosRetrait onClose={handleDismiss} />
          </BottomSheet>
        )}
        
        {/* Espace pour éviter le chevauchement avec la BottomNavBar */}
        <div className="h-24"></div>
      </div>

      {/* Version Desktop */}
      <div className="bg-gradient-to-b from-[#1C1C1CFF] to-[#272728FF] min-h-screen hidden md:flex flex-col pb-20">
        <div className="max-w-4xl mx-auto w-full">
          <h1 className="text-3xl font-bold text-white mb-2 text-center">Retrait d'argent 1xBet</h1>
          <p className="text-gray-300 text-center mb-8">
            Retirez rapidement et en toute sécurité de l'argent de votre compte 1xBet
          </p>

          <div className="bg-[#272728FF]/70 backdrop-blur-sm rounded-2xl border border-white/10 shadow-xl p-8">
            <form onSubmit={handleSubmit} className="flex flex-col">
              {/* Bouton d'information */}
              <div
                onClick={() => setOpen(true)}
                className="bg-[#0087BFFF]/20 backdrop-blur-sm mb-6 py-3 px-4 rounded-xl flex items-center justify-center cursor-pointer border border-[#0087BFFF]/30 transition hover:bg-[#0087BFFF]/30"
              >
                <FontAwesomeIcon icon={faInfoCircle} className="text-[#0087BFFF] mr-2" />
                <span className="text-white font-medium">Comment fonctionne le retrait ?</span>
              </div>

              {/* Type d'opération */}
              <div className="mb-6">
                <label className="block text-gray-300 text-sm font-medium mb-3">
                  Type de retrait
                </label>
                <div className="grid grid-cols-2 gap-4">
                  <div
                    onClick={() => setOperationType("automatique")}
                    className={`flex flex-col items-center justify-center p-4 rounded-xl cursor-pointer transition duration-200 ease-in-out
                      ${operationType === "automatique" 
                        ? "bg-[#0087BFFF] bg-opacity-20 shadow-lg border border-[#0087BFFF]/50" 
                        : "bg-[#272728FF]/80 border border-gray-700/40 hover:bg-[#1C1C1CFF]"}`}
                  >
                    <FontAwesomeIcon icon={faExchangeAlt} className={`text-2xl mb-2 ${operationType === "automatique" ? "text-white" : "text-gray-400"}`} />
                    <span className={`font-medium ${operationType === "automatique" ? "text-white" : "text-gray-400"}`}>Automatique</span>
                    <p className={`text-xs mt-1 ${operationType === "automatique" ? "text-[#f9d57d]" : "text-gray-500"}`}>
                      Retrait et dépôt MTN automatisés
                    </p>
                  </div>
                  
                  <div
                    onClick={() => setOperationType("manuel")}
                    className={`flex flex-col items-center justify-center p-4 rounded-xl cursor-pointer transition duration-200 ease-in-out
                      ${operationType === "manuel" 
                        ? "bg-[#0087BFFF] bg-opacity-20 shadow-lg border border-[#0087BFFF]/50" 
                        : "bg-[#272728FF]/80 border border-gray-700/40 hover:bg-[#1C1C1CFF]"}`}
                  >
                    <FontAwesomeIcon icon={faUserClock} className={`text-2xl mb-2 ${operationType === "manuel" ? "text-white" : "text-gray-400"}`} />
                    <span className={`font-medium ${operationType === "manuel" ? "text-white" : "text-gray-400"}`}>Manuel</span>
                    <p className={`text-xs mt-1 ${operationType === "manuel" ? "text-[#f9d57d]" : "text-gray-500"}`}>
                      Traitement par un caissier
                    </p>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-2 gap-6">
                <div>
                  {/* ID du compte 1xBet */}
                  <div className="mb-6">
                    <div className="flex justify-between items-center mb-2">
                      <label className="text-gray-300 text-sm font-medium">ID du compte 1xBet</label>
                      <span className="text-xs text-[#0087BFFF]">
                        {inputValue ? <FontAwesomeIcon icon={faCheckCircle} className="mr-1" /> : ""}
                        requis
                      </span>
                    </div>
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <FontAwesomeIcon icon={faIdCard} className="text-gray-400" />
                      </div>
                      <input
                        className="w-full h-12 pl-10 pr-3 py-2 bg-[#1C1C1CFF]/80 text-white placeholder-gray-500 border border-gray-700/60 rounded-xl focus:ring-2 focus:ring-[#0087BFFF] focus:border-[#0087BFFF] transition duration-200 outline-none"
                        type="number"
                        pattern="\d*"
                        inputMode="numeric"
                        placeholder="ID 1xBet"
                        value={inputValue}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <p className="mt-1.5 text-xs text-gray-400">
                      Vous pouvez modifier votre ID 1xBet par défaut dans les paramètres.
                    </p>
                  </div>

                  {/* Code de retrait 1xBet */}
                  <div className="mb-6">
                    <div className="flex justify-between items-center mb-2">
                      <label className="text-gray-300 text-sm font-medium">Code de retrait 1xBet</label>
                      <span className="text-xs text-[#0087BFFF]">
                        {code && code.length === 4 ? <FontAwesomeIcon icon={faCheckCircle} className="mr-1" /> : ""}
                        requis
                      </span>
                    </div>
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <FontAwesomeIcon icon={faLock} className="text-gray-400" />
                      </div>
                      <input
                        className="w-full h-12 pl-10 pr-3 py-2 bg-[#1C1C1CFF]/80 text-white placeholder-gray-500 border border-gray-700/60 rounded-xl focus:ring-2 focus:ring-[#0087BFFF] focus:border-[#0087BFFF] transition duration-200 outline-none"
                        type="text"
                        placeholder="Code 1xBet (4 caractères)"
                        value={code}
                        onChange={handleChangeCode}
                        maxLength={4}
                        required
                      />
                    </div>
                    <p className="mt-1.5 text-xs text-gray-400">
                      Code à 4 caractères reçu sur votre compte 1xBet pour autoriser le retrait.
                    </p>
                  </div>

                  {/* Numéro de retrait MTN MoMo */}
                  <div className="mb-6">
                    <div className="flex justify-between items-center mb-2">
                      <label className="text-gray-300 text-sm font-medium">Numéro MTN MoMo</label>
                      <span className="text-xs text-[#0087BFFF]">
                        {numero ? <FontAwesomeIcon icon={faCheckCircle} className="mr-1" /> : ""}
                        requis
                      </span>
                    </div>
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <FontAwesomeIcon icon={faMobile} className="text-gray-400" />
                      </div>
                      <input
                        className="w-full h-12 pl-10 pr-3 py-2 bg-[#1C1C1CFF]/80 text-white placeholder-gray-500 border border-gray-700/60 rounded-xl focus:ring-2 focus:ring-[#0087BFFF] focus:border-[#0087BFFF] transition duration-200 outline-none"
                        type="text"
                        inputMode="tel"
                        placeholder="Numéro MTN MoMo"
                        value={numero}
                        onChange={handleChangeNumero}
                        required
                      />
                    </div>
                    <p className="mt-1.5 text-xs text-gray-400">
                      Ce numéro recevra le montant retiré.
                    </p>
                  </div>
                </div>

                <div>
                  {/* Montant */}
                  <div className="mb-6">
                    <div className="flex justify-between items-center mb-2">
                      <label className="text-gray-300 text-sm font-medium">Montant (min. 900 FCFA)</label>
                      <span className="text-xs text-[#0087BFFF]">
                        {montant >= 900 ? <FontAwesomeIcon icon={faCheckCircle} className="mr-1" /> : ""}
                        requis
                      </span>
                    </div>
                    
                    {/* Montants prédéfinis */}
                    <div className="grid grid-cols-4 gap-2 mb-3">
                      {updatedAmounts.map((amount, index) => (
                        <div
                          key={index}
                          onClick={() => handleClickAmount(amount)}
                          className={`py-2 px-3 rounded-xl flex items-center justify-center cursor-pointer transition duration-200 text-sm font-medium
                            ${selectedAmount === amount 
                              ? `bg-[${colors.bleu}] text-white border border-[${colors.bleu}]/70 shadow-md` 
                              : "bg-[#1C1C1CFF]/80 text-gray-300 border border-gray-700/60 hover:bg-[#272728FF]"}`}
                        >
                          {amount.toLocaleString()} F
                        </div>
                      ))}
                    </div>
                    
                    {/* Input pour montant personnalisé */}
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <FontAwesomeIcon icon={faCoins} className="text-gray-400" />
                      </div>
                      <input
                        className="w-full h-12 pl-10 pr-3 py-2 bg-[#1C1C1CFF]/80 text-white placeholder-gray-500 border border-gray-700/60 rounded-xl focus:ring-2 focus:ring-[#0087BFFF] focus:border-[#0087BFFF] transition duration-200 outline-none"
                        type="number"
                        pattern="\d*"
                        inputMode="numeric"
                        placeholder="Montant personnalisé"
                        value={montant}
                        onChange={handleChangeMontant}
                        min="900"
                        required
                      />
                    </div>
                    {montant && parseInt(montant) < 900 ? (
                      <p className="mt-1.5 text-xs text-[#ff6f61]">
                        Le montant minimum est de 900 FCFA
                      </p>
                    ) : null}
                  </div>

                  {/* Affichage du montant à recevoir */}
                  {visible && (
                    <div className="mb-6">
                      <div className="p-4 bg-[#0087BFFF]/10 rounded-xl border border-[#0087BFFF]/30 flex items-center">
                        <div className="p-3 bg-[#0087BFFF]/20 rounded-full mr-4">
                          <FontAwesomeIcon icon={faMoneyBill} className="text-[#0087BFFF] text-xl" />
                        </div>
                        <div>
                          <p className="text-[#0087BFFF] font-medium">Montant à recevoir</p>
                          <p className="text-white text-2xl font-semibold">{mon.toLocaleString()} FCFA</p>
                          <p className="text-gray-400 text-xs mt-1">Frais de service : 100 FCFA</p>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Nom et prénom MTN MoMo */}
                  <div className="mb-6">
                    <div className="flex justify-between items-center mb-2">
                      <label className="text-gray-300 text-sm font-medium">Nom et prénom MTN MoMo</label>
                      <span className="text-xs text-[#0087BFFF]">
                        {nom ? <FontAwesomeIcon icon={faCheckCircle} className="mr-1" /> : ""}
                        requis
                      </span>
                    </div>
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <FontAwesomeIcon icon={faUser} className="text-gray-400" />
                      </div>
                      <input
                        className="w-full h-12 pl-10 pr-3 py-2 bg-[#1C1C1CFF]/80 text-white placeholder-gray-500 border border-gray-700/60 rounded-xl focus:ring-2 focus:ring-[#0087BFFF] focus:border-[#0087BFFF] transition duration-200 outline-none"
                        type="text"
                        placeholder="Nom et prénom MTN MoMo"
                        value={nom}
                        onChange={handleChangeNom}
                        required
                      />
                    </div>
                    <p className="mt-1.5 text-xs text-gray-400">
                      Le nom doit correspondre à celui associé au numéro MTN MoMo.
                    </p>
                  </div>
                </div>
              </div>

              {/* Note de sécurité */}
              <div className="bg-[#f9d57d]/10 border border-[#f9d57d]/20 rounded-xl p-3 mb-6 flex items-start">
                <FontAwesomeIcon icon={faShieldAlt} className="text-[#f9d57d] mt-0.5 mr-3 flex-shrink-0" />
                <p className="text-[#f9d57d] text-sm">
                  Pour votre sécurité, assurez-vous que toutes les informations sont correctes avant de soumettre votre demande de retrait.
                </p>
              </div>

              {/* Bouton de soumission */}
              <button
                className="w-full h-14 flex justify-center items-center bg-[#0087BFFF] bg-opacity-20 text-white rounded-xl shadow-lg shadow-[#0087BFFF]/20 transition duration-200 hover:bg-opacity-30 mt-4 font-medium"
                type="submit"
                disabled={loading}
              >
                {loading ? (
                  <div className="flex items-center">
                    <div className="w-6 h-6 mr-2 animate-spin">
                      <CircularProgressbar
                        value={progress}
                        styles={buildStyles({
                          pathColor: "white",
                          trailColor: "rgba(255, 255, 255, 0.2)",
                          strokeLinecap: "round",
                        })}
                      />
                    </div>
                    <span>Traitement...</span>
                  </div>
                ) : (
                  <span className="flex items-center">
                    <FontAwesomeIcon icon={faMoneyBillTransfer} className="mr-2" />
                    {operationType === "automatique" ? "Effectuer le retrait automatique" : "Soumettre la demande de retrait"}
                  </span>
                )}
              </button>
              
              <p className="text-center text-gray-400 text-xs mt-4">
                En procédant au retrait, vous acceptez nos conditions générales d'utilisation.
              </p>
            </form>
          </div>
        </div>

        {open && (
          <div className="fixed inset-0 hidden md:flex items-center justify-center bg-black/70 backdrop-blur-sm z-50">
            <InfosRetraitpc onClose={handleDismiss} />
          </div>
        )}
      </div>
    </>
  );
}