import {
  faCheck,
  faEnvelope,
  faPaperPlane,
  faSearch,
  faArrowLeft,
  faArrowUp,
  faArrowDown,
  faBalanceScale,
  faLaptop,
  faFutbol,
  faPiggyBank,
  faWallet,
  faArrowRight,
  faArrowCircleLeft,
  faKeyboard,
  faExchangeAlt,
  faSyncAlt,
  faExclamationCircle,
  faMobile,
  faFingerprint,
  faInfoCircle,
  faCopy,
  faMoneyBillWave,
  faClock,
  faPhoneAlt,
  faHome
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ArrowLeft } from "react-feather";
import { useState, useEffect } from "react";
import Lottie from "lottie-react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import bravo from "../../animation/succes.json";
import { useParams, Link, useNavigate } from "react-router-dom";

export default function DepotManuelSucces() {
  const navigate = useNavigate();
  const { id } = useParams();

  // Récupérer les informations stockées
  const depotXbet = localStorage.getItem("depotxbet");
  const depotMontant = localStorage.getItem("depotmontant");
  const externalId = localStorage.getItem("externalId");
  const idTransactionMTN = localStorage.getItem("idTransactionMTN");
  
  // Sur la page de succès, le paiement est toujours considéré comme confirmé
  const [isConfirmed, setIsConfirmed] = useState(true);
  const [countdown, setCountdown] = useState(300); // 5 minutes en secondes (pas utilisé)
  
  // Fonction pour formater le temps restant
  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
  };
  
  // Notification de copie
  const notifySuccess = () => {
    toast.success("Référence copiée", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  
  // Compte à rebours
  useEffect(() => {
    if (countdown > 0 && !isConfirmed) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
      
      return () => clearTimeout(timer);
    }
  }, [countdown, isConfirmed]);
  
  // À la page de succès, on n'a plus besoin de simuler une confirmation
  // car le paiement est déjà confirmé

  return (
    <>
      <ToastContainer />
      
      {/* Version Mobile */}
      <div className="bg-gradient-to-b from-[#0F172A] to-[#1E293B] min-h-screen flex flex-col md:hidden">
        <div className="mt-5 flex justify-center items-center">
          <Lottie className="w-36 mb-2" animationData={bravo} loop={true} />
        </div>

        <div className="text-indigo-400 font-bold text-center text-2xl">
          {isConfirmed ? "Paiement confirmé !" : "Action requise !"}
        </div>

        <div className="text-white text-center px-6 text-sm mt-1">
          {isConfirmed 
            ? "Votre dépôt a été confirmé et sera traité rapidement."
            : "Veuillez confirmer le paiement sur votre téléphone."}
        </div>
        
        {!isConfirmed && (
          <div className="mx-5 mt-4 bg-indigo-900/30 p-4 rounded-xl border border-indigo-500/30">
            <div className="flex items-center mb-3">
              <FontAwesomeIcon icon={faInfoCircle} className="text-indigo-400 mr-3" />
              <p className="text-white text-sm">Une demande de paiement a été envoyée à votre téléphone. Veuillez confirmer la transaction.</p>
            </div>
            
            <div className="flex justify-between items-center mb-2 bg-indigo-950/50 p-3 rounded-lg">
              <div className="text-gray-300 text-sm">Temps restant:</div>
              <div className="text-white font-semibold flex items-center">
                <FontAwesomeIcon icon={faClock} className="text-yellow-500 mr-2" />
                {formatTime(countdown)}
              </div>
            </div>
          </div>
        )}

        <div className="bg-[#1E293B] flex flex-col rounded-xl border border-gray-700/70 mx-5 mt-4 shadow-lg">
          <div className="p-4 border-b border-gray-700/70">
            <div className="text-gray-300 text-sm font-medium mb-1">Référence de paiement</div>
            <div className="flex justify-between items-center">
              <div className="bg-[#0F172A] py-2 px-3 rounded-lg text-indigo-300 text-sm font-mono flex-grow mr-2 truncate">
                {externalId || "Non disponible"}
              </div>
              <CopyToClipboard text={externalId || ""} onCopy={notifySuccess}>
                <button className="p-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition">
                  <FontAwesomeIcon icon={faCopy} />
                </button>
              </CopyToClipboard>
            </div>
            <p className="text-xs text-gray-400 mt-1">
              Cette référence peut être demandée pour confirmer votre paiement.
            </p>
          </div>
          
          <div className="p-4">
            <div className="grid grid-cols-2 gap-3">
              <div className="bg-[#0F172A]/70 p-3 rounded-lg">
                <div className="text-gray-400 text-xs mb-1">ID Transaction</div>
                <div className="text-white font-medium">{id}</div>
              </div>
              <div className="bg-[#0F172A]/70 p-3 rounded-lg">
                <div className="text-gray-400 text-xs mb-1">Compte 1xbet</div>
                <div className="text-white font-medium">{depotXbet}</div>
              </div>
              <div className="bg-[#0F172A]/70 p-3 rounded-lg">
                <div className="text-gray-400 text-xs mb-1">Type</div>
                <div className="text-white font-medium">Dépôt</div>
              </div>
              <div className="bg-[#0F172A]/70 p-3 rounded-lg">
                <div className="text-gray-400 text-xs mb-1">Montant</div>
                <div className="text-white font-medium">{depotMontant} FCFA</div>
              </div>
            </div>
            
            <div className="mt-4 flex items-center justify-between bg-[#0F172A]/70 py-3 px-4 rounded-lg">
              <div className="text-gray-300 text-sm font-medium">Statut</div>
              <div className={`px-3 py-1 rounded-full text-xs font-medium ${
                isConfirmed 
                  ? "bg-green-900/50 text-green-400 border border-green-700/50" 
                  : "bg-yellow-900/50 text-yellow-400 border border-yellow-700/50"
              }`}>
                {isConfirmed ? "Confirmé" : "En attente"}
              </div>
            </div>
          </div>
        </div>
        
        {!isConfirmed && (
          <div className="mx-5 mt-4 bg-indigo-900/30 p-4 rounded-xl border border-indigo-500/30">
            <div className="flex items-center">
              <FontAwesomeIcon icon={faPhoneAlt} className="text-indigo-400 mr-3" />
              <p className="text-white text-sm">Vérifiez votre téléphone et confirmez le paiement dans l'application MTN MoMo.</p>
            </div>
          </div>
        )}
        
        <div className="flex flex-col space-y-3 mx-5 mt-6">
          <button 
            onClick={() => {
              navigate("/clients/espace");
            }}
            className="w-full py-3 bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-xl font-medium shadow-lg"
          >
            <FontAwesomeIcon icon={faHome} className="mr-2" />
            Retour à l'accueil
          </button>
          
          <button 
            onClick={() => {
              navigate("/clients/menu-transaction");
            }}
            className="w-full py-3 bg-[#1E293B] text-white border border-indigo-500/30 rounded-xl font-medium"
          >
            <FontAwesomeIcon icon={faSyncAlt} className="mr-2" />
            Mes transactions
          </button>
        </div>
      </div>

      {/* Version Desktop */}
      <div className="bg-gradient-to-b from-[#0F172A] to-[#1E293B] min-h-screen hidden md:flex flex-col">
        <div className="max-w-3xl mx-auto w-full">
          <div className="flex justify-center items-center">
            <Lottie className="w-32" animationData={bravo} loop={true} />
          </div>

          <div className="text-indigo-400 font-bold text-center text-3xl mb-2">
            {isConfirmed ? "Paiement confirmé !" : "Action requise !"}
          </div>

          <div className="text-white text-center mb-6">
            {isConfirmed 
              ? "Votre dépôt a été confirmé et sera traité rapidement."
              : "Veuillez confirmer le paiement sur votre téléphone."}
          </div>
          
          <div className="bg-white/5 backdrop-blur-sm rounded-2xl border border-white/10 shadow-xl overflow-hidden">
            {!isConfirmed && (
              <div className="bg-indigo-900/30 p-5 border-b border-indigo-700/30">
                <div className="flex items-start">
                  <div className="bg-indigo-600 p-3 rounded-full mr-4">
                    <FontAwesomeIcon icon={faMobile} className="text-white text-xl" />
                  </div>
                  <div>
                    <h3 className="text-indigo-300 font-semibold mb-1">Confirmation requise</h3>
                    <p className="text-white text-sm">Une demande de paiement a été envoyée à votre téléphone. Veuillez confirmer la transaction pour continuer.</p>
                    
                    <div className="mt-3 flex items-center">
                      <FontAwesomeIcon icon={faClock} className="text-yellow-500 mr-2" />
                      <span className="text-white font-medium">Temps restant: {formatTime(countdown)}</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            
            <div className="p-6">
              <div className="flex justify-between mb-6">
                <div className="w-1/2 pr-4">
                  <div className="text-gray-300 text-sm font-medium mb-2">Référence de paiement</div>
                  <div className="flex justify-between items-center">
                    <div className="bg-[#0F172A] py-2 px-3 rounded-lg text-indigo-300 text-sm font-mono flex-grow mr-2 truncate">
                      {externalId || "Non disponible"}
                    </div>
                    <CopyToClipboard text={externalId || ""} onCopy={notifySuccess}>
                      <button className="p-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition">
                        <FontAwesomeIcon icon={faCopy} />
                      </button>
                    </CopyToClipboard>
                  </div>
                  <p className="text-xs text-gray-400 mt-1">
                    Cette référence peut être demandée pour confirmer votre paiement.
                  </p>
                </div>
                
                <div className="w-1/2 pl-4">
                  <div className="text-gray-300 text-sm font-medium mb-2">Statut du paiement</div>
                  <div className={`flex items-center p-3 rounded-lg ${
                    isConfirmed 
                      ? "bg-green-900/20 border border-green-700/30" 
                      : "bg-yellow-900/20 border border-yellow-700/30"
                  }`}>
                    <div className={`w-3 h-3 rounded-full mr-3 ${
                      isConfirmed ? "bg-green-500" : "bg-yellow-500"
                    }`}></div>
                    <div className="flex-grow">
                      <div className={`font-medium ${isConfirmed ? "text-green-400" : "text-yellow-400"}`}>
                        {isConfirmed ? "Paiement confirmé" : "En attente de confirmation"}
                      </div>
                      <div className="text-gray-400 text-xs">
                        {isConfirmed 
                          ? "Votre transaction est en cours de traitement" 
                          : "Veuillez confirmer sur votre téléphone"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="grid grid-cols-4 gap-4 mb-6">
                <div className="bg-[#0F172A]/70 p-4 rounded-lg">
                  <div className="text-gray-400 text-xs mb-1">ID Transaction</div>
                  <div className="text-white font-medium">{id}</div>
                </div>
                <div className="bg-[#0F172A]/70 p-4 rounded-lg">
                  <div className="text-gray-400 text-xs mb-1">Compte 1xbet</div>
                  <div className="text-white font-medium">{depotXbet}</div>
                </div>
                <div className="bg-[#0F172A]/70 p-4 rounded-lg">
                  <div className="text-gray-400 text-xs mb-1">Type</div>
                  <div className="text-white font-medium">Dépôt</div>
                </div>
                <div className="bg-[#0F172A]/70 p-4 rounded-lg">
                  <div className="text-gray-400 text-xs mb-1">Montant</div>
                  <div className="text-white font-medium">{depotMontant} FCFA</div>
                </div>
              </div>
              
              {!isConfirmed && (
                <div className="bg-blue-900/20 border border-blue-700/30 p-4 rounded-lg mb-6">
                  <div className="flex items-start">
                    <FontAwesomeIcon icon={faInfoCircle} className="text-blue-400 mt-1 mr-3" />
                    <div>
                      <h4 className="text-blue-300 font-medium mb-1">Comment confirmer votre paiement</h4>
                      <ol className="text-gray-300 text-sm space-y-1 list-decimal list-inside">
                        <li>Ouvrez l'application MTN MoMo sur votre téléphone</li>
                        <li>Cherchez la notification de paiement en attente</li>
                        <li>Suivez les instructions pour confirmer la transaction</li>
                        <li>Revenez sur cette page pour voir le statut mis à jour</li>
                      </ol>
                    </div>
                  </div>
                </div>
              )}
              
              <div className="flex space-x-4">
                <button 
                  onClick={() => {
                    navigate("/clients/espace");
                  }}
                  className="w-full py-3 bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-xl font-medium shadow-lg"
                >
                  <FontAwesomeIcon icon={faHome} className="mr-2" />
                  Retour à l'accueil
                </button>
                
                <button 
                  onClick={() => {
                    navigate("/clients/menu-transaction");
                  }}
                  className="w-full py-3 bg-[#1E293B] text-white border border-indigo-500/30 rounded-xl font-medium"
                >
                  <FontAwesomeIcon icon={faSyncAlt} className="mr-2" />
                  Mes transactions
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
