import React, { useState } from 'react';
import { FiEdit, FiUnlock, FiLock, FiSearch, FiRefreshCw, FiArrowUp, FiArrowDown, FiFilter } from 'react-icons/fi';
import { FaExchangeAlt, FaMoneyBillWave, FaUserCheck, FaCalendarAlt } from 'react-icons/fa';

const AttributionList = () => {
  // Données de démonstration
  const [attributions, setAttributions] = useState([
    {
      id: 1,
      caisse: { id: 1, nom: "Caisse Principale", solde: 1500000 },
      caissier: { id: 1, nom: "Jean Dupont", email: "jean.dupont@payforbet.com" },
      dateAttribution: "2023-01-20",
      statut: "active",
      transactions: 450,
      montantGere: 23500000
    },
    {
      id: 2,
      caisse: { id: 2, nom: "Caisse Express", solde: 850000 },
      caissier: { id: 2, nom: "Marie Martin", email: "marie.martin@payforbet.com" },
      dateAttribution: "2023-02-25",
      statut: "active",
      transactions: 325,
      montantGere: 12800000
    },
    {
      id: 3,
      caisse: { id: 3, nom: "Caisse VIP", solde: 3200000 },
      caissier: { id: 3, nom: "Paul Robert", email: "paul.robert@payforbet.com" },
      dateAttribution: "2023-03-10",
      statut: "active",
      transactions: 210,
      montantGere: 38500000
    },
    {
      id: 4,
      caisse: { id: 5, nom: "Caisse Mobile", solde: 450000 },
      caissier: { id: 4, nom: "Sophie Leclerc", email: "sophie.leclerc@payforbet.com" },
      dateAttribution: "2023-05-30",
      statut: "suspendue",
      transactions: 75,
      montantGere: 4200000
    }
  ]);

  // État pour les caissiers disponibles (sans caisse)
  const [caissiersDisponibles, setCaissiersDisponibles] = useState([
    { id: 5, nom: "Thomas Bernard", email: "thomas.bernard@payforbet.com" }
  ]);

  // État pour les caisses disponibles (sans caissier)
  const [caissesDisponibles, setCaissesDisponibles] = useState([
    { id: 4, nom: "Caisse Régionale", solde: 120000 }
  ]);

  // État pour la recherche
  const [searchTerm, setSearchTerm] = useState('');
  
  // État pour le tri
  const [sortConfig, setSortConfig] = useState({
    key: 'id',
    direction: 'ascending'
  });

  // État pour les filtres
  const [filters, setFilters] = useState({
    statut: 'tous'
  });

  // Fonction pour formater les montants
  const formatMontant = (montant) => {
    return new Intl.NumberFormat('fr-FR').format(montant);
  };

  // Fonction pour trier les données
  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // Fonction pour gérer la recherche
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  // Fonction pour gérer les filtres
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };

  // Fonction pour attribuer une caisse
  const handleAttribuerCaisse = () => {
    alert('Formulaire d\'attribution de caisse à implémenter');
  };

  // Fonction pour modifier une attribution
  const handleModifierAttribution = (attribution) => {
    alert(`Modifier l'attribution de la caisse ${attribution.caisse.nom} à ${attribution.caissier.nom}`);
  };

  // Fonction pour verrouiller/déverrouiller une attribution
  const handleToggleStatut = (attribution) => {
    const nouvelleAttribution = {
      ...attribution,
      statut: attribution.statut === 'active' ? 'suspendue' : 'active'
    };
    
    setAttributions(attributions.map(a => a.id === attribution.id ? nouvelleAttribution : a));
    
    alert(`L'attribution de la caisse ${attribution.caisse.nom} a été ${nouvelleAttribution.statut === 'active' ? 'activée' : 'suspendue'}`);
  };

  // Fonction pour filtrer les attributions
  const getFilteredAttributions = () => {
    return attributions
      .filter(attribution => {
        // Filtre de recherche
        if (searchTerm && 
            !attribution.caisse.nom.toLowerCase().includes(searchTerm.toLowerCase()) &&
            !attribution.caissier.nom.toLowerCase().includes(searchTerm.toLowerCase())) {
          return false;
        }
        
        // Filtre par statut
        if (filters.statut !== 'tous' && attribution.statut !== filters.statut) {
          return false;
        }
        
        return true;
      })
      .sort((a, b) => {
        let aValue, bValue;
        
        if (sortConfig.key === 'caisse') {
          aValue = a.caisse.nom;
          bValue = b.caisse.nom;
        } else if (sortConfig.key === 'caissier') {
          aValue = a.caissier.nom;
          bValue = b.caissier.nom;
        } else if (sortConfig.key === 'montantGere') {
          aValue = a.montantGere;
          bValue = b.montantGere;
        } else if (sortConfig.key === 'transactions') {
          aValue = a.transactions;
          bValue = b.transactions;
        } else {
          aValue = a[sortConfig.key];
          bValue = b[sortConfig.key];
        }
        
        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
  };

  // Obtenir les attributions filtrées
  const attributionsFiltered = getFilteredAttributions();

  return (
    <div className="w-full">
      {/* En-tête de la page */}
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-white mb-2">Attribution de caisses</h1>
        <p className="text-gray-400">Gérez les attributions de caisses aux caissiers</p>
      </div>

      {/* Carte de statistiques */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-8">
        <div className="bg-gris rounded-xl p-5 shadow-lg flex items-center justify-between">
          <div>
            <p className="text-gray-400 text-sm">Attributions</p>
            <h3 className="text-white text-2xl font-bold mt-1">{attributions.length}</h3>
          </div>
          <div className="bg-bleu bg-opacity-20 p-3 rounded-full">
            <FaExchangeAlt className="text-bleu text-xl" />
          </div>
        </div>

        <div className="bg-gris rounded-xl p-5 shadow-lg flex items-center justify-between">
          <div>
            <p className="text-gray-400 text-sm">Caisses non attribuées</p>
            <h3 className="text-white text-2xl font-bold mt-1">{caissesDisponibles.length}</h3>
          </div>
          <div className="bg-softYellow bg-opacity-20 p-3 rounded-full">
            <FaMoneyBillWave className="text-softYellow text-xl" />
          </div>
        </div>

        <div className="bg-gris rounded-xl p-5 shadow-lg flex items-center justify-between">
          <div>
            <p className="text-gray-400 text-sm">Attributions actives</p>
            <h3 className="text-white text-2xl font-bold mt-1">
              {attributions.filter(a => a.statut === 'active').length}
            </h3>
          </div>
          <div className="bg-vert bg-opacity-20 p-3 rounded-full">
            <FaUserCheck className="text-vert text-xl" />
          </div>
        </div>

        <div className="bg-gris rounded-xl p-5 shadow-lg flex items-center justify-between">
          <div>
            <p className="text-gray-400 text-sm">Montant total géré</p>
            <h3 className="text-white text-2xl font-bold mt-1">
              {formatMontant(attributions.reduce((sum, attr) => sum + attr.montantGere, 0))} F
            </h3>
          </div>
          <div className="bg-softPurple bg-opacity-20 p-3 rounded-full">
            <FaCalendarAlt className="text-softPurple text-xl" />
          </div>
        </div>
      </div>

      {/* Section des éléments disponibles */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
        <div className="bg-gris rounded-xl p-5 shadow-lg">
          <h2 className="text-lg font-semibold text-white mb-4 flex items-center">
            <FaMoneyBillWave className="text-softYellow mr-2" /> Caisses disponibles
          </h2>
          {caissesDisponibles.length > 0 ? (
            <div className="space-y-3">
              {caissesDisponibles.map(caisse => (
                <div key={caisse.id} className="bg-noir p-3 rounded-lg flex justify-between items-center">
                  <div>
                    <h3 className="text-white font-medium">{caisse.nom}</h3>
                    <p className="text-gray-400 text-sm">Solde: {formatMontant(caisse.solde)} F</p>
                  </div>
                  <button 
                    onClick={handleAttribuerCaisse}
                    className="bg-bleu bg-opacity-10 hover:bg-opacity-20 text-bleu px-3 py-1 rounded-md text-sm transition-colors"
                  >
                    Attribuer
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-gray-400 text-center py-4">Toutes les caisses sont attribuées</p>
          )}
        </div>
        
        <div className="bg-gris rounded-xl p-5 shadow-lg">
          <h2 className="text-lg font-semibold text-white mb-4 flex items-center">
            <FaUserCheck className="text-vert mr-2" /> Caissiers disponibles
          </h2>
          {caissiersDisponibles.length > 0 ? (
            <div className="space-y-3">
              {caissiersDisponibles.map(caissier => (
                <div key={caissier.id} className="bg-noir p-3 rounded-lg flex justify-between items-center">
                  <div>
                    <h3 className="text-white font-medium">{caissier.nom}</h3>
                    <p className="text-gray-400 text-sm">{caissier.email}</p>
                  </div>
                  <button 
                    onClick={handleAttribuerCaisse}
                    className="bg-vert bg-opacity-10 hover:bg-opacity-20 text-vert px-3 py-1 rounded-md text-sm transition-colors"
                  >
                    Attribuer
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-gray-400 text-center py-4">Tous les caissiers ont une caisse attribuée</p>
          )}
        </div>
      </div>

      {/* Barre d'outils */}
      <div className="flex flex-col md:flex-row gap-4 justify-between mb-6">
        <div className="flex-1">
          <div className="relative">
            <input
              type="text"
              placeholder="Rechercher une attribution..."
              className="w-full py-2 pl-10 pr-4 bg-gris border border-gray-700 rounded-lg text-white text-sm focus:outline-none focus:border-bleu"
              value={searchTerm}
              onChange={handleSearch}
            />
            <div className="absolute left-3 top-2.5 text-gray-400">
              <FiSearch />
            </div>
          </div>
        </div>
        
        <div className="flex gap-3">
          <select
            name="statut"
            value={filters.statut}
            onChange={handleFilterChange}
            className="bg-gris border border-gray-700 rounded-lg text-white text-sm py-2 px-4 focus:outline-none focus:border-bleu"
          >
            <option value="tous">Tous les statuts</option>
            <option value="active">Active</option>
            <option value="suspendue">Suspendue</option>
          </select>
          
          <button
            onClick={handleAttribuerCaisse}
            className="flex items-center gap-2 bg-bleu hover:bg-blue-600 text-white py-2 px-4 rounded-lg transition-colors"
          >
            <FaExchangeAlt /> Nouvelle attribution
          </button>
        </div>
      </div>

      {/* Tableau des attributions */}
      <div className="bg-gris rounded-xl shadow-lg overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-700">
            <thead className="bg-noir">
              <tr>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer"
                  onClick={() => handleSort('id')}
                >
                  <div className="flex items-center">
                    ID
                    {sortConfig.key === 'id' && (
                      sortConfig.direction === 'ascending' ? <FiArrowUp className="ml-1" /> : <FiArrowDown className="ml-1" />
                    )}
                  </div>
                </th>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer"
                  onClick={() => handleSort('caisse')}
                >
                  <div className="flex items-center">
                    Caisse
                    {sortConfig.key === 'caisse' && (
                      sortConfig.direction === 'ascending' ? <FiArrowUp className="ml-1" /> : <FiArrowDown className="ml-1" />
                    )}
                  </div>
                </th>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer"
                  onClick={() => handleSort('caissier')}
                >
                  <div className="flex items-center">
                    Caissier
                    {sortConfig.key === 'caissier' && (
                      sortConfig.direction === 'ascending' ? <FiArrowUp className="ml-1" /> : <FiArrowDown className="ml-1" />
                    )}
                  </div>
                </th>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer"
                  onClick={() => handleSort('dateAttribution')}
                >
                  <div className="flex items-center">
                    Date
                    {sortConfig.key === 'dateAttribution' && (
                      sortConfig.direction === 'ascending' ? <FiArrowUp className="ml-1" /> : <FiArrowDown className="ml-1" />
                    )}
                  </div>
                </th>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer"
                  onClick={() => handleSort('transactions')}
                >
                  <div className="flex items-center">
                    Transactions
                    {sortConfig.key === 'transactions' && (
                      sortConfig.direction === 'ascending' ? <FiArrowUp className="ml-1" /> : <FiArrowDown className="ml-1" />
                    )}
                  </div>
                </th>
                <th 
                  className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer"
                  onClick={() => handleSort('statut')}
                >
                  <div className="flex items-center">
                    Statut
                    {sortConfig.key === 'statut' && (
                      sortConfig.direction === 'ascending' ? <FiArrowUp className="ml-1" /> : <FiArrowDown className="ml-1" />
                    )}
                  </div>
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-700">
              {attributionsFiltered.length > 0 ? (
                attributionsFiltered.map((attribution) => (
                  <tr key={attribution.id} className="hover:bg-noir transition-colors">
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                      {attribution.id}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      <div className="text-white font-medium">{attribution.caisse.nom}</div>
                      <div className="text-gray-400 text-xs">Solde: {formatMontant(attribution.caisse.solde)} F</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      <div className="text-white">{attribution.caissier.nom}</div>
                      <div className="text-gray-400 text-xs">{attribution.caissier.email}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                      {attribution.dateAttribution}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      <div className="text-white">{formatMontant(attribution.transactions)}</div>
                      <div className="text-gray-400 text-xs">{formatMontant(attribution.montantGere)} F</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      <span className={`px-2 py-1 rounded-full text-xs ${
                        attribution.statut === 'active' 
                          ? 'bg-vert bg-opacity-10 text-vert' 
                          : 'bg-softRed bg-opacity-10 text-softRed'
                      }`}>
                        {attribution.statut === 'active' ? 'Active' : 'Suspendue'}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-400">
                      <div className="flex gap-2">
                        <button 
                          onClick={() => handleModifierAttribution(attribution)}
                          className="p-1 hover:text-bleu transition-colors"
                          title="Modifier"
                        >
                          <FiEdit />
                        </button>
                        <button 
                          onClick={() => handleToggleStatut(attribution)}
                          className={`p-1 ${attribution.statut === 'active' ? 'hover:text-softRed' : 'hover:text-vert'} transition-colors`}
                          title={attribution.statut === 'active' ? 'Suspendre' : 'Activer'}
                        >
                          {attribution.statut === 'active' ? <FiLock /> : <FiUnlock />}
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="px-6 py-8 text-center text-gray-400">
                    Aucune attribution trouvée avec les critères de recherche actuels.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AttributionList;