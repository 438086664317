import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faExchangeAlt, faSearch, faCog } from '@fortawesome/free-solid-svg-icons';

export default function BottomNavBar() {
  const location = useLocation();
  
  const isActive = (path) => {
    return location.pathname === path;
  };

  const navItems = [
    { 
      path: '/assistant', 
      icon: faHome, 
      label: 'Accueil',
      isActive: isActive('/assistant')
    },
    { 
      path: '/assistant/transactions', 
      icon: faExchangeAlt, 
      label: 'Transactions',
      isActive: isActive('/assistant/transactions')
    },
    { 
      path: '/assistant/search', 
      icon: faSearch, 
      label: 'Recherche',
      isActive: isActive('/assistant/search') 
    },
    { 
      path: '/assistant/setting', 
      icon: faCog, 
      label: 'Paramètres',
      isActive: isActive('/assistant/setting')
    }
  ];
  
  return (
    <>
      {/* Version mobile */}
      <div className="md:hidden fixed bottom-0 left-0 right-0 bg-gris border-t border-bleu/20 z-50">
        <div className="flex justify-around">
          {navItems.map((item, index) => (
            <Link 
              key={index}
              to={item.path} 
              className={`flex flex-col items-center py-3 px-2 ${
                item.isActive ? 'text-bleu' : 'text-gray-400'
              }`}
            >
              <div className={`${item.isActive ? 'bg-bleu/20' : 'bg-transparent'} w-12 h-12 flex items-center justify-center rounded-full mb-1`}>
                <FontAwesomeIcon icon={item.icon} size="lg" />
              </div>
              <span className="text-xs">{item.label}</span>
            </Link>
          ))}
        </div>
      </div>

      {/* Version desktop */}
      <div className="hidden md:block fixed left-0 top-0 bottom-0 w-64 bg-gris border-r border-bleu/20 z-40 pt-20">
        <div className="px-4 mb-6">
          <div className="py-2 px-4 bg-bleu/10 rounded-lg">
            <span className="text-xs text-gray-400">Connecté en tant que</span>
            <p className="text-white font-medium">Assistant</p>
          </div>
        </div>
        
        <div className="space-y-2 px-4">
          {navItems.map((item, index) => (
            <Link
              key={index}
              to={item.path}
              className={`flex items-center py-3 px-4 rounded-lg transition-all ${
                item.isActive 
                  ? 'bg-bleu text-white' 
                  : 'text-gray-400 hover:bg-bleu/10'
              }`}
            >
              <FontAwesomeIcon icon={item.icon} className="mr-3" />
              <span>{item.label}</span>
              
              {item.isActive && (
                <div className="ml-auto w-2 h-2 rounded-full bg-white"></div>
              )}
            </Link>
          ))}
        </div>
        
        <div className="absolute bottom-8 left-0 right-0 px-6">
          <div className="p-4 rounded-lg bg-gradient-to-r from-bleu/20 to-vert/20">
            <p className="text-white text-sm font-medium mb-2">Besoin d'aide?</p>
            <p className="text-gray-400 text-xs">Contactez notre équipe de support pour toute assistance</p>
          </div>
        </div>
      </div>
    </>
  );
}