export default async function addAgent(email, nom, whatsapp, pays, token) {
  const newAgent = { email, nom, whatsapp, pays };

  try {
    // Crée l'URL en encodant les paramètres
    const baseURL = process.env.REACT_APP_BASE_URL || 'https://31f2-197-234-221-233.ngrok-free.app/api/';
    const url = `${baseURL}ajouter-agent?email=${encodeURIComponent(
      email
    )}&nom=${encodeURIComponent(nom)}&whatsapp=${encodeURIComponent(
      whatsapp
    )}&pays=${encodeURIComponent(pays)}`;

    // Envoie la requête GET avec le token dans les headers
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await response.json();

    if (data.error) {
      throw new Error(data.message || "Erreur lors de l'ajout de l'agent");
    }

    return { success: true, message: data.message };
  } catch (error) {
    return { success: false, message: error.message };
  }
}
