import React from "react";
import { Routes, Route } from "react-router-dom";

import IndexVisiteur from "../visiteurs/IndexVisiteur";
import Login from "../visiteurs/Login";
import Verify from "../visiteurs/Verify";
import Authentification from "../visiteurs/Authentification";
import Signup from "../visiteurs/Signup";
import SplashScreen from "../visiteurs/Spa";
import AideIndex from "../visiteurs/AideIndex";

const VisiteursRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<IndexVisiteur />} />
      <Route path="/login" element={<Login />} />
      <Route path="/verify" element={<Verify />} />
      <Route path="/authentification" element={<Authentification />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/sp" element={<SplashScreen />} />
      <Route path="/aide" element={<AideIndex />} />
    </Routes>
  );
};

export default VisiteursRoutes;
