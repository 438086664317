import React from "react";
import { Routes, Route } from "react-router-dom";

import IndexVisiteur from "../visiteurs/IndexVisiteur";
import Login from "../visiteurs/Login";
import Verify from "../visiteurs/Verify";
import Authentification from "../visiteurs/Authentification";
import Signup from "../visiteurs/Signup";
import BottomNavBar from "../clients/BottomNavBar";
import IndexClient from "../clients/IndexClient";
import Accueil from "../clients/Accueil";
import IndexTransaction from "../clients/Transactions/IndexTransaction";
import Afficher from "../clients/Transactions/Afficher";
import DepotManuel from "../clients/depots/DepotManuel";
import DepotManuelSucces from "../clients/depots/DepotManuelSucces";
import DepotConfirmation from "../clients/depots/DepotConfirmation";
import RetraitManuel from "../clients/retraits/RetraitsManuel";
import AideIndex from "../visiteurs/AideIndex";
import Tarifs from "../clients/aides/Tarifs";
import IndexParametre from "../clients/parametre/IndexParametre";
import RetraitManuelSucces from "../clients/retraits/RetraitManuelSucces";
import IndexParrainage from "../clients/parrainage/Index";
import InfosTransaction from "../clients/InfosTransaction";
import BonusList from "../clients/bonus/BonusList";

const ClientsRoutes = () => {
  return (
    <div className="m-0 p-0 overflow-hidden bg-black min-h-screen">
      <BottomNavBar />
      <div className="m-0 p-0 md:ml-16 lg:ml-20">
        <Routes>
          <Route path="/espace" element={<Accueil />} />
          <Route path="/aide" element={<AideIndex />} />
          <Route path="/afficher" element={<Afficher />} />
          <Route path="/menu-transaction" element={<IndexTransaction />} />
          <Route path="/depot-manuel" element={<DepotManuel />} />
          <Route path="/depot-manuel-succes/:id" element={<DepotManuelSucces />} />
          <Route path="/depot-confirmation/:externalId/:idTransaction" element={<DepotConfirmation />} />
          <Route path="/retrait-manuel" element={<RetraitManuel />} />
          <Route path="/retrait-manuel-succes/:id" element={<RetraitManuelSucces />} />
          <Route path="/tarifss" element={<Tarifs />} />
          <Route path="/setting" element={<IndexParametre />} />
          <Route path="/parrainage" element={<IndexParrainage />} />
          <Route path="/bonus" element={<BonusList />} />
          <Route path="/infos-transaction/:id/:idTran" element={<InfosTransaction />} />
        </Routes>
      </div>
    </div>
  );
};

export default ClientsRoutes;