import React, { useEffect } from "react";
import { useLocation, Link } from "react-router-dom";

const IndexVisiteur = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const refValue = queryParams.get("ref");

  // Couleurs personnalisées
  const colors = {
    noir: "#1C1C1CFF", // bg-noir dans Login.js
    bleu: "#0087BFFF", // bg-bleu dans Login.js
    vert: "#28C281FF", 
    gris: "#272728FF", // bg-gris dans Login.js
    softRed: "#ff6f61",
    softYellow: "#f9d57d",
    softPurple: "#b19cd9",
    black: "#1C1C1CFF",
  };

  useEffect(() => {
    if (refValue) {
      localStorage.setItem("ref", refValue);
    }
  }, [refValue]);

  return (
    <div style={{ 
      backgroundColor: colors.noir,
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      padding: 0,
      margin: 0,
      fontFamily: "system-ui, sans-serif",
      overflowX: "hidden"
    }}>
      {/* Header Minimaliste comme Login.js */}
      <header style={{ 
        backgroundColor: colors.gris,
        padding: "16px 24px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderLeft: `4px solid ${colors.bleu}`,
        boxShadow: "0 2px 10px rgba(0,0,0,0.2)"
      }}>
        <h1 style={{ 
          fontSize: "24px", 
          fontWeight: "700",
          color: "white",
          margin: 0
        }}>
          Pay<span style={{ color: colors.bleu }}>For</span>Bet
        </h1>
        
        <div style={{ display: "flex", gap: "12px" }}>
          <Link to="/login" style={{
            backgroundColor: colors.bleu,
            color: "white",
            padding: "8px 16px",
            borderRadius: "10px",
            fontWeight: "600",
            fontSize: "14px",
            textDecoration: "none",
            transition: "all 0.2s ease",
            boxShadow: "0 4px 6px rgba(0, 135, 191, 0.25)"
          }}>
            Connexion
          </Link>
        </div>
      </header>

      {/* Hero Section */}
      <section style={{
        padding: "60px 24px 40px",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }}>
        <h2 style={{
          fontSize: "34px",
          fontWeight: "700",
          color: "white",
          marginBottom: "20px",
          maxWidth: "800px",
          lineHeight: 1.2
        }}>
          Dépôts et retraits simplifiés
          <span style={{ 
            display: "block", 
            color: colors.bleu,
            fontSize: "36px",
            marginTop: "8px"
          }}>
            pour vos paris en ligne
          </span>
        </h2>
        
        <p style={{
          fontSize: "16px",
          color: "#9ca3af", // text-gray-400 comme dans Login.js
          maxWidth: "600px",
          marginBottom: "32px",
          lineHeight: 1.6
        }}>
          Profitez de transactions instantanées, de méthodes de paiement variées et des bonus.
        </p>
        
        <div>
          <Link to="/login" style={{
            backgroundColor: colors.bleu,
            color: "white",
            padding: "14px 32px",
            borderRadius: "12px",
            fontWeight: "600",
            fontSize: "16px",
            textDecoration: "none",
            transition: "all 0.3s ease",
            boxShadow: "0 4px 12px rgba(0, 135, 191, 0.3)",
            display: "inline-block"
          }}>
            Se connecter
          </Link>
        </div>
      </section>

      {/* Caractéristiques principales en grille stylisée comme Login.js */}
      <section style={{
        padding: "30px 24px 60px",
        backgroundColor: colors.noir
      }}>
        <div style={{
          maxWidth: "1200px",
          margin: "0 auto"
        }}>
          <h3 style={{
            fontSize: "24px",
            fontWeight: "700",
            color: "white",
            textAlign: "center",
            marginBottom: "40px",
            position: "relative",
            paddingBottom: "12px"
          }}>
            Pourquoi choisir PayForBet?
            <span style={{
              position: "absolute",
              bottom: 0,
              left: "50%",
              transform: "translateX(-50%)",
              width: "60px",
              height: "3px",
              backgroundColor: colors.bleu,
              borderRadius: "2px"
            }}></span>
          </h3>

          <div style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
            gap: "24px"
          }}>
            {/* Carte Dépôts */}
            <div style={{
              backgroundColor: colors.gris,
              borderRadius: "16px",
              padding: "25px",
              borderLeft: `4px solid ${colors.bleu}`,
              boxShadow: "0 10px 25px rgba(0,0,0,0.2)"
            }}>
              <div style={{
                width: "50px",
                height: "50px",
                borderRadius: "12px",
                backgroundColor: "rgba(0, 135, 191, 0.15)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "20px",
                color: colors.bleu,
                fontSize: "24px"
              }}>💰</div>
              
              <h4 style={{
                fontSize: "20px",
                fontWeight: "700",
                color: "white",
                marginBottom: "16px"
              }}>Dépôts Instantanés</h4>
              
              <ul style={{
                color: "#9ca3af", // text-gray-400 comme dans Login.js
                textAlign: "left",
                paddingLeft: "20px",
                marginBottom: "20px",
                lineHeight: 1.6,
                fontSize: "15px"
              }}>
                <li>Dépôts à partir de 500 FCFA</li>
                <li>Validation instantanée 24/7</li>
                <li>Aucun frais sur le 1er dépôt</li>
                <li>Bonus 100% premier dépôt</li>
              </ul>
            </div>

            {/* Carte Retraits */}
            <div style={{
              backgroundColor: colors.gris,
              borderRadius: "16px",
              padding: "25px",
              borderLeft: `4px solid ${colors.vert}`,
              boxShadow: "0 10px 25px rgba(0,0,0,0.2)"
            }}>
              <div style={{
                width: "50px",
                height: "50px",
                borderRadius: "12px",
                backgroundColor: "rgba(40, 194, 129, 0.15)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "20px",
                color: colors.vert,
                fontSize: "24px"
              }}>💸</div>
              
              <h4 style={{
                fontSize: "20px",
                fontWeight: "700",
                color: "white",
                marginBottom: "16px"
              }}>Retraits Rapides</h4>
              
              <ul style={{
                color: "#9ca3af", // text-gray-400 comme dans Login.js
                textAlign: "left",
                paddingLeft: "20px",
                marginBottom: "20px",
                lineHeight: 1.6,
                fontSize: "15px"
              }}>
                <li>Traitement en moins de 24h</li>
                <li>Minimum 1000 FCFA seulement</li>
                <li>Sans vérification pour petits montants</li>
                <li>Directement sur votre mobile</li>
              </ul>
            </div>

            {/* Carte Sécurité */}
            <div style={{
              backgroundColor: colors.gris,
              borderRadius: "16px",
              padding: "25px",
              borderLeft: `4px solid ${colors.softPurple}`,
              boxShadow: "0 10px 25px rgba(0,0,0,0.2)"
            }}>
              <div style={{
                width: "50px",
                height: "50px",
                borderRadius: "12px",
                backgroundColor: "rgba(177, 156, 217, 0.15)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "20px",
                color: colors.softPurple,
                fontSize: "24px"
              }}>🔒</div>
              
              <h4 style={{
                fontSize: "20px",
                fontWeight: "700",
                color: "white",
                marginBottom: "16px"
              }}>Sécurité Garantie</h4>
              
              <ul style={{
                color: "#9ca3af", // text-gray-400 comme dans Login.js
                textAlign: "left",
                paddingLeft: "20px",
                marginBottom: "20px",
                lineHeight: 1.6,
                fontSize: "15px"
              }}>
                <li>Transactions 100% sécurisées</li>
                <li>Paiements cryptés</li>
                <li>Protection des données</li>
                <li>Support client disponible 24/7</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Footer très simplifié */}
      <footer style={{ 
        backgroundColor: colors.gris,
        padding: "20px 24px",
        color: "#9ca3af", // text-gray-400 comme dans Login.js
        borderTop: `1px solid rgba(255,255,255,0.05)`,
        textAlign: "center"
      }}>
        <p style={{ fontSize: "13px", margin: 0 }}>© 2025 PayForBet</p>
      </footer>
    </div>
  );
};

export default IndexVisiteur;