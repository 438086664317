import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPaperPlane,
  faLock,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";

import Swal from "sweetalert2";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import checks from "../ressources/images/check.png";
import logo from "../ressources/images/logo.png";

import AuthContext from "../contexts/AuthContext";
import { existelogin } from "./alert/existelogin";

import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

const Login = () => {
  // États et hooks
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  
  // URL API
  const API_URL = process.env.REACT_APP_BASE_URL || "https://31f2-197-234-221-233.ngrok-free.app/api";
  
  // Vérifier s'il y a un paramètre ref dans l'URL
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const refValue = queryParams.get("ref");
    
    // Stocker le ref dans localStorage s'il existe
    if (refValue) {
      localStorage.setItem("ref", refValue);
    }
  }, [location]);
  
  // Gestion du changement d'email
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  
  // Gestion du clic sur le bouton
  const handleButtonClick = () => {
    // Empêcher les clics multiples
    if (buttonClicked || loading) return;
    
    // Vérifier que l'email est valide
    if (!email || !email.includes("@")) return;
    
    // Marquer le bouton comme cliqué et activer le chargement
    setButtonClicked(true);
    setLoading(true);
    
    // Stocker l'email dans localStorage immédiatement
    localStorage.setItem("userEmail", email);
    
    // Effectuer la requête API
    axios.get(`${API_URL}login`, { params: { email } })
      .then(response => {
        // Stocker l'ID utilisateur si disponible
        if (response.data && response.data.idUser) {
          localStorage.setItem("idUser", response.data.idUser);
        }
        
        // Traiter la réponse
        if (response.data && response.data.error) {
          // Gérer l'erreur
          setLoading(false);
          existelogin().then(result => {
            if (result.dismiss === Swal.DismissReason.cancel) {
              navigate("/signup");
            }
            setButtonClicked(false);
          });
        } else {
          // Succès - naviguer vers authentification
          setTimeout(() => {
            navigate("/authentification");
          }, 100);
        }
      })
      .catch(error => {
        console.error("Erreur de connexion:", error);
        setLoading(false);
        existelogin().then(result => {
          if (result.dismiss === Swal.DismissReason.cancel) {
            navigate("/signup");
          }
          setButtonClicked(false);
        });
      });
  };

  return (
    <div className="min-h-screen bg-noir flex items-center justify-center p-4">
      <div className="w-full max-w-md">
        {/* VERSION MOBILE */}
        <div className="md:hidden w-full">
          <div className="flex flex-col items-center mb-8">
            <div className="w-20 h-20 mb-4">
              <img src={logo} alt="Logo" className="w-full h-full object-contain" />
            </div>
            <h1 className="text-2xl font-bold text-white mb-1">Connexion</h1>
            <p className="text-gray-400 text-center text-sm mb-4">
              Entrez votre e-mail pour recevoir votre code
            </p>
          </div>
          
          <div className="bg-gris rounded-2xl p-6 shadow-lg border-l-4 border-bleu">
            <div className="space-y-5">
              <div className="space-y-2">
                <label htmlFor="email-mobile" className="text-white text-sm font-medium">Email</label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <FontAwesomeIcon icon={faEnvelope} className="text-bleu" />
                  </div>
                  <input
                    id="email-mobile"
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    placeholder="votreemail@exemple.com"
                    className="w-full h-12 pl-10 pr-4 bg-noir text-white rounded-xl border border-gray-700 focus:border-bleu focus:ring-1 focus:ring-bleu outline-none transition-all"
                    disabled={loading}
                    required
                  />
                </div>
              </div>
              
              <button
                onClick={handleButtonClick}
                disabled={loading || !email || !email.includes("@")}
                className={`w-full h-14 flex justify-center items-center text-white rounded-xl shadow-lg transition-all duration-300 ${
                  loading || !email || !email.includes("@") 
                    ? "bg-gray-600 cursor-not-allowed opacity-70" 
                    : "bg-bleu hover:bg-blue"
                }`}
              >
                {loading ? (
                  <div className="w-6 h-6 mr-2">
                    <CircularProgressbar
                      value={65}
                      styles={buildStyles({
                        pathColor: "white",
                        trailColor: "rgba(255,255,255,0.2)",
                        strokeLinecap: "round",
                      })}
                    />
                  </div>
                ) : (
                  <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
                )}
                <span className="text-white font-medium">
                  {loading ? "Vérification..." : "Envoyer le code"}
                </span>
              </button>
              
              <div className="flex items-center justify-center mt-4">
               
              </div>
            </div>
          </div>
        </div>
        
        {/* VERSION DESKTOP */}
        <div className="hidden md:block bg-gris rounded-2xl shadow-2xl overflow-hidden">
          <div className="bg-bleu py-4 px-6 flex items-center">
            <div className="bg-white/10 p-2 rounded-full mr-3">
              <FontAwesomeIcon icon={faLock} className="text-white" />
            </div>
            <h2 className="text-xl font-bold text-white">Espace Sécurisé</h2>
          </div>
          
          <div className="p-8 flex flex-col items-center">
            <div className="w-20 h-20 bg-bleu/10 rounded-full p-2 mb-4 flex items-center justify-center">
              <img
                src={checks}
                alt="Check"
                className="w-2/3 h-2/3 object-contain"
              />
            </div>
            
            <h1 className="text-2xl font-bold text-white mb-2">Connexion</h1>
            <p className="text-gray-400 text-center max-w-xs mb-6">
              Entrez votre adresse e-mail pour recevoir votre code de connexion
            </p>
            
            <div className="w-full max-w-sm space-y-6">
              <div className="space-y-2">
                <label htmlFor="email-desktop" className="text-white text-sm font-medium">Adresse Email</label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <FontAwesomeIcon icon={faEnvelope} className="text-bleu" />
                  </div>
                  <input
                    id="email-desktop"
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    placeholder="votreemail@exemple.com"
                    className="w-full h-12 pl-10 pr-4 bg-noir text-white rounded-xl border border-gray-700 focus:border-bleu focus:ring-1 focus:ring-bleu outline-none transition-all"
                    disabled={loading}
                    required
                  />
                </div>
              </div>
              
              <button
                onClick={handleButtonClick}
                disabled={loading || !email || !email.includes("@")}
                className={`w-full h-14 flex justify-center items-center text-white rounded-xl shadow-lg transition-all duration-300 ${
                  loading || !email || !email.includes("@") 
                    ? "bg-gray-600 cursor-not-allowed opacity-70" 
                    : "bg-bleu hover:bg-blue"
                }`}
              >
                {loading ? (
                  <div className="w-6 h-6 mr-2">
                    <CircularProgressbar
                      value={65}
                      styles={buildStyles({
                        pathColor: "white",
                        trailColor: "rgba(255,255,255,0.2)",
                        strokeLinecap: "round",
                      })}
                    />
                  </div>
                ) : (
                  <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
                )}
                <span className="text-white font-medium">
                  {loading ? "Vérification..." : "Envoyer le code"}
                </span>
              </button>
              
              <div className="flex items-center justify-center">
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;