import axios from 'axios';

// URL de base pour l'API depuis les variables d'environnement
// Récupérer l'URL de base du serveur en cours d'exécution
const BASE_URL = window.location.origin; // Ex: "http://localhost:3000" en développement
const API_URL = process.env.REACT_APP_BASE_URL || `${BASE_URL}/api`;
const API_BASE_URL_SUITE = process.env.REACT_APP_BASE_URL_SUITE || BASE_URL;

// Configuration d'axios avec autorisation
const apiClient = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Intercepteur pour ajouter le token d'authentification à toutes les requêtes
apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Service pour la gestion des bonus
const bonusService = {
  // Récupérer les statistiques des bonus
  getStatistiques: async () => {
    try {
      // Essayer d'abord avec l'API standard
      try {
        const response = await apiClient.get('/v1/bonus/statistiques');
        return response.data;
      } catch (e) {
        console.warn('Erreur API standard, tentative avec admin/bonus:', e);
        // Essayer avec l'API admin
        const responseAdmin = await apiClient.get('/v1/admin/bonus/statistiques');
        return responseAdmin.data;
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des statistiques de bonus:', error);
      
      // Renvoyer des données fictives pour éviter les erreurs
      return {
        success: true,
        statistiques: {
          totalBonusCalcules: 0,
          totalBonusDemandes: 0,
          totalBonusPayes: 0,
          montantTotalCalcule: 0,
          montantTotalDemande: 0,
          montantTotalPaye: 0,
          nombreUtilisateursAvecBonus: 0,
          dernieresDemandes: [],
          periodes: []
        }
      };
    }
  },

  // Récupérer les bonus d'un utilisateur spécifique
  getBonusUtilisateur: async (idUser) => {
    try {
      const response = await apiClient.get(`/v1/bonus/utilisateur/${idUser}`);
      return response.data;
    } catch (error) {
      console.error(`Erreur lors de la récupération des bonus de l'utilisateur ${idUser}:`, error);
      throw error;
    }
  },

  // Calculer un aperçu des bonus pour un mois spécifique sans les enregistrer
  calculerBonusPreview: async (mois, annee, tauxBonus) => {
    try {
      // Utiliser la bonne URL correspondant au backend v1
      const response = await apiClient.post('/v1/bonus/calculer-preview', {
        mois,
        annee,
        tauxBonus
      });
      return response.data;
    } catch (error) {
      console.error('Erreur lors du calcul de l\'aperçu des bonus:', error);
      throw error;
    }
  },

  // Approuver les bonus pour un mois spécifique
  approuverBonusMensuel: async (mois, annee, tauxBonus, idAdmin) => {
    try {
      // Utiliser la bonne URL correspondant au backend v1  
      const response = await apiClient.post('/v1/bonus/approuver-bonus-mensuel', {
        mois,
        annee,
        tauxBonus,
        idAdmin
      });
      return response.data;
    } catch (error) {
      console.error('Erreur lors de l\'approbation des bonus mensuels:', error);
      throw error;
    }
  },

  // Calculer les bonus pour un mois spécifique
  calculerBonusMensuel: async (mois, annee, tauxBonus, forceCalculation = false) => {
    try {
      const response = await apiClient.post('/v1/bonus/calculer-mensuel', {
        mois,
        annee,
        tauxBonus,
        forceCalculation
      });
      return response.data;
    } catch (error) {
      console.error('Erreur lors du calcul des bonus mensuels:', error);
      throw error;
    }
  },

  // Calculer le bonus pour le mois actuel
  calculerBonusMoisActuel: async (tauxBonus, forceCalculation = false) => {
    try {
      const response = await apiClient.post('/v1/bonus/calculer-mois-actuel', {
        tauxBonus,
        forceCalculation
      });
      return response.data;
    } catch (error) {
      console.error('Erreur lors du calcul des bonus pour le mois actuel:', error);
      throw error;
    }
  },

  // Calculer le bonus pour un utilisateur spécifique
  calculerBonusUtilisateur: async (idUser, mois, annee, tauxBonus) => {
    try {
      const response = await apiClient.post(`/v1/bonus/calculer-utilisateur/${idUser}`, {
        mois,
        annee,
        tauxBonus
      });
      return response.data;
    } catch (error) {
      console.error(`Erreur lors du calcul du bonus pour l'utilisateur ${idUser}:`, error);
      throw error;
    }
  },

  // Approuver une demande de retrait de bonus
  approuverRetraitBonus: async (idDemande, idAdmin) => {
    try {
      const response = await apiClient.post('/v1/bonus/approuver-retrait', {
        idDemande,
        idAdmin
      });
      return response.data;
    } catch (error) {
      console.error('Erreur lors de l\'approbation du retrait de bonus:', error);
      throw error;
    }
  },

  // Récupérer toutes les demandes de retrait en attente
  getDemandesRetrait: async () => {
    try {
      // Cette route spécifique n'est pas documentée, mais nous pouvons la déduire
      // ou utiliser les statistiques qui contiennent les dernières demandes
      const response = await apiClient.get('/v1/bonus/demandes-retrait');
      return response.data.demandes || [];
    } catch (error) {
      console.error('Erreur lors de la récupération des demandes de retrait:', error);
      // Fallback en utilisant les statistiques si la route spécifique échoue
      try {
        const statsResponse = await apiClient.get('/v1/bonus/statistiques');
        return statsResponse.data.statistiques.dernieresDemandes || [];
      } catch (fallbackError) {
        console.error('Erreur lors de la récupération des statistiques:', fallbackError);
        throw error;
      }
    }
  },
  
  // Récupérer les bonus payés incluant ceux demandés par les clients
  getBonusPayes: async () => {
    try {
      // Essayer d'abord avec l'endpoint spécifique pour les bonus payés
      try {
        const response = await apiClient.get('/v1/admin/retraits/bonus-payes');
        if (response.data.success && response.data.demandes) {
          return response.data.demandes;
        }
      } catch (error) {
        console.warn('Erreur API /admin/retraits/bonus-payes:', error);
      }
      
      // Essayer ensuite avec l'API historique
      try {
        const historicResponse = await apiClient.get('/v1/admin/retraits/historique');
        if (historicResponse.data.success && historicResponse.data.historique) {
          // Filtrer pour ne garder que les bonus payés
          return historicResponse.data.historique.filter(bonus => 
            bonus.statut === 'payé' || bonus.statut === 'à payer' || bonus.statut === 'approuvé'
          );
        }
      } catch (historyError) {
        console.warn('Erreur API /admin/retraits/historique:', historyError);
      }
      
      // Essayer avec une troisième méthode - API directe
      try {
        // Créer un client axios directement
        const directClient = axios.create({
          baseURL: window.location.origin,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        
        const directResponse = await directClient.get('/api/v1/admin/retraits/historique');
        if (directResponse.data.success && directResponse.data.historique) {
          return directResponse.data.historique.filter(bonus => 
            bonus.statut === 'payé' || bonus.statut === 'à payer' || bonus.statut === 'approuvé'
          );
        }
      } catch (directError) {
        console.warn('Erreur méthode directe:', directError);
      }
      
      // Dernière tentative avec les statistiques
      try {
        const statsResponse = await apiClient.get('/v1/bonus/statistiques');
        if (statsResponse.data.success && statsResponse.data.statistiques && statsResponse.data.statistiques.dernieresDemandes) {
          const demandesHistorique = statsResponse.data.statistiques.dernieresDemandes || [];
          return demandesHistorique.filter(bonus => 
            bonus.statut === 'payé' || bonus.statut === 'à payer' || bonus.statut === 'approuvé'
          );
        }
      } catch (statsError) {
        console.warn('Erreur API statistiques:', statsError);
      }
      
      // Si on arrive ici, aucune méthode n'a fonctionné
      console.warn('Aucune méthode de récupération des bonus payés n\'a fonctionné');
      return [];
      
    } catch (error) {
      console.error('Erreur générale lors de la récupération des bonus payés:', error);
      return [];
    }
  }
};

export default bonusService;