import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FiArrowLeft, FiClock, FiCheckCircle, FiXCircle, FiUsers, FiBarChart2, FiRepeat, FiSmartphone, FiMonitor } from 'react-icons/fi';
import { Pie, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js';

// Register ChartJS components
ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title);

const NotificationDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  
  // État pour les données de notification
  const [notification, setNotification] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // Charger les données de notification
  useEffect(() => {
    const fetchNotificationDetails = async () => {
      try {
        // Dans une implémentation réelle, cela proviendrait d'une API
        // Simulons un délai de chargement
        await new Promise(resolve => setTimeout(resolve, 800));
        
        // Données fictives pour l'exemple
        const mockNotification = {
          id: id,
          title: "Maintenance prévue",
          body: "Une maintenance du système est prévue le 15 juin de 23h à 2h. Certaines fonctionnalités pourraient être temporairement indisponibles.",
          type: "alerte",
          cible: "tous",
          nombreDestinataires: 450,
          dateCréation: "2023-05-18",
          statutEnvoi: "envoyé",
          envoyéPar: "Service Technique",
          // Statistiques détaillées
          stats: {
            delivered: 437,
            failed: 13,
            opened: 252,
            clicked: 187,
            dismissed: 46,
            ignored: 204,
            deliveryTime: {
              min: 0.2,
              max: 5.1,
              avg: 1.2
            },
            platforms: {
              android: 236,
              ios: 112,
              web: 89
            },
            browsers: {
              chrome: 62,
              safari: 21,
              firefox: 6
            },
            timeDistribution: {
              "0-3s": 312,
              "3-10s": 82,
              "10-30s": 26,
              "30s+": 17
            },
            deviceTypes: {
              mobile: 348,
              desktop: 89
            },
            byHour: [12, 45, 62, 73, 86, 38, 52, 42, 36, 24, 18, 7]
          }
        };
        
        setNotification(mockNotification);
      } catch (err) {
        console.error("Erreur lors de la récupération des détails de la notification:", err);
        setError("Impossible de charger les détails de la notification.");
      } finally {
        setLoading(false);
      }
    };
    
    fetchNotificationDetails();
  }, [id]);
  
  if (loading) {
    return (
      <div className="w-full flex items-center justify-center p-12">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-bleu"></div>
      </div>
    );
  }
  
  if (error || !notification) {
    return (
      <div className="w-full p-8 text-center">
        <div className="bg-softRed bg-opacity-10 text-softRed p-4 rounded-lg inline-block">
          {error || "Notification non trouvée"}
        </div>
        <div className="mt-4">
          <button
            onClick={() => navigate('/admin/notifications')}
            className="px-4 py-2 bg-bleu text-white rounded-lg flex items-center gap-2 mx-auto"
          >
            <FiArrowLeft /> Retour à la liste
          </button>
        </div>
      </div>
    );
  }
  
  // Données pour le graphique de distribution des plateformes
  const platformData = {
    labels: ['Android', 'iOS', 'Web'],
    datasets: [
      {
        data: [
          notification.stats.platforms.android,
          notification.stats.platforms.ios,
          notification.stats.platforms.web
        ],
        backgroundColor: [
          'rgba(102, 187, 106, 0.6)',
          'rgba(79, 195, 247, 0.6)',
          'rgba(255, 167, 38, 0.6)'
        ],
        borderColor: [
          'rgba(102, 187, 106, 1)',
          'rgba(79, 195, 247, 1)',
          'rgba(255, 167, 38, 1)'
        ],
        borderWidth: 1,
      },
    ],
  };
  
  // Données pour le graphique de statut de livraison
  const deliveryData = {
    labels: ['Livrées', 'Échouées'],
    datasets: [
      {
        data: [notification.stats.delivered, notification.stats.failed],
        backgroundColor: [
          'rgba(75, 192, 192, 0.6)',
          'rgba(255, 99, 132, 0.6)'
        ],
        borderColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(255, 99, 132, 1)'
        ],
        borderWidth: 1,
      },
    ],
  };
  
  // Données pour le graphique d'engagement
  const engagementData = {
    labels: ['Ouvertes', 'Cliquées', 'Fermées', 'Ignorées'],
    datasets: [
      {
        data: [
          notification.stats.opened,
          notification.stats.clicked,
          notification.stats.dismissed,
          notification.stats.ignored
        ],
        backgroundColor: [
          'rgba(54, 162, 235, 0.6)',
          'rgba(75, 192, 192, 0.6)',
          'rgba(255, 159, 64, 0.6)',
          'rgba(201, 203, 207, 0.6)'
        ],
        borderColor: [
          'rgba(54, 162, 235, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(255, 159, 64, 1)',
          'rgba(201, 203, 207, 1)'
        ],
        borderWidth: 1,
      },
    ],
  };
  
  // Données pour le graphique de temps de réponse
  const timeData = {
    labels: ['0-3s', '3-10s', '10-30s', '30s+'],
    datasets: [
      {
        label: 'Ouvertures par temps de réponse',
        data: [
          notification.stats.timeDistribution['0-3s'],
          notification.stats.timeDistribution['3-10s'],
          notification.stats.timeDistribution['10-30s'],
          notification.stats.timeDistribution['30s+']
        ],
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
    ],
  };
  
  // Données pour le graphique horaire
  const hourlyData = {
    labels: ['8h', '9h', '10h', '11h', '12h', '13h', '14h', '15h', '16h', '17h', '18h', '19h'],
    datasets: [
      {
        label: 'Nombre d\'ouvertures par heure',
        data: notification.stats.byHour,
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };
  
  return (
    <div className="w-full">
      {/* En-tête avec bouton de retour */}
      <div className="mb-6">
        <div className="flex items-center gap-4">
          <button 
            onClick={() => navigate('/admin/notifications')}
            className="bg-gris hover:bg-noir text-gray-300 p-2 rounded-lg transition-colors"
          >
            <FiArrowLeft size={20} />
          </button>
          <div>
            <h1 className="text-2xl font-bold text-white mb-1">Détails de la notification</h1>
            <p className="text-gray-400">Statistiques détaillées et performances</p>
          </div>
        </div>
      </div>
      
      {/* Carte de détails de la notification */}
      <div className="bg-gris rounded-xl shadow-lg overflow-hidden mb-8">
        <div className="p-6">
          <div className="flex flex-col md:flex-row md:items-start justify-between mb-4">
            <div>
              <div className="flex items-center gap-2 mb-2">
                <div className={`px-3 py-1 rounded-full text-sm ${
                  notification.type === 'info' ? 'bg-bleu bg-opacity-10 text-bleu' :
                  notification.type === 'alerte' ? 'bg-softRed bg-opacity-10 text-softRed' :
                  notification.type === 'important' ? 'bg-softYellow bg-opacity-10 text-softYellow' :
                  notification.type === 'promo' ? 'bg-softPurple bg-opacity-10 text-softPurple' :
                  'bg-vert bg-opacity-10 text-vert'
                }`}>
                  {notification.type.charAt(0).toUpperCase() + notification.type.slice(1)}
                </div>
                <div className="text-gray-400">
                  Cible: <span className="text-white capitalize">{notification.cible}</span>
                </div>
              </div>
              <h2 className="text-xl font-bold text-white mb-1">{notification.title}</h2>
              <p className="text-gray-300">{notification.body}</p>
            </div>
            <div className="mt-4 md:mt-0 text-right">
              <div className="text-gray-400">Créée le</div>
              <div className="text-white font-medium">{notification.dateCréation}</div>
              <div className="text-gray-400 mt-2">Par</div>
              <div className="text-white font-medium">{notification.envoyéPar}</div>
            </div>
          </div>
          
          <hr className="border-gray-700 my-4" />
          
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            <div className="bg-noir rounded-lg p-4">
              <div className="text-gray-400 text-sm">Destinataires</div>
              <div className="text-white text-xl font-bold">{notification.nombreDestinataires}</div>
            </div>
            <div className="bg-noir rounded-lg p-4">
              <div className="text-gray-400 text-sm">Taux de livraison</div>
              <div className="text-white text-xl font-bold">
                {Math.round((notification.stats.delivered / notification.nombreDestinataires) * 100)}%
              </div>
            </div>
            <div className="bg-noir rounded-lg p-4">
              <div className="text-gray-400 text-sm">Taux d'ouverture</div>
              <div className="text-white text-xl font-bold">
                {Math.round((notification.stats.opened / notification.stats.delivered) * 100)}%
              </div>
            </div>
            <div className="bg-noir rounded-lg p-4">
              <div className="text-gray-400 text-sm">Taux de clics</div>
              <div className="text-white text-xl font-bold">
                {Math.round((notification.stats.clicked / notification.stats.opened) * 100)}%
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Graphiques principaux */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
        <div className="bg-gris rounded-xl p-5 shadow-lg">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-semibold text-white">Livraison</h3>
            <div className="bg-vert bg-opacity-20 p-2 rounded-full">
              <FiCheckCircle className="text-vert" />
            </div>
          </div>
          
          <div className="w-full h-52 flex items-center justify-center">
            <Pie data={deliveryData} options={{ maintainAspectRatio: false }} />
          </div>
          
          <div className="grid grid-cols-3 gap-2 mt-4">
            <div className="bg-noir p-3 rounded-lg text-center">
              <div className="text-gray-400 text-xs">Livraison réussie</div>
              <div className="text-white font-semibold">{notification.stats.delivered}</div>
            </div>
            <div className="bg-noir p-3 rounded-lg text-center">
              <div className="text-gray-400 text-xs">Échecs</div>
              <div className="text-white font-semibold">{notification.stats.failed}</div>
            </div>
            <div className="bg-noir p-3 rounded-lg text-center">
              <div className="text-gray-400 text-xs">Temps moyen</div>
              <div className="text-white font-semibold">{notification.stats.deliveryTime.avg}s</div>
            </div>
          </div>
        </div>
        
        <div className="bg-gris rounded-xl p-5 shadow-lg">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-semibold text-white">Engagement</h3>
            <div className="bg-bleu bg-opacity-20 p-2 rounded-full">
              <FiUsers className="text-bleu" />
            </div>
          </div>
          
          <div className="w-full h-52 flex items-center justify-center">
            <Pie data={engagementData} options={{ maintainAspectRatio: false }} />
          </div>
          
          <div className="grid grid-cols-4 gap-2 mt-4">
            <div className="bg-noir p-3 rounded-lg text-center">
              <div className="text-gray-400 text-xs">Ouvertes</div>
              <div className="text-white font-semibold">{notification.stats.opened}</div>
            </div>
            <div className="bg-noir p-3 rounded-lg text-center">
              <div className="text-gray-400 text-xs">Cliquées</div>
              <div className="text-white font-semibold">{notification.stats.clicked}</div>
            </div>
            <div className="bg-noir p-3 rounded-lg text-center">
              <div className="text-gray-400 text-xs">Fermées</div>
              <div className="text-white font-semibold">{notification.stats.dismissed}</div>
            </div>
            <div className="bg-noir p-3 rounded-lg text-center">
              <div className="text-gray-400 text-xs">Ignorées</div>
              <div className="text-white font-semibold">{notification.stats.ignored}</div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Graphiques secondaires */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        <div className="bg-gris rounded-xl p-5 shadow-lg">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-semibold text-white">Plateformes</h3>
            <div className="bg-softPurple bg-opacity-20 p-2 rounded-full">
              <FiSmartphone className="text-softPurple" />
            </div>
          </div>
          
          <div className="w-full h-52 flex items-center justify-center">
            <Pie data={platformData} options={{ maintainAspectRatio: false }} />
          </div>
          
          <div className="grid grid-cols-3 gap-2 mt-4">
            <div className="bg-noir p-3 rounded-lg text-center">
              <div className="text-gray-400 text-xs">Android</div>
              <div className="text-white font-semibold">{notification.stats.platforms.android}</div>
            </div>
            <div className="bg-noir p-3 rounded-lg text-center">
              <div className="text-gray-400 text-xs">iOS</div>
              <div className="text-white font-semibold">{notification.stats.platforms.ios}</div>
            </div>
            <div className="bg-noir p-3 rounded-lg text-center">
              <div className="text-gray-400 text-xs">Web</div>
              <div className="text-white font-semibold">{notification.stats.platforms.web}</div>
            </div>
          </div>
        </div>
        
        <div className="bg-gris rounded-xl p-5 shadow-lg">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-semibold text-white">Appareils</h3>
            <div className="bg-softYellow bg-opacity-20 p-2 rounded-full">
              <FiMonitor className="text-softYellow" />
            </div>
          </div>
          
          <div className="w-full h-40 flex items-center justify-center">
            <Pie 
              data={{
                labels: ['Mobile', 'Desktop'],
                datasets: [{
                  data: [notification.stats.deviceTypes.mobile, notification.stats.deviceTypes.desktop],
                  backgroundColor: ['rgba(255, 99, 132, 0.6)', 'rgba(54, 162, 235, 0.6)'],
                  borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
                  borderWidth: 1,
                }]
              }} 
              options={{ maintainAspectRatio: false }}
            />
          </div>
          
          <div className="grid grid-cols-2 gap-2 mt-4">
            <div className="bg-noir p-3 rounded-lg text-center">
              <div className="text-gray-400 text-xs">Mobile</div>
              <div className="text-white font-semibold">{notification.stats.deviceTypes.mobile}</div>
              <div className="text-gray-400 text-xs">
                ({Math.round((notification.stats.deviceTypes.mobile / notification.stats.opened) * 100)}%)
              </div>
            </div>
            <div className="bg-noir p-3 rounded-lg text-center">
              <div className="text-gray-400 text-xs">Desktop</div>
              <div className="text-white font-semibold">{notification.stats.deviceTypes.desktop}</div>
              <div className="text-gray-400 text-xs">
                ({Math.round((notification.stats.deviceTypes.desktop / notification.stats.opened) * 100)}%)
              </div>
            </div>
          </div>
        </div>
        
        <div className="bg-gris rounded-xl p-5 shadow-lg">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-semibold text-white">Temps de réponse</h3>
            <div className="bg-softRed bg-opacity-20 p-2 rounded-full">
              <FiClock className="text-softRed" />
            </div>
          </div>
          
          <div className="w-full h-52 flex items-center justify-center">
            <Bar 
              data={timeData} 
              options={{ 
                maintainAspectRatio: false,
                scales: {
                  y: {
                    beginAtZero: true,
                    ticks: {
                      color: 'rgba(255, 255, 255, 0.7)'
                    },
                    grid: {
                      color: 'rgba(255, 255, 255, 0.1)'
                    }
                  },
                  x: {
                    ticks: {
                      color: 'rgba(255, 255, 255, 0.7)'
                    },
                    grid: {
                      display: false
                    }
                  }
                }
              }} 
            />
          </div>
          
          <div className="grid grid-cols-3 gap-2 mt-4">
            <div className="bg-noir p-3 rounded-lg text-center">
              <div className="text-gray-400 text-xs">Min</div>
              <div className="text-white font-semibold">{notification.stats.deliveryTime.min}s</div>
            </div>
            <div className="bg-noir p-3 rounded-lg text-center">
              <div className="text-gray-400 text-xs">Max</div>
              <div className="text-white font-semibold">{notification.stats.deliveryTime.max}s</div>
            </div>
            <div className="bg-noir p-3 rounded-lg text-center">
              <div className="text-gray-400 text-xs">Moyen</div>
              <div className="text-white font-semibold">{notification.stats.deliveryTime.avg}s</div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Graphique d'activité par heure */}
      <div className="bg-gris rounded-xl p-5 shadow-lg mb-8">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-lg font-semibold text-white">Activité par heure</h3>
          <div className="bg-bleu bg-opacity-20 p-2 rounded-full">
            <FiBarChart2 className="text-bleu" />
          </div>
        </div>
        
        <div className="w-full h-64 flex items-center justify-center">
          <Bar 
            data={hourlyData} 
            options={{ 
              maintainAspectRatio: false,
              plugins: {
                title: {
                  display: false
                },
              },
              scales: {
                y: {
                  beginAtZero: true,
                  ticks: {
                    color: 'rgba(255, 255, 255, 0.7)'
                  },
                  grid: {
                    color: 'rgba(255, 255, 255, 0.1)'
                  }
                },
                x: {
                  ticks: {
                    color: 'rgba(255, 255, 255, 0.7)'
                  },
                  grid: {
                    display: false
                  }
                }
              }
            }} 
          />
        </div>
        
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-4">
          <div className="bg-noir p-3 rounded-lg text-center">
            <div className="text-gray-400 text-xs">Heure de pointe</div>
            <div className="text-white font-semibold">12h</div>
          </div>
          <div className="bg-noir p-3 rounded-lg text-center">
            <div className="text-gray-400 text-xs">Ouvertures en pointe</div>
            <div className="text-white font-semibold">86</div>
          </div>
          <div className="bg-noir p-3 rounded-lg text-center">
            <div className="text-gray-400 text-xs">Temps moyen d'ouverture</div>
            <div className="text-white font-semibold">4.2s</div>
          </div>
          <div className="bg-noir p-3 rounded-lg text-center">
            <div className="text-gray-400 text-xs">Taux de conversion</div>
            <div className="text-white font-semibold">74%</div>
          </div>
        </div>
      </div>
      
      {/* Recommandations */}
      <div className="bg-gris rounded-xl p-5 shadow-lg mb-8">
        <h3 className="text-lg font-semibold text-white mb-4">Recommandations</h3>
        
        <div className="space-y-4">
          <div className="bg-noir p-4 rounded-lg">
            <div className="font-medium text-white mb-1">Optimisez le moment d'envoi</div>
            <div className="text-gray-300 text-sm">
              D'après vos statistiques, les utilisateurs sont plus réactifs entre 11h et 13h. Considérez planifier vos prochaines notifications dans cette plage horaire.
            </div>
          </div>
          
          <div className="bg-noir p-4 rounded-lg">
            <div className="font-medium text-white mb-1">Amélioration du contenu</div>
            <div className="text-gray-300 text-sm">
              Les notifications avec des titres plus courts (moins de 40 caractères) ont un taux d'ouverture 23% plus élevé. Ce message pourrait être raccourci pour plus d'impact.
            </div>
          </div>
          
          <div className="bg-noir p-4 rounded-lg">
            <div className="font-medium text-white mb-1">Conversion des utilisateurs mobiles</div>
            <div className="text-gray-300 text-sm">
              Les utilisateurs mobiles représentent 80% de votre audience pour cette notification. Assurez-vous que l'expérience après-clic est optimisée pour les appareils mobiles.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationDetails;