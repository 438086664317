import "./App.css";

import React, { useEffect } from "react";

import Navigator from "./Navigator";
import { AppProvider } from "./contexts/AuthContext";
import InstallPWA from "./contexts/InstallPWA";
import NotificationManager from "./components/NotificationManager";

function App() {
  // Supprime les marges et espaces blancs lors du chargement de l'application
  useEffect(() => {
    // Reset les marges et paddings pour éviter les espaces blancs
    document.body.style.margin = "0";
    document.body.style.padding = "0";
    document.body.style.backgroundColor = "#1C1C1C";
    document.body.style.minHeight = "100vh";
    document.body.style.width = "100%";
    document.body.style.overflowX = "hidden";
    document.body.style.position = "relative";
    document.body.style.top = "0";
    document.body.style.left = "0";
    
    document.documentElement.style.margin = "0";
    document.documentElement.style.padding = "0";
    document.documentElement.style.backgroundColor = "#1C1C1C";
    document.documentElement.style.minHeight = "100vh";
    document.documentElement.style.width = "100%";
    document.documentElement.style.overflowX = "hidden";
    
    const rootElement = document.getElementById("root");
    if (rootElement) {
      rootElement.style.margin = "0";
      rootElement.style.padding = "0";
      rootElement.style.position = "relative";
      rootElement.style.backgroundColor = "#1C1C1C";
      rootElement.style.minHeight = "100vh";
      rootElement.style.width = "100%";
      rootElement.style.overflowX = "hidden";
      rootElement.style.top = "0";
      rootElement.style.left = "0";
    }
    
    // Ajout d'une règle CSS inline pour résoudre les problèmes d'espacement
    const style = document.createElement('style');
    style.textContent = `
      html, body, #root {
        margin: 0 !important;
        padding: 0 !important;
        background-color: #1C1C1C !important;
        min-height: 100vh !important;
        width: 100% !important;
        overflow-x: hidden !important;
        position: relative !important;
      }
      
      /* Pour cibler l'espace noir en haut */
      body::before, #root::before {
        content: "";
        display: none !important;
      }
      
      /* Désactiver les marges et animations qui pourraient causer le problème */
      * {
        box-sizing: border-box !important;
      }
    `;
    document.head.appendChild(style);
  }, []);

  return (
    <AppProvider>
      <div className="app-container" style={{ 
        margin: 0, 
        padding: 0, 
        minHeight: "100vh", 
        backgroundColor: "#1C1C1C",
        position: "relative",
        top: 0,
        left: 0,
        width: "100%",
        overflowX: "hidden"
      }}>
        <NotificationManager />
        <Navigator />
        <InstallPWA />
      </div>
    </AppProvider>
  );
}

export default App;