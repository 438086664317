import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faHistory, 
  faExclamationCircle, 
  faArrowCircleDown, 
  faArrowCircleUp 
} from "@fortawesome/free-solid-svg-icons";
import InfosContext from "../../contexts/InfosAppProvider";
import PaginatedTransactions from "./PaginatedTransactions";
import PaginateHistoriques from "./PaginateHistoriques";
import RetraitsWithPagination from "./RetraitsWithPagination";
import EmptyTransactions from "../EmptyTransactions";

const MyTabs = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isLoading, setIsLoading] = useState(false);

  const { transactions } = useContext(InfosContext);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleRefresh = () => {
    setIsLoading(true);
    // Simuler un rechargement
    setTimeout(() => {
      setIsLoading(false);
    }, 800);
  };

  // Tab data avec icônes et empty states
  const tabContents = [
    { 
      id: "pending", 
      title: isMobile ? "Attente" : "En attente", 
      icon: faExclamationCircle,
      component: transactions && Object.keys(transactions).length > 0 
        ? <PaginatedTransactions transactions={transactions} itemsPerPage={isMobile ? 5 : 8} /> 
        : <EmptyTransactions type="pending" onRefresh={handleRefresh} />,
      color: "text-bleu",
      count: transactions ? Object.values(transactions).filter(t => t && t.statut === "en cours").length : 0
    },
    { 
      id: "deposits", 
      title: "Dépôts", 
      icon: faArrowCircleUp,
      component: transactions && Object.keys(transactions).length > 0 
        ? <PaginatedTransactions 
            transactions={Object.entries(transactions)
              .filter(([_, t]) => t && t.type === "depot")
              .reduce((acc, [k, v]) => ({...acc, [k]: v}), {})} 
            itemsPerPage={isMobile ? 5 : 8} /> 
        : <EmptyTransactions type="approved" onRefresh={handleRefresh} />,
      color: "text-vert",
      count: transactions ? Object.values(transactions).filter(t => t && t.type === "depot").length : 0
    },
    { 
      id: "withdrawals", 
      title: "Retraits", 
      icon: faArrowCircleDown,
      component: transactions && Object.keys(transactions).length > 0 
        ? <RetraitsWithPagination transactions={transactions} itemsPerPage={isMobile ? 5 : 8} /> 
        : <EmptyTransactions type="withdrawals" onRefresh={handleRefresh} />,
      color: "text-[#AA1D22FF]",
      count: transactions ? Object.values(transactions).filter(t => t && t.type === "retrait").length : 0
    },
    { 
      id: "history", 
      title: isMobile ? "Histo." : "Historique", 
      icon: faHistory,
      component: transactions && Object.keys(transactions).length > 0 
        ? <PaginateHistoriques transactions={transactions} itemsPerPage={isMobile ? 5 : 8} /> 
        : <EmptyTransactions type="history" onRefresh={handleRefresh} />,
      color: "text-gray-500",
      count: transactions ? Object.keys(transactions).length : 0
    }
  ];

  // Indicateur de chargement
  const LoadingState = () => (
    <div className="flex justify-center items-center p-10">
      <div className="w-10 h-10 border-2 border-bleu border-t-transparent rounded-full animate-spin"></div>
    </div>
  );

  return (
    <div className="mb-16 md:mb-10">
      {/* Custom tabs avec badges - optimisé pour mobile */}
      <div className="flex bg-gris rounded-xl p-1 mb-4 mx-auto max-w-lg overflow-x-auto hide-scrollbar">
        {tabContents.map((tab, index) => (
          <button
            key={tab.id}
            className={`flex-1 min-w-[80px] md:min-w-[100px] py-2 px-2 md:px-3 rounded-lg text-center text-xs md:text-sm font-medium transition-all ${
              activeTab === index 
                ? `bg-gris/80 ${tab.color}` 
                : "text-gray-400 hover:text-white hover:bg-gris/40"
            }`}
            onClick={() => {
              // Ne pas montrer de chargement pour un simple changement d'onglet
              setActiveTab(index);
            }}
          >
            <div className="flex items-center justify-center space-x-1">
              <FontAwesomeIcon icon={tab.icon} className={activeTab === index ? tab.color : ""} />
              <span>{tab.title}</span>
              {tab.count > 0 && !isMobile && (
                <span className={`text-xs px-1.5 py-0.5 rounded-full bg-gris ${tab.color}`}>
                  {tab.count}
                </span>
              )}
              {/* Sur mobile, afficher juste un point si count > 0 */}
              {tab.count > 0 && isMobile && (
                <span className={`w-2 h-2 rounded-full ${tab.color.replace('text-', 'bg-')}`}></span>
              )}
            </div>
          </button>
        ))}
      </div>

      {/* Tab content avec animation */}
      <div className="text-white transition-all duration-300 px-1 md:px-0">
        {isLoading ? <LoadingState /> : tabContents[activeTab].component}
      </div>
    </div>
  );
};

export default MyTabs;
