import React, { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { FiSettings, FiEdit, FiCode, FiPercent, FiCreditCard, FiDollarSign } from "react-icons/fi";
import InfosContext from "../../contexts/InfosAppProvider";

const Index = () => {
  const {
    infosAdmin,
    setInfosAdmin,
    userInfos,
    setUserInfos,
    transactions,
    setTransactions,
  } = useContext(InfosContext);

  const navigate = useNavigate();

  // Fonction pour formater les montants en liste
  const formatMontantList = (montantString) => {
    if (!montantString) return "Non défini";
    
    const montants = montantString.split(',');
    return montants.map(montant => montant.trim()).join(', ');
  };

  return (
    <div className="w-full">
      {/* En-tête de la page */}
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-white mb-2">Configuration du système</h1>
        <p className="text-gray-400">Gérez les paramètres globaux de l'application</p>
      </div>

      {/* Carte principale des paramètres */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="lg:col-span-2 bg-gris rounded-xl shadow-lg overflow-hidden">
          <div className="p-6 border-b border-gray-700">
            <div className="flex items-center justify-between">
              <h2 className="text-xl font-semibold text-white flex items-center">
                <FiSettings className="mr-2 text-bleu" /> Paramètres généraux
              </h2>
              <button 
                onClick={() => navigate("/admin/configuration/edit")} 
                className="px-4 py-2 bg-bleu hover:bg-blue-600 text-white rounded-lg text-sm flex items-center transition-colors"
              >
                <FiEdit className="mr-2" /> Modifier
              </button>
            </div>
          </div>
          
          <div className="p-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Code Promo */}
              <div className="bg-noir rounded-lg p-5 flex items-start">
                <div className="bg-bleu bg-opacity-20 p-3 rounded-full mr-4">
                  <FiCode className="text-bleu text-xl" />
                </div>
                <div>
                  <h3 className="text-gray-400 text-sm mb-1">Code Promo</h3>
                  <p className="text-white text-xl font-medium">{infosAdmin.codePromo || "Non défini"}</p>
                </div>
              </div>
              
              {/* Mode */}
              <div className="bg-noir rounded-lg p-5 flex items-start">
                <div className="bg-softPurple bg-opacity-20 p-3 rounded-full mr-4">
                  <FiCreditCard className="text-softPurple text-xl" />
                </div>
                <div>
                  <h3 className="text-gray-400 text-sm mb-1">Mode de traitement</h3>
                  <p className="text-white text-xl font-medium capitalize">{infosAdmin.mode || "Non défini"}</p>
                </div>
              </div>
              
              {/* Taux affiliation dépôt */}
              <div className="bg-noir rounded-lg p-5 flex items-start">
                <div className="bg-vert bg-opacity-20 p-3 rounded-full mr-4">
                  <FiPercent className="text-vert text-xl" />
                </div>
                <div>
                  <h3 className="text-gray-400 text-sm mb-1">Taux d'affiliation (dépôt)</h3>
                  <p className="text-white text-xl font-medium">{infosAdmin.taux_affiliation_depot || "0"}%</p>
                </div>
              </div>
              
              {/* Minimum retrait affiliation */}
              <div className="bg-noir rounded-lg p-5 flex items-start">
                <div className="bg-softRed bg-opacity-20 p-3 rounded-full mr-4">
                  <FiDollarSign className="text-softRed text-xl" />
                </div>
                <div>
                  <h3 className="text-gray-400 text-sm mb-1">Minimum retrait d'affiliation</h3>
                  <p className="text-white text-xl font-medium">{infosAdmin.minimum_retrait_affiliation || "0"} F</p>
                </div>
              </div>
            </div>
            
            {/* Montants disponibles */}
            <div className="mt-6 bg-noir rounded-lg p-5">
              <h3 className="text-gray-400 text-sm mb-3">Montants disponibles</h3>
              <div className="flex flex-wrap gap-2">
                {infosAdmin.montant 
                  ? infosAdmin.montant.split(',').map((montant, index) => (
                    <span 
                      key={index} 
                      className="bg-gris px-3 py-1 rounded-full text-white text-sm"
                    >
                      {montant.trim()} F
                    </span>
                  ))
                  : <span className="text-gray-500">Aucun montant défini</span>
                }
              </div>
            </div>
          </div>
        </div>
        
        {/* Carte d'information */}
        <div className="bg-gris rounded-xl shadow-lg overflow-hidden">
          <div className="p-6 border-b border-gray-700">
            <h2 className="text-xl font-semibold text-white">Informations</h2>
          </div>
          
          <div className="p-6">
            <p className="text-gray-300 mb-4">
              Cette section vous permet de configurer les paramètres globaux du système, notamment :
            </p>
            
            <ul className="space-y-2 text-gray-400">
              <li className="flex items-start">
                <span className="bg-bleu bg-opacity-20 p-1 rounded-full mr-2 mt-1">
                  <FiCode className="text-bleu text-xs" />
                </span>
                <span>Code promotionnel utilisé pour les parrainages</span>
              </li>
              <li className="flex items-start">
                <span className="bg-softPurple bg-opacity-20 p-1 rounded-full mr-2 mt-1">
                  <FiCreditCard className="text-softPurple text-xs" />
                </span>
                <span>Mode de traitement des transactions (manuel/automatique)</span>
              </li>
              <li className="flex items-start">
                <span className="bg-vert bg-opacity-20 p-1 rounded-full mr-2 mt-1">
                  <FiPercent className="text-vert text-xs" />
                </span>
                <span>Taux appliqué pour le programme d'affiliation</span>
              </li>
              <li className="flex items-start">
                <span className="bg-softRed bg-opacity-20 p-1 rounded-full mr-2 mt-1">
                  <FiDollarSign className="text-softRed text-xs" />
                </span>
                <span>Montant minimum pour effectuer un retrait d'affiliation</span>
              </li>
            </ul>
            
            <div className="mt-6 pt-6 border-t border-gray-700">
              <p className="text-gray-400 text-sm">
                Note : Les modifications apportées aux paramètres seront appliquées immédiatement à l'ensemble du système.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;