import React from "react";
import ballon from "../ressources/images/ballon.png";
import basket from "../ressources/images/basket.png";
import autre from "../ressources/images/autre.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFutbol, faBasketball, faFootballBall, faTrophy } from "@fortawesome/free-solid-svg-icons";

import "./spa.css";

export default function SplashScreen() {
  return (
    <div className="min-h-screen flex flex-col relative overflow-hidden">
      <div className="h-screen w-full bg-gradient-to-br from-blue-900 via-black to-blue-800 flex items-center justify-center">
        {/* Effet de particules animées */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="stars-container">
            {[...Array(20)].map((_, i) => (
              <div key={i} className="star" style={{
                top: `${Math.random() * 100}%`,
                left: `${Math.random() * 100}%`,
                animationDuration: `${3 + Math.random() * 7}s`
              }}></div>
            ))}
          </div>
        </div>
        
        {/* Cercle lumineux en arrière-plan */}
        <div className="absolute w-96 h-96 rounded-full bg-blue-600 filter blur-3xl opacity-20 animate-pulse-slow"></div>
        
        {/* Ballons animés */}
        <div className="relative flex space-x-10 z-10">
          <div className="animate-bounce-custom delay-0 transform hover:scale-110 transition-transform">
            <div className="w-16 h-16 rounded-full bg-gradient-to-br from-blue-400 to-blue-700 flex items-center justify-center shadow-lg shadow-blue-500/50">
              <img
                src={basket}
                alt="Basketball"
                loading="lazy"
                className="w-10 h-10 object-contain"
              />
            </div>
          </div>
          
          <div className="animate-bounce-custom delay-200 transform hover:scale-110 transition-transform">
            <div className="w-16 h-16 rounded-full bg-gradient-to-br from-orange-400 to-red-700 flex items-center justify-center shadow-lg shadow-orange-500/50">
              <img
                src={ballon}
                alt="Football"
                loading="lazy"
                className="w-10 h-10 object-contain"
              />
            </div>
          </div>
          
          <div className="animate-bounce-custom delay-400 transform hover:scale-110 transition-transform">
            <div className="w-16 h-16 rounded-full bg-gradient-to-br from-green-400 to-green-700 flex items-center justify-center shadow-lg shadow-green-500/50">
              <img
                src={autre}
                alt="Tennis"
                loading="lazy"
                className="w-10 h-10 object-contain"
              />
            </div>
          </div>
        </div>
        
        {/* Titre avec effet */}
        <div className="absolute z-20 text-center">
          <h1 className="text-5xl font-bold animate-fade-in mt-32 bg-clip-text text-transparent bg-gradient-to-r from-white via-blue-300 to-white drop-shadow-lg">
            Payfor<span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-blue-600">Bet</span>
          </h1>
          
          <p className="text-white text-lg mt-4 opacity-80 animate-fade-in-delayed">
            La plateforme de paris sportifs la plus rapide
          </p>
        </div>
        
        {/* Éléments décoratifs supplémentaires */}
        <div className="absolute bottom-10 left-10 animate-spin-slow opacity-30">
          <FontAwesomeIcon icon={faTrophy} className="text-yellow-500 text-4xl" />
        </div>
        
        <div className="absolute top-10 right-10 animate-spin-slow opacity-30">
          <FontAwesomeIcon icon={faFutbol} className="text-white text-4xl" />
        </div>
      </div>
    </div>
  );
}
