// Fonction pour ajouter un assistant 
const addAssistant = async (nom, email, whatsapp, pays, token) => {
    try {
        // URL complète avec le backend
        const baseURL = "https://8ad3-197-234-221-167.ngrok-free.app/api/";
        const url = `${baseURL}ajouter-assitant?nom=${encodeURIComponent(nom)}&email=${encodeURIComponent(email)}&whatsapp=${encodeURIComponent(whatsapp)}&pays=${encodeURIComponent(pays)}`;
        
        console.log("Envoi de la requête à:", url);
        
        // Configuration de la requête
        const requestOptions = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            // Important: désactiver credentials pour éviter le problème CORS
            credentials: 'omit'
        };
        
        // Envoi de la requête
        const response = await fetch(url, requestOptions);
        
        // Vérifier si la réponse est du JSON
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
            const data = await response.json();
            
            // Vérification de la réponse
            if (response.ok) {
                return { success: true, message: data.message };
            } else {
                return { success: false, message: data.message || "Erreur lors de l'ajout de l'assistant" };
            }
        } else {
            // Si ce n'est pas du JSON, c'est probablement une erreur HTML
            const textResponse = await response.text();
            console.error("Réponse non-JSON reçue:", textResponse.substring(0, 100) + "...");
            return { 
                success: false, 
                message: `Erreur de serveur: La réponse n'est pas au format JSON. Statut: ${response.status}`
            };
        }
    } catch (error) {
        console.error("Erreur lors de l'ajout de l'assistant:", error);
        return { success: false, message: `Erreur de connexion: ${error.message}` };
    }
};

export default addAssistant;