import React, { useState } from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";

// Import du fichier CSS de thème pour l'admin
import "../admin/components/admin-theme.css";

// Composants du layout
import Header from "../admin/components/Header";
import SideBar from "../admin/components/SideBar";

// Pages de l'administration
import Dashbord from "../admin/dashbord/Dashbord";
import IndexAdmin from "../admin/IndexAdmin";
import { AdminInfosAppProvider } from "../contexts/admin/AdminInfosAppProvider";

// Pages de transactions
import ListTransactions from "../admin/transactions/ListTransactions";

// Pages de gestion des agents
import ListAgents from "../admin/agents/ListAgents";
import AgentDetails from "../admin/agents/AgentDetails";
import AddAgent from "../admin/agents/AddAgent";

// Pages de gestion des assistants
import ListAssistants from "../admin/assistants/ListAssistants";
import AssistantDetails from "../admin/assistants/AssistantDetails";
import AddAssistant from "../admin/assistants/AddAssistant";

// Pages de gestion des clients
import ListClients from "../admin/clients/ListClients";
import ClientDetails from "../admin/clients/ClientDetails";

// Pages de configuration
import Index from "../admin/configuration/Index";
import PromoEditor from "../admin/configuration/PromoEditor";

// Pages de retraits
import RetraitListe from "../admin/retraits/RetraitListe";
import RetraitDetail from "../admin/retraits/RetraitDetail";

// Nouvelles pages ajoutées
import CaisseList from "../admin/caisses/CaisseList";
import CaissierList from "../admin/caissiers/CaissierList";
import AttributionList from "../admin/attributions/AttributionList";
import BonusList from "../admin/bonus/BonusList";
import RetraitBonusList from "../admin/bonus/RetraitBonusList";
import BonusPaidList from "../admin/bonus/BonusPaidList";

// Pages des notifications
import NotificationsList from "../admin/notifications/NotificationsList";
import CreateNotification from "../admin/notifications/create";
import ScheduleNotification from "../admin/notifications/create/schedule";
import NotificationAnalytics from "../admin/notifications/statistics/NotificationAnalytics";
import NotificationTemplates from "../admin/notifications/statistics/NotificationTemplates";
import NotificationDetails from "../admin/notifications/statistics/NotificationDetails";

// Layout principal avec Header et Sidebar
const AdminLayout = ({ isSidebarOpen, toggleSidebar }) => {
  return (
    <div className="w-full overflow-x-hidden bg-noir min-h-screen m-0 p-0">
      {/* Header toujours affiché */}
      <Header toggleSidebar={toggleSidebar} />

      <div className="flex m-0 p-0">
        {/* Sidebar : affichée/masquée selon l'état */}
        <div
          className={`fixed top-0 left-0 h-full pt-16 z-30 lg:z-0 lg:static lg:pt-0 lg:w-72 ${
            isSidebarOpen ? "block" : "hidden"
          } lg:block m-0 p-0 transition-all duration-300 ease-in-out`}
        >
          <SideBar isSidebarOpen={isSidebarOpen} />
        </div>
        
        {/* Overlay pour fermer le sidebar sur mobile */}
        {isSidebarOpen && (
          <div 
            className="fixed inset-0 bg-black bg-opacity-50 z-20 lg:hidden" 
            onClick={toggleSidebar}
          />
        )}

        {/* Contenu principal */}
        <div className="w-full p-4 lg:p-6 bg-noir text-white min-h-[calc(100vh-60px)]">
          {/* Outlet indique où afficher les routes enfants */}
          <Outlet />
        </div>
      </div>
    </div>
  );
};

// Routes de l'administration
const AdminRoutes = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  return (
    <AdminInfosAppProvider className="admin-theme">
      <div className="m-0 p-0 overflow-hidden">
        <Routes>
          {/* Route principale avec AdminLayout */}
          <Route
            path="/"
            element={
              <AdminLayout
                isSidebarOpen={isSidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
          >
            {/* Route par défaut : Dashboard */}
            <Route index element={<Dashbord />} />

            {/* Route vers la page Transactions */}
            <Route path="transactions" element={<ListTransactions />} />

            {/* Routes de gestion des agents */}
            <Route path="listAgents" element={<ListAgents />} />
            <Route path="agentDetails/:id" element={<AgentDetails />} />
            <Route path="addAgent" element={<AddAgent />} />
            
            {/* Routes de gestion des assistants */}
            <Route path="listAssistants" element={<ListAssistants />} />
            <Route path="assistantDetails/:id" element={<AssistantDetails />} />
            <Route path="addAssistant" element={<AddAssistant />} />

            {/* Routes de gestion des clients */}
            <Route path="listClients" element={<ListClients />} />
            <Route path="clientDetails/:id" element={<ClientDetails />} />

            {/* Routes de configuration */}
            <Route path="configuration" element={<Index />} />
            <Route path="configuration/edit" element={<PromoEditor />} />

            {/* Routes financières */}
            <Route path="liste-retrait" element={<RetraitListe />} />
            <Route path="retrait-detail/:id" element={<RetraitDetail />} />

            {/* Nouvelles routes pour les fonctionnalités demandées */}
            <Route path="caisses" element={<CaisseList />} />
            <Route path="caissiers" element={<CaissierList />} />
            <Route path="attribution-caisses" element={<AttributionList />} />
            <Route path="bonus-mensuel" element={<BonusList />} />
            <Route path="bonus-retraits" element={<RetraitBonusList />} />
            <Route path="bonus-payes" element={<BonusPaidList />} />
            <Route path="notifications" element={<NotificationsList />} />
            <Route path="notifications/create" element={<CreateNotification />} />
            <Route path="notifications/schedule" element={<ScheduleNotification />} />
            <Route path="notifications/analytics" element={<NotificationAnalytics />} />
            <Route path="notifications/templates" element={<NotificationTemplates />} />
            <Route path="notifications/details/:id" element={<NotificationDetails />} />
          </Route>
        </Routes>
      </div>
    </AdminInfosAppProvider>
  );
};

export default AdminRoutes;