import React, { useState, useEffect, useContext } from 'react';
import InfosContextAdmin from '../../contexts/admin/AdminInfosAppProvider';
import axios from 'axios';
import { FiCheckCircle, FiAlertTriangle, FiFilter, FiRefreshCw, FiCheck } from 'react-icons/fi';
import { IoMdStats } from 'react-icons/io';
import { FaMoneyBillWave } from 'react-icons/fa';
import { formatDate, formatCurrency } from '../../utils/formatters';

// Configuration de l'API
const API_URL = process.env.REACT_APP_API_URL || 'https://3e0f-197-234-221-167.ngrok-free.app';

/**
 * Composant pour gérer les demandes de retrait de bonus par l'administrateur
 */
const RetraitBonusList = () => {
  const adminContext = useContext(InfosContextAdmin);
  const [demandesEnAttente, setDemandesEnAttente] = useState([]);
  const [statistiques, setStatistiques] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [recherche, setRecherche] = useState('');
  const [selectedDemandes, setSelectedDemandes] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const [traitementEnCours, setTraitementEnCours] = useState(false);
  const [filtreActif, setFiltreActif] = useState(false);
  const [afficherStats, setAfficherStats] = useState(false);

  // Récupérer le token d'authentification
  const getToken = () => {
    return localStorage.getItem('token');
  };

  // Configurer Axios avec le token
  const api = axios.create({
    baseURL: API_URL,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getToken()}`
    }
  });

  // Récupérer les demandes en attente faites par les clients
  const fetchDemandesEnAttente = async () => {
    try {
      setLoading(true);
      
      const response = await api.get('/api/v1/admin/retraits/en-attente-par-client');
      
      if (response.data.success) {
        setDemandesEnAttente(response.data.demandesEnAttente || []);
      } else {
        setErrorMessage(response.data.message || 'Erreur lors de la récupération des demandes');
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des demandes:', error);
      setErrorMessage('Impossible de récupérer les demandes. Veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };

  // Récupérer les statistiques
  const fetchStatistiques = async () => {
    try {
      const response = await api.get('/api/v1/admin/retraits/statistiques');
      
      if (response.data.success) {
        setStatistiques(response.data.statistiques || null);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des statistiques:', error);
    }
  };

  // Charger les données lors du chargement du composant
  useEffect(() => {
    fetchDemandesEnAttente();
    fetchStatistiques();
  }, []);

  // Filtrer les demandes en fonction de la recherche
  const demandesFiltrees = demandesEnAttente.filter(demande => {
    if (!recherche) return true;
    
    const rechercheLower = recherche.toLowerCase();
    
    return (
      (demande.nomUser && demande.nomUser.toLowerCase().includes(rechercheLower)) ||
      (demande.numeroReception && demande.numeroReception.includes(recherche)) ||
      (demande.montant && demande.montant.toString().includes(recherche))
    );
  });

  // Gérer la sélection d'une demande
  const handleSelectDemande = (id) => {
    if (selectedDemandes.includes(id)) {
      setSelectedDemandes(selectedDemandes.filter(demandeId => demandeId !== id));
    } else {
      setSelectedDemandes([...selectedDemandes, id]);
    }
  };

  // Gérer la sélection de toutes les demandes
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedDemandes([]);
    } else {
      setSelectedDemandes(demandesFiltrees.map(demande => demande.id));
    }
    
    setSelectAll(!selectAll);
  };

  // Approuver une demande spécifique
  const handleApprouverDemande = (id) => {
    setSelectedDemandes([id]);
    setConfirmAction('single');
    setShowConfirmModal(true);
  };

  // Approuver toutes les demandes sélectionnées
  const handleApprouverSelection = () => {
    if (selectedDemandes.length === 0) {
      setErrorMessage('Veuillez sélectionner au moins une demande');
      return;
    }
    
    setConfirmAction('multiple');
    setShowConfirmModal(true);
  };

  // Approuver toutes les demandes en attente
  const handleApprouverTout = () => {
    if (demandesFiltrees.length === 0) {
      setErrorMessage('Aucune demande en attente');
      return;
    }
    
    setConfirmAction('all');
    setShowConfirmModal(true);
  };

  // Confirmer l'approbation
  const confirmerApprobation = async () => {
    try {
      setTraitementEnCours(true);
      
      // En développement, ajouter l'ID admin pour les tests
      const idAdmin = adminContext?.id || 'admin-default';
      
      let response;
      
      if (confirmAction === 'single') {
        // Approuver une seule demande
        response = await api.post('/api/v1/admin/retraits/approuver', {
          idDemande: selectedDemandes[0],
          idAdmin
        });
        
        if (response.data.success) {
          setSuccessMessage(`Demande approuvée avec succès. Montant: ${response.data.montant} F`);
        } else {
          setErrorMessage(response.data.message || 'Erreur lors de l\'approbation');
        }
      } else if (confirmAction === 'multiple') {
        // Approuver les demandes sélectionnées une par une
        let approuvees = 0;
        let montantTotal = 0;
        
        for (const idDemande of selectedDemandes) {
          const result = await api.post('/api/v1/admin/retraits/approuver', {
            idDemande,
            idAdmin
          });
          
          if (result.data.success) {
            approuvees++;
            montantTotal += parseFloat(result.data.montant) || 0;
          }
        }
        
        setSuccessMessage(`${approuvees}/${selectedDemandes.length} demandes approuvées. Montant total: ${montantTotal.toFixed(2)} F`);
      } else if (confirmAction === 'all') {
        // Approuver toutes les demandes en attente
        response = await api.post('/api/v1/admin/retraits/approuver-tout', {
          idAdmin
        });
        
        if (response.data.success) {
          setSuccessMessage(`${response.data.nombreApprouve}/${response.data.nombreTotal} demandes approuvées. Montant total: ${response.data.totalApprouve.toFixed(2)} F`);
        } else {
          setErrorMessage(response.data.message || 'Erreur lors de l\'approbation');
        }
      }
      
      // Réinitialiser la sélection
      setSelectedDemandes([]);
      setSelectAll(false);
      
      // Rafraîchir les données
      fetchDemandesEnAttente();
      fetchStatistiques();
    } catch (error) {
      console.error('Erreur lors de l\'approbation:', error);
      setErrorMessage('Impossible de traiter l\'approbation. Veuillez réessayer.');
    } finally {
      setTraitementEnCours(false);
      setShowConfirmModal(false);
    }
  };

  // Mettre le nom en évidence avec la recherche
  const highlightText = (text, search) => {
    if (!search) return text;
    
    const parts = text.split(new RegExp(`(${search})`, 'gi'));
    
    return (
      <>
        {parts.map((part, index) => 
          part.toLowerCase() === search.toLowerCase() 
            ? <span key={index} className="bg-yellow-400 text-black font-medium">{part}</span> 
            : part
        )}
      </>
    );
  };

  return (
    <div className="max-w-7xl mx-auto">
      {successMessage && (
        <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-4 rounded">
          <div className="flex items-center">
            <FiCheckCircle className="h-6 w-6 mr-2" />
            <p>{successMessage}</p>
            <button 
              className="ml-auto text-green-700 hover:text-green-900" 
              onClick={() => setSuccessMessage(null)}
            >
              &times;
            </button>
          </div>
        </div>
      )}
      
      {errorMessage && (
        <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4 rounded">
          <div className="flex items-center">
            <FiAlertTriangle className="h-6 w-6 mr-2" />
            <p>{errorMessage}</p>
            <button 
              className="ml-auto text-red-700 hover:text-red-900" 
              onClick={() => setErrorMessage(null)}
            >
              &times;
            </button>
          </div>
        </div>
      )}
      
      <div className="bg-noir shadow-lg rounded-lg overflow-hidden">
        <div className="p-6 border-b border-gray-700">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-semibold text-white flex items-center">
              <FaMoneyBillWave className="mr-2 text-jaune" />
              Demandes de Retrait de Bonus (Faites par les clients)
            </h2>
            
            <button 
              onClick={() => setAfficherStats(!afficherStats)}
              className="flex items-center bg-bleu text-white px-3 py-2 rounded-md hover:bg-blue-600 transition-colors"
            >
              <IoMdStats className="mr-1" />
              {afficherStats ? 'Masquer stats' : 'Afficher stats'}
            </button>
          </div>
          
          {afficherStats && statistiques && (
            <div className="mt-4 grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="bg-gris p-4 rounded-lg">
                <h3 className="text-gray-400 text-sm mb-1">En attente</h3>
                <div className="flex justify-between items-end">
                  <div className="text-2xl font-bold text-white">{statistiques.enAttente}</div>
                  <div className="text-lg text-jaune">{formatCurrency(statistiques.montantEnAttente)}</div>
                </div>
              </div>
              
              <div className="bg-gris p-4 rounded-lg">
                <h3 className="text-gray-400 text-sm mb-1">Approuvés</h3>
                <div className="flex justify-between items-end">
                  <div className="text-2xl font-bold text-white">{statistiques.approuve}</div>
                  <div className="text-lg text-vert">{formatCurrency(statistiques.montantApprouve)}</div>
                </div>
              </div>
              
              <div className="bg-gris p-4 rounded-lg">
                <h3 className="text-gray-400 text-sm mb-1">Total</h3>
                <div className="flex justify-between items-end">
                  <div className="text-2xl font-bold text-white">{statistiques.total}</div>
                  <div className="text-lg text-white">{formatCurrency(statistiques.montantTotal)}</div>
                </div>
              </div>
            </div>
          )}
        </div>
        
        <div className="p-6">
          <div className="mb-4 flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
            <div className="relative w-full md:w-1/3">
              <input
                type="text"
                placeholder="Rechercher un client ou un numéro..."
                value={recherche}
                onChange={(e) => setRecherche(e.target.value)}
                className="pl-4 pr-10 py-2 w-full bg-gris border border-gray-700 rounded-md focus:ring-bleu focus:border-bleu text-white"
              />
              <button 
                className={`absolute right-3 top-1/2 transform -translate-y-1/2 ${filtreActif ? 'text-bleu' : 'text-gray-400'}`}
                onClick={() => setFiltreActif(!filtreActif)}
              >
                <FiFilter />
              </button>
            </div>
            
            <div className="flex space-x-2 w-full md:w-auto">
              <button 
                className="flex items-center justify-center bg-gris text-white px-4 py-2 rounded-md hover:bg-gray-700 transition-colors"
                onClick={fetchDemandesEnAttente}
              >
                <FiRefreshCw className="mr-2" />
                Actualiser
              </button>
              
              <button 
                className="flex items-center justify-center bg-jaune text-noir px-4 py-2 rounded-md hover:bg-yellow-500 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                onClick={handleApprouverSelection}
                disabled={selectedDemandes.length === 0}
              >
                <FiCheck className="mr-2" />
                Approuver ({selectedDemandes.length})
              </button>
              
              <button 
                className="flex items-center justify-center bg-vert text-noir px-4 py-2 rounded-md hover:bg-green-500 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                onClick={handleApprouverTout}
                disabled={demandesFiltrees.length === 0}
              >
                <FiCheck className="mr-2" />
                Tout approuver ({demandesFiltrees.length})
              </button>
            </div>
          </div>
          
          {loading ? (
            <div className="flex justify-center items-center p-8">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-bleu"></div>
            </div>
          ) : (
            <>
              {demandesFiltrees.length === 0 ? (
                <div className="bg-gris text-center p-8 rounded-lg">
                  <p className="text-gray-400">Aucune demande de retrait en attente</p>
                </div>
              ) : (
                <div className="overflow-x-auto">
                  <table className="min-w-full">
                    <thead className="bg-gris">
                      <tr>
                        <th className="p-3 text-left">
                          <input
                            type="checkbox"
                            checked={selectAll}
                            onChange={handleSelectAll}
                            className="rounded text-bleu focus:ring-bleu"
                          />
                        </th>
                        <th className="p-3 text-left text-xs font-semibold text-gray-400">Client</th>
                        <th className="p-3 text-left text-xs font-semibold text-gray-400">Numéro MoMo</th>
                        <th className="p-3 text-left text-xs font-semibold text-gray-400">Montant</th>
                        <th className="p-3 text-left text-xs font-semibold text-gray-400">Période</th>
                        <th className="p-3 text-left text-xs font-semibold text-gray-400">Date de demande</th>
                        <th className="p-3 text-left text-xs font-semibold text-gray-400">Actions</th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-700">
                      {demandesFiltrees.map(demande => (
                        <tr key={demande.id} className={`
                          ${selectedDemandes.includes(demande.id) ? 'bg-blue-900 bg-opacity-30' : ''}
                          hover:bg-gray-800 transition-colors
                        `}>
                          <td className="p-3">
                            <input
                              type="checkbox"
                              checked={selectedDemandes.includes(demande.id)}
                              onChange={() => handleSelectDemande(demande.id)}
                              className="rounded text-bleu focus:ring-bleu"
                            />
                          </td>
                          <td className="p-3 whitespace-nowrap">
                            {highlightText(demande.nomUser || 'N/A', recherche)}
                          </td>
                          <td className="p-3 whitespace-nowrap">
                            {highlightText(demande.numeroReception || 'N/A', recherche)}
                          </td>
                          <td className="p-3 whitespace-nowrap font-semibold text-jaune">
                            {formatCurrency(demande.montant)}
                          </td>
                          <td className="p-3 whitespace-nowrap">
                            {demande.mois}/{demande.annee}
                          </td>
                          <td className="p-3 whitespace-nowrap">
                            {formatDate(demande.dateCreation || demande.dateDemande)}
                          </td>
                          <td className="p-3">
                            <button 
                              className="bg-vert text-noir px-3 py-1 rounded-md hover:bg-green-500 transition-colors text-sm font-medium"
                              onClick={() => handleApprouverDemande(demande.id)}
                            >
                              Approuver
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      
      {/* Modal de confirmation */}
      {showConfirmModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="fixed inset-0 bg-black bg-opacity-75" onClick={() => !traitementEnCours && setShowConfirmModal(false)}></div>
          
          <div className="relative bg-gris rounded-lg max-w-md w-full p-6 z-10">
            <h3 className="text-xl font-semibold text-white mb-4">Confirmation d'approbation</h3>
            
            <div className="mb-6">
              {confirmAction === 'single' && (
                <p className="text-gray-300">
                  Êtes-vous sûr de vouloir approuver cette demande de retrait de bonus ?
                </p>
              )}
              
              {confirmAction === 'multiple' && (
                <p className="text-gray-300">
                  Êtes-vous sûr de vouloir approuver les {selectedDemandes.length} demandes sélectionnées faites par les clients ?
                </p>
              )}
              
              {confirmAction === 'all' && (
                <p className="text-gray-300">
                  Êtes-vous sûr de vouloir approuver toutes les {demandesFiltrees.length} demandes en attente faites par les clients ?
                </p>
              )}
              
              <div className="mt-4 bg-red-900 bg-opacity-30 border-l-4 border-red-500 p-4 text-red-300">
                <p className="text-sm font-medium">
                  <FiAlertTriangle className="inline-block mr-2" />
                  Cette action est irréversible et déclenchera le paiement des montants aux clients.
                </p>
              </div>
            </div>
            
            <div className="flex justify-end space-x-3">
              <button 
                className="bg-gray-600 text-white px-4 py-2 rounded-md hover:bg-gray-500 transition-colors disabled:opacity-50"
                onClick={() => setShowConfirmModal(false)}
                disabled={traitementEnCours}
              >
                Annuler
              </button>
              
              <button 
                className="bg-vert text-noir px-6 py-2 rounded-md hover:bg-green-500 transition-colors font-medium disabled:opacity-50 flex items-center"
                onClick={confirmerApprobation}
                disabled={traitementEnCours}
              >
                {traitementEnCours ? (
                  <>
                    <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-noir" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Traitement...
                  </>
                ) : (
                  'Confirmer'
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RetraitBonusList;