import axios from "axios";

//{{baseURL}}authentification?idUser=10&code=193695

// Function to send a login code to the specified email
export default async function authentification(id, code) {
  const baseURL = process.env.REACT_APP_BASE_URL || "https://31f2-197-234-221-233.ngrok-free.app/api"; // Utilise la variable d'environnement
  try {
    const res = await axios.get(`${baseURL}/authentification`, {
      params: { idUser: id, code: code },
    });
    //alert(res.data);

    //   alert(res);Process the response (if needed)
    if (!res.data.error) {
      // alert("pas erreur");
      console.log("Code sent successfully to:", id);
      return res.data; // Return the response data (or do further processing)
    } else {
      //  alert(" erreur 1");
      console.log("Failed to send the code:", res.data.message);
      return null; // Or handle the failure as needed
    }
  } catch (error) {
    //alert("erreur");
    console.error("Error occurred while sending code:", error.message);
    return null; // Handle errors here (network issues, etc.)
  }
}
///

// Usage example:
