import axios from "axios"; // Importation d'Axios

export async function updateRetraitAffiliation(
  idRetrait,
  nouveauStatut,
  token
) {
  // Utiliser la valeur de .env (https://8ad3-197-234-221-167.ngrok-free.app/api/)
  let baseURL = process.env.REACT_APP_BASE_URL || "https://8ad3-197-234-221-167.ngrok-free.app/api/";
  
  // S'assurer que l'URL se termine par un slash
  if (!baseURL.endsWith('/')) {
    baseURL += '/';
  }
  
  // Construire l'URL complète
  const fullURL = `${baseURL}approuver-retrait-affiliation`;
  
  console.log("URL complète de l'API:", fullURL);
  console.log("Envoi de la requête avec les paramètres:", {
    idRetrait: idRetrait,
    nouveauStatut: nouveauStatut,
    tokenPresent: !!token,
    tokenLength: token ? token.length : 0
  });

  try {
    console.log("Début de la requête API");
    const response = await axios.get(fullURL, {
      params: {
        idRetrait: idRetrait,
        nouveauStatut: nouveauStatut,
      },
      headers: {
        Authorization: `Bearer ${token}`, // Ajout du token d'authentification
      },
      // Augmenter le timeout
      timeout: 15000,
    });
    console.log("Requête API terminée avec succès");

    console.log("Réponse:", response.data);
    return response.data; // Manipuler la réponse si nécessaire
  } catch (error) {
    console.error("Erreur lors de la requête:", error);
    
    // Afficher plus de détails sur l'erreur pour le débogage
    console.error("Détails complets de l'erreur:", error);
    
    if (error.response) {
      // La requête a été faite et le serveur a répondu avec un code d'état
      // qui n'est pas dans la plage 2xx
      console.error("Données d'erreur:", error.response.data);
      console.error("Statut d'erreur:", error.response.status);
      console.error("En-têtes d'erreur:", error.response.headers);
      
      // Si le serveur a renvoyé un message d'erreur, renvoyer ce message
      if (error.response.data && error.response.data.message) {
        return { 
          error: true, 
          message: error.response.data.message,
          status: error.response.status
        };
      }
    } else if (error.request) {
      // La requête a été faite mais aucune réponse n'a été reçue
      console.error("Requête sans réponse:", error.request);
      return {
        error: true,
        message: "Aucune réponse reçue du serveur. Vérifiez votre connexion internet ou contactez l'administrateur.",
        originalError: "NO_RESPONSE"
      };
    } else {
      // Une erreur s'est produite lors de la configuration de la requête
      console.error("Erreur de configuration:", error.message);
      return {
        error: true,
        message: "Erreur de configuration de la requête: " + error.message,
        originalError: error.message
      };
    }
    
    // Retourner un objet d'erreur au lieu de propager l'exception
    return { 
      error: true, 
      message: "Une erreur est survenue lors de la communication avec le serveur. Veuillez réessayer.",
      originalError: error.message
    };
  }
}

/*
// Exemple d'utilisation
const token = "votre_token_ici"; // Remplacez par le token réel
updateRetraitAffiliation("sggdhd", "terminé", token)
  .then((data) => console.log("Succès:", data))
  .catch((err) => console.error("Erreur:", err));
  */
