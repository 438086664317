import axios from "axios";

export const ajouterTransaction = async (
  type,
  idUser,
  idCompte1xbet,
  codeRetrait,
  moyenPaiement,
  montant,
  mode,
  idTransactionMobile,
  token, // Token passé en paramètre
  numeroMtn = null // Paramètre optionnel pour le numéro MTN
) => {
  const baseURL = process.env.REACT_APP_BASE_URL || "https://back.payforbet.com/api/"; // Utilise l'URL de l'environnement ou l'URL par défaut
  try {
    // Construire l'URL avec les paramètres
    const endpoint = `${baseURL}ajouter-transactions`;

    const params = {
      type: type,
      idUser: idUser,
      idCompte1xbet: idCompte1xbet,
      codeRetrait: codeRetrait,
      moyenPaiement: moyenPaiement,
      montant: montant,
      mode: mode,
      idTransactionMobile: idTransactionMobile,
    };

    // Ajouter numeroMtn pour les modes semi-automatique et automatique si fourni
    if ((mode === "semi" || mode === "automatique") && numeroMtn) {
      params.numeroMtn = numeroMtn;
    }

    // Configurer les headers pour inclure le token
    const headers = {
      Authorization: `Bearer ${token}`, // Assurez-vous que le token est bien formaté avec "Bearer"
    };

    console.log("Envoi de la requête avec paramètres:", params);

    // Effectuer la requête GET avec les paramètres dans l'URL et les headers
    const response = await axios.get(endpoint, {
      params,
      headers, // Ajout des headers ici
    });

    // Traiter la réponse
    console.log("Réponse du serveur:", response.data);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la requête:", error);
    
    // Si l'erreur provient d'une réponse (ex. statut 400, 500)
    if (error.response) {
      console.error("Données d'erreur:", error.response.data);
      throw error.response.data; // Renvoyer les données d'erreur
    }
    
    // Sinon, propager l'erreur complète
    throw error;
  }
};
