import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faEye, faSpinner, faCheck, faTimes, faClock } from '@fortawesome/free-solid-svg-icons';

export default function RecentTransactions({ transactions, onViewAll }) {
  // Prendre les 5 dernières transactions
  const recentTransactions = transactions?.slice(0, 5) || [];

  const getStatusIcon = (status) => {
    switch (status) {
      case 'SUCCESSFUL': return faCheck;
      case 'FAILED': return faTimes;
      case 'PENDING': return faClock;
      default: return faSpinner;
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'SUCCESSFUL': return 'text-vert';
      case 'FAILED': return 'text-softRed';
      case 'PENDING': return 'text-softYellow';
      default: return 'text-gray-400';
    }
  };

  const getStatusBgColor = (status) => {
    switch (status) {
      case 'SUCCESSFUL': return 'bg-vert/10';
      case 'FAILED': return 'bg-softRed/10';
      case 'PENDING': return 'bg-softYellow/10';
      default: return 'bg-gray-700';
    }
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  if (!transactions) {
    return (
      <div className="bg-gris rounded-xl p-5 shadow-lg animate-pulse">
        <div className="flex justify-between items-center mb-6">
          <div className="h-6 w-40 bg-bleu/20 rounded"></div>
          <div className="h-6 w-20 bg-bleu/20 rounded"></div>
        </div>
        
        <div className="space-y-4">
          {[1, 2, 3].map(i => (
            <div key={i} className="flex gap-4">
              <div className="w-10 h-10 rounded-full bg-noir/40"></div>
              <div className="flex-1">
                <div className="h-4 bg-noir/40 rounded w-1/3 mb-2"></div>
                <div className="h-3 bg-noir/40 rounded w-1/2"></div>
              </div>
              <div className="h-6 w-12 bg-noir/40 rounded"></div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gris rounded-xl overflow-hidden shadow-lg border border-bleu/10">
      <div className="px-6 py-5 border-b border-bleu/10">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-bold text-white">Transactions récentes</h2>
          <button 
            onClick={onViewAll}
            className="px-4 py-2 rounded-lg bg-bleu/10 text-bleu hover:bg-bleu/20 transition flex items-center"
          >
            <span>Tout voir</span>
            <FontAwesomeIcon icon={faEye} className="ml-2" />
          </button>
        </div>
      </div>
      
      {recentTransactions.length > 0 ? (
        <div className="divide-y divide-bleu/10">
          {recentTransactions.map(transaction => (
            <div key={transaction.id} className="px-6 py-4 hover:bg-noir/30 transition">
              <div className="flex items-center">
                <div className={`w-10 h-10 rounded-full flex items-center justify-center mr-4 ${transaction.type === 'depot' ? 'bg-bleu/10' : 'bg-vert/10'}`}>
                  <FontAwesomeIcon 
                    icon={transaction.type === 'depot' ? faArrowDown : faArrowUp} 
                    className={transaction.type === 'depot' ? 'text-bleu' : 'text-vert'} 
                  />
                </div>
                
                <div className="flex-1">
                  <div className="flex justify-between items-start mb-1">
                    <div>
                      <span className="text-white font-medium">
                        {transaction.type === 'depot' ? 'Dépôt' : 'Retrait'}
                      </span>
                      <div className="text-sm text-gray-400">
                        {transaction.partyId}
                      </div>
                    </div>
                    
                    <div className="flex flex-col items-end">
                      <span className="text-white font-bold">
                        {transaction.amount} {transaction.webhookData?.currency || 'XOF'}
                      </span>
                      <div className={`inline-flex items-center text-xs px-2 py-1 rounded-full ${getStatusBgColor(transaction.status)} ${getStatusColor(transaction.status)}`}>
                        <FontAwesomeIcon icon={getStatusIcon(transaction.status)} className="mr-1" />
                        {transaction.status}
                      </div>
                    </div>
                  </div>
                  
                  <div className="text-xs text-gray-500 mt-1">
                    {formatDate(transaction.updatedAt || transaction.createdAt)}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="p-8 text-center">
          <div className="w-16 h-16 bg-noir/20 rounded-full flex items-center justify-center mx-auto mb-4">
            <FontAwesomeIcon icon={faSpinner} className="text-gray-500 text-xl" />
          </div>
          <p className="text-gray-400">Aucune transaction récente à afficher</p>
        </div>
      )}
    </div>
  );
}