// AuthContext.js
import React, { createContext, useState, useEffect, useContext } from "react";
import { Routes, Route, Outlet, Link, useNavigate } from "react-router-dom";

import getUserInfos from "../../requtes/clients/getUserInfos";

//import AuthContext from "./AuthContext";
import Splash from "../Splash";

const InfosContextAdmin = createContext();

export function AdminInfosAppProvider({ children }) {
  // const { isSplashVisible, setSplashVisible } = useContext(AuthContext);

  const navigate = useNavigate(); // Utilisation de useNavigate

  const [searchTerm, setSearchTerm] = useState("");

  const [open, setOpen] = useState(false);

  const [role, setRole] = useState("admin");

  const [listeUser, setListeUser] = useState(null);

  const [listeCaisses, setListeCaisses] = useState(null);

  const [allRetraitAffiliations, setAllRetraitAffiliations] = useState(null);

  const [selectedStatuses, setSelectedStatuses] = useState([
    "en cours",
    "réussi",
    "échoué",
  ]); // Tous les statuts sont sélectionnés par défaut

  // Fonction pour retirer un statut
  const removeStatus = (statusToRemove) => {
    setSelectedStatuses((prevStatuses) =>
      prevStatuses.filter((status) => status !== statusToRemove)
    );
  };

  // Fonction pour ajouter un statut
  const addStatus = (newStatus) => {
    setSelectedStatuses((prevStatuses) => {
      if (!prevStatuses.includes(newStatus)) {
        return [...prevStatuses, newStatus];
      }
      return prevStatuses; // Ne rien faire si le statut est déjà présent
    });
  };

  const [userInfos, setUserInfos] = useState(null);
  const [infosAdmin, setInfosAdmin] = useState(null);
  const [isSplashVisible, setSplashVisible] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [notificationsStats, setNotificationsStats] = useState(null);

  const [transactions, setTransactions] = useState(null);
  const [id, setId] = useState(10);
  const [token, setToken] = useState(
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRlQ3JlYXRpb24iOjE3Mjc4NzIzMDIyODgsImVtYWlsIjoiYXJuZWxsYXdzb243QGdtYWlsLmNvbSIsImlkQ29tcHRlMXhiZXQiOjU1NTg1LCJpZFBhcnJhaW4iOiI1Iiwibm9tIjoiQXJuZWwgTEFXU09OIiwibm9tTW9tb1JlY2VwdGlvbiI6NTU1NTUsIm51bWVyb01vbW9SZWNlcHRpb24iOiIiLCJudW1lcm9Nb21vUmVjaGFyZ2UiOiIiLCJudW1lcm9SZXRyYWl0QWZmaWxpYXRpb24iOiIiLCJwYXlzIjoiQmVuaW4iLCJyb2xlIjoiYWRtaW4iLCJzb2xkZSI6MCwic29sZGVEaXNwb25pYmxlIjowLCJzb2xkZVRvdGFsIjowLCJzdGF0dXQiOiJhY3RpZiIsInRhdXhBZmZpbGlhdGlvbkRlcG90IjowLCJ0YXV4QWZmaWxpYXRpb25SZXRyYWl0IjowLCJ0b3RhbFJldHJhaXQiOjAsIndoYXRzYXBwIjoiMjI5NjI4NjE1NzEiLCJpYXQiOjE3Mjg3MTI3NDV9.GrHTSfDgqUbRQWkJf1SYxazFJZ2kM1xMj3Ak8PYf0KI"
  );

  const [update, setUpatde] = useState(null);

  const [isAuthenticated, setIsAuthenticated] = useState(true);

  useEffect(() => {
    const idUser = localStorage.getItem("idUser");
    const isAuthenticatedlocal = localStorage.getItem("isAuthenticated");
    const tokenlocal = localStorage.getItem("token");

    //alert("idUser est " + idUser);

    // S'assurer que localStorage a bien la bonne valeur
    // localStorage.setItem("isAuthenticated", "1");

    //console.log("Valeur récupérée depuis localStorage:", isAuthenticatedlocal);

    // Mettre à jour l'état correctement si isAuthenticatedlocal est "1"
    if (isAuthenticatedlocal == "1") {
      setIsAuthenticated(true); // Utiliser un booléen pour plus de clarté
      setToken(tokenlocal);
      setId(idUser);
    } else {
      // setIsAuthenticated(false);
    }
  }, []);

  // useEffect dépendant de isAuthenticated
  useEffect(() => {
    if (isAuthenticated !== null) {
      // alert("État de isAuthenticated: " + isAuthenticated + role);

      //   navigate("/signup");

      if (isAuthenticated && role == "admin") {
        // Appeler une fonction pour récupérer les infos de l'utilisateur
        // alert("id est" + id + "token" + token);
        getUserInfos(id, token)
          .then((response) => {
            //  alert("reponse est " + response);
            if (!response.error) {
              //  alert("reponse est " + response.infos.userInfos);
              setInfosAdmin(response.infos.infosAdmin);
              setUserInfos(response.infos.userInfos);
              setTransactions(response.infos.transactions);
              setListeUser(response.infos.listeUser);

              // Traiter les notifications si disponibles
              if (response.infos.notifications) {
                setNotifications(response.infos.notifications);
              }

              // Traiter les statistiques de notifications si disponibles
              if (response.infos.notificationsStats) {
                setNotificationsStats(response.infos.notificationsStats);
              }

              setAllRetraitAffiliations(response.infos.allRetraitAffiliations);

              //  allRetraitAffiliations;s

              /// alert(JSON.stringify(response.infos.transactions, null, 19)); // Pretty print with 2 spaces of indentation

              setListeCaisses(response.infos.listeCaisses);
              setSplashVisible(false);
            }
          })
          .catch((error) => {
            setSplashVisible(false);
            alert("erreur" + error);

            ///  alert("Erreur : " + error.message || error);

            //  alert("Erreur: " + error.message); // Affiche le message d'erreur principal
            console.error(
              "Erreur lors de la récupération des informations utilisateur:",
              error
            );
          });
      } else {
        navigate("/signup");
        setSplashVisible(false);
      }
    }
  }, [isAuthenticated, id, token]);

  return (
    <InfosContextAdmin.Provider
      value={{
        id,
        setId,
        token,
        setToken,
        infosAdmin,
        setInfosAdmin,
        userInfos,
        setUserInfos,
        transactions,
        setTransactions,
        isSplashVisible,
        setSplashVisible,
        selectedStatuses,
        removeStatus,
        addStatus,
        open,
        setOpen,
        searchTerm,
        setSearchTerm,
        listeUser,
        setListeUser,
        listeCaisses,
        setListeCaisses,
        allRetraitAffiliations,
        setAllRetraitAffiliations,
        notifications,
        setNotifications,
        notificationsStats,
        setNotificationsStats
      }}
    >
      {isSplashVisible ? <Splash /> : children}
    </InfosContextAdmin.Provider>
  );
}

export default InfosContextAdmin;
