import React, { useEffect, useState } from 'react';
import { requestPermissionAndGetToken, onMessageListener, showNotification } from '../contexts/FirebaseMessaging';
import { Toast, ToastContainer } from 'react-bootstrap';

// Clé VAPID de Firebase pour les notifications push
const VAPID_KEY = "BGFgOky4BDvlgaCoFiAG1Q2uhc1TSCmwvp0vUN1UXSiuWADXagaNiDT3hRYIlRUQS564BRNL03CxzRr1lxCloE4";

function NotificationManager() {
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: '', body: '' });
  const [isTokenFound, setTokenFound] = useState(false);

  useEffect(() => {
    // Vérifier si nous sommes dans un navigateur
    if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
      // Demander la permission et obtenir le token
      requestPermissionAndGetToken(VAPID_KEY).then(token => {
        if (token) {
          setTokenFound(true);
          
          // Si vous voulez sauvegarder le token dans localStorage pour y accéder facilement
          localStorage.setItem('fcmToken', token);
        }
      });
    }
  }, []);

  // Configurer l'écouteur de message pour les notifications lorsque l'application est au premier plan
  useEffect(() => {
    let messageListener = null;
    
    // Configurer l'écouteur de message
    const setupMessageListener = async () => {
      try {
        // onMessageListener() retourne une Promise qui se résout avec le payload du message
        messageListener = onMessageListener()
          .then(payload => {
            // Message reçu lorsque l'application est ouverte
            console.log('Notification reçue au premier plan:', payload);
            
            // Mettre à jour l'état pour afficher une notification toast
            setNotification({
              title: payload.notification.title,
              body: payload.notification.body
            });
            
            // Afficher le toast
            setShow(true);
            
            // Créer également une notification système si l'utilisateur a accordé la permission
            if (Notification.permission === 'granted') {
              showNotification(
                payload.notification.title,
                payload.notification.body,
                payload.notification.icon || '/logo192p.png',
                payload.data?.url || '/'
              );
            }
            
            // Réinstaller l'écouteur pour le prochain message
            setupMessageListener();
          })
          .catch(err => {
            console.log('Erreur de notification au premier plan: ', err);
            // Réinstaller l'écouteur même en cas d'erreur
            setupMessageListener();
          });
      } catch (error) {
        console.error('Erreur lors de la configuration de l\'écouteur de message:', error);
      }
    };
    
    // Démarrer l'écoute des messages
    setupMessageListener();
    
    // Nettoyer l'écouteur de message
    return () => {
      // Rien à nettoyer ici, car onMessageListener ne retourne pas de fonction de nettoyage
      messageListener = null; // Eviter les fuites de mémoire
    };
  }, []);

  return (
    <ToastContainer position="top-end" className="p-3" style={{ zIndex: 9999 }}>
      <Toast onClose={() => setShow(false)} show={show} delay={6000} autohide>
        <Toast.Header>
          <img src="/logo192p.png" className="rounded me-2" alt="PayForBet" height="20" />
          <strong className="me-auto">{notification.title}</strong>
          <small>à l'instant</small>
        </Toast.Header>
        <Toast.Body>{notification.body}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
}

export default NotificationManager;