import React, { useState, useContext, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import InfosContext from "../contexts/InfosAppProvider";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
  faArrowDown,
  faCheck,
  faExclamation,
  faClock,
  faCopy,
  faSpinner
} from "@fortawesome/free-solid-svg-icons";

const InfosTransaction = () => {
  const navigate = useNavigate();
  const { id, idTran } = useParams();
  
  // Décoder les paramètres d'URL
  const decodedId = decodeURIComponent(id);
  const decodedIdTran = decodeURIComponent(idTran);
  
  console.log("Paramètres d'URL décodés:", { decodedId, decodedIdTran });
  
  const {
    userInfos,
    transactions = {},
    listeUser = {},
    token,
    fetchUserInfos
  } = useContext(InfosContext) || {};

  const [infos, setInfos] = useState(null);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [textToCopy, setTextToCopy] = useState(decodedIdTran || "");
  const [copied, setCopied] = useState(false);
  
  // Effet pour rafraîchir les données au chargement de la page
  useEffect(() => {
    // Tenter de recharger les données utilisateur pour avoir les dernières transactions
    if (userInfos && userInfos.idUser && fetchUserInfos) {
      fetchUserInfos(userInfos.idUser, { showSplash: false, forceFetch: true });
    }
    
    setIsLoading(true);
    
    // Simulation d'un court délai pour afficher le chargement
    const timer = setTimeout(() => {
      findTransactionAndUser();
      setIsLoading(false);
    }, 500);
    
    return () => clearTimeout(timer);
  }, [transactions, decodedIdTran, decodedId]);
  
  // Fonction pour trouver la transaction et l'utilisateur associé
  const findTransactionAndUser = () => {
    console.log("Recherche de la transaction...");
    console.log("Transactions disponibles:", Object.keys(transactions).length);
    
    // Tentative de récupération de la transaction
    let foundTransaction = null;
    
    // Vérifier si la transaction existe directement avec son ID comme clé
    if (transactions && decodedIdTran in transactions) {
      console.log("Transaction trouvée avec la clé directe:", decodedIdTran);
      foundTransaction = transactions[decodedIdTran];
    }
    // Sinon chercher dans toutes les transactions par leur propriété idTransaction
    else if (transactions && Object.keys(transactions).length > 0) {
      // Déboguer les transactions pour voir leur structure
      const transactionKeys = Object.keys(transactions);
      if (transactionKeys.length > 0) {
        console.log("Exemple de transaction:", transactions[transactionKeys[0]]);
      }
      
      // Rechercher la transaction par l'ID exact
      for (const key in transactions) {
        // Vérification stricte de l'ID de transaction
        if (
          transactions[key] && 
          (transactions[key].idTransaction === decodedIdTran || key === decodedIdTran)
        ) {
          console.log("Transaction trouvée par correspondance idTransaction:", key);
          foundTransaction = transactions[key];
          break;
        }
      }
      
      // Si toujours pas trouvé, essayer une recherche partielle 
      // (parfois les ID peuvent être tronqués ou transformés)
      if (!foundTransaction) {
        for (const key in transactions) {
          if (
            transactions[key] && 
            ((transactions[key].idTransaction && transactions[key].idTransaction.includes(decodedIdTran)) || 
             key.includes(decodedIdTran))
          ) {
            console.log("Transaction trouvée par correspondance partielle:", key);
            foundTransaction = transactions[key];
            break;
          }
        }
      }
    }
    
    // Si aucune transaction n'a été trouvée, utiliser des données fictives
    if (!foundTransaction) {
      console.log("Aucune transaction trouvée avec l'ID:", decodedIdTran);
      
      foundTransaction = {
        idCompte1xbet: "N/A",
        idTransaction: decodedIdTran,
        idTransactionMobile: "N/A",
        idUser: decodedId,
        montant: 0,
        moyenPaiement: "N/A",
        numeroRetraitMomo: "N/A",
        nomMtnMomo: "N/A",
        statut: "en cours",
        type: "depot",
        dateHeure: Date.now()
      };
    }
    
    // Mise à jour de l'état avec la transaction trouvée
    setInfos(foundTransaction);
    console.log("Transaction détaillée:", foundTransaction);
    
    // Sécuriser l'accès à idUser
    const transactionUserId = foundTransaction && foundTransaction.idUser ? foundTransaction.idUser : decodedId;
    console.log("ID utilisateur de la transaction:", transactionUserId);
    
    // Tenter de trouver l'utilisateur associé
    let foundUser = null;
    if (listeUser && Object.keys(listeUser).length > 0 && transactionUserId) {
      foundUser = Object.values(listeUser).find(u => u && u.id == transactionUserId);
      if (foundUser) {
        console.log("Utilisateur trouvé dans la liste des utilisateurs:", foundUser.id);
      }
    }
    
    // Si l'utilisateur n'est pas trouvé, créer un utilisateur fictif
    if (!foundUser) {
      console.log("Utilisateur non trouvé, création d'un utilisateur fictif");
      foundUser = {
        id: transactionUserId,
        whatsapp: userInfos?.whatsapp || "N/A",
        nom: userInfos?.nom || "Utilisateur"
      };
    }
    
    // Mise à jour de l'état avec l'utilisateur trouvé
    setUser(foundUser);
    console.log("Utilisateur associé:", foundUser);
  };
  
  // La fonction notifySuccess a été intégrée directement dans le bouton

  // Fonction de retour en arrière
  const handleBack = () => {
    window.history.back(); // Retour à la page précédente
  };

  // Afficher un état de chargement
  if (isLoading) {
    return (
      <div className="min-h-screen flex justify-center items-center bg-black py-8 md:pl-8">
        <div className="flex flex-col items-center">
          <FontAwesomeIcon 
            icon={faSpinner} 
            spin 
            className="text-[#0087BFFF] text-4xl mb-4" 
          />
          <p className="text-white text-lg">Chargement des détails de la transaction...</p>
        </div>
      </div>
    );
  }

  // Protection contre les valeurs nulles
  if (!infos) {
    return (
      <div className="min-h-screen flex justify-center items-center bg-black py-8 md:pl-8">
        <div className="max-w-lg w-full bg-[#1C1C1CFF] text-white px-8 py-6 rounded-xl shadow-2xl space-y-6 border border-[#333333]">
          <button
            onClick={handleBack}
            className="mb-4 py-2 px-4 bg-[#272728FF] hover:bg-[#333333] text-white font-bold rounded-lg shadow-md transition-all duration-300"
          >
            ← Retour
          </button>
          <div className="text-center py-8">
            <FontAwesomeIcon icon={faExclamation} className="text-[#ff6f61] text-4xl mb-4" />
            <h2 className="text-xl font-bold mb-2">Transaction non trouvée</h2>
            <p className="text-gray-400">Les informations de cette transaction ne sont pas disponibles.</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className="min-h-screen flex justify-center bg-black py-8 md:pl-8">
        <div className="max-w-lg w-full bg-[#1C1C1CFF] text-white px-8 py-6 rounded-xl shadow-2xl space-y-6 border border-[#333333]">
          {/* Bouton Back */}
          <button
            onClick={handleBack}
            className="mb-4 py-2 px-4 bg-[#272728FF] hover:bg-[#333333] text-white font-bold rounded-lg shadow-md transition-all duration-300"
          >
            ← Retour
          </button>

          {/* En-tête avec statut */}
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-extrabold tracking-wide text-gray-200">
              Détails de la Transaction
            </h2>
            <div className={`px-3 py-1 rounded-full text-xs font-semibold 
              ${infos.statut === "en cours" 
                ? "bg-[#f9d57d]/20 text-[#f9d57d]" 
                : infos.statut === "terminé" || infos.statut === "valide" || infos.statut === "réussi"
                  ? "bg-[#28C281FF]/20 text-[#28C281FF]"
                  : "bg-[#ff6f61]/20 text-[#ff6f61]"}`}>
              {infos.statut === "en cours" ? "En attente" : 
               infos.statut === "terminé" || infos.statut === "valide" || infos.statut === "réussi" 
                 ? "Complété" : "Annulé"}
            </div>
          </div>

          {/* Indicateur de type */}
          <div className="flex items-center gap-3 bg-[#272728FF] p-4 rounded-lg">
            <div className={`w-12 h-12 rounded-full flex items-center justify-center
              ${infos.type === "depot" ? "bg-[#28C281FF]" : "bg-[#ff6f61]"}`}>
              <FontAwesomeIcon 
                icon={infos.type === "depot" ? faArrowDown : faArrowUp} 
                className="text-white text-lg"
              />
            </div>
            <div>
              <div className="text-gray-400 text-sm">Type</div>
              <div className="text-white text-lg font-bold capitalize">
                {infos.type === "depot" ? "Dépôt" : "Retrait"}
              </div>
            </div>
            <div className="ml-auto">
              <div className="text-gray-400 text-sm">Montant</div>
              <div className={`text-lg font-bold ${infos.type === "depot" ? "text-[#28C281FF]" : "text-[#ff6f61]"}`}>
                {infos.montant.toLocaleString()} FCFA
              </div>
            </div>
          </div>

          {/* Détails de la transaction */}
          <div className="space-y-4 text-gray-300 bg-[#272728FF] p-4 rounded-lg">
            <div className="flex justify-between items-center border-b border-[#333333] pb-2">
              <span className="font-semibold">ID Compte 1xbet:</span>
              <span className="text-white">{infos.idCompte1xbet || "N/A"}</span>
            </div>

            <div className="flex justify-between items-center border-b border-[#333333] pb-2">
              <span className="font-semibold">ID Transaction:</span>
              <span className="text-white">{infos.idTransaction || "N/A"}</span>
            </div>

            <div className="flex justify-between items-center border-b border-[#333333] pb-2">
              <span className="font-semibold">Numéro WhatsApp:</span>
              <span className="text-white">{user?.whatsapp || "N/A"}</span>
            </div>

            <div className="flex justify-between items-center border-b border-[#333333] pb-2">
              <span className="font-semibold">Moyen de Paiement:</span>
              <span className="text-white">{infos.moyenPaiement || "N/A"}</span>
            </div>

            <div className="flex justify-between items-center border-b border-[#333333] pb-2">
              <span className="font-semibold">Numéro MoMo:</span>
              <span className="text-white">{infos.numeroRetraitMomo || infos.numeroMtn || "N/A"}</span>
            </div>
            
            <div className="flex justify-between items-center border-b border-[#333333] pb-2">
              <span className="font-semibold">Type:</span>
              <span className="text-white capitalize">{infos.type || "N/A"}</span>
            </div>

            <div className="flex justify-between items-center border-b border-[#333333] pb-2">
              <span className="font-semibold">Statut:</span>
              <span className={`${
                infos.statut === "en cours" ? "text-[#f9d57d]" : 
                infos.statut === "terminé" || infos.statut === "réussi" || infos.statut === "valide" 
                  ? "text-[#28C281FF]" : "text-[#ff6f61]"
              }`}>
                {infos.statut ? (infos.statut.charAt(0).toUpperCase() + infos.statut.slice(1)) : "N/A"}
              </span>
            </div>

            <div className="flex justify-between items-center">
              <span className="font-semibold">Date:</span>
              <span className="text-white">
                {infos.dateHeure ? new Date(infos.dateHeure).toLocaleString('fr-FR') : "N/A"}
              </span>
            </div>
          </div>

          {/* Bouton de copie */}
          <button
            className="w-full py-3 mt-4 rounded-lg text-white font-semibold shadow-md transition-all duration-300 bg-[#0087BFFF] hover:bg-[#0075a5] flex items-center justify-center gap-2"
            onClick={() => {
              navigator.clipboard.writeText(infos.idTransaction || idTran || "");
              setCopied(true);
              toast.success("Code copié", {
                position: "top-center",
                autoClose: 2000,
              });
              setTimeout(() => setCopied(false), 2000);
            }}
          >
            <FontAwesomeIcon icon={copied ? faCheck : faCopy} />
            <span>{copied ? "ID copié!" : "Copier ID transaction"}</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default InfosTransaction;
